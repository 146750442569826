import React from 'react';
import Latex from 'react-latex';
import closeImg from '../../images/actions/close.svg';
import { ImageUtility } from '../../utility';



const LiveHistoryQuizQuestion = (props: {
    question: {
        id: string,
        value: string,
        imagePath: string,
        answers: Array<LiveHistoryQuestionAnswer>,
        image_path: string,
    },
    setShow: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    return (
        <div className="col-6 h-100 p-2" id="question-container">
            <div className="question-name-layout h-100">

                <img
                    className="quiz-close can-click"
                    id="question-side-close"
                    src={closeImg}
                    alt=""
                    onClick={() => props.setShow(false)}
                />

                <div>
                    <p className="question-text">
                        <Latex>{props.question.value ?? ''}</Latex>
                    </p>
                    {ImageUtility.isImage(props.question.image_path) && (
                        <div className="question-image">
                            <img
                                src={props.question.image_path}
                                alt="Illustration for this Question"
                            />
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default LiveHistoryQuizQuestion;
