/**
 * upload file to server
 * @param {NaTypes.Authenticate} accessData
 * @param {File} file
 * @return {Promise<Response>}
 */
export function uploadFile(accessData: NaTypes.Authenticate, file: File): Promise<Response> {
  const url = `${process.env.REACT_APP_API_URL}/api/v2/method/profile.uploadPhoto`;
  const formData = new FormData();
  formData.set('accessToken', accessData.accessToken!);
  formData.set('accountId', accessData.accountId!);
  formData.set('uploaded_file', file);

  return fetch(url,
      {
        method: 'POST',
        body: formData,
        headers: new Headers({}),
      });
};
