import React from 'react';



const PaymentStepPosition = (props: {
    step: number
}) => {
    return (
        <div>
            <div className="premium-step-top">
                <div className="premium-step-point active"></div>
                <div className="premium-step-line active"></div>
                <div className={`premium-step-line ${props.step >= 2 ? 'active' : ''}`}></div>
                <div className={`premium-step-point ${props.step >= 2 ? 'active' : ''}`}></div>
                <div className={`premium-step-line ${props.step >= 2 ? 'active' : ''}`}></div>
                <div className={`premium-step-line ${props.step >= 3 ? 'active' : ''}`}></div>
                <div className={`premium-step-point ${props.step >= 3 ? 'active' : ''}`}></div>
            </div>
            <div className="premium-step-text mt-2">
                <div>
                    <p className="text-left m-0 title active">Step 1</p>
                    <p className="text-left m-0 subtitle active">
                        การสมัครใช้บริการ
                    </p>
                </div>
                <div>
                    <p className={`text-center m-0 title ${props.step >= 2 ? 'active' : ''}`}>
                        Step 2
                    </p>
                    <p className={`text-center m-0 subtitle ${props.step >= 2 ? 'active' : ''}`}>
                        ชำระค่าบริการ
                    </p>
                </div>
                <div>
                    <p className={`text-right m-0 title ${props.step >= 3 ? 'active' : ''}`}>
                        Step 3
                    </p>
                    <p className={`text-right m-0 subtitle ${props.step >= 3 ? 'active' : ''}`}>
                        แจ้งการโอน และ
                        <br />รอตรวจสอบ
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PaymentStepPosition;
