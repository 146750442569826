import { sign } from 'jsonwebtoken';

const jwtSecret = process.env.REACT_APP_JWT_SECRET;

function generateToken(id: string, subject: string): string {

    if (!jwtSecret) {
        throw new Error("JWT_SECRET is not defined in environment variables");
    }

    return sign({}, Buffer.from(jwtSecret), {
        issuer: 'webnaschool',
        algorithm: 'HS256',
        noTimestamp: true,
        jwtid: id,
        subject: subject,
    });
}

export {
    generateToken,
};
