const initialState: NaTypes.ShopeePayState = {
  alert: false,
  transactionStatus: 0,
};


const SET_TRANSACTION_STATUS = 'SET_TRANSACTION_STATUS';
const setTransactionStatus = (transationStatus: number) => {
  return {
    type: SET_TRANSACTION_STATUS,
    transationStatus,
  };
};

const CLEAR_TRANSACTION_STATUS = 'CLEAR_TRANSACTION_STATUS';
const clearTransactionStatus = () => {
  return {};
};

export {
  setTransactionStatus,
  clearTransactionStatus,
};

/**
 * shopee pay state
 * @param {NaTypes.ShopeePayState} state
 * @param {any} action
 * @return {NaTypes.ShopeePayState} current state
 */
export function ShopeePayState(
    state: NaTypes.ShopeePayState = initialState,
    action: any): NaTypes.ShopeePayState {
  switch (action.type) {
    case SET_TRANSACTION_STATUS:
      return Object.assign({}, state, {
        alert: true,
        transactionStatus: action.transactionStatus,
      });
    case CLEAR_TRANSACTION_STATUS:
      return Object.assign({}, state, {
        alert: false,
        transactionStatus: 0,
      });
    default:
      return state;
  }
};
