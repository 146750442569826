import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';



const LiveStreamPagePdf = (props: { resizing: boolean }) => {

    const pdf = useRef<HTMLIFrameElement>(null);
    const [pdfLoadComplete, setPdfLoadComplete] = useState<boolean>(false);

    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const liveStream = useSelector((state: NaTypes.Store) => state.liveStreamTableList);

    const documentPath = liveStream.currentLS?.document;
    const documentUrl = documentPath ? `https://docs.google.com/gview?embedded=true&url=${process.env.REACT_APP_API_URL}/api/v7/method/doclesson?path=${documentPath}` : '';



    useEffect(() => {
        if (!pdfLoadComplete && documentPath) {
            const intervalId = setInterval(() => {
                if (pdf.current) pdf.current.setAttribute('src', documentUrl);
            }, 3000);
            return () => clearInterval(intervalId);
        }
    }, [pdfLoadComplete, documentPath, documentUrl]);



    return (
        <div className="h-100">
            {props.resizing && <div className="h-100 w-100 position-absolute"></div>}
            {documentPath ? (
                <iframe
                    ref={pdf}
                    className="w-100 h-100 doc-layout"
                    title="Study Document"
                    onLoad={() => setPdfLoadComplete(true)}
                    src={documentUrl}
                    sandbox={accessData.accountId ? undefined : "allow-scripts allow-same-origin"}
                ></iframe>
            ) : (
                <iframe className="w-100 h-100 doc-layout" title="No Study Document"></iframe>
            )}
        </div>
    );
};

export default LiveStreamPagePdf;
