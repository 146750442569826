const initialState: NaTypes.Chapter = {
  topicId: '',
  chapterId: '',
  subject: '',
  grade: '',
  topicDescription: '',
  description: '',
  isPoint: 0,
  isFree: 0,
  videoList: [],
  stepVOD: 0,
};

const SET_CURRENT_CHAPTER = 'SET_CURRENT_CHAPTER';
const setCurrentChapter = (chapter: NaTypes.Chapter) => {
  return {
    type: SET_CURRENT_CHAPTER,
    chapter,
  };
};

export {
  setCurrentChapter,
};

/**
 * Chapter state
 * @param {NaTypes.Chapter} state
 * @param {any} action
 * @return {NaTypes.Chapter} current state
 */
export function ChapterState(
    state: NaTypes.Chapter = initialState,
    action: any): NaTypes.Chapter {
  switch (action.type) {
    case SET_CURRENT_CHAPTER:
      return Object.assign({}, state, {
        topicId: action.chapter.topicId,
        chapterId: action.chapter.chapterId,
        subject: action.chapter.subject,
        grade: action.chapter.grade,
        topicDescription: action.chapter.topicDescription,
        description: action.chapter.description,
        isPoint: action.chapter.isPoint,
        isFree: action.chapter.isFree,
        videoList: action.chapter.videoList,
        stepVOD: action.chapter.stepVOD,
      });
    default:
      return state;
  }
};
