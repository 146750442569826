import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { setCustomerChat } from '../../stores/facebookModule/FbModule';
import { clearTransactionStatus } from '../../stores/shopee-pay/ShopeePayState';
import { FbModule } from '../../types';
import Alert from 'react-bootstrap/Alert';
import PaymentHistoryList from '../../components/payment-history/PaymentHistoryList';
import './PaymentHistory.css';
import msgIcon from '../../images/facebook/msg-icon.svg';

interface FB {
    Event: { subscribe(event: string, callback: () => void): void; };
    XFBML: { parse(): void; };
    CustomerChat: { show(): void; hide(): void; };
}



const PaymentHistoryPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const search = useLocation().search;
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const fbModule = useSelector((state: NaTypes.Store) => state.facebookModule) as FbModule;
    const shopeePayState = useSelector((state: NaTypes.Store) => state.shopeePay);
    const transactionState = useSelector((state: NaTypes.Store) => state.transaction);



    const pendingTransactions = transactionState.transactionList.filter((transaction) => {
        return transaction.status === '1' || transaction.status === '3' || transaction.status === '4';
    });

    const successTransactions = transactionState.transactionList.filter((transaction) => {
        return transaction.status === '2';
    });



    const getShopeePayAlert = () => {
        const resultCode = new URLSearchParams(search).get('result_code');
        if (resultCode !== null) {
            switch (resultCode) {
                case '100':
                    return (
                        <Alert variant="success">
                            <Alert.Heading>สำเร็จ!</Alert.Heading>
                            <p>การชำระค่าบริการสำเร็จ</p>
                        </Alert>
                    );
                case '201':
                    return (
                        <Alert variant="danger">
                            <Alert.Heading>แจ้งเตือน!</Alert.Heading>
                            <p>การชำระค่าบริการถูกยกเลิก</p>
                        </Alert>
                    );
                case '202':
                    return (
                        <Alert variant="danger">
                            <Alert.Heading>แจ้งเตือน!</Alert.Heading>
                            <p>การชำระค่าบริการล้มเหลว</p>
                        </Alert>
                    );
                case '203':
                    return (
                        <Alert variant="primary">
                            <Alert.Heading>แจ้งเตือน!</Alert.Heading>
                            <p>ระบบกำลังตรวจสอบการชำระค่าบริการ</p>
                        </Alert>
                    );
                case '204':
                    return (
                        <Alert variant="danger">
                            <Alert.Heading>แจ้งเตือน!</Alert.Heading>
                            <p>หมดระยะเวลาการชำระค่าบริการ</p>
                        </Alert>
                    );
            }
        } else {
            return <></>;
        }
    };

    const getTransactionStatusAlert = () => {
        if (shopeePayState.alert) {
            if (shopeePayState.transactionStatus === 3) {
                return (
                    <Alert variant="success" onClose={() => dispatch(clearTransactionStatus())}>
                        <Alert.Heading>สำเร็จ!</Alert.Heading>
                        <p>การชำระค่าบริการสำเร็จ</p>
                    </Alert>);
            } else if (shopeePayState.transactionStatus === 4) {
                return (
                    <Alert variant="danger" onClose={() => dispatch(clearTransactionStatus())}>
                        <Alert.Heading>แจ้งเตือน!</Alert.Heading>
                        <p>การชำระค่าบริการล้มเหลว กรุณาชำระเงินใหม่อีกครั้ง</p>
                    </Alert>);
            }
        } else {
            return <></>;
        }
    };

    const initializeFBChat = () => {
        if (!fbModule.sdk) return;

        const FB = window.FB as FB;

        if (!fbModule.customerChat) {
            FB.Event.subscribe('customerchat.load', () => {
                dispatch(setCustomerChat(true));
            });
            FB.XFBML.parse();
        } else {
            FB.CustomerChat.show();
        }
    };

    const cleanupFBChat = () => {
        if (fbModule.customerChat) window.FB.CustomerChat.hide();
    };



    useEffect(() => {
        initializeFBChat();
        return cleanupFBChat;
    }, [dispatch, fbModule.customerChat, fbModule.sdk]);

    useEffect(() => { if (!accessData.account) history.push('/'); }, [accessData.account, history]);



    if (!accessData.account) return null;

    return (
        <>
            <div className="payment-history-content">
                {shopeePayState.alert ? getTransactionStatusAlert() : getShopeePayAlert()}
                <div className="payment-history-title-container">
                    <p className="payment-history-title">สถานะการชำระค่าบริการ</p>
                    <a
                        href={`https://m.me/NockAcademyElementary?text=ขอสอบถามเรื่องการชำระค่าบริการ%20–%20$${accessData.account.fullname}`}
                        className="message-us-button"
                        target="_blank"
                    >
                        <img src={msgIcon} alt="Message Icon" className="msg-icon" />
                        ติดต่อเรา
                    </a>
                </div>
                <PaymentHistoryList title={'กำลังดำเนินการ'} transactionList={pendingTransactions.length > 1 ? [pendingTransactions[0]] : pendingTransactions} />
                <PaymentHistoryList title={'ประวัติการชำระค่าบริการ'} transactionList={successTransactions} />
            </div>
            <div className="payment-phone-handle">
            </div>
        </>
    );
};

export default PaymentHistoryPage;
