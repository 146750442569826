import React, { UIEventHandler, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import SideBarFilter from '../../components/sidebar-filter/SidebarFilter';
import SideBarFilterSearch from '../../components/sidebar-filter/SidebarFilterSearch';
import LiveStreamShowTopicListRender from './LiveStreamShowTopicListRender';
import { getLSPlaybackList, getLSPlaybackListLoadMore } from './LiveStreamShowTopicListUtils';
import loading from '../../images/loading/loading_spinner.svg';
import './LiveStreamShowTopicList.css';



const LiveStreamShowTopicList = () => {
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const filterState = useSelector((state: NaTypes.Store) => state.filter);
    const searchText = useSelector((state: NaTypes.Store) => state.searchText);

    const [liveStreamHistoryFilter, setLiveStreamHistoryFilter] = useState<NaTypes.LiveStreamPlaybackTopicList>([]);
    const [loadAble, setLoadAble] = useState<boolean>(false);
    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const [paging, setPaging] = useState<number>(1);

    const refSectionElement = useRef<HTMLElement>(null);



    const checkScroll: UIEventHandler<HTMLDivElement> = (e) => {
        if (
            e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 1 <= e.currentTarget.clientHeight &&
            loadAble &&
            !loadingMore
        ) {
            getLSPlaybackListLoadMore(
                accessData, filterState, liveStreamHistoryFilter, paging, searchText,
                setLiveStreamHistoryFilter, setLoadAble, setLoadingMore, setPaging
            );
        }
    }



    useEffect(() => {
        setPaging(1);

        const searchParams = new URLSearchParams({
            page: '1',
            itemPerPage: '18',
            ...(filterState.gradeId && { grade: filterState.gradeId }),
            ...(filterState.subjectId && { subject: filterState.subjectId }),
            ...(filterState.typeId && { type: filterState.typeId }),
            ...(searchText && { keyword: searchText }),
        }).toString();

        getLSPlaybackList(
            accessData, searchParams, setLiveStreamHistoryFilter, setLoadAble, setLoadingMore
        );
    }, [filterState, searchText]);




    if (isMobile) {
        refSectionElement.current?.scrollIntoView();
    }



    return (
        <>
            <ToastContainer
                autoClose={3000} closeOnClick draggable hideProgressBar newestOnTop
                pauseOnFocusLoss pauseOnHover position="top-center" rtl={false}
            />

            <div className="row m-0" id="topic-list-main" onScroll={checkScroll}>
                <div className='col-sm-4 col-md-3 col-lg-2 d-none d-sm-block'>
                    <SideBarFilterSearch />
                    <SideBarFilter isPlaybackTopic={true} />
                </div>

                <div className='col-sm-8 col-md-9 col-lg-10' id="topic-list-container" >
                    <div className='content-ls-topic-list'>
                        <h3 className="rainbow_text_animated">คอร์สไลฟ์ย้อนหลัง</h3>
                        <p className="lesson-list-sub-title">กดย้อนดูคลิปที่เคยผ่านการไลฟ์สดไปแล้ว เพื่อทบทวนอีกครั้ง</p>

                        <LiveStreamShowTopicListRender
                            liveStreamHistoryFilter={liveStreamHistoryFilter}
                        />

                        {loadingMore ? (
                            <div className="row" id="topic-list-loading">
                                <div className='col-12'>
                                    <img src={loading} />
                                </div>
                            </div>
                        ) : (
                            !loadingMore && liveStreamHistoryFilter.length <= 0 ? (
                                <div className="row" id="topic-list-empty">
                                    <div className='col-12'>
                                        ขออภัย ขณะนี้ยังไม่มีไลฟ์ที่เกี่ยวข้องกับการค้นหา <br />
                                        กรุณาเลือกระดับชั้นหรือรายวิชาอื่น
                                    </div>
                                </div>
                            ) : null
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LiveStreamShowTopicList;
