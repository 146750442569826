import React from 'react';
import PinIcon from '../../../images/pin/pin_left.png';
import CloseIcon from '../../../images/close/close.svg';



const LiveStreamPageAnnouncementPin = (props: {
    announcementPinOn: boolean,
    announcementPin: string,
    setAnnouncementPinOn: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
    return (
        <>
            {props.announcementPinOn && (
                <div className='pin-annoucement-top'>
                    <div className='pin-announcement-content'>
                        <div className='pin-announcement-text'>
                            <img className='pin-icon' src={PinIcon} alt='' />
                            &nbsp;Admin
                        </div>
                        <img
                            className='close-icon'
                            src={CloseIcon}
                            alt=''
                            onClick={() => props.setAnnouncementPinOn(false)}
                        />
                    </div>
                    <div className='pin-announcement-description'>{props.announcementPin}</div>
                </div>
            )}
        </>
    );
};

export default LiveStreamPageAnnouncementPin;
