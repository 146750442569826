import React from 'react';
import { isValidEmailFormat } from '../PaymentUtils';
import emailImg from '../../../images/other/email.svg';
import phoneImg from '../../../images/other/phone.svg';
import userBlue from '../../../images/user/user-blue.svg';
import userOrange from '../../../images/user/user-orange.svg';



const PaymentStepOne = (props: {
    name: string,
    surName: string,
    email: string,
    phone: string,
    isValidEmail: boolean,
    setEmail: React.Dispatch<React.SetStateAction<string>>,
    setIsValidEmail: React.Dispatch<React.SetStateAction<boolean>>,
    setName: React.Dispatch<React.SetStateAction<string>>,
    setSurName: React.Dispatch<React.SetStateAction<string>>,
    setPhone: React.Dispatch<React.SetStateAction<string>>,
}) => {

    props.setIsValidEmail(isValidEmailFormat(props.email));

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        props.setEmail(newValue);
        props.setIsValidEmail(isValidEmailFormat(newValue));
    }

    return (
        <>
            <div className="mt-4 premium-title">สมัครใช้บริการพรีเมียม</div>
            <div className="row mt-4">
                <div className="col-12 pt-2">
                    <p className="m-0">ชื่อ</p>
                </div>
                <div className="col-12 input-premium">
                    <img src={userBlue} alt="" />
                    <input
                        placeholder="ชื่อ"
                        value={props.name}
                        onChange={(e) => props.setName(e.target.value)}
                        required
                    />
                </div>
                <div className="col-12 pt-2">
                    <p className="m-0">นามสกุล</p>
                </div>
                <div className="col-12 input-premium">
                    <img src={userOrange} alt="" />
                    <input
                        placeholder="นามสกุล"
                        value={props.surName}
                        onChange={(e) => props.setSurName(e.target.value)}
                        required
                    />
                </div>
                <div className="col-12 pt-2">
                    <p className="m-0">อีเมล {!props.isValidEmail && <span className="invalid-email">- รูปแบบอีเมลไม่ถูกต้อง</span>}</p>
                </div>
                <div className="col-12 input-premium">
                    <img src={emailImg} alt="" />
                    <input
                        type="email"
                        placeholder="อีเมล"
                        required
                        value={props.email}
                        onChange={(e) => handleEmailChange(e)}
                    />
                </div>
                <div className="col-12 pt-2">
                    <p className="m-0">เบอร์โทรศัพท์</p>
                </div>
                <div className="col-12 input-premium">
                    <img src={phoneImg} alt="" />
                    <input
                        placeholder="เบอร์โทรศัพท์"
                        type="tel"
                        pattern="[0-9]{10}"
                        minLength={10}
                        value={props.phone}
                        onChange={(e) => {
                            e.preventDefault();
                            const keyMap: any = {
                                'จ': 0,
                                'ๅ': 1,
                                '/': 2,
                                '_': 3,
                                'ภ': 4,
                                'ถ': 5,
                                'ุ': 6,
                                'ึ': 7,
                                'ค': 8,
                                'ต': 9,
                            };
                            let value = e.target.value;
                            Object.keys(keyMap).forEach((k: any) => {
                                const c = keyMap[k];
                                value = value.replace(k, c);
                            });
                            if ((/^\d+$/.test(value) && value.length <= 10) || value === '') {
                                props.setPhone(value);
                            }
                        }}
                        required
                    />
                </div>
                <div className="col-12 mt-4 d-flex align-items-center">
                    <input className="premium-accept mr-2" type="checkbox" required />
                    <span>  ข้าพเจ้ายอมรับ
                        <a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/terms-and-conditions"> เงื่อนไข</a>
                        และ<a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/privacy-and-cookie-policy">ข้อตกลงการใช้บริการ</a>
                    </span>
                </div>
            </div>
        </>
    );
};

export default PaymentStepOne;
