// firebase.js
// contains the Firebase context and provider

import React, {createContext} from 'react';
import firebaseConfig from './FirebaseConfig';
import app from 'firebase/app';
import 'firebase/database';

// we create a React Context, for this to be accessible
// from a component later
const FirebaseContext = createContext<FirebaseContextType>({});
export {FirebaseContext};

const Firebase = (props: {children: any}) => {
  let firebase: FirebaseContextType = {};

  // check if firebase app has been initialized previously
  // if not, initialize with the config we saved earlier
  if (!app.apps.length) {
    app.initializeApp(firebaseConfig);
    firebase = {
      app: app,
      database: app.database(),
    };
  }

  return (
    <FirebaseContext.Provider value={firebase}>
      {props.children}
    </FirebaseContext.Provider>
  );
};

export default Firebase;
