import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { addExchangePoint } from '../../api/VideoOnDemand';
import bgScore from '../../images/score/bg-score.svg';
import scoreCrown from '../../images/score/score-crown.svg';



const Score = (props: {
  test: NaTypes.Test,
}) => {
  const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
  const chapter = useSelector((state: NaTypes.Store) => state.chapter);
  const [score, setScore] = useState<number>(0);



  useEffect(() => {
    window.scrollTo(0, 0);
    const correctAnswersCount = props.test.testQuestionList
      .filter(question => {
        const selectedChoice = question.choiceList.find(c => c.id === question.selectedAnswer);
        return selectedChoice?.isCorrect === true;
      })
      .length;
    setScore(correctAnswersCount);
  }, [props.test]);

  useEffect(() => {
    const { testQuestionList } = props.test;
    if (score === testQuestionList.length) {
      addExchangePoint(accessData, chapter.subject, chapter.grade, chapter.topicId, chapter.chapterId);
    }
  }, [accessData, chapter, props.test, score]);



  return (
    <div className="mini-test-score-container" style={{ backgroundImage: `url(${bgScore})` }}>
      {score === 4 && <img className="mini-test-score-image-crown" src={scoreCrown} alt="" />}
      <div>
        <p className="mini-test-score-text-score">{score}/{props.test.testQuestionList.length}</p>
        <p className="mini-test-score-text-score-unit">คะแนน</p>
      </div>
    </div>
  );
};

export default Score;
