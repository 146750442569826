import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import userProfile from '../../images/user/user.svg';



const Message = (props: {
    message: MessageItem,
    delete: (key: string) => void,
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLButtonElement | null>(null);
    const [showMiniProfile, setShowMiniProfile] = useState({
        show: false,
        position: [0, 0],
    });

    const ITEM_HEIGHT = 48;
    const open = Boolean(anchorEl);

    const options = [
        { value: 'delete', text: 'ลบ' },
    ];



    const handleClick = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        !open ? setAnchorEl(event.currentTarget) : handleClose();
    };

    const handleSelect = (value: string, key: string) => (value === 'delete' ? props.delete(key) : undefined);


    const handleClose = () => setAnchorEl(null);



    return (
        <>
            {props.message.type === "JOIN" ? (
                null
            ) : (
                <>
                    <div
                        className="chat-message-item"
                        onClick={handleClick}
                        onMouseEnter={(e) => {
                            const bounding = e.currentTarget.getBoundingClientRect();
                            setShowMiniProfile((showMiniProfile) => ({
                                ...showMiniProfile,
                                show: true,
                                position: [(bounding.x + bounding.width) * 0.2, bounding.y - bounding.height],
                            }));
                        }}
                        onMouseLeave={() => setShowMiniProfile((showMiniProfile) => ({ ...showMiniProfile, show: false }))}
                    >
                        {props.message.userImageUrl === '' ? (
                            <img className="chat-message-item-image-profile" src={userProfile} alt="" />
                        ) : (
                            <img className="chat-message-item-image-profile" src={props.message.userImageUrl} alt="" />
                        )}
                        <div className="chat-message-item-message-container">
                            <div>
                                <div className="chat-message-item-text-container">
                                    <div className={`chat-message-item-text ${props.message.self ? 'self' : 'other'}`}>
                                        <div className={`chat-message-item-username-${props.message.self ? 'self' : 'other'}`} >
                                            {props.message.userName}
                                        </div>
                                        {props.message.message}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {props.message.self ? (
                            <div className="chat-message-item-three-dots-menu-container">
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: '10ch',
                                        },
                                    }}
                                >
                                    {options.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                            onClick={() => {
                                                handleClose();
                                                handleSelect(option.value, props.message.key);
                                            }}
                                        >
                                            {option.text}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default Message;
