const initialState = {
  showing: true,
};

const SET_SHOWING_BOARD = 'SET_SHOWING_BOARD';
const setShowingBoard = (v: Boolean) => {
  return {
    type: SET_SHOWING_BOARD,
    value: v,
  };
};

export {
  setShowingBoard,
};

/**
 * Authorization state
 * @param {any} state
 * @param {any} action
 * @return {any} current state
 */
export function NoticeBoardState(
    state: any = initialState,
    action: any) {
  switch (action.type) {
    case SET_SHOWING_BOARD:
      return Object.assign(state, {showing: action.value});
    default:
      return state;
  }
};
