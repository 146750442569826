
let initialState: NaTypes.FbModule = {
  sdk: false,
  customerChat: false,
};

const accessData = localStorage.getItem('accessData_v');
if (accessData) {
  initialState = JSON.parse(accessData);
}

const SET_CUSTOMER_CHAT = 'SET_CUSTOMER_CHAT';
const setCustomerChat = (v: boolean) => {
  return {
    type: SET_CUSTOMER_CHAT,
    value: v,
  };
};

const SET_SDK = 'SET_SDK';
const setSdkFacebook = (v: boolean) => {
  return {
    type: SET_SDK,
    value: v,
  };
};

export {
  setSdkFacebook,
  setCustomerChat,
};

/**
 * Authorization state
 * @param {NaTypes.FbModule} state
 * @param {any} action
 * @return {NaTypes.FbModule} current state
 */
export function FbModule(
    state: NaTypes.FbModule = initialState,
    action: any): NaTypes.FbModule {
  switch (action.type) {
    case SET_CUSTOMER_CHAT:
      return Object.assign({}, state, {customerChat: action.value});
    case SET_SDK:
      return Object.assign({}, state, {sdk: action.value});
    default:
      return state;
  }
};
