import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { generateToken } from '../../utility/Token';
import { reloadTransaction } from '../../stores/transaction/Transaction';



export const copyNumber = async (text: number) => {
    const numberMap: {
        [key: number]: {
            number: string;
            message: string;
        };
    } = {
        1: { number: "0205557003965", message: "คัดลอกหมายเลข PromptPay แล้ว" },
        2: { number: "5991390102", message: "คัดลอกหมายเลขบัญชีกสิกรแล้ว" },
    };

    const entry = numberMap[text];

    if (entry) {
        navigator.clipboard.writeText(entry.number);
        toast(entry.message);
    }
};



export const isValidEmailFormat = (email: string) => {
    if (email.trim() === '') {
        return false;
    }

    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailPattern.test(email);
};



export const QRCode = async (
    amount: number,
    qrCodeBank: string,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
    setMethod: React.Dispatch<React.SetStateAction<number>>,
    omiseQRHandler: (resOmiseSource: any) => void,
) => {
    if (qrCodeBank == "") {
        window.Omise.setPublicKey(process.env.REACT_APP_OMISE_KEY);
        window.Omise.createSource('promptpay', {
            "amount": amount * 100,
            "currency": "THB"
        }, function (statusCode: any, response: any) {
            if (statusCode === 200) {
                omiseQRHandler(response);
            } else {
                setErrorMessage(response.message);
                toast.warning("ขณะนี้ QRcode มีปัญหา กรุณาเลือกช่องทางชำระอื่น");
                setMethod(2);
            }
        });
    }
}



export const redeemCode = async (
    accessData: NaTypes.Authenticate,
    actualAmount: number,
    amount: number,
    couponCode: string,
    currentTransaction: any,
    dispatch: Dispatch<any>,
    setAmount: React.Dispatch<React.SetStateAction<number>>,
    setCouponCode: React.Dispatch<React.SetStateAction<string>>,
    setDiscountAmount: React.Dispatch<React.SetStateAction<number>>,
    setErrorCode: React.Dispatch<React.SetStateAction<string>>,
    setMethod: React.Dispatch<React.SetStateAction<number>>,
    setTransactionInfo: React.Dispatch<any>
) => {
    const at = accessData.accessToken ? accessData.accessToken : '';
    const aid = accessData.accountId ? accessData.accountId : '';
    const url = `${process.env.REACT_APP_API_URL_V2}/v1/discount/validate`;
    if (currentTransaction.discount_id != null && currentTransaction.discount_id != "") {
        setDiscountAmount(amount - actualAmount);
    }
    return await fetch(
        url,
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': generateToken(accessData.accountId!, accessData.accessToken!),
            },
            body: JSON.stringify({
                "couponCode": couponCode,
                "transactionId": currentTransaction.transaction_id
            }),
        })
        .then((res) =>
            res.json()
        )
        .then((res) => {
            if (res.transaction.amount) {
                dispatch(reloadTransaction(at, aid));
                setTransactionInfo(res.transaction);
                setAmount(parseFloat(res.transaction.amount));
                setDiscountAmount(actualAmount - res.transaction.amount);
            }
            setCouponCode("");
            setMethod(3);
            setErrorCode(res.message_th);
        })
        .catch((err) => console.log(err));
}
