import {generateToken} from '../../utility/Token';

const initialState: NaTypes.ProfileState = {
  pending: false,
  profile: null,
};

const FETCH_PROFILE_PENDING = 'FETCH_PROFILE_PENDING';
const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';

const fetchProfilePending = () => {
  return {
    type: FETCH_PROFILE_PENDING,
  };
};

const fetchProfileSuccess = (profile: NaTypes.Profile) => {
  return {
    type: FETCH_PROFILE_SUCCESS,
    profile,
  };
};

/**
 * fetch profile
 * @param {string} accessToken
 * @param {string} accountId
 * @return {Function}
 */
function fetchProfile(accessToken: string, accountId: string): Function {
  return function(dispatch: any) {
    dispatch(fetchProfilePending());
    const url = `${process.env.REACT_APP_API_URL_V2}/v1/user/profile/${accountId}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': generateToken(accountId, accessToken),
      },
    })
        .then((res) => res.json())
        .then((res) => {
          const profile: NaTypes.Profile = {
            id: res.data.id,
            fullname: res.data.fullname,
            grade: res.data.grade,
            parentEmail: res.data.parent_email,
            nameSchool: res.data.nameschool,
            login: res.data.login,
            province: res.data.province,
            status: res.data.status,
            lowPhotoUrl: res.data.low_photo_url,
            originPhotoUrl: res.data.origin_photo_url,
            normalPhotoUrl: res.data.normal_photo_url,
            bigPhotoUrl: res.data.big_photo_url,
            gradeDescriptionTh: res.data.grade_description_th,
            provinceNameLocal: res.data.province_name_local,
            phoneNumber: res.data.phone,
            emailSubscribe:res.data.email_subscribe,
            email:res.data.email,    
            emailPlayback: res.data.email_playback == 1 ? true : false,
            emailCert: res.data.email_cert == 1 ? true : false,
          };
          return dispatch(fetchProfileSuccess(profile));
        });
  };
}

export {
  fetchProfile,
  fetchProfilePending,
  fetchProfileSuccess,
};

/**
 * profile state
 * @param {NaTypes.ProfileState} state
 * @param {any} action
 * @return {NaTypes.ProfileState} current state
 */
export function ProfileState(
    state: NaTypes.ProfileState = initialState,
    action: any): NaTypes.ProfileState {
  switch (action.type) {
    case FETCH_PROFILE_PENDING:
      return Object.assign({}, state, {
        pending: true,
      });
    case FETCH_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        pending: false,
        profile: action.profile,
      });
    default:
      return state;
  }
};
