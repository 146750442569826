import React, { useEffect, useState } from 'react';
import SampleVideoPlayer from './SampleVideoPlayer/SampleVideoPlayer';
import './SampleVideos.css';
import closeImg from '../../images/actions/close.svg';
import sampleVideos from './sampleVideos.json';

const SampleVideos = (props: {
    index: number,
    setIsSampleVideosEnabled: React.Dispatch<React.SetStateAction<boolean>>
}) => {

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [videoList, setVideoList] = useState<File[]>([]);

    const isMobileDevice = /Mobi/i.test(navigator.userAgent);
    const isValidIndex = props.index >= 0 && props.index < sampleVideos.length;

    const sampleVideoTitleBase = isMobileDevice ? "คลิปตัวอย่างการสอน" : "คลิปตัวอย่างการสอน – ";
    const sampleVideoTitleName = (isValidIndex && !isMobileDevice) ? sampleVideos[props.index].name : "";
    const sampleVideoTitle = sampleVideoTitleBase + sampleVideoTitleName;



    const handleCloseButtonClick = () => {
        setShowConfirmationModal(true);
    };

    const handleCloseConfirmed = () => {
        setShowConfirmationModal(false);
        props.setIsSampleVideosEnabled(false);
    };

    const handleCloseCanceled = () => {
        setShowConfirmationModal(false);
    };

    const getVimeo = async (index: number) => {
        try {
            const res = await fetch(`https://api.vimeo.com/videos/${sampleVideos[index].ID}`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_VIMEO_AUTH}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await res.json();
            setVideoList(data.files);
        } catch (error) {
            console.error("Error fetching Vimeo videos:", error);
        }
    };



    useEffect(() => {
        if (isValidIndex) {
            getVimeo(props.index);
        }
    }, [props.index]);



    return (
        <>
            {isValidIndex && (
                <>
                    <div className="wraper" id="sampleVideoWrapper" />
                    <div className="sample-video-bg">
                        <div className="sample-video-modal">

                            {showConfirmationModal && (
                                <div className="sample-video-confirmation-modal">
                                    <p>คุณกำลังจะออกจากหน้าตัวอย่างคลิปการสอน{isMobileDevice && <span> ดำเนินการต่อ?</span>}</p>
                                    {isMobileDevice ? null : <p>ดำเนินการต่อ?</p>}
                                    <div className="sample-video-confirmation-modal-buttons">
                                        <button onClick={handleCloseConfirmed}>ตกลง</button>
                                        <button onClick={handleCloseCanceled}>ยกเลิก</button>
                                    </div>
                                </div>
                            )}

                            <img
                                className="sample-video-close-button can-click"
                                src={closeImg}
                                alt="Close Button"
                                onClick={handleCloseButtonClick}
                            />

                            <h1 className="sample-video-title">{sampleVideoTitle}</h1>
                            {isMobileDevice && isValidIndex && (
                                <h2 className="sample-video-title">{sampleVideos[props.index].name}</h2>
                            )}

                            <div className="sample-video-container">
                                <SampleVideoPlayer fileList={videoList} />
                            </div>

                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default SampleVideos;
