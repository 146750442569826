import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateToken } from '../../utility/Token';
import { checkAutoPlay, checkNotification } from './LiveStreamUtils';
import { LiveStreamVideo, LiveStreamDetails, LiveStreamLoading } from './LiveStreamRenders';
import './LiveStream.css';



const LiveStream = () => {
  const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
  const liveStreamList = useSelector((state: NaTypes.Store) => state.liveStreamTableList);
  const gradeState = useSelector((state: NaTypes.Store) => state.grade);

  const [notificationCheck, setNotification] = useState<boolean>(false);
  const [videoList, setVideoList] = useState<Array<any>>([]);
  const [invitedUserIDs, setInvitedUserIDs] = useState<Array<number>>([]);

  const dispatch = useDispatch();

  const isMobile = window.innerWidth <= 575;



  const fetchInvitedUserIDs = async () => {
    try {
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': generateToken(accessData.accountId!, accessData.accessToken!),
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/user/invitational/ids`, {
        method: 'GET',
        headers: headers,
      });

      if (response.ok) {
        const responseData = await response.json();
        const newInvitedUserIDs = responseData.data.map((item: { id: number }) => item.id);
        setInvitedUserIDs(newInvitedUserIDs);
      } else {
        console.error('Failed to fetch data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching invited user IDs:', error);
    }
  };



  useEffect(() => {
    checkNotification(accessData, liveStreamList, setNotification);
    checkAutoPlay(liveStreamList, setVideoList);

    if (liveStreamList.currentLS?.invitational == 1) {
      fetchInvitedUserIDs();
    }
  }, [liveStreamList.currentLS]);



  return (
    <div className='live-stream-container'>
      {liveStreamList.currentLS ? (
        <div className='my-card bg-white live-stream-current-container row m-0'>

          {isMobile && (
            <h1 className="live-stream-broadcasting-title">
              {liveStreamList.currentLS.urlVideo ? 'กำลังไลฟ์สด' : 'รายการเรียนถัดไป'}
            </h1>
          )}

          <div className="live-stream-items-container">
            <LiveStreamVideo
              accessData={accessData}
              invitedUserIDs={invitedUserIDs}
              liveStreamList={liveStreamList}
              videoList={videoList}
            />
            <LiveStreamDetails
              accessData={accessData}
              dispatch={dispatch}
              gradeState={gradeState}
              invitedUserIDs={invitedUserIDs}
              liveStreamList={liveStreamList}
              notificationCheck={notificationCheck}
              setNotification={setNotification}
            />
          </div>

        </div>
      ) : (
        <LiveStreamLoading />
      )}
    </div>
  );
};

export default LiveStream;
