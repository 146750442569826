import React from 'react';
import clockIcon from '../../images/clock/clock-icon.svg';

const VideoDuration = ({ chapterList }: { lessonName: string, chapterList: NaTypes.ChapterList }) => {
  const sum = chapterList.reduce((total, chapter) => {
    return total + chapter.videoList.reduce((videoTotal, video) => videoTotal + video.duration, 0);
  }, 0);

  return sum > 0 ? (
    <>
      <img src={clockIcon} className="video-duration-image" alt="Clock" />
      <span>
        {Math.floor(sum / 3600) > 0 && `${Math.floor(sum / 3600)} ชั่วโมง `}
        {`${Math.floor((sum % 3600) / 60)} นาที`}
      </span>
    </>
  ) : null;
};

export default VideoDuration;