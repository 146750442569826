import React, { useRef, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import { MixpanelTracking } from '../../utility';
import ExamListHeader from './ExamListHeader';
import ExamListItem from './ExamListItem';
import "./Exam.css"



const Exam = (props: {
    title: string,
    list: NaTypes.QuaterExamList,
}) => {
    const [redirect, setRedirect] = useState<any>();
    const [scroll, setScroll] = useState(0);

    const dispatch = useDispatch();
    const history = useHistory();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const filterState = useSelector((state: NaTypes.Store) => state.filter);
    const subjectState = useSelector((state: NaTypes.Store) => state.subject);
    const scrollContainer = useRef<HTMLDivElement>(null);



    if (accessData.accessToken) {
        if (redirect) {
            history.push(redirect);
            setRedirect(null);
        }
    }



    const scrollPanels = (direction: number) => {
        const scrollWidth = scrollContainer.current?.scrollWidth || 0;
        const clientWidth = scrollContainer.current?.clientWidth || 0;

        let newScroll = scroll + direction * clientWidth;

        if (newScroll < 0) {
            newScroll = 0;
        } else if (newScroll > scrollWidth - clientWidth) {
            newScroll = scrollWidth - clientWidth;
        }

        scrollContainer.current?.scroll({ left: newScroll });
        setScroll(newScroll);
    };

    const onExamItemClick = (quarterExam: NaTypes.QuaterExam) => {
        const to = `/exam/${filterState.gradeId}/${quarterExam.quarter}`;

        if (!accessData.accessToken) {
            dispatch(requestLogin());
            setRedirect(to);
        } else {
            const { quarter, title } = quarterExam;
            MixpanelTracking.examSelected(quarter, title, accessData.account.grade);
            history.push(to);
        }
    };



    useEffect(() => {
        const scrollWidth = scrollContainer.current?.scrollWidth || 0;
        const clientWidth = scrollContainer.current?.clientWidth || 0;

        const scrollInterval = setInterval(() => {
            const newScroll = scroll + clientWidth;

            if (newScroll >= scrollWidth) {
                scrollContainer.current?.scroll({ left: 0 });
                setScroll(0);
            } else {
                scrollContainer.current?.scroll({ left: newScroll });
                setScroll(newScroll);
            }
        }, 5000);

        return () => {
            clearInterval(scrollInterval);
        };
    }, [scroll, scrollContainer, props.list]);



    if (!props.list || props.list.length <= 0) {
        return null;
    }



    return (
        <div className="lesson-list-container my-card bg-blue exam-component">

            <ExamListHeader
                title={props.title}
                showArrows={props.list && props.list.length > 4}
                onScrollLeft={() => scrollPanels(-1)}
                onScrollRight={() => scrollPanels(1)}
            />

            <div ref={scrollContainer} className="ls-playback-list-container exam-items-container">
                {props.list?.map((quarterExam, index) => (
                    <ExamListItem
                        quarterExam={quarterExam}
                        subjectState={subjectState}
                        filterState={filterState}
                        index={index}
                        onExamItemClick={() => onExamItemClick(quarterExam)}
                    />
                ))}
            </div>

            <Link to="/exam-list">
                <div className="view-all-recent" id="exam-list-button-bottom" style={{ cursor: "pointer" }}>
                    <p className="view-all-text">View All</p>
                </div>
            </Link>
        </div>
    );
};

export default Exam;
