import { Dispatch } from 'react';
import { addLesson } from './LessonListState';

const initialState: NaTypes.LessonSetList = [];

const SET_LESSON_SET = 'SET_LESSON_SET';
const setLessonSet = (grades: Array<NaTypes.Grade>, subjects: Array<NaTypes.Subject>) => {
    return {
        type: SET_LESSON_SET,
        grades,
        subjects,
    };
};

const UPDATE_LESSON_SET = 'UPDATE_LESSON_SET';
const loadComplete = (
    subject: string,
    grade: string,
    list: Array<NaTypes.LessonList>) => {
    return {
        type: UPDATE_LESSON_SET,
        subject,
        grade,
        list,
    };
};

const RESET_LESSON_SET = 'RESET_LESSON_SET';
const resetLessonSet = () => {
    return {
        type: RESET_LESSON_SET,
    };
};



const loadLesson = async (subject: string, grade: string, accountId: string | null) => {
    try {
        const params = new URLSearchParams({ subject, grade });
        if (accountId) params.append('accountId', accountId);

        const url = `${process.env.REACT_APP_API_URL_V2}/v1/subject/getGradeAndSubject?${params.toString()}`;
        const response = await fetch(url, { method: 'GET', headers: new Headers({}) });
        const res = await response.json();

        return res.topics.reduce((lessons: Array<NaTypes.Lesson>, topic: any): Array<NaTypes.Lesson> => {
            if (topic && topic.chapters.length > 0) {
                const filteredChapters = topic.chapters.filter((chapter: any) => chapter.grade === topic.grade);

                if (filteredChapters.length > 0) {
                    lessons.push({
                        index: lessons.length,
                        topicId: topic.topic_id,
                        subject: topic.subject_id,
                        grade: topic.grade,
                        name: topic.description,
                        thumbnail: filteredChapters[0].videos[0].video_thumbnail_hd_url,
                        videoSize: filteredChapters.length * 2,
                        isFree: filteredChapters.every((chapter: any) => chapter.is_free === '1'),
                        isCompleted: filteredChapters.every((chapter: any) => chapter.is_point === '1'),
                        chapterList: filteredChapters.map((chapter: any): NaTypes.Chapter => ({
                            topicId: chapter.topic_id,
                            chapterId: chapter.chapter_id,
                            subject: chapter.subject_id,
                            grade: chapter.grade,
                            topicDescription: chapter.topic_description_th,
                            description: chapter.description,
                            isPoint: parseInt(chapter.is_point),
                            isFree: parseInt(chapter.is_free),
                            stepVOD: parseInt(chapter.step_vod),
                            videoList: chapter.videos.map((video: any): NaTypes.Video => ({
                                host: video.video_host,
                                videoRef: video.video_id,
                                thumbnail: {
                                    normal: video.video_thumbnail_url,
                                    hd: video.video_thumbnail_hd_url,
                                },
                                documentList: [video.document_url],
                                questionList: [],
                                duration: video.video_duration ? parseInt(video.video_duration) : 0,
                            })),
                        })),
                    });
                }
            }
            return lessons;
        }, []);
    } catch (err) {
        console.error(err);
        return [];
    }
};



const getLessonListSet = (subject: string, grade: string, accountId: string | null) => async (dispatch: Dispatch<any>) => {
    const lessons = await loadLesson(subject, grade, accountId);
    if (lessons) {
        lessons.forEach((lesson: NaTypes.Lesson) => dispatch(addLesson(lesson)));
        dispatch(loadComplete(subject, grade, lessons));
    }
};



export {
    setLessonSet,
    loadComplete,
    getLessonListSet,
    resetLessonSet,
};

export function LessonSetListState(
    state: NaTypes.LessonSetList = initialState,
    action: any): NaTypes.LessonSetList {
    switch (action.type) {
        case SET_LESSON_SET:
            const list = action.grades.map((g: NaTypes.Grade) => {
                return action.subjects.map((s: NaTypes.Subject) => {
                    return {
                        subject: s.subjectId,
                        grade: g.grade,
                        isLoaded: false,
                        listLesson: [],
                    };
                });
            }).reduce((current: any[], value: any[]) => {
                value.forEach((v) => current.push(v));
                return current;
            }, []);
            return Object.assign([], state, list);
        case UPDATE_LESSON_SET:
            return state.map((set) => {
                return set.grade === action.grade &&
                    set.subject === action.subject ? {
                    ...set,
                    isLoaded: true,
                    listLesson: action.list,
                } : set;
            });
        case RESET_LESSON_SET:
            return initialState;
        default:
            return state;
    }
};
