const initialState: NaTypes.LiveQuizPercentage = {
  state: '',
  percentage: null,
};

const FETCH_LIVE_QUIZ_PERCENTAGE_PENDING = 'FETCH_LIVE_QUIZ_PERCENTAGE_PENDING';
const fetchLiveQuizPercentagePending = () => {
  return {
    type: FETCH_LIVE_QUIZ_PERCENTAGE_PENDING,
  };
};

const FETCH_LIVE_QUIZ_PERCENTAGE_SUCCESS = 'FETCH_LIVE_QUIZ_PERCENTAGE_SUCCESS';
const fetchLiveQuizPercentageSuccess = (percentage: NaTypes.QuestionPercentage | null) => {
  return {
    type: FETCH_LIVE_QUIZ_PERCENTAGE_SUCCESS,
    percentage,
  };
};

const fetchLiveQuizPercentageById = (id: string) => {
  return function(dispatch: any) {
    dispatch(fetchLiveQuizPercentagePending());
    fetch(`${process.env.REACT_APP_API_URL}/api/v7/method/liveQuestion.percentage?question_id=${id}`)
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            throw (res.error);
          }
          const percentage = {
            id: res.question.id.toString(),
            answers: res.question.answers.map((a: any): NaTypes.AnswerPercentage => ({
              id: a.id.toString(),
              percent: a.percent,
              isCorrect: a.is_correct,
            })),
          };

          dispatch(fetchLiveQuizPercentageSuccess(percentage));
        })
        .catch(() => {});
  };
};

export {
  fetchLiveQuizPercentageById,
  fetchLiveQuizPercentagePending,
  fetchLiveQuizPercentageSuccess,
};

/**
 * schedule live stream list state
 * @param {Array<any>} state
 * @param {any} action
 * @return {Array<any>} current state
 */
export function LiveQuizPercentageState(
    state: NaTypes.LiveQuizPercentage = initialState,
    action: any): NaTypes.LiveQuizPercentage {
  switch (action.type) {
    case FETCH_LIVE_QUIZ_PERCENTAGE_PENDING:
      return {
        state: 'PENDING',
        percentage: null,
      };
    case FETCH_LIVE_QUIZ_PERCENTAGE_SUCCESS:
      return {
        state: 'SUCCESS',
        percentage: action.percentage,
      };
    default:
      return state;
  }
};
