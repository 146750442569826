import {generateToken} from '../../utility/Token';

const initialState: NaTypes.LiveStreamHistoryState =
{
    pending: false,
    liveStreamHistoryRecentList:[],
    liveStreamHistoryPopularList:[]
}

const FETCH_LSHISTORY_PENDING = 'FETCH_LSHISTORY_PENDING';
const FETCH_LSHISTORY_SUCCESS = 'FETCH_LSHISTORY_SUCCESS';

const fetchLiveStreamHistoryPending = () => {
    return {
        type: FETCH_LSHISTORY_PENDING,
    };
};

const fetchLiveStreamHistorySuccess = (liveStreamHistoryRecentList: NaTypes.LiveStreamHistoryList,
    liveStreamHistoryPopularList: NaTypes.LiveStreamHistoryList) => {
    return {
        type: FETCH_LSHISTORY_SUCCESS,
        liveStreamHistoryRecentList,
        liveStreamHistoryPopularList
    };
};

function fetchLiveStreamHistory(accessData: NaTypes.Authenticate): Function {
    return function (dispatch: any) {
        dispatch(fetchLiveStreamHistoryPending());
        let token = "";
        if(accessData.accountId)
        {
            token = generateToken(accessData.accountId!, accessData.accessToken!);
        }
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/type/all`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "token" : token
            },
        })
            .then((res) => res.json())
            .then((res) => {
                const liveStreamHistoryRecentList: NaTypes.LiveStreamHistoryList = res.liveStreamRecent;
                const liveStreamHistoryPopularList: NaTypes.LiveStreamHistoryList = res.liveStreamPopular;
                return dispatch(fetchLiveStreamHistorySuccess(liveStreamHistoryRecentList, liveStreamHistoryPopularList));
            });
    };
}

export {
    fetchLiveStreamHistory,
    fetchLiveStreamHistoryPending,
    fetchLiveStreamHistorySuccess,
};

export function LiveStreamHistoryState(
    state: NaTypes.LiveStreamHistoryState = initialState,
    action: any): NaTypes.LiveStreamHistoryState {
    switch (action.type) {
        case FETCH_LSHISTORY_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_LSHISTORY_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                liveStreamHistoryRecentList: action.liveStreamHistoryRecentList,
                liveStreamHistoryPopularList: action.liveStreamHistoryPopularList
            });
        default:
            return state;
    }
};