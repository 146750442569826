const initialState: NaTypes.SubjectState = {
  pending: false,
  subjects: [],
  error: null,
};

const FETCH_SUBJECT_PENDING = 'FETCH_SUBJECT_PENDING';
const fetchSubjectPending = () => {
  return {
    type: FETCH_SUBJECT_PENDING,
  };
};

const FETCH_SUBJECT_SUCCESS = 'FETCH_SUBJECT_SUCCESS';
const fetchSubjectSuccess = (subjects: Array<NaTypes.Subject>) => {
  return {
    type: FETCH_SUBJECT_SUCCESS,
    subjects,
  };
};

const FETCH_SUBJECT_ERROR = 'FETCH_SUBJECT_ERROR';
const fetchSubjectError = (error: string) => {
  return {
    type: FETCH_SUBJECT_ERROR,
    error,
  };
};

export const fetchSubjects = () => {
  return (dispatch: any) => {
    dispatch(fetchSubjectPending());
    const url = `${process.env.REACT_APP_API_URL_V2}/v1/subject/list?enabled=1`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
        .then((res) => res.json())
        .then((res) => {
          if (res.error === 0) {
            const subjects = res.data.subjects.map((s: any): NaTypes.Subject => {
              return {
                subjectId: s.subject_id,
                description: s.description,
                descriptionTh: s.description_th,
                displayOrder: s.display_order,
                isEnabled: s.enabled === 1 ? true : false,
                enableSubjectGrade: s.enable_subject_grade
              };
            });
            dispatch(fetchSubjectSuccess(subjects));
          } else {
            dispatch(fetchSubjectError(res.error));
          }
        })
        .catch((err) => {
          dispatch(fetchSubjectError(err));
        });
  };
};

/**
 * Subject state
 * @param {NaTypes.SubjectState} state
 * @param {any} action
 * @return {NaTypes.SubjectState} current state
 */
export function SubjectState(
    state: NaTypes.SubjectState = initialState,
    action: any): NaTypes.SubjectState {
  switch (action.type) {
    case FETCH_SUBJECT_PENDING:
      return Object.assign({}, state, {
        pending: true,
        subjects: [],
        error: null,
      });
    case FETCH_SUBJECT_SUCCESS:
      return Object.assign({}, state, {
        pending: false,
        subjects: action.subjects,
        error: null,
      });
    case FETCH_SUBJECT_ERROR:
      return Object.assign({}, state, {
        pending: false,
        error: action.error,
      });
    default:
      return state;
  }
};
