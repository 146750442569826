import {generateToken} from '../utility/Token';

export const trackStudyActivity = (accessData: NaTypes.Authenticate, subjectId: string, grade: string, topicId: string, chapterId: string, tag: string) => {
  if (typeof accessData.accountId !== 'string' || typeof accessData.accessToken !== 'string') {
    return;
  }
  const token = generateToken(accessData.accountId!, accessData.accessToken!);

  fetch(`${process.env.REACT_APP_API_URL_V2}/v1/tracking/study-activity`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          'grade': grade,
          'subject_id': subjectId,
          'topic_id': topicId,
          'chapter_id': chapterId,
          'tag': tag,
        }),
      })
      .then((res) => {})
      .catch((err) => console.log(err));
};
