import { generateToken } from '../utility/Token';

export const updateProfile = async (
  accessData: NaTypes.Authenticate,
  fullname: string,
  nickname: string,
  bio: string,
  phoneNumber: string,
  gradeCode: string,
  provinceCode: number,
  school: string,
  emailSubscribe: boolean,
  userEmail: string,
  emailPlayback: boolean,
  emailCert: boolean,
) => {
  const url = `${process.env.REACT_APP_API_URL_V2}/v1/user/profile/${accessData.accountId!}`;
  return await fetch(
    url,
    {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': generateToken(accessData.accountId!, accessData.accessToken!),
      },
      body: JSON.stringify({
        'fullname': fullname,
        'login': nickname,
        'status': bio,
        'phone': phoneNumber,
        'grade': gradeCode,
        'province': provinceCode,
        'nameschool': school,
        'email_subscribe': emailSubscribe,
        'email': userEmail,
        'email_playback': emailPlayback,
        'email_cert': emailCert,
      }),
    });
};


export const fetchProfileById = async (accessData: NaTypes.Authenticate, id: Number) => {
  const url = `${process.env.REACT_APP_API_URL_V2}/v1/user/profile/${id}`;

  return await fetch(
    url,
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': generateToken(accessData.accountId!, accessData.accessToken!),
      },
    })
    .then((res) => res.json())
    .then((res): NaTypes.Profile => {
      const profile: NaTypes.Profile = {
        id: res.data.id,
        fullname: res.data.fullname,
        grade: res.data.grade,
        parentEmail: res.data.parent_email,
        nameSchool: res.data.nameschool,
        login: res.data.login,
        province: res.data.province,
        status: res.data.status,
        lowPhotoUrl: res.data.low_photo_url,
        originPhotoUrl: res.data.origin_photo_url,
        normalPhotoUrl: res.data.normal_photo_url,
        bigPhotoUrl: res.data.big_photo_url,
        gradeDescriptionTh: res.data.grade_description_th,
        provinceNameLocal: res.data.province_name_local,
        phoneNumber: res.data.phone,
        emailSubscribe: res.data.email_subscribe,
        email: res.data.email,
        emailPlayback: res.data.email_playback == 1 ? true : false,
        emailCert: res.data.email_cert == 1 ? true : false,
      };
      return profile;
    })
    .catch((err) => console.log(err));
};
