import React from 'react';
import { useSelector } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import NumberFormat from 'react-number-format';
import Popover from 'react-bootstrap/Popover';
import { OverlayTrigger } from 'react-bootstrap'
import { AiFillQuestionCircle } from 'react-icons/ai';
import loading from '../../../images/loading/loading_spinner.svg';
import msgIcon from '../../../images/facebook/msg-icon.svg';
import phoneImg from '../../../images/other/phone.svg';



const PaymentStepTwoDetails = (props: {
    amount: number, cardNumber: string, cvv: string, dateTransfer: Date, expiryDate: string, method: number, nameOnCard: string,
    phone: string, qrCodeBank: string, transactionInfo: any, setAmount: React.Dispatch<React.SetStateAction<number>>,
    setCardNumber: React.Dispatch<React.SetStateAction<string>>, setCvv: React.Dispatch<React.SetStateAction<string>>,
    setDateTransfer: React.Dispatch<React.SetStateAction<Date>>, setExpiryDate: React.Dispatch<React.SetStateAction<string>>,
    setNameOnCard: React.Dispatch<React.SetStateAction<string>>, setPhone: React.Dispatch<React.SetStateAction<string>>
}) => {

    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const fullname = accessData.account.fullname;

    const UNIT = new Map([
        ['SECOND', 'วินาที'], ['MINUTE', 'นาที'], ['HOUR', 'ชั่วโมง'], ['DAY', 'วัน'],
        ['WEEK', 'สัปดาห์'], ['MONTH', 'เดือน'], ['YEAR', 'ปี']
    ]);



    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const keyMap = { 'จ': 0, 'ๅ': 1, '/': 2, '_': 3, 'ภ': 4, 'ถ': 5, 'ุ': 6, 'ึ': 7, 'ค': 8, 'ต': 9, };
        let value = e.target.value;
        value = Object.entries(keyMap).reduce((acc, [k, v]) => acc.replace(new RegExp(k, 'g'), v.toString()), value);
        if (/^\d{0,10}$/.test(value)) props.setPhone(value);
    };

    const PaymentPopover = (
        <Popover id="popover-basic">
            <Popover.Content>
                3-digit number on the back of your credit card
            </Popover.Content>
        </Popover>
    );



    return (
        <>
            <div className="payment-amount-container">
                <p className="payment-amount-text">
                    จำนวนเงิน{' '}
                    <NumberFormat
                        className="payment-amount-text-span" displayType={'text'}
                        value={props.amount.toFixed(2)} thousandSeparator={true}
                    />{' '}
                    บาท / {props.transactionInfo.lifetime_of_product}{' '}
                    {UNIT.get(props.transactionInfo.unit_of_product)}
                </p>

                {props.method === 3 && (
                    <p style={{ color: 'black', border: "dashed red 3px", fontSize: "large", padding: "10px", marginTop: "10px", textAlign: "center" }}>
                        หากชำระผ่านบัตรเครดิต <br />ระบบจะทำการต่ออายุอัตโนมัติทันทีเมื่อหมดอายุการใช้งาน
                    </p>
                )}
            </div>

            {props.method === 1 && (
                <>
                    <div className="qr-payment-section" style={{ border: "none" }} hidden={props.qrCodeBank !== ""}>
                        <img style={{ maxHeight: "250px" }} src={loading} />
                    </div>
                    <div className="qr-payment-section" hidden={props.qrCodeBank === ""}>
                        <img src={props.qrCodeBank} />
                        <a href={props.qrCodeBank}>Download</a>
                    </div>
                    <div>
                        * ระบบจะเปลี่ยนหน้าอัตโนมัติหลังการชำระเงินเสร็จสิ้น
                    </div>
                </>
            )}

            {props.method === 2 && (
                <>
                    <div className="col-12 pt-2">
                        <p className="m-0">จำนวนเงิน</p>
                    </div>
                    <div className="col-12 input-premium-date">
                        <input
                            placeholder="จำนวนเงิน" disabled={true} type="number" value={props.amount.toFixed(2)}
                            onChange={(e) => props.setAmount(parseFloat(e.target.value))} required
                        />
                    </div>
                    <div className="col-12 pt-2">
                        <p className="m-0">วันที่โอน</p>
                    </div>
                    <div className="col-12 input-premium-date">
                        <DayPickerInput
                            value={props.dateTransfer} format={'YYYY/M/D'} onDayChange={(d) => props.setDateTransfer(d)}
                            formatDate={(d) => { return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`; }}
                            classNames={{
                                container: '', overlayWrapper: 'position-relative overTop', overlay: 'position-relative overTop',
                            }}
                        />
                        <div className="premium-date-hint mt-1">
                            (กรอกเวลาตามสลิปที่ได้จากการโอน) เช่น 24/09/2022
                        </div>
                    </div>
                    <div className="col-12 pt-4">
                        <p className="m-0">ส่งสลิปหลักฐานการโอน</p>
                    </div>
                    <div className="col-12 mt-2">
                        <div className="premium-msg-button" onClick={() => {
                            window.open(`https://m.me/NockAcademyElementary?text=ขอแจ้งโอนเงินสำหรับผู้ใช้งาน%20–%20${fullname}`, '_blank');
                        }}>
                            <img src={msgIcon} alt="Messenger Icon" />
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <p className="m-0 premium-date-hint">
                            ส่งรูปสลิปจากการโอนของคุณให้เราเพื่อยืนยันการโอน
                        </p>
                    </div>
                </>
            )}

            {props.method === 3 && (
                <>
                    <div className="col-12 pt-2">
                        <p className="m-0">Card number</p>
                    </div>
                    <div className="col-12 input-premium-date">
                        <NumberFormat
                            placeholder="_ _ _ _  _ _ _ _  _ _ _ _  _ _ _ _" value={props.cardNumber}
                            format="#### #### #### ####" autoComplete="cc-number"
                            onChange={(e) => props.setCardNumber(e.target.value)} required
                        />
                    </div>
                    <div className="col-12 pt-2">
                        <p className="m-0">Name on card</p>
                    </div>
                    <div className="col-12 input-premium-date">
                        <input
                            placeholder="Ex. John Wick" type="text" name="name-on-card" value={props.nameOnCard}
                            onChange={(e) => props.setNameOnCard(e.target.value)} required
                        />
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="col-12 pt-2">
                                <p className="m-0">Expiry date</p>
                            </div>
                            <div className="col-12 input-premium-date">
                                <NumberFormat
                                    format="##/##" placeholder="MM/YY" value={props.expiryDate} autoComplete="cc-exp"
                                    onChange={(e) => props.setExpiryDate(e.target.value)} required
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="col-12 pt-2">
                                <div className="row">
                                    <div className="col-8">
                                        <p className="m-0">Security code</p>
                                    </div>
                                    <div className="col-4 text-right">
                                        <OverlayTrigger trigger="click" placement="right" overlay={PaymentPopover}>
                                            <AiFillQuestionCircle />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 input-premium-date">
                                <NumberFormat
                                    format="###" placeholder="CVV" value={props.cvv} autoComplete="cc-csc"
                                    onChange={(e) => props.setCvv(e.target.value)} required
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {props.method === 4 && (
                <>
                    <div className="col-12 pt-2">
                        <p className="m-0">เบอร์โทรศัพท์</p>
                    </div>
                    <div className="col-12 input-premium">
                        <img src={phoneImg} alt="Phone Icon" />
                        <input
                            placeholder="เบอร์โทรศัพท์" type="tel" pattern="[0-9]{10}" minLength={10}
                            value={props.phone} onChange={handlePhoneChange} required
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default PaymentStepTwoDetails;
