import { Dispatch } from 'redux';

interface PlaybackTypeFetch {
    type_id: string;
    description: string;
    description_th: string;
    display_order: number;
    enabled: number;
}



const initialState: NaTypes.PlaybackTypeState = {
    pending: false,
    playbackTypes: [],
    error: null,
};



const FETCH_PLAYBACKTYPE_PENDING = 'FETCH_PLAYBACKTYPE_PENDING';
const fetchPlaybackPending = () => ({
    type: FETCH_PLAYBACKTYPE_PENDING,
});

const FETCH_PLAYBACKTYPE_SUCCESS = 'FETCH_PLAYBACKTYPE_SUCCESS';
const fetchPlaybackSuccess = (playbackTypes: Array<NaTypes.PlaybackType>) => ({
    type: FETCH_PLAYBACKTYPE_SUCCESS,
    playbackTypes,
});

const FETCH_PLAYBACKTYPE_ERROR = 'FETCH_PLAYBACKTYPE_ERROR';
const fetchPlaybackError = (error: string) => ({
    type: FETCH_PLAYBACKTYPE_ERROR,
    error,
});



export const fetchPlaybackTypes = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(fetchPlaybackPending());
        try {
            const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/lsplayback/types`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();

            if (res.error === 0) {
                const playbackTypes = res.data.map((t: PlaybackTypeFetch): NaTypes.PlaybackType => {
                    return {
                        typeId: t.type_id,
                        description: t.description,
                        descriptionTh: t.description_th,
                        displayOrder: t.display_order,
                        isEnabled: t.enabled === 1 ? true : false,
                    };
                });
                dispatch(fetchPlaybackSuccess(playbackTypes));
            } else {
                dispatch(fetchPlaybackError(res.error));
            }
        } catch (err) {
            if (err instanceof Error) {
                dispatch(fetchPlaybackError(err.message));
            } else {
                dispatch(fetchPlaybackError('An unknown error occurred'));
            }
        }
    };
};



export const PlaybackState = (
    state = initialState,
    action: any
) => {
    switch (action.type) {
        case FETCH_PLAYBACKTYPE_PENDING:
            return {
                ...state,
                pending: true,
                playbackTypes: [],
                error: null,
            };
        case FETCH_PLAYBACKTYPE_SUCCESS:
            return {
                ...state,
                pending: false,
                playbackTypes: action.playbackTypes,
                error: null,
            };
        case FETCH_PLAYBACKTYPE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
};
