import { Dispatch } from 'redux';
import { History } from 'history';
import { MixpanelTracking } from '../../utility';
import { calculateTest } from '../../api/MiniTest';
import { createPost, recordStudyTime } from '../../api/VideoOnDemand';
import { trackStudyActivity } from '../../api/Tracking';
import { showScore } from '../../stores/mini-test/MiniTestState';
import './MiniTest.css';



export const submitAnswer = (
    accessData: NaTypes.Authenticate,
    miniTestState: NaTypes.MiniTest,
    subjectState: NaTypes.SubjectState,
    chapter: NaTypes.Chapter,
    refCountStayingTime: number,
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>,
    dispatch: Dispatch<any>,
) => {
    const selectedQuestions = miniTestState.test!.testQuestionList.filter(question => question.selectedAnswer === '0');

    if (selectedQuestions.length === 0) {
        const correctAnswers = miniTestState.test!.testQuestionList.filter(question => {
            const selectedChoice = question.choiceList.find(c => c.id === question.selectedAnswer);
            return selectedChoice?.isCorrect === true;
        });

        const score = correctAnswers.length;
        const maxScore = miniTestState.test!.testQuestionList.length;

        const subject = subjectState.subjects.find(s => s.subjectId === chapter.subject);
        const descriptionTh = subject?.descriptionTh || '';

        calculateTest(accessData, miniTestState.test!.testId, miniTestState.testAttempt.test_attempt_no)
            .then(() => {
                setShowAlert(false);
                dispatch(showScore());
                createPost(accessData, chapter.subject, descriptionTh, refCountStayingTime);
                recordStudyTime(accessData, chapter.subject, refCountStayingTime);
                trackStudyActivity(accessData, chapter.subject, chapter.grade, chapter.topicId, chapter.chapterId, 'minitest');
                const status = score === maxScore ? 'Perfect' : 'Finish';
                MixpanelTracking.miniTestFinished(chapter, descriptionTh, score, maxScore, refCountStayingTime, status);
            });
    } else {
        setShowAlert(true);
    }
};



export const nextLesson = (
    lessonSetList: NaTypes.LessonSetList,
    subject: string,
    grade: string,
    topic: string,
    chapter: NaTypes.Chapter,
    chapters: NaTypes.ChapterList,
    history: History<any>,
    setChapter: React.Dispatch<React.SetStateAction<NaTypes.Chapter | undefined>>,
    setActiveChapter: React.Dispatch<React.SetStateAction<string | undefined>>,
    setState: React.Dispatch<React.SetStateAction<string | undefined>>,
) => {
    const currentLessonIndex = lessonSetList.findIndex(l => l.subject === subject && l.grade === grade);
    const currentTopicIndex = lessonSetList[currentLessonIndex].listLesson.findIndex(t => t.topicId + '' === topic + '');
    const currentChapterIndex = lessonSetList[currentLessonIndex].listLesson[currentTopicIndex].chapterList.findIndex(c => c.chapterId === chapter.chapterId);
    const nextChapter = lessonSetList[currentLessonIndex].listLesson[currentTopicIndex].chapterList[currentChapterIndex + 1];

    if (nextChapter) {
        setChapter(chapters[currentChapterIndex + 1]);
        setActiveChapter(nextChapter.chapterId);
    } else {
        const nextTopic = lessonSetList[currentLessonIndex].listLesson[currentTopicIndex + 1];
        const target = nextTopic || lessonSetList[currentLessonIndex].listLesson[0];
        history.push(`/video/${target.chapterList[0].subject}/${target.chapterList[0].grade}/${target.chapterList[0].topicId}`);
        window.location.reload();
    }

    setState('INTRO');
};
