import React from 'react';
import badge from '../../images/promote/badge.svg';
import bank from '../../images/logo/bank.svg';
import credit from '../../images/logo/credit.svg';
import promptPay from '../../images/logo/prompt_pay.svg';



const ProductItem = (props: {
    product: {
        product_id: number;
        is_promote: string;
        description_th: string;
        save_off_text: string;
        subscription_months: number;
        price: number;
        is_bank_transfer: boolean;
    };
}) => {

    const handleItemClick = () => {
        window.location.href = `${process.env.REACT_APP_WEB_URL}/pay/${props.product.product_id}?come_from=learnPage`;
    };

    return (
        <div className="col-12 col-md-6 col-xl-4 p-0 main-course">
            <div className="course-item" onClick={handleItemClick}>
                {props.product.is_promote === "1" && <img src={badge} className="badge-img" />}

                <div className="product-description">{props.product.description_th}</div>
                <div className="bargain-description">{props.product.save_off_text}</div>

                {props.product.save_off_text ? (
                    <div className="bargain-container">
                        <u className="bargain-old-price">
                            {(550 * props.product.subscription_months).toLocaleString(undefined, { maximumFractionDigits: 2 })} ฿
                        </u>
                    </div>
                ) : (
                    <div className="bargain-container-blank"></div>
                )}

                <div className="price-main"><b>{parseInt(props.product.price.toString()).toLocaleString()} ฿</b></div>

                <div>{(props.product.price / props.product.subscription_months).toLocaleString(undefined, { maximumFractionDigits: 2 })} บาท / เดือน</div>

                <div className="payment-description">
                    <div >บัตรเครดิต</div>
                    {props.product.is_bank_transfer && <div>โอนผ่านธนาคาร</div>}
                    {props.product.is_bank_transfer && <div>พร้อมเพย์</div>}
                </div>

                <div className="subscribe-button">สมัคร</div>
            </div>

            <div className="course-item-images">
                <div className="course-item-image-container">
                    <img src={credit} className="course-item-image" alt="Credit Card" />
                </div>

                {props.product.is_bank_transfer && (
                    <div className="course-item-image-container">
                        <img src={bank} className="course-item-image" alt="Bank Transfer" />
                    </div>
                )}

                {props.product.is_bank_transfer && (
                    <div className="course-item-image-container">
                        <img src={promptPay} className="course-item-image" alt="PromptPay" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductItem;
