import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { generateToken } from '../../../utility/Token';
import { reloadTransaction } from '../../../stores/transaction/Transaction';
import { copyNumber, QRCode, redeemCode } from '../PaymentUtils';
import amexLogo from '../../../images/omise/card-logo-amex.svg';
import coupon from '../../../images/discount/coupon.png';
import credit from '../../../images/other/credit.svg';
import mastercardLogo from '../../../images/omise/card-logo-mastercard.svg';
import PromptPay from '../../../images/bank/PromptPay2.png';
import rabbitLinePayLogo from '../../../images/omise/rabbit-line-pay-logo.png';
import shopeePayLogo from '../../../images/shopee-pay/shopee-pay-logo.png';
import tickBlank from '../../../images/other/tick-blank.svg';
import tickChoose from '../../../images/other/tick-choose.svg';
import truemoneyWalletLogo from '../../../images/omise/truemoney-wallet-logo.png';
import unknownLogo from '../../../images/omise/card-logo-unknown.svg';
import visaLogo from '../../../images/omise/card-logo-visa.svg';



const PaymentStepTwoSelection = (props: {
    actualAmount: number, amount: number, couponCode: string, discountAmount: number, errorCode: string, method: number,
    packageId?: string | undefined, qrCodeBank: string, transactionInfo: any, setActualAmount: React.Dispatch<React.SetStateAction<number>>,
    setAmount: React.Dispatch<React.SetStateAction<number>>, setCouponCode: React.Dispatch<React.SetStateAction<string>>,
    setDiscountAmount: React.Dispatch<React.SetStateAction<number>>, setErrorCode: React.Dispatch<React.SetStateAction<string>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>, setMethod: React.Dispatch<React.SetStateAction<number>>,
    setQrCodeBank: React.Dispatch<React.SetStateAction<string>>, setTransactionInfo: React.Dispatch<any>,
}) => {

    const dispatch = useDispatch();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const productList = useSelector((state: NaTypes.Store) => state.transaction.productList);
    const transaction = useSelector((state: NaTypes.Store) => state.transaction);

    const at = accessData.accessToken ? accessData.accessToken : '';
    const aid = accessData.accountId ? accessData.accountId : '';
    const currentTransaction = transaction.transactionList.find((t) => t.status === '3' || t.status === '4');



    const changePlan = async (selectPlan: String) => {
        try {
            const product = productList.find((t) => t.product_id === selectPlan);
            const formData = new URLSearchParams();

            props.setAmount(Number(product.price));
            props.setActualAmount(Number(product.price));
            props.setDiscountAmount(0);

            formData.append('access_token', generateToken(aid, at));
            formData.append('accountId', aid);
            formData.append('transactionId', currentTransaction.transaction_id);
            formData.append('packageId', product.product_id);

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v7/method/paymentSystem.banktranfer.change`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            const result = await response.json();
            dispatch(reloadTransaction(at, aid));
            props.setTransactionInfo(result); props.setCouponCode(""); props.setErrorCode("");

        } catch (error) {
            console.error(error);
        }
    };

    const omiseQRHandler = async (resOmiseSource: any) => {
        try {
            const userToken = generateToken(aid, at);

            const response = await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/omise/charge`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': userToken,
                },
                body: JSON.stringify({
                    'source': resOmiseSource,
                    'transaction_id': currentTransaction.transaction_id,
                    'return_uri': `${process.env.REACT_APP_WEB_URL}/payment-history`,
                }),
            });

            const result = await response.json();
            if (result.error === 0) props.setQrCodeBank(result.data.source.scannable_code.image.download_uri);

        } catch (error) {
            console.error(error);
            if (error instanceof Error) {
                props.setErrorMessage(error.message);
            } else {
                props.setErrorMessage('An unknown error occurred.');
            }
            toast.warning("ขณะนี้ QRcode มีปัญหา กรุณาเลือกช่องทางชำระอื่น");
            props.setMethod(2);
        }
    };


    return (
        <>
            <div className="mt-4 premium-title">ชำระค่าบริการ</div>

            <div className="mt-1 premium-text-payment">
                คอร์สเรียนที่ท่านเลือกอยู่ตอนนี้คือ&nbsp;
                {props.packageId == "21" ? (
                    "แพ็คเกจ 1 ปี แถมฟรี 1 เดือน"
                ) : (
                    <select
                        onChange={(e) => {
                            changePlan(e.target.value);
                            props.setMethod(3);
                            props.setQrCodeBank("");
                        }}
                        className="form-select-sm form-payment"
                        value={props.transactionInfo.product_id}
                    >
                        {productList.map((m) => (
                            <option value={m.product_id}>{m.description_th}</option>
                        ))}
                    </select>
                )}
            </div>

            <table>
                <tbody>
                    <tr hidden={currentTransaction.code_name && !(currentTransaction.code_name != null && currentTransaction.code_name != "")}>
                        <td className="mt-1 premium-text-payment" style={{ minWidth: "150px" }}>ยอดจริง</td>
                        <td className="mt-1 premium-text-payment" style={{ minWidth: "60px" }}>{props.actualAmount.toFixed(2)}</td>
                        <td className="mt-1 premium-text-payment">บาท</td>
                    </tr>
                    <tr hidden={currentTransaction.code_name && !(currentTransaction.code_name != null && currentTransaction.code_name != "")}>
                        <td className="mt-1 premium-text-payment">ยอดส่วนลด</td>
                        <td className="mt-1 premium-text-payment">{props.discountAmount.toFixed(2)}</td>
                        <td className="mt-1 premium-text-payment">บาท</td>
                    </tr>
                    <tr>
                        <td className="mt-1 premium-text-payment green">ยอดที่ต้องชำระ</td>
                        <td className="mt-1 premium-text-payment green">{props.amount.toFixed(2)}</td>
                        <td className="mt-1 premium-text-payment green">บาท</td>
                    </tr>
                </tbody>
            </table>

            <div className="mt-4 premium-text">
                {currentTransaction.code_name != null && currentTransaction.code_name != "" ? (
                    <>
                        ตอนนี้ท่านใช้โค้ด {currentTransaction.code_name} อยู่ <a className="cancle-code" onClick={() => changePlan(props.transactionInfo.product_id + "")}>ยกเลิกโค้ด</a> <br />
                        *โค้ดนี้ใช้ได้กับการชำระเงินครั้งแรกเท่านั้น การชำระเงินครั้งต่อไปจะเป็นราคาปกติ
                    </>
                ) : props.errorCode}
            </div>

            <div style={{ display: "flex", textAlign: "center" }}>
                <div className="col-8 input-signup">
                    <img src={coupon} alt="" />
                    <input
                        type="text"
                        autoComplete="off"
                        name="code"
                        value={props.couponCode}
                        placeholder="Coupon Code"
                        onChange={(e) => props.setCouponCode(e.target.value)}
                    />
                </div>
                <div className="col-4 input-signup" style={{ margin: "auto" }}>
                    <button
                        type='button'
                        onClick={() => redeemCode(
                            accessData, props.actualAmount, props.amount, props.couponCode, currentTransaction, dispatch, props.setAmount,
                            props.setCouponCode, props.setDiscountAmount, props.setErrorCode, props.setMethod, props.setTransactionInfo
                        )}
                        className='btn-success'
                    >
                        Redeem Code
                    </button>
                </div>
            </div>

            <div className="mt-4 premium-text">เลือกช่องทางการชำระบริการ</div>

            <div className="premuim-bank-select-layout">
                {currentTransaction.product_is_promotion === '0' &&
                    <div className={`premuim-bank-select p-2 ${props.method === 3 ? 'active' : ''}`}
                        onClick={() => props.setMethod(3)}
                    >
                        <img src={props.method === 3 ? tickChoose : tickBlank} alt="" />
                        <div className="d-flex justify-content-center w-100">
                            <div>
                                <p className='text-center font-weight-bold'>Credit / Debit</p>
                                <img className="mr-2 img-phone" src={visaLogo} height="35px" alt="" />
                                <img className="mr-2 img-phone" src={mastercardLogo} height="35px" alt="" />
                                <img className="mr-2 img-phone" src={amexLogo} height="35px" alt="" />
                                <img className="mr-2 img-phone-disappear" src={unknownLogo} height="35px" alt="" />
                            </div>
                        </div>
                    </div>
                }

                {currentTransaction.product_is_bank_transfer === '1' &&
                    <>
                        <div className={`premuim-bank-select p-2 ${props.method === 1 ? 'active' : ''}`}
                            onClick={() => {
                                props.setMethod(1);
                                QRCode(props.amount, props.qrCodeBank, props.setErrorMessage, props.setMethod, omiseQRHandler);
                            }}
                        >
                            <img src={props.method === 1 ? tickChoose : tickBlank} alt="" />
                            <div className="d-flex justify-content-center w-100">
                                <div>
                                    <p className='text-center font-weight-bold'>Prompt Pay</p>
                                    <img id="premium-prompt-image" src={PromptPay} alt="" />
                                </div>
                            </div>

                        </div>
                        <div className={`premuim-bank-select p-2 ${props.method === 2 ? 'active' : ''}`}
                            onClick={() => { props.setMethod(2); copyNumber(2); }}
                        >
                            <img src={props.method === 2 ? tickChoose : tickBlank} alt="" />
                            <div>
                                <div style={{ width: '88px' }}>
                                    <img src={credit} width="68px" height="29px" alt="" />
                                </div>
                                <p className="premium-method-text m-0">Bank Account</p>
                            </div>
                            <div className="premium-method-info img-phone-disappear">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <p className="m-0 premium-bank-text bold">
                                            Liclass Education co.,Ltd.
                                        </p>
                                        <p className="m-0 premium-bank-text bold">
                                            Krungsri Bank
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0 premium-bank-text bold">Bank Account</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0 premium-bank-text text-right">
                                            599-1-39010-2
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <p className="m-0 premium-bank-text bold">Branch</p>
                                    </div>
                                    <div className="col-8">
                                        <p className="m-0 premium-bank-text text-right">
                                            Robinson Sriracha
                                        </p>
                                    </div>
                                    <div className="col-8">
                                        <p className="m-0 premium-bank-text bold">Account type</p>
                                    </div>
                                    <div className="col-4">
                                        <p className="m-0 premium-bank-text text-right">Saving</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0 premium-bank-text bold">Amount</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0 premium-bank-text text-right">{props.amount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {currentTransaction.product_is_truemoney === '1' &&
                    <div className={`premuim-bank-select p-2 ${props.method === 4 ? 'active' : ''}`}
                        onClick={() => props.setMethod(4)}
                    >
                        <img src={props.method === 4 ? tickChoose : tickBlank} alt="" />
                        <div className="d-flex justify-content-center w-100">
                            <div>
                                <img className="mr-2 img-logo-phone" src={truemoneyWalletLogo} height="80px" alt="" />
                            </div>
                        </div>
                    </div>
                }

                {currentTransaction.product_is_rabbit_linepay === '1' &&
                    <div className={`premuim-bank-select p-2 ${props.method === 5 ? 'active' : ''}`}
                        onClick={() => props.setMethod(5)}
                    >
                        <img src={props.method === 5 ? tickChoose : tickBlank} alt="" />
                        <div className="d-flex justify-content-center w-100">
                            <div>
                                <img className="mr-2 img-logo-phone" src={rabbitLinePayLogo} height="50px" alt="" />
                            </div>
                        </div>
                    </div>
                }

                {currentTransaction.product_is_shopee_pay === '1' &&
                    <div className={`premuim-bank-select p-2 ${props.method === 6 ? 'active' : ''}`}
                        onClick={() => props.setMethod(6)}
                    >
                        <img src={props.method === 6 ? tickChoose : tickBlank} alt="" />
                        <div className="d-flex justify-content-center w-100">
                            <div>
                                <img className="mr-2 img-logo-phone" src={shopeePayLogo} height="50px" alt="" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default PaymentStepTwoSelection;
