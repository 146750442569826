import React from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setAccessData } from '../../../stores/authorization/AuthorizationState';
import { resetLessonSet } from '../../../stores/lesson-list/LessonSetListState';
import { MixpanelTracking } from '../../../utility/MixpanelTracking';
import facebookFIcon from '../../../images/facebook/facebook_f.svg';



const NaLoginStepVerifyOTP = (props: {
    phone: string,
    otp: string,
    setOtp: React.Dispatch<React.SetStateAction<string>>,
    setState: React.Dispatch<React.SetStateAction<string>>,
    requestOtp: (phoneNumber: string) => Promise<any>,
    verifyOtp: (phoneNumber: string, otp: string) => Promise<any>,
    logInFacebook: () => void,
    trySignInWithPhoneNumberAndOTP: (phoneNumber: string, otp: string) => Promise<any>
}) => {
    const dispatch = useDispatch();
    const isMobile = window.innerWidth <= 575;
    const title = isMobile ? 'ลงชื่อเข้าใช้งาน' : 'ลงชื่อเข้าใช้งาน | สร้างบัญชีฟรี';

    return (
        <div className="login-bg position-fixed">
            <div className="row p-4">

                <div className="col-12">
                    <h3 className="text-center register-title">{title}</h3>
                    <h5 className="text-center">ใส่รหัส 4 หลัก ที่นี่</h5>
                    <p className="login-text">
                        โปรดใส่รหัสที่ส่งไปที่กล่องข้อความของเบอร์ {props.phone}
                    </p>
                </div>

                <div className="col-12" style={{ marginTop: '6px' }}>
                    <div className='row'>
                        <div className="col-10 col-sm-8 otp-container">
                            <OtpInput
                                value={props.otp}
                                onChange={(e: string) => props.setOtp(e)}
                                numInputs={4}
                                inputStyle={"otp-input"}
                                separator={<span></span>}
                                isInputNum={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12" style={{ marginTop: '30px' }}>
                    <p className="login-text">
                        โปรดป้อนรหัสที่คุณได้รับจาก NockAcademy ทาง SMS ของคุณ หากคุณไม่ได้รับรหัส โปรดกดปุ่ม
                    </p>
                    <div className="d-flex justify-content-center" style={{ marginBottom: '20px' }}>
                        <b><a href="#" onClick={() => {
                            props.requestOtp(props.phone);
                        }} className="can-click">ขอรหัสใหม่</a></b>
                    </div>
                </div>

                <div className="col-12">
                    <button id="login-button2" onClick={() => {
                        let text = '';
                        if (props.otp.length === 4) {
                            props.verifyOtp(props.phone, props.otp)
                                .then((res) => {
                                    if (res.error === true) {
                                        toast.error(res.error_description, { position: "top-center", autoClose: 5000, hideProgressBar: false });
                                    } else {
                                        props.trySignInWithPhoneNumberAndOTP(props.phone, text)
                                            .then((res) => {
                                                if (res.error_code === 404) {
                                                    props.setState('SIGN_UP');
                                                } else if (res.error_code === 0) {
                                                    MixpanelTracking.identify(res.accountId);
                                                    MixpanelTracking.signIn();
                                                    dispatch(resetLessonSet());
                                                    dispatch(setAccessData({
                                                        requestLogin: false,
                                                        accessToken: res.accessToken,
                                                        accountId: res.accountId,
                                                        account: res.account[0],
                                                    }));
                                                    window.location.reload();
                                                } else if (res.error === true) {
                                                    toast.error(res.error_description, { position: "top-center", autoClose: 5000, hideProgressBar: false });
                                                }
                                            });
                                    }
                                });
                        }
                    }}>
                        <p className="m-0">ลงชื่อเข้าใช้งาน</p>
                    </button>
                </div>

                <div className="col-12 center register-line" style={{ marginTop: '30px' }}>
                    <p className="line"></p>
                </div>

                <div className="col-12 center" >
                    <p className="login-text">เข้าใช้งานด้วยวิธีอื่น</p>
                </div>

                {isMobile ? (
                    <div className="col-12">
                        <div className="login-facebook-button2 can-click" onClick={() => {
                            props.logInFacebook();
                        }}><img className="fa-facebook" src={facebookFIcon} alt="Facebook Icon" />ผ่านเฟสบุ๊ค</div>
                    </div>
                ) : (
                    <div className="col-12">
                        <div className="login-facebook-button2 can-click" onClick={() => {
                            props.logInFacebook();
                        }}><img className="fa-facebook" src={facebookFIcon} alt="Facebook Icon" /> ลงชื่อเข้าใช้งานผ่านเฟสบุ๊ค</div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default NaLoginStepVerifyOTP;
