import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import provinceJson from '../../json/thai_provinces.json';
import districtJson from '../../json/thai_amphures.json';
import subdistrictJson from '../../json/thai_tambons.json';
import './AddressModal.css';

interface AddressModalProps {
    isOpen: boolean; onClose: () => void; onSelect: (address: string) => void;
}

interface District {
    id: number; name_th: string; province_id: number;
}

interface Subdistrict {
    id: number; zip_code: number; name_th: string; amphure_id: number;
}

interface SelectedAddress {
    province: string; district: string; subdistrict: string; zipCode: string;
}



const AddressModal: React.FC<AddressModalProps> = ({ isOpen, onClose, onSelect }) => {

    const [selected, setSelected] = useState<SelectedAddress>({ province: '', district: '', subdistrict: '', zipCode: '' });
    const [districtOptions, setDistrictOptions] = useState<District[]>([]);
    const [subdistrictOptions, setSubdistrictOptions] = useState<Subdistrict[]>([]);
    const [step, setStep] = useState(1);



    const handleConfirm = () => {
        const address = [
            getSubdistrictName(selected.subdistrict), getDistrictName(selected.district),
            getProvinceName(selected.province), selected.zipCode
        ].filter(Boolean).join(', ');
        onSelect(address); onClose();
    };

    const getProvinceName = (id: string) => provinceJson.find(p => p.id === Number(id))?.name_th || '';
    const getDistrictName = (id: string) => districtOptions.find(d => d.id === Number(id))?.name_th || '';
    const getSubdistrictName = (id: string) => subdistrictOptions.find(s => s.id === Number(id))?.name_th || '';

    const handleReset = () => {
        setSelected({ province: '', district: '', subdistrict: '', zipCode: '' });
        setDistrictOptions([]); setSubdistrictOptions([]); setStep(1);
    };

    const renderOptions = (options: any[], onSelect: (id: string) => void) => (
        <div className="address-modal-scrollable-list">
            {options.map(option => (
                <div key={option.id} className="address-modal-clickable-item" onClick={() => onSelect(option.id.toString())} >
                    {option.name_th}
                </div>
            ))}
        </div>
    );

    const getModalTitle = () => {
        const subdistrictName = getSubdistrictName(selected.subdistrict);
        const districtName = getDistrictName(selected.district);
        const provinceName = getProvinceName(selected.province);

        const addressParts = [
            subdistrictName || null, districtName || null, provinceName || null, selected.zipCode || null
        ].filter(Boolean);

        return addressParts.length > 0
            ? addressParts.join(', ')
            : 'เลือกที่อยู่';
    };

    const handleSelectSubdistrict = (id: string) => {
        const selectedSubdistrict = subdistrictOptions.find(s => s.id === Number(id));
        setSelected(prev => ({
            ...prev, subdistrict: id, zipCode: selectedSubdistrict ? selectedSubdistrict.zip_code.toString() : ''
        }));
        setStep(4);
    };

    const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const numericValue = value.replace(/\D/g, '');
        setSelected(prev => ({ ...prev, zipCode: numericValue }));
    };



    useEffect(() => {
        if (selected.province) {
            setDistrictOptions(districtJson.filter(d => d.province_id === Number(selected.province)));
            setSelected(prev => ({ ...prev, district: '', subdistrict: '' }));
            setSubdistrictOptions([]); setStep(2);
        }
    }, [selected.province]);

    useEffect(() => {
        if (selected.district) {
            setSubdistrictOptions(subdistrictJson.filter(s => s.amphure_id === Number(selected.district)));
            setSelected(prev => ({ ...prev, subdistrict: '' }));
        }
    }, [selected.district]);



    return (
        <Modal show={isOpen} onHide={onClose} className="address-modal-modal" dialogClassName="modal-dialog-centered"  >

            <Modal.Header closeButton className="address-modal-header">
                <Modal.Title className="address-modal-title">{getModalTitle()}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="address-modal-body">
                {step === 1 && (
                    <>
                        <h5 className="address-modal-h5">เลือกจังหวัด:</h5>
                        {renderOptions(provinceJson, id => { setSelected(prev => ({ ...prev, province: id })); setStep(2); })}
                    </>
                )}
                {step === 2 && (
                    <>
                        <h5 className="address-modal-h5">เลือกอำเภอ:</h5>
                        {renderOptions(districtOptions, id => { setSelected(prev => ({ ...prev, district: id })); setStep(3); })}
                    </>
                )}
                {step === 3 && (
                    <>
                        <h5 className="address-modal-h5">เลือกตำบล:</h5>
                        {renderOptions(subdistrictOptions, handleSelectSubdistrict)}
                    </>
                )}
                {step === 4 && (
                    <div className="address-modal-step-4">
                        <h5 className="address-modal-h5">รหัสไปรษณีย์:</h5>
                        <input
                            type="text" value={selected.zipCode} onChange={handleZipCodeChange} maxLength={5}
                            pattern="[0-9]*" inputMode="numeric" className="address-modal-input"
                        />
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer className="address-modal-footer">
                <Button variant="light" onClick={handleReset} className="address-modal-btn address-modal-btn-light">เลือกใหม่</Button>
                {step === 4 && <Button variant="primary" onClick={handleConfirm} className="address-modal-btn address-modal-btn-primary">ยืนยัน</Button>}
            </Modal.Footer>

        </Modal>
    );
};

export default AddressModal;
