import React, { useRef, useEffect, useState, } from 'react';
import { useParams } from 'react-router';
import './ExamAnswer.css'
import NaTypes, { ExamList, ExamSubmitAnswer, ExplanationVideo } from '../../types';
import { generateToken } from '../../utility/Token';
import { useSelector, useDispatch } from 'react-redux';
import Latex from 'react-latex';
import { ImageUtility } from '../../utility';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import VideoExplain from '../../components/video-explain/VideoExplain';


const ExamAnswer = () => {
    const { grade, quarter, id }: any = useParams();
    const accessData: NaTypes.Authenticate = useSelector((state: NaTypes.Store) => state.authenticate);
    const [exams, setExams] = useState<Array<ExamList>>([]);
    const [exam, setExam] = useState<ExamList>();
    const gradeState = useSelector((state: NaTypes.Store) => state.grade);
    const examContainer = useRef<HTMLDivElement>(null);
    const [currentSection, setCurrentSection] = useState<number>(0);
    const [examResult, setExamResult] = useState<any>();
    const [isStaying, setIsStaying] = useState<boolean>(false);
    const [videoState, setVideoState] = useState<any>(null);
    const [isWatching, setIsWatching] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [currentExplain, setCurrentExplain] = useState<number>(0);
    const [lastPosition, setLastPosition] = useState<number>(1);

    useEffect(() => {
        if (!exam) {
            const fetch = async () => {
                setExams(await getExamList());
            };
            fetch().catch(console.error);
        }
    }, []);

    useEffect(() => {
        if (!examResult) {
            const fetch = async () => {
                const quest = await getAnswerExam();
                if (quest) {
                    setExamResult(quest);
                }
            };
            fetch().catch(console.error);
        }
    }, []);

    useEffect(() => {
        setExam(exams.filter(ex => ex.questionSetId == id)[0]);
    });

    const canPlayHandler = (player: any) => {
        setIsStaying(true);
        setVideoState(player);
    };

    const playHandler = () => {
        setIsWatching(true);
    };

    const pauseHandler = () => {
        setIsWatching(false);
    };

    const lockHandle = () => {
        dispatch(requestLogin());
    };

    const currentTimeHandler = (sec: number) => {
    };

    function getExamList() {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/exam/${grade}/${quarter}`;
        return fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': generateToken(accessData.accountId!, accessData.accessToken!),
                },
            })
            .then((res) => res.json())
            .then((res) => {
                return res.data.map((d: any): NaTypes.ExamList => {
                    return {
                        duration: d.duration,
                        enable: d.enable,
                        examYear: d.exam_year,
                        grade: d.grade,
                        id: d.id,
                        lsId: d.ls_id,
                        name: d.name,
                        quarter: d.quarter,
                        questionSetId: d.question_set_id,
                        sectionId: d.section_id,
                        subject: d.subject,
                        subjectName: d.subject_name,
                        subjectTHName: d.subject_th_name,
                        pdfFile: d.pdf_exam
                    }
                })
            })
            .catch((err) => console.log(err));
    };

    function getAnswerExam() {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/exam/examanswer/${id}`;
        return fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': generateToken(accessData.accountId!, accessData.accessToken!),
                },
            })
            .then((res) => res.json())
            .then((res) => {
                const answerData = res.data;
                answerData.explanation_video.map((v: any) => {
                    v.videoId = v.video_id;
                })
                return answerData;
            })
            .catch((err) => console.log(err));
    }

    function chooseSection(index: number) {
        setCurrentSection(index);
        scroll(index);
        if (examResult && examResult?.explanation_video.length > 0) {
            let selectVideo = examResult?.explanation_video.findIndex((v: { position: number; }) => index < v.position);
            if (selectVideo) {
                setCurrentExplain(selectVideo);
                if (examResult)
                    setLastPosition(examResult?.explanation_video[selectVideo - 1].position + 1);
            } else {
                setCurrentExplain(0);
                setLastPosition(1);
            }
        }
    }

    const scroll = (index: number) => {
        if (examContainer != null) {
            examContainer.current?.scroll({ left: (index) * 421 });
        }
    };

    if (!accessData.accessToken) {
        window.location.href = `${process.env.REACT_APP_WEB_URL}`;
    }

    return <>
        <div className='col-12 bg-grey' style={{ display: "flex", margin: "auto", justifyContent: "center", flexWrap: "wrap" }}>
            <div className='bg-white col-11'
                style={{ display: "flex", justifyContent: "center", marginTop: "20px", borderRadius: "10px", paddingTop: "20px", paddingBottom: "20px", marginBottom: "20px" }}>
                <div className='col-12'>
                    <div style={{ display: "inline-flex" }}>
                        <div className='start-text'>เริ่มสอบ</div>
                        <div className="video-tag-info exam-tag-info">
                            {
                                gradeState.grades.find((g) => {
                                    return exam?.grade === g.grade;
                                })?.descriptionTh
                            }
                        </div>
                        <div className="video-tag-info exam-tag-info">{exam?.subjectTHName}</div>
                        {/* <div className="video-tag-info exam-tag-info">เทอม 2</div> */}
                        <div className="video-tag-info exam-tag-info">{examResult?.question_detail?.question_list.length} ข้อ</div>
                    </div>
                    <div className='question-count-group'>
                        {
                            examResult?.question_detail.question_list.map((exam: any, index: number) => {
                                return (<>
                                    <div className={currentSection + "" === index + "" ? 'question-count select-count' : "question-count"}
                                        onClick={() => {
                                            chooseSection(index);
                                        }}>
                                        <div>{index + 1}</div>
                                    </div>
                                </>
                                )
                            })
                        }
                    </div>
                    <div ref={examContainer} className="exam-section-container">
                        {
                            examResult?.question_detail?.question_list.map((exam: any, index: number) => {
                                return (
                                    <div className={currentSection == index ? 'exam-section exam-section-select' : "exam-section"}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            chooseSection(index);
                                        }}
                                    >
                                        <div style={{ display: "flex" }}>
                                            <div className="select-count">
                                                {index + 1}
                                            </div>
                                            <div className="exam-question-text">
                                                <Latex>{exam.description != null ? exam.description : ''}</Latex>
                                            </div>
                                        </div>
                                        {ImageUtility.isImage(exam.image_url) ? (
                                            <div className="question-image">
                                                <img className="w-100" src={exam.image_url} />
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <div>
                                            {exam.choice_list.map((a: any, i: number) => (
                                                <div className='exam-choice' style={{ display: "flex" }}>
                                                    <div
                                                        className={a.is_correct == 1 ? "choice-number select-count-right" : "choice-number"}>
                                                        {i + 1}</div>
                                                    <div className="choice-exam-value"><Latex>{a.text}</Latex></div>
                                                    <div className='choice-exam-value'>{ImageUtility.isImage(a.image_url) ? (
                                                        <img className="image-choice w-100"
                                                            src={a.image_url} alt="" />
                                                    ) : (
                                                        <></>
                                                    )}</div>
                                                </div>
                                            ))}
                                        </div>
                                        {exam.reason_text != "" && exam.reason_text != null ? <div className='exam-choice' style={{ border: "1px grey solid", borderRadius: "15px", padding: "10px", marginTop: "30px" }}>
                                            คำอธิบาย <br />
                                            {exam.reason_text}
                                        </div> : <></>}

                                    </div>
                                )
                            })
                        }
                        <div className="exam-section-none"></div>
                        <div className="exam-section-none"></div>
                    </div>
                    {examResult && examResult.explanation_video.length > 0 ?
                        <>
                            <div className='start-text' style={{ textAlign: "center", marginTop: "20px", marginBottom: "10px" }}>
                                วีดีโอเฉลย
                            </div>
                            <div style={{ width: "100%", textAlign: "center", marginBottom: "10px" }}>
                                <div style={{ textAlign: "center", margin: "auto", display: "inline-flex", flexFlow: "wrap" }}>
                                    {examResult.explanation_video.map((video: any, i: number) =>
                                        <>
                                            <div className={currentExplain == i ? 'choice-number select-count-right' : 'choice-number'} onClick={() => { setCurrentExplain(i) }}>
                                                {i > 0 ? examResult.explanation_video[i - 1].position + 1 : i + 1} - {examResult.explanation_video[i].position}</div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="explain-video-container col-12 col-sm-6 p-0">
                                <VideoExplain
                                    lsVideoExplain={examResult.explanation_video[currentExplain]}
                                    currentTimeHandler={currentTimeHandler}
                                    canPlayHandler={canPlayHandler}
                                    playHandler={playHandler}
                                    pauseHandler={pauseHandler}
                                    lock={!accessData.accessToken}
                                    lockHandle={lockHandle}
                                    enablePopQuiz={false}
                                />
                            </div>
                        </>
                        :
                        <></>}
                    <div className="col-12 exam-summit">
                        <div className={'exam-summit-btn col-2'}
                            onClick={() => {
                                window.location.href = '/exam/' + grade + "/" + quarter
                            }
                            }
                        >เสร็จสิ้น
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;



}

export default ExamAnswer;