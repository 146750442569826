import React from "react";
import { useSelector } from 'react-redux';
import CloseIcon from "../../../images/close/close.svg";
import UserProfileIcon from "../../../images/user/user.svg";

interface Student {
    bigPhotoUrl: string;
    color: string;
    fullname: string;
    score: string;
}

const LiveStreamPageRankingScore = (props: {
    liveStream: NaTypes.Store['liveStreamTableList'],
    rankingAmount: number;
    rankingScoreShow: boolean;
    studentRankingScore: Student[];
    setRankingScoreShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {

    const maxScore = Math.max(...props.studentRankingScore.map(student => parseInt(student.score, 10)));
    const profileState = useSelector((state: NaTypes.Store) => state.profile);



    const calculateBarWidth = (score: string) => {
        const containerWidth = document.querySelector(".ranking-score-bar-container")?.clientWidth || 0;
        const normalizedScore = parseInt(score, 10) / maxScore;
        return `${containerWidth * normalizedScore}px`;
    };

    const getBackgroundColor = (student: Student) => {
        return student.fullname === profileState.profile?.fullname ? student.color : 'silver';
    };

    const calculateRank = () => {
        const scores = props.studentRankingScore.map(student => parseInt(student.score, 10));
        const uniqueScores = Array.from(new Set(scores)).sort((a, b) => b - a);
        const rankMap = uniqueScores.reduce((map, score, index) => map.set(score, index + 1), new Map());
        return scores.map(score => rankMap.get(score)!);
    };

    const rearrangeStudentRanking = () => {
        const currentUser = props.studentRankingScore.find(student => student.fullname === profileState.profile?.fullname);
        if (!currentUser) return props.studentRankingScore;

        const currentUserScore = parseInt(currentUser.score, 10);
        const scoreGroups: { [key: number]: Student[] } = {};

        props.studentRankingScore.forEach(student => {
            const score = parseInt(student.score, 10);
            if (!scoreGroups[score]) {
                scoreGroups[score] = [];
            }
            scoreGroups[score].push(student);
        });

        const sortedScores = Object.keys(scoreGroups).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
        const rearrangedStudents: Student[] = [];

        sortedScores.forEach(score => {
            const students = scoreGroups[parseInt(score, 10)] as Student[];
            if (parseInt(score, 10) === currentUserScore) rearrangedStudents.push(currentUser);
            rearrangedStudents.push(...students.filter(student => student.fullname !== currentUser.fullname));
        });

        return rearrangedStudents;
    };



    const ranks = calculateRank();
    const rearrangedStudentRanking = rearrangeStudentRanking();



    return (
        <div className="ranking-score-board" hidden={!props.rankingScoreShow}>
            <div className="ranking-score-close">
                <img
                    src={CloseIcon}
                    onClick={() => props.setRankingScoreShow(false)}
                    alt="Close"
                />
            </div>

            <div className="ranking-score-menu-container">
                <h1 className="ranking-score-title">Quiz Rank</h1>
                <div className="ranking-score-live-name">
                    {props.liveStream.currentLS?.subject}
                </div>
            </div>

            <div className="ranking-score-top-background">
                <div className="ranking-score-top-container">
                    {rearrangedStudentRanking.slice(0, 3).map((student, index) => (
                        <div className="ranking-score-rank-top" key={index} id={`ranking-score-top-${index + 1}`}>

                            <div className="ranking-score-position">
                                {ranks[index]}
                            </div>

                            <div className="ranking-score-profile">
                                <img
                                    className="ranking-score-rank-img"
                                    src={student.bigPhotoUrl || UserProfileIcon}
                                    alt={`Profile Picture of ${student.fullname}`}
                                />
                            </div>

                            <div className="ranking-score-bar-container">
                                <div className="ranking-score-bar-description">
                                    <div className="ranking-score-rank-name">{student.fullname}</div>
                                    <div className="ranking-score-rank-score">{`${student.score}pts`}</div>
                                </div>
                                <div className="ranking-score-bar-background">
                                    <div
                                        className="ranking-score-bar"
                                        style={{ width: calculateBarWidth(student.score) }}
                                    />
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>

            <div className="ranking-score-student-container">
                {rearrangedStudentRanking.slice(3).map((student, index) => (
                    <div className="ranking-score-rank" key={index}>
                        <div
                            className="ranking-score-position"
                            style={{ backgroundColor: getBackgroundColor(student) }}
                        >
                            {ranks[index + 3]}
                        </div>

                        <div className="ranking-score-profile">
                            <img
                                className="ranking-score-rank-img"
                                style={{ borderColor: getBackgroundColor(student) }}
                                src={student.bigPhotoUrl || UserProfileIcon}
                                alt={`Profile Picture of ${student.fullname}`}
                            />
                        </div>

                        <div className="ranking-score-bar-container">
                            <div className="ranking-score-bar-description">
                                <div className="ranking-score-rank-name">{student.fullname}</div>
                                <div className="ranking-score-rank-score">{`${student.score}pts`}</div>
                            </div>
                            <div className="ranking-score-bar-background">
                                <div
                                    className="ranking-score-bar"
                                    style={{ width: calculateBarWidth(student.score) }}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );
};

export default LiveStreamPageRankingScore;
