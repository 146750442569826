const initialState: NaTypes.LessonList = [];

const ADD_LESSON = 'ADD_LESSON';
const addLesson = (lesson: NaTypes.Lesson) => {
    return {
        type: ADD_LESSON,
        payload: lesson,
    };
};

export {
    addLesson,
};

export function LessonListState(
    state: NaTypes.LessonList = initialState,
    action: any): NaTypes.LessonList {
    switch (action.type) {
        case ADD_LESSON:
            return [
                ...state,
                action.payload,
            ];
        default:
            return state;
    }
};
