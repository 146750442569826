import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NaTypes from '../../types';
// import { useParams } from 'react-router';


const TitleConfig = () => {
  const location = useLocation();
  const chapter: NaTypes.Chapter = useSelector((state: NaTypes.Store) => state.chapter);
  const gradeState = useSelector((state: NaTypes.Store) => state.grade);
  const subjectState = useSelector((state: NaTypes.Store) => state.subject);
  // const { id }: any = useParams();
  const liveStreamHistoryRecent: NaTypes.LiveStreamHistoryList = useSelector((state: NaTypes.Store) => state.liveStreamHistory.liveStreamHistoryRecentList);
  // const liveStreamHistory = liveStreamHistoryRecent.find((d) => d.id == id);
  const liveStream = useSelector((state: NaTypes.Store) => state.liveStreamTableList);

  let title = '';
  let description = "NockAcademy คือโรงเรียนกวดวิชาออนไลน์สำหรับเด็กไทยทุกคน พวกเราสนับสนุนการเรียนรู้ประจำวันของเด็กๆ ในแอป NockAcademy นักเรียนสามารถดูคลิปบทเรียนวิชา คณิตศาสตร์ ภาษาอังกฤษ และภาษาไทย ที่มีมากกว่า 1,000 คลิป";

  let fbTitle = "NockAcademy";
  let fbDescription = "NockAcademy คือโรงเรียนกวดวิชาออนไลน์สำหรับเด็กไทยทุกคน พวกเราสนับสนุนการเรียนรู้ประจำวันของเด็กๆ ในแอป NockAcademy นักเรียนสามารถดูคลิปบทเรียนวิชา คณิตศาสตร์ ภาษาอังกฤษ และภาษาไทย ที่มีมากกว่า 1,000 คลิป";
  let fbImage = "%PUBLIC_URL%/cover.png"

  if (location.pathname.includes('/payment-history')) {
    title = 'ประวัติการชำระค่าบริการ - NockAcademy';
  } else if (location.pathname.includes('/payment-management')) {
    title = 'จัดการข้อมูลการชำระเงิน - NockAcademy';
  } else if (location.pathname.includes('/pay')) {
    title = 'สมัครใช้บริการ - NockAcademy';
  } else if (location.pathname.includes('/video')) {
    title = chapter.topicDescription + ' / ' + chapter.description + ' - NockAcademy';
  } else if (location.pathname.includes('/livehistory')) {
    title = 'บันทึกไลฟ์สด - NockAcademy';
    let id = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
    let liveStreamHistory = liveStreamHistoryRecent.find((d) => d.id + "" === id + "");
    if (liveStreamHistory) {
      title = liveStreamHistory?.subject + ' - NockAcademy';
      description = liveStreamHistory?.detail;
    }
  } else if (location.pathname.includes('/live')) {
    title = 'ไลฟ์สด - NockAcademy';
    if (liveStream.currentLS)
      fbTitle = liveStream.currentLS.subject;
    if (liveStream.currentLS?.scheduleImagePath)
      fbImage = liveStream.currentLS.scheduleImagePath;
    if (liveStream.currentLS?.detail)
      fbDescription = liveStream.currentLS.detail;
  } else if (location.pathname.includes('/promotion')) {
    title = 'โปรโมชั่น - NockAcademy';
  } else if (location.search) {
    const query = new URLSearchParams(location.search);
    const grade = query.get('grade');
    const subject = query.get('subject');
    const findedGrade = gradeState.grades.find((g) => g.grade === grade);
    const findedSubject = subjectState.subjects.find((s) => s.subjectId === subject);
    if (findedGrade || findedSubject) {
      const merge = [];
      if (findedSubject) merge.push(findedSubject.descriptionTh);
      if (findedGrade) merge.push(findedGrade.descriptionTh);
      title = merge.join(' / ') + ' - NockAcademy';
    } else {
      title = 'คลิป - NockAcademy';
    }
  } else {
    title = 'คลิป - NockAcademy';
  }
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      {/* <meta name="description" content={description} /> */}

      <meta name="description" content={description} />
      <meta property="og:image" content={fbImage} />
      <meta property="og:description" content={fbDescription} />
      <meta property="og:title" content={fbTitle} />
    </Helmet>
  );
};

export default TitleConfig;
