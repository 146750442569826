import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { isMobile } from 'react-device-detect';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Latex from 'react-latex';
import SplitPane from 'react-split-pane';

import VideoContent from '../../components/video-content/VideoContent';
import MiniTest from '../../components/mini-test/MiniTest';
import SkipQuestion from '../../components/skip-question/SkipQuestion';

import { getLessonListSet } from '../../stores/lesson-list/LessonSetListState';
import { setCurrentChapter } from '../../stores/chapter/ChapterState';
import { createPost, recordStudyTime, sendStepVOD } from '../../api/VideoOnDemand';
import { useInterval, MixpanelTracking } from '../../utility';
import { trackStudyActivity } from '../../api/Tracking';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import { Lesson } from '../../types';
import { generateToken } from '../../utility/Token';

import './VODPage.css';
import correctGray from '../../images/correct/correct_gray.svg';
import correctGreen from '../../images/correct/correct_green.svg';
import correctYellow from '../../images/correct/correct_yellow.svg';
import dotGrey from '../../images/dot/dot-grey.svg';
import dotOrange from '../../images/dot/dot-orange.svg';
import lockedPadlock from '../../images/lock/locked-padlock.svg';
import playGray from '../../images/play/play_gray.svg';
import playYellow from '../../images/play/play_yellow.svg';

const VODPage = () => {
    const dispatch = useDispatch();
    const { subject, grade, topic }: any = useParams();

    const refCountStayingTime = useRef(0);
    const refCountWatchingTime = useRef(0);
    const refDurationTime = useRef(0);
    const refSectionElement = useRef<HTMLElement>(null);

    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const gradeState = useSelector((state: NaTypes.Store) => state.grade);
    const subjectState = useSelector((state: NaTypes.Store) => state.subject);
    const transaction = useSelector((state: NaTypes.Store) => state.transaction);

    const [activeChapter, setActiveChapter] = useState<string>();
    const [anchorEl, setAnchorEl] = useState(null);
    const [chapter, setChapter] = useState<NaTypes.Chapter>();
    const [countStayingTime, setCountStayingTime] = useState<number>(0);
    const [countWatchingTime, setCountWatchingTime] = useState<number>(0);
    const [iframeKey, setIframeKey] = useState<number>(0);
    const [isStaying, setIsStaying] = useState<boolean>(false);
    const [isWatching, setIsWatching] = useState<boolean>(false);
    const [lesson, setLesson] = useState<Lesson>();
    const [prevChapter, setPrevChapter] = useState<NaTypes.Chapter>();
    const [resizing, setResizing] = useState<boolean>(false);
    const [showConfirmPayment, setShowConfirmPayment] = useState<boolean>(false);
    const [splitAxis, setSplitAxis] = useState<'vertical' | 'horizontal'>();
    const [state, setState] = useState<string>();
    const [videoState, setVideoState] = useState<any>(null);

    const open = Boolean(anchorEl);



    const handleOpenDropdown = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDropdown = () => {
        setAnchorEl(null);
    };

    const onClickDownloadDocument = (url: string) => {
        window.open(url);
    };

    const resetCounter = () => {
        setCountStayingTime(0);
        setIsStaying(false);
        setCountWatchingTime(0);
        setIsWatching(false);
    };

    const canPlayHandler = (player: any) => {
        setIsStaying(true);
        setVideoState(player);
    };

    const playHandler = () => {
        setIsWatching(true);
    };

    const pauseHandler = () => {
        setIsWatching(false);
    };

    const lockHandle = () => {
        dispatch(requestLogin());
    };

    const getLesson = async () => {
        const token = accessData.accountId ? generateToken(accessData.accountId, accessData.accessToken!) : '';
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/topic/detail/${topic}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                },
            });

            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const { data } = await response.json();

            const urlGrade = window.location.pathname.match(/\/video\/[^\/]+\/([^\/]+)\//)?.[1];
            const grade = data.grade || urlGrade;
            const filteredChapters = data.chapters.filter((c: any) => c.grade === grade);

            const lessonResult: Lesson = {
                index: 0,
                topicId: data.topic_id,
                subject: data.subject_id,
                grade,
                name: data.description_th,
                thumbnail: data.topic_thumbnail_url,
                videoSize: filteredChapters.length * 2,
                isFree: filteredChapters.every((c: any) => c.is_free === '1'),
                isCompleted: false,
                chapterList: filteredChapters.map((c: any): NaTypes.Chapter => ({
                    topicId: c.topic_id,
                    chapterId: c.chapter_id,
                    subject: c.subject_id,
                    grade: c.grade,
                    topicDescription: c.topic_description_th,
                    description: c.description,
                    isPoint: parseInt(c.is_point),
                    isFree: parseInt(c.is_free),
                    stepVOD: parseInt(c.step_vod),
                    videoList: c.videos.map((v: any): NaTypes.Video => ({
                        host: v.video_host,
                        videoRef: v.video_id,
                        thumbnail: {
                            normal: v.video_thumbnail_url,
                            hd: v.video_thumbnail_hd_url,
                        },
                        documentList: [v.document_url],
                        questionList: v.questions.map((q: any): NaTypes.Question => ({
                            id: q.id,
                            imagePath: q.image_path,
                            value: q.value,
                            lsId: q.ls_id,
                            showAt: parseInt(q.show_at),
                            answerList: q.answers.map((a: any): NaTypes.Answer => ({
                                id: a.id,
                                imagePath: a.image_path,
                                value: a.value,
                                isCorrect: a.is_correct === 1,
                                questionId: a.question_id,
                            })),
                        })),
                        duration: v.video_duration == null ? 0 : parseInt(v.video_duration),
                    })),
                })),
            };

            setLesson(lessonResult);

        } catch (error) {
            console.error('Error fetching lesson:', error);
        }
    };



    useEffect(() => {
        getLesson();
    }, [topic]);

    useEffect(() => {
        if (accessData.accessToken && transaction.isPremium) {
        } else {
            setState('INTRO');
        }
    }, [accessData.accessToken, transaction.isPremium]);

    useEffect(() => {
        if (videoState) {
            refDurationTime.current = videoState.player.duration;
        } else {
            refDurationTime.current = 0;
        }
    }, [videoState]);

    useEffect(() => {
        refCountStayingTime.current = countStayingTime;
    }, [countStayingTime]);

    useEffect(() => {
        refCountWatchingTime.current = countWatchingTime;
    }, [countWatchingTime]);

    useEffect(() => {
        const handelResizeScreen = () => {
            if (window.innerWidth < 768) {
                setSplitAxis('horizontal');
            } else {
                setSplitAxis('vertical');
            }
        };
        handelResizeScreen();
        window.addEventListener('resize', handelResizeScreen);
        return () => {
            window.removeEventListener('resize', handelResizeScreen);
        };
    });

    useEffect(() => {
        dispatch(getLessonListSet(subject!, grade!, accessData.accountId));
    }, [accessData, grade, subject, state, dispatch]);

    useEffect(() => {
        if (state && chapter) {
            if (state === 'INTRO') {
                sendStepVOD(accessData, chapter?.subject, chapter?.grade, chapter?.topicId, chapter?.chapterId, 1);
                trackStudyActivity(accessData, chapter?.subject, chapter?.grade, chapter?.topicId, chapter?.chapterId, 'intro');
            } else if (state === 'EXERCISE') {
                sendStepVOD(accessData, chapter?.subject, chapter?.grade, chapter?.topicId, chapter?.chapterId, 2);
                trackStudyActivity(accessData, chapter?.subject, chapter?.grade, chapter?.topicId, chapter?.chapterId, 'exercise');
            } else if (state === 'MINITEST') {
                sendStepVOD(accessData, chapter?.subject, chapter?.grade, chapter?.topicId, chapter?.chapterId, 3);
            }
        }
    }, [accessData, state, chapter, dispatch]);

    useEffect(() => {
        if (chapter) {
            dispatch(setCurrentChapter(chapter));
            if (chapter.subject !== prevChapter?.subject || chapter.grade !== prevChapter?.grade ||
                chapter.topicId !== prevChapter?.topicId || chapter.chapterId !== prevChapter?.chapterId) {
                const timer = setTimeout(() => {
                    const subject = subjectState.subjects.find((s) => { return s.subjectId === chapter.subject });
                    MixpanelTracking.videoSelected(chapter, subject!.descriptionTh);
                    setPrevChapter(chapter);
                }, 1000);
                return () => clearTimeout(timer);
            }
        }
    }, [chapter, prevChapter, dispatch, subjectState]);

    useEffect(() => {
        return () => {
            if (chapter) {
                const subject = subjectState.subjects.find((s) => { return s.subjectId === chapter.subject });
                if (state === 'INTRO') {
                    createPost(accessData, chapter.subject, subject!.descriptionTh, refCountWatchingTime.current);
                    recordStudyTime(accessData, chapter.subject, refCountWatchingTime.current);
                    MixpanelTracking.introWatched(chapter, subject!.descriptionTh, parseInt(refDurationTime.current.toFixed(0)), refCountStayingTime.current, refCountWatchingTime.current);
                } else if (state === 'EXERCISE') {
                    createPost(accessData, chapter.subject, subject!.descriptionTh, refCountWatchingTime.current);
                    recordStudyTime(accessData, chapter.subject, refCountWatchingTime.current);
                    MixpanelTracking.exerciseWatched(chapter, subject!.descriptionTh, parseInt(refDurationTime.current.toFixed(0)), refCountStayingTime.current, refCountWatchingTime.current);
                }
                setVideoState(null);
                resetCounter();
            }
        };
    }, [chapter, state, accessData, subjectState]);

    useEffect(() => {
        setIframeKey(iframeKey + 1);
    }, [chapter]);



    useInterval(() => {
        setCountStayingTime(countStayingTime + (process.env.NODE_ENV === 'production' ? 1 : 60));
    }, isStaying ? 1000 : null);

    useInterval(() => {
        setCountWatchingTime(countWatchingTime + (process.env.NODE_ENV === 'production' ? 1 : 60));
    }, isWatching ? 1000 : null);



    if (!lesson) return <p>Loading</p>;
    if (!chapter) changeChapter(lesson.chapterList[0], 0, 'INTRO');

    function getContent(): JSX.Element | undefined {
        if (state === 'INTRO') {
            return <>
                <div className="h-100 row m-0">
                    <SplitPane split={splitAxis} size="70%"
                        onDragStarted={() => setResizing(true)}
                        onDragFinished={() => setResizing(false)}>
                        <div className="w-100">
                            <div className="video-layout">
                                <VideoContent chapter={chapter!}
                                    video={chapter?.videoList[0]}
                                    canPlayHandler={canPlayHandler}
                                    playHandler={playHandler}
                                    pauseHandler={pauseHandler}
                                    lock={!accessData.accessToken}
                                    lockHandle={lockHandle}
                                />
                            </div>
                            <div className="vod-chapter-description-content">
                                <h3 className="vod-chapter-description rainbow_text_animated">
                                    <Latex>{chapter?.description}</Latex>

                                </h3>
                            </div>
                            <p className="vod-chapter-description-detail">{subjectState.subjects.find((s) => {
                                return s.subjectId === (chapter?.subject ? chapter?.subject : '')
                            })?.descriptionTh} {gradeState.grades.find((s) => {
                                return s.grade === (chapter?.grade ? chapter?.grade : '')
                            })?.descriptionTh}
                            </p>
                        </div>
                        <div className="h-100">
                            {resizing ? (
                                <div className="h-100 w-100 position-absolute"></div>
                            ) : (
                                <></>
                            )
                            }
                            {chapter?.videoList[0].documentList[0] ? (
                                <iframe className="w-100 h-100 doc-layout" title="document"
                                    src={`https://docs.google.com/gview?embedded=true&url=${process.env.REACT_APP_API_URL}/api/v7/method/doclesson?path=${chapter?.videoList[0].documentList[0]}`}>
                                </iframe>
                            ) : (
                                <iframe className="w-100 h-100 doc-layout"
                                    title="no document">
                                </iframe>
                            )
                            }
                        </div>
                    </SplitPane>
                </div>
            </>;
        } else if (state === 'EXERCISE') {
            return <>
                <div className="h-100 row m-0">
                    <SplitPane split={splitAxis} size="70%"
                        onDragStarted={() => setResizing(true)}
                        onDragFinished={() => setResizing(false)}>
                        <div className="w-100">
                            <div className="video-layout">
                                <VideoContent chapter={chapter!}
                                    video={chapter?.videoList[1]}
                                    enablePopQuiz={true}
                                    canPlayHandler={canPlayHandler}
                                    playHandler={playHandler}
                                    pauseHandler={pauseHandler}
                                    lock={!accessData.accessToken}
                                    lockHandle={lockHandle}
                                />
                            </div>
                            <div className="vod-chapter-description-content">
                                <h3 className="vod-chapter-description">
                                    <Latex>{chapter?.description}</Latex>

                                </h3>
                                {
                                    (chapter?.videoList[1].documentList[0] && videoState) ? (
                                        <>
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={handleOpenDropdown}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={open}
                                                onClose={handleCloseDropdown}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: 48 * 4.5,
                                                        width: '22ch',
                                                    },
                                                }}
                                            >
                                                <MenuItem
                                                    onClick={() => onClickDownloadDocument(`${process.env.REACT_APP_API_URL}/api/v7/method/doclesson?path=${chapter?.videoList[1].documentList[0]}`)}>
                                                    ดาวน์โหลดแบบฝึกหัด
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                            <p className="vod-chapter-description-detail">{subjectState.subjects.find((s) => {
                                return s.subjectId === (chapter?.subject ? chapter?.subject : '')
                            })?.descriptionTh} {gradeState.grades.find((s) => {
                                return s.grade === (chapter?.grade ? chapter?.grade : '')
                            })?.descriptionTh}
                            </p>
                            <SkipQuestion questionList={chapter?.videoList[1].questionList} />
                        </div>
                        <div className="h-100">
                            {resizing ? (
                                <div className="h-100 w-100 position-absolute"></div>
                            ) : (
                                <></>
                            )
                            }
                            {chapter?.videoList[1].documentList[0] && accessData.accessToken ? (
                                <iframe key={iframeKey} className="w-100 h-100 doc-layout" title="document"
                                    src={`https://docs.google.com/gview?embedded=true&url=${process.env.REACT_APP_API_URL}/api/v7/method/doclesson?path=${chapter?.videoList[1].documentList[0]}`}>
                                </iframe>
                            ) : (
                                <iframe className="w-100 h-100 doc-layout"
                                    title="no document">
                                </iframe>
                            )
                            }
                        </div>
                    </SplitPane>
                </div>
            </>;
        } else if (state === 'MINITEST' && accessData.accessToken) {
            return <MiniTest chapter={chapter!}
                setChapter={setChapter}
                setActiveChapter={setActiveChapter}
                setState={setState}
                chapters={lesson!.chapterList}
            />;
        }
    }

    function changeChapter(c: NaTypes.Chapter, i: number, s: string) {
        if (accessData.accessToken) {
            if (transaction.isPremium) {
                setChapter(c);
                setActiveChapter(c.chapterId);
                setState(s);
            } else {
                if (s === 'INTRO') {
                    setChapter(c);
                    setActiveChapter(c.chapterId);
                    setState(s);
                } else if (c.isFree === 1) {
                    setChapter(c);
                    setActiveChapter(c.chapterId);
                    setState(s);
                } else {
                    setShowConfirmPayment(true);
                }
            }
        } else {
            setChapter(c);
            setActiveChapter(c.chapterId);
            setState(s);
        }
        getLesson();
        if (isMobile) {
            refSectionElement.current?.scrollIntoView();
        }
    }

    function getChapterMenu(c: NaTypes.Chapter, i: number): JSX.Element {
        return (<>
            <div className="col-12" onClick={() => {
                changeChapter(c, i, 'INTRO');
            }}>
                <div className={`state-item can-click ${state === 'INTRO' && c.chapterId === chapter?.chapterId ? 'active' : ''}`}>
                    <div>
                        {
                            !accessData.accessToken && (lesson!.index > 0 || i !== 0) ?
                                <div className='vod-lock-container'>
                                    <img className='vod-lock-image' src={lockedPadlock} alt="" />
                                </div> :
                                state === 'INTRO' && c.chapterId === chapter?.chapterId ?
                                    <img className='vod-can-play-image' src={playYellow} alt="" /> :
                                    c.stepVOD > 0 ?
                                        <img className='vod-can-play-image' src={correctGreen} alt="" /> :
                                        <img className='vod-can-play-image' src={playGray} alt="" />
                        }
                    </div>
                    <div className={`vod-chapter-menu-title${state === 'INTRO' ? '-active' : ''}`}>บทเรียน</div>
                </div>
            </div>
            <div className="col-12" onClick={() => {
                changeChapter(c, i, 'EXERCISE');
            }}>
                <div className={`state-item can-click ${state === 'EXERCISE' && c.chapterId === chapter?.chapterId ? 'active' : ''}`}>
                    <div>
                        {
                            !accessData.accessToken ?
                                <div className='vod-lock-container'>
                                    <img className='vod-lock-image' src={lockedPadlock} alt="" />
                                </div> :
                                !transaction.isPremium && c.isFree === 0 ?
                                    <div className='vod-lock-container'>
                                        <img className='vod-lock-image' src={lockedPadlock} alt="" />
                                    </div> :
                                    state === 'EXERCISE' && c.chapterId === chapter?.chapterId ?
                                        <img className='vod-can-play-image' src={playYellow} alt="" /> :
                                        c.stepVOD > 1 ?
                                            <img className='vod-can-play-image' src={correctGreen} alt="" /> :
                                            <img className='vod-can-play-image' src={playGray} alt="" />
                        }
                    </div>
                    <div className={`vod-chapter-menu-title${state === 'EXERCISE' ? '-active' : ''}`}>แบบฝึกหัด</div>
                </div>
            </div>
            <div className="col-12" onClick={() => {
                changeChapter(c, i, 'MINITEST');
            }}>
                <div className={`state-item can-click ${state === 'MINITEST' && c.chapterId === chapter?.chapterId ? 'active' : ''}`}>
                    <div>
                        {
                            !accessData.accessToken ?
                                <div className='vod-lock-container'>
                                    <img className='vod-lock-image' src={lockedPadlock} alt="" />
                                </div> :
                                !transaction.isPremium && c.isFree === 0 ?
                                    <div className='vod-lock-container'>
                                        <img className='vod-lock-image' src={lockedPadlock} alt="" />
                                    </div> :
                                    state === 'MINITEST' && c.chapterId === chapter?.chapterId ?
                                        <img className='vod-can-play-image' src={correctYellow} alt="" /> :
                                        c.stepVOD > 2 && c.isPoint === 1 ?
                                            <img className='vod-can-play-image' src={correctGreen} alt="" /> :
                                            c.stepVOD > 2 && c.isPoint === 0 ?
                                                <img className='vod-can-play-image' src={correctYellow} alt="" /> :
                                                <img className='vod-can-play-image' src={correctGray} alt="" />
                        }
                    </div>
                    <div className={`vod-chapter-menu-title${state === 'MINITEST' ? '-active' : ''}`}>แบบทดสอบ</div>
                </div>
            </div>
        </>);
    }

    return (<>
        <div className="row w-100 h-100 m-0">
            <aside className="col-12 col-sm-6 col-md-4 col-lg-3 vod-sibebar-bg-container">
                <div className="vod-sidebar-container">
                    <div className="p-2 row" >
                        <div className="col-12">
                            <h2 className="vod-classroom-name rainbow_text_animated">{subjectState.subjects.find((s) => {
                                return s.subjectId === (chapter?.subject ? chapter?.subject : '')
                            })?.descriptionTh} {gradeState.grades.find((s) => {
                                return s.grade === (chapter?.grade ? chapter?.grade : '')
                            })?.descriptionTh}
                            </h2>
                        </div>
                        <div className="col-12">
                            <p className="vod-lesson-name">{lesson.name}</p>
                        </div>
                    </div>
                    <div>
                        <Accordion defaultActiveKey={chapter?.chapterId} activeKey={activeChapter}>
                            {
                                lesson.chapterList.map((c: NaTypes.Chapter, index: number) => (
                                    <div key={index} className="vod-accordion-item-container">
                                        <Accordion.Toggle as="div" variant="link"
                                            eventKey={c.chapterId}>
                                            <div onClick={() => {
                                                setActiveChapter(c.chapterId);
                                            }} className={`chapter-item can-click`}>
                                                <div>
                                                    {c.isPoint === 1 ?
                                                        <img className="chapter-tiem-status-success-image" src={correctGreen} alt="" /> :
                                                        c.stepVOD === 0 ?
                                                            <img className="chapter-tiem-status-default-image" src={dotGrey} alt="" /> :
                                                            <img className="chapter-tiem-status-default-image" src={dotOrange} alt="" />
                                                    }
                                                </div>
                                                <Latex>{c.description}</Latex>
                                            </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={c.chapterId}>
                                            <div className="row">
                                                {getChapterMenu(c, index)}
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                ))
                            }
                        </Accordion>
                    </div>
                </div>
            </aside>
            <section ref={refSectionElement} onLoad={(e) => {
                e.currentTarget.scrollIntoView();
            }} className="h-100 col-12 col-sm-6 col-md-8 col-lg-9 no-padding">
                {getContent()}
            </section>
        </div>
        <Modal dialogClassName="vod-modal-payment" show={showConfirmPayment && !transaction.waitingPayment} centered onHide={() => setShowConfirmPayment(false)}>
            <Modal.Body>
                <div className="row p-2">
                    <div className="col-12 mb-4 na-no-hover-text-nomal">ต้องการสมัครใช้บริการหรือไม่</div>
                    <div className="col-6 na-text-accept can-click" onClick={() => {
                        window.location.href = `${process.env.REACT_APP_PAY_PORTAL}`;
                    }}>สมัคร</div>
                    <div className="col-6 na-text-cancel can-click" onClick={() => {
                        setShowConfirmPayment(false);
                    }}>ยกเลิก</div>
                </div>
            </Modal.Body>
        </Modal>
        <Modal dialogClassName="vod-modal-payment" show={showConfirmPayment && transaction.waitingPayment} centered onHide={() => setShowConfirmPayment(false)}>
            <Modal.Body>
                <div className="row p-2">
                    <div className="col-12 mb-4 na-no-hover-text-nomal">รอยืนยันการชำระเงิน</div>
                    <div className="col-6"></div>
                    <div className="col-3"></div>
                    <div className="col-3 na-text-cancel can-click" onClick={() => {
                        setShowConfirmPayment(false);
                    }}>ตกลง</div>
                </div>
            </Modal.Body>
        </Modal>
    </>);
};

export default VODPage;
