import React from 'react';
import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import { setGradeFilter, setSubjectFilter } from '../../stores/filter/FilterState';
import clockIcon from '../../images/clock/clock-icon.svg';
import arrowBlack from '../../images/actions/arrow-black.svg';



export const LiveStreamPlaybackTopicPopularTableLoading = () => {
    const sizeText = Math.floor(Math.random() * 20) + 10;
    const sizeItemp = Math.floor(Math.random() * 4) + 10;

    return (
        <div className="row m-0">
            <h2 className='loading-header gradient-loading' style={{ width: `${sizeText}%` }}></h2>
            <div id="container">
                {Array.from({ length: sizeItemp }, (_, index) => {
                    const sizeTitle = Math.floor(Math.random() * 100) + 10;
                    return (
                        <div key={`temp-${index}`} className="item">
                            <div className="video-image-container-temp gradient-loading"></div>
                            <p className="topic-name gradient-loading" style={{ width: `${sizeTitle}%`, height: '20px' }}></p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};



export const LiveStreamPlaybackTopicPopularTableHeader = (props: { scrollContainer: (direction: number) => void }) => {
    return (
        <div className="w-100 p-0 lsList-container">
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <p className="lesson-list-title lsList-title rainbow_text_animated">
                        หัวข้อไลฟ์สดย้อนหลังยอดนิยม
                    </p>
                    <p className="lesson-list-sub-title">กดย้อนดูคลิปที่เคยผ่านการไลฟ์สดไปแล้ว เพื่อทบทวนอีกครั้ง</p>
                </div>
                <div className="lesson-list-scroll-button-container">
                    <img className="lesson-list-scroll-left-button lsList-scroll-button" src={arrowBlack} alt="Left Arrow" onClick={() => { props.scrollContainer(0) }} />
                    <img className="lesson-list-scroll-right-button lsList-scroll-button" src={arrowBlack} alt="Right Arrow" onClick={() => { props.scrollContainer(1) }} />
                </div>
            </div>
        </div>
    );
};



export const LiveStreamPlaybackTopicPopularTableItems = (props: {
    liveStreamListContainerRef: React.RefObject<HTMLDivElement>,
    LiveStreamPlaybackTopicPopularList: NaTypes.LiveStreamPlaybackTopicList,
    subjectState: NaTypes.SubjectState,
    gradeState: NaTypes.GradeState,
    dispatch: Dispatch<any>,
}) => {
    return (
        <div ref={props.liveStreamListContainerRef} className="ls-playback-list-container">

            {props.LiveStreamPlaybackTopicPopularList.map((livestream) => {
                const sum_duration = livestream.videos.reduce((sum, video) => sum + video.video_duration, 0);
                const subject = props.subjectState.subjects.find((s) => s.subjectId === livestream.subject_id);
                const grades = livestream.grade.split(",").map((grade) => {
                    const foundGrade = props.gradeState.grades.find((g) => g.grade === grade);
                    return foundGrade && (
                        <Link key={`grade-${foundGrade.grade}`} to="" onClick={() => {
                            if (window.innerWidth > 575) {
                                props.dispatch(setGradeFilter(grade));
                                props.dispatch(setSubjectFilter(""));
                            }
                        }}>
                            <div className="video-tag-info video-tag-info-ls">
                                <p className="subject-name-ls">+ {foundGrade.descriptionTh}</p>
                            </div>
                        </Link>
                    );
                });

                return (
                    <div className="item-video col-6 col-sm-6 col-lg-4 col-xl-3 lsList-item" key={`lsHistoryPop-${livestream.id}`}>
                        <Link to={`/playbacktopic/${livestream.id}`}>
                            <div className="video-image-container" style={{ position: "relative" }}>
                                <img className="video-image" src={livestream.videos[0].video_thumbnail_hd_url} />
                                {livestream.new_lesson && <div className="new-lesson">บทเรียนใหม่</div>}
                            </div>
                            <div className="lsList-header-container">
                                <p className="topic-name topic-name-ls topic-name-ls-list">{livestream.name}</p>
                                <div className="video-tag-info lsList-time">
                                    <img src={clockIcon} className="video-duration-image-lsList" alt="Clock Icon" />
                                    <span>
                                        {Math.floor(sum_duration / 3600) !== 0 &&
                                            <>{Math.floor(sum_duration / 3600)} ชั่วโมง </>}
                                        {Math.floor((sum_duration % 3600) / 60)} นาที
                                    </span>
                                </div>
                            </div>
                        </Link>

                        <div className="video-tag-group lsList-tag-container">
                            {subject && (
                                <Link key={`subject-${subject.subjectId}`} to="" onClick={() => {
                                    if (window.innerWidth > 575) {
                                        props.dispatch(setSubjectFilter(livestream.subject_id));
                                    }
                                }}>
                                    <div className="video-tag-info video-tag-info-ls" onClick={() => {
                                        if (window.innerWidth > 575) {
                                            props.dispatch(setSubjectFilter(livestream.subject_id));
                                        }
                                    }}>
                                        <p className="subject-name-ls">+ {subject.descriptionTh}</p>
                                    </div>
                                </Link>
                            )}

                            {grades}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
