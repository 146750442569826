import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import ChatInput from './ChatInput';
import Message from './Message';
import { Transition } from 'react-spring/renderprops';
import './Chat.css';



const Chat = forwardRef((props: ChatProps, ref) => {
    const [messageList, setMessageList] = useState<Array<MessageItem>>([]);
    const [message, setMessage] = useState('');
    const [autoScroll, setAutoScroll] = useState<boolean>(true);
    const [autoScrollButton, setAutoScrollButton] = useState<ChatAutoScrollButton>({
        show: false,
    });
    const [lastScrollPos, setLastScrollPos] = useState<{
        direction: string,
        lastScrollPos: number,
    }>({
        direction: '',
        lastScrollPos: 0,
    });
    const [showChatContent, setShowChatContent] = useState<boolean>(true);

    const messagesContainer = React.createRef<HTMLDivElement>();
    const messagesEndRef = React.createRef<HTMLDivElement>();



    const checkingAutoButton = (message: string) => {
        !autoScroll && setAutoScrollButton({ show: true, lastestText: `"${message}"` });
    };

    const send = () => {
        props.send && props.send(message);
        setMessage('');
    };

    const scrollToBottom = () => {
        if (autoScroll && messagesEndRef.current) {
            messagesContainer.current?.scrollTo({ top: messagesContainer.current.scrollHeight });
        }
    };

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const currentScrollPos = event.currentTarget.scrollTop;
        const direction = lastScrollPos.lastScrollPos > currentScrollPos ? 'up' : 'down';
        setLastScrollPos({ direction, lastScrollPos: currentScrollPos });
    };

    const handleAutoScrollUp = () => {
        lastScrollPos.direction === 'up' && setAutoScroll(false);
    };

    const handleKeyDown = (e: any) => {
        e.key === 'Enter' && send();
    };

    const toggleChatContent = () => {
        setShowChatContent(!showChatContent);
    };



    useImperativeHandle(ref, (): ChatRef => ({
        fetchMessageList: (messageList: MessageItem[]) => setMessageList(messageList),
        addMessage: (message: MessageItem) => {
            checkingAutoButton(message.message);
            setMessageList([...messageList, message]);
        },
        deleteMessage: (key: string) => setMessageList(messageList.filter((m) => m.key !== key)),
        updateMessage: (_message: MessageItem) => {
            // TODO: Implement finding the message by id and updating it.
        },
        scrollToBottom: () => {
            scrollToBottom();
            setAutoScroll(true);
            setAutoScrollButton({ ...autoScrollButton, show: false });
        },
    }));



    useEffect(handleAutoScrollUp, [lastScrollPos]);
    useEffect(scrollToBottom, [messageList, autoScroll]);



    return (
        <div className="chat-container">
            <div className="my-card chat-background">
                <div className="my-card bg-white chat-title-container">
                    <div className="chat-title-text rainbow_text_animated">แชต</div>
                    <div
                        className={`toggle-chat-content chat-button-${showChatContent ? '' : 'rotate'}`}
                        onClick={toggleChatContent}>
                        ×
                    </div>
                </div>

                {showChatContent && (<div ref={messagesContainer} className="chat-message-container" onScroll={handleScroll}>
                    {messageList.map((m, i) => (
                        <Message key={i} message={m} delete={props.delete} />
                    ))}
                    <div ref={messagesEndRef} />
                </div>)}

                {showChatContent && (<div className="chat-autoscolling-container">
                    <Transition
                        items={autoScrollButton.show}
                        from={{ opacity: 0, transform: 'translate3d(0, 10px, 0)' }}
                        enter={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
                        leave={{ opacity: 0, transform: 'translate3d(0, 10px, 0)' }}
                    >
                        {(show) =>
                            show &&
                            ((props) => (
                                <div
                                    className="chat-autoscolling-button"
                                    style={props}
                                    onClick={() => {
                                        scrollToBottom();
                                        setAutoScroll(true);
                                        setAutoScrollButton(Object.assign({}, autoScrollButton, { show: false }));
                                    }}
                                >
                                    {autoScrollButton.lastestText}
                                </div>
                            ))
                        }
                    </Transition>
                </div>)}

                {showChatContent && (<div className="chat-input-container">
                    <div className="chat-input-container-item" style={{ alignItems: 'unset' }}>
                        <ChatInput
                            senderProfile={props.senderProfile}
                            message={message}
                            setMessage={setMessage}
                            handleKeyDown={handleKeyDown}
                            send={send}
                        />
                    </div>
                </div>)}
            </div>
        </div>
    );
});

Chat.displayName = 'Chat';

export default Chat;
