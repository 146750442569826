import React, { Component } from 'react';
import Hls from 'hls.js';

export default class HLSSource extends Component {
    hls = new Hls();

    componentDidMount() {
        const { src, video } = this.props;
        if (Hls.isSupported()) {
            this.hls.loadSource(src);
            this.hls.attachMedia(video);
            this.hls.on(Hls.Events.MANIFEST_PARSED, () => video.play());
        }
    }

    componentWillUnmount() {
        this.hls.destroy();
    }

    render() {
        const { src, type = 'application/x-mpegURL' } = this.props;
        return <source src={src} type={type} />;
    }
}
