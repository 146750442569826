import React, { useState } from 'react';
import Latex from 'react-latex';
import Lightbox from 'react-awesome-lightbox';
import Answer from './Answer';
import { ImageUtility } from '../../utility';
import { useSelector } from 'react-redux';
import 'react-awesome-lightbox/build/style.css';
import 'katex/dist/katex.min.css';



const Question = (props: {
  index: number,
  question: NaTypes.TestQuestion,
}) => {
  const miniTestState: NaTypes.MiniTest = useSelector((state: NaTypes.Store) => state.miniTest);
  const [fullscreenQuestionImage, setFullscreenQuestionImage] = useState(false);
  const [fullscreenReasonImage, setFullscreenReasonImage] = useState(false);



  return (
    <div>
      <div className="mini-test-question-item">
        <div className="mini-test-question-index-container">
          <span className="mini-test-question-index-text">{props.index + 1}</span>
        </div>
        <div className="mini-test-question-detail-container">
          {ImageUtility.isImage(props.question.imageUrl) && (
            <>
              {!fullscreenQuestionImage ? (
                <div className="mini-test-question-image-container">
                  <img
                    className="mini-test-question-image"
                    src={props.question.imageUrl}
                    onClick={() => setFullscreenQuestionImage(!fullscreenQuestionImage)}
                    alt=""
                  />
                </div>
              ) : (
                <Lightbox
                  onClose={() => setFullscreenQuestionImage(!fullscreenQuestionImage)}
                  image={props.question.imageUrl}
                  title=""
                />
              )}
            </>
          )}
          <p className="mini-test-question-title">
            <Latex>{props.question.descriptionTh}</Latex>
          </p>
          <div className="row">
            {props.question.choiceList.map((choice, index) => (
              <div key={choice.id} className={`col-${props.question.choiceType === 'image' ? '6' : '12'}`}>
                <Answer
                  answerNo={index + 1}
                  questionNo={props.question.questionNo}
                  choice={choice}
                  choiceType={props.question.choiceType}
                  selectedAnswer={props.question.selectedAnswer}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {miniTestState.showScore && (props.question.reasonImage || props.question.reasonText) && (
        <div className="answer-reason-container">
          <h2 className="answer-reason-title">อธิบายคำตอบ</h2>
          {props.question.reasonImage && (
            <>
              {!fullscreenReasonImage ? (
                <div className="answer-reason-image-container">
                  <img
                    className="answer-reason-image"
                    src={props.question.reasonImage}
                    onClick={() => setFullscreenReasonImage(!fullscreenReasonImage)}
                    alt=""
                  />
                </div>
              ) : (
                <Lightbox
                  image={props.question.reasonImage}
                  onClose={() => setFullscreenReasonImage(!fullscreenReasonImage)}
                  title=""
                />
              )}
            </>
          )}
          {props.question.reasonText && (
            <p className="answer-reason-text">
              <Latex>{props.question.reasonText}</Latex>
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Question;
