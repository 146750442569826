import React, { useEffect, useState } from 'react';
import './LiveStreamShowTopic.css';
import { useParams } from 'react-router';
// import watchButton from '../../images/button/watch.svg';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import crownGrey from '../../images/crown/crown-grey.svg';
import crownGold from '../../images/crown/crown-gold.svg';
import blankvideo from '../../images/loading/blankvideo.svg';
import { generateToken } from '../../utility/Token';
import backArrow from '../../images/arrow/circle_arrow_left.png'
import clockIcon from '../../images/clock/clock-icon.svg';



const LiveStreamShowTopic = () => {
    const { topic }: any = useParams();
    const [playbackTopic, setPlaybackTopic] = useState<NaTypes.LiveStreamPlaybackTopic>();
    const [selectedVideo, setSelectedVideo] = useState<number>(0);
    const gradeState = useSelector((state: NaTypes.Store) => state.grade);
    const subjectState = useSelector((state: NaTypes.Store) => state.subject);
    const accessData: NaTypes.Authenticate = useSelector((state: NaTypes.Store) => state.authenticate);
    const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const history = useHistory();

    useEffect(() => {
        let token = "";
        if (accessData.accountId) {
            token = generateToken(accessData.accountId!, accessData.accessToken!);
        }
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/topic/id/${topic}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "token": token
            },
        })
            .then((res) => res.json())
            .then((res) => {
                let date = new Date(res.data.videos[selectedVideo].starttime);
                res.data.startDate = date.getDate() + " " + month[date.getMonth()] + " " + date.getFullYear();
                let sum = 0;
                res.data.videos.forEach((video: { video_duration: 0 | number }) => { sum = sum + video.video_duration });
                res.data.sum_duration = sum;
                setPlaybackTopic(res.data);
            });
    }, [topic]);

    return <>
        <div className="row m-0" style={{ position: "relative" }}>
            <div className='col-12' style={{ background: "#e9e9f2", padding: "30px 10%" }} >
                <div style={{ background: "white", borderRadius: "1vw", padding: "15px" }}>
                    <div className='content-ls-playback-detail'>
                        <div className='img-content-ls-playback'>
                            <img className='img-topic' src={playbackTopic?.videos[selectedVideo].video_thumbnail_hd_url} />
                        </div>
                        <div style={{ padding: "20px" }}>
                            <div>
                                <div className='date-topic' style={{ display: "inline" }}>{playbackTopic?.startDate}</div>
                                <div className="video-tag-info" style={{ marginLeft: "10px", display: "inline", whiteSpace: "nowrap" }}>
                                    {
                                        playbackTopic?.sum_duration ?
                                            <><img src={clockIcon} className="video-duration-image" alt="" /><span>
                                                {Math.floor(playbackTopic.sum_duration / 3600).toFixed(0) !== '0' &&
                                                    <>{Math.floor(playbackTopic.sum_duration / 3600).toFixed(0)} ชั่วโมง </>}
                                                {Math.floor((playbackTopic.sum_duration % 3600) / 60).toFixed(0)} นาที
                                            </span></> : <></>}


                                </div>
                            </div>
                            <div className='name-topic rainbow_text_animated'>{playbackTopic?.name}</div>
                            <div className='detail-topic'>{playbackTopic?.description}</div>
                            <div className="video-tag-group">
                                {subjectState.subjects.find((s) => { return s.subjectId === playbackTopic?.subject_id; }) ?
                                    <div className="video-tag-info video-tag-info-ls topic-name-list-topic">
                                        <p className="subject-name-ls">+ {subjectState.subjects.find((s) => {
                                            return s.subjectId === playbackTopic?.subject_id!;
                                        })?.descriptionTh}</p>
                                    </div>
                                    : <></>}
                                {/* {playbackTopic?.videos[0].instructor_name ?
                                    <div className="video-tag-info video-tag-info-ls topic-name-list-topic">
                                        <p className="subject-name-ls">+ {playbackTopic?.videos[0].instructor_name}</p>
                                    </div>
                                    : <></>} */}
                                {
                                    playbackTopic?.grade.split(",")?.map((grades) =>
                                        gradeState.grades.find((g) => { return grades === g.grade }) ?
                                            <div className="video-tag-info video-tag-info-ls topic-name-list-topic">
                                                <p className="subject-name-ls">+ {gradeState.grades.find((g) => {
                                                    return grades === g.grade;
                                                })?.descriptionTh}</p>
                                            </div>
                                            : <></>
                                    )}
                                {/* {livestream.name ?
                                                    <div className="video-tag-info video-tag-info-ls topic-name-list-topic">
                                                        <p className="subject-name-ls">+ {livestream.name}</p>
                                                    </div>
                                                    : <></>} */}
                            </div>
                            {/* <img style={{ maxWidth: "170px", cursor: "pointer" }} src={watchButton} /> */}
                        </div>
                    </div>
                    <div className='all-video rainbow_text_animated'>วีดีโอทั้งหมด</div>
                    <div className="row" style={{ paddingTop: "20px", paddingLeft: '15px', paddingRight: '15px' }}>
                        {
                            playbackTopic?.videos.map((livestream) => {
                                let date = new Date(livestream.starttime);
                                let dateStart = date.getDate() + " " + month[date.getMonth()] + " " + date.getFullYear();
                                return (
                                    livestream.enabled ?
                                        livestream.is_free === 1 ?
                                            <>
                                                <div className="item-video col-12 col-sm-5 col-lg-4 col-xl-3">
                                                    <Link to={`/livehistory/${livestream.id}`}>
                                                        <div className="video-image-container" style={{ position: "relative" }}>
                                                            {
                                                                livestream.video_thumbnail_hd_url ? <img className="video-image"
                                                                    src={livestream.video_thumbnail_hd_url} alt={livestream.subject} /> : <img className="video-image"
                                                                        src={blankvideo} alt={livestream.subject} />
                                                            }
                                                            <div className="video-type" style={{ position: "absolute", top: "10px", right: "10px" }}>
                                                                <p id="free">Free</p>
                                                            </div>
                                                            <div className="watched-ls">
                                                                {livestream.watched === 1 ?
                                                                    <img className="video-tag-status-image" style={{ width: "32px", height: "22px" }} src={crownGold} alt="" /> :
                                                                    <img className="video-tag-status-image" style={{ width: "32px", height: "22px" }} src={crownGrey} alt="" />
                                                                }
                                                            </div>
                                                            <div className="LSH-duration" style={{ fontSize: "10px" }}>
                                                                {Math.floor(livestream.video_duration / 3600).toFixed(0) !== '0' &&
                                                                    <>{Math.floor(livestream.video_duration / 3600).toFixed(0)} : </>
                                                                }
                                                                <>{("0" + Math.floor((livestream.video_duration % 3600) / 60).toFixed(0)).slice(-2)} : </>
                                                                {("0" + Math.floor(((livestream.video_duration % 3600) % 60)).toFixed(0)).slice(-2)}
                                                            </div>
                                                        </div>
                                                        <p className="topic-name topic-name-ls topic-name-list-topic">{livestream.subject}</p>
                                                        <p className="livestream-date topic-name-list-topic">{dateStart}</p>
                                                    </Link>
                                                    <div className="video-tag-group">
                                                        {subjectState.subjects.find((s) => { return s.subjectId === livestream.subject_id; }) ?
                                                            <div className="video-tag-info video-tag-info-ls topic-name-list-topic">
                                                                <p className="subject-name-ls">+ {subjectState.subjects.find((s) => {
                                                                    return s.subjectId === livestream.subject_id!;
                                                                })?.descriptionTh}</p>
                                                            </div>
                                                            : <></>}
                                                        {livestream.instructor_name ?
                                                            <div className="video-tag-info video-tag-info-ls topic-name-list-topic">
                                                                <p className="subject-name-ls">+ {livestream.instructor_name}</p>
                                                            </div>
                                                            : <></>}
                                                        {
                                                            livestream.grade.split(",")?.map((grades: string) =>
                                                                gradeState.grades.find((g) => { return grades === g.grade }) ?

                                                                    <div className="video-tag-info video-tag-info-ls topic-name-list-topic">
                                                                        <p className="subject-name-ls">+ {gradeState.grades.find((g) => {
                                                                            return grades === g.grade;
                                                                        })?.descriptionTh}</p>
                                                                    </div>

                                                                    : <></>
                                                            )}
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="item-video col-12 col-sm-5 col-lg-4 col-xl-3">
                                                    <Link to={`/livehistory/${livestream.id}`}>
                                                        <div className="video-image-container" style={{ position: "relative" }}>
                                                            {
                                                                livestream.video_thumbnail_hd_url ? <img className="video-image"
                                                                    src={livestream.video_thumbnail_hd_url} alt={livestream.subject} /> : <img className="video-image"
                                                                        src={blankvideo} alt={livestream.subject} />
                                                            }
                                                            <div className="watched-ls">
                                                                {livestream.watched === 1 ?
                                                                    <img className="video-tag-status-image" style={{ width: "32px", height: "22px" }} src={crownGold} alt="" /> :
                                                                    <img className="video-tag-status-image" style={{ width: "32px", height: "22px" }} src={crownGrey} alt="" />
                                                                }
                                                            </div>
                                                            <div className="LSH-duration" style={{ fontSize: "10px" }}>
                                                                {Math.floor(livestream.video_duration / 3600).toFixed(0) !== '0' &&
                                                                    <>{Math.floor(livestream.video_duration / 3600).toFixed(0)} : </>
                                                                }
                                                                <>{("0" + Math.floor((livestream.video_duration % 3600) / 60).toFixed(0)).slice(-2)} : </>
                                                                {("0" + Math.floor(((livestream.video_duration % 3600) % 60)).toFixed(0)).slice(-2)}
                                                            </div>
                                                        </div>
                                                        <p className="topic-name topic-name-ls topic-name-list-topic">{livestream.subject}</p>
                                                        <p className="livestream-date topic-name-list-topic">{dateStart}</p>
                                                    </Link>
                                                    <div className="video-tag-group">
                                                        {subjectState.subjects.find((s) => { return s.subjectId === livestream.subject_id; }) ?
                                                            <div className="video-tag-info video-tag-info-ls topic-name-list-topic">
                                                                <p className="subject-name-ls">+ {subjectState.subjects.find((s) => {
                                                                    return s.subjectId === livestream.subject_id!;
                                                                })?.descriptionTh}</p>
                                                            </div>
                                                            : <></>}
                                                        {livestream.instructor_name ?
                                                            <div className="video-tag-info video-tag-info-ls topic-name-list-topic">
                                                                <p className="subject-name-ls">+ {livestream.instructor_name}</p>
                                                            </div>
                                                            : <></>}
                                                        {
                                                            livestream.grade.split(",")?.map((grades: string) =>
                                                                gradeState.grades.find((g) => { return grades === g.grade }) ?

                                                                    <div className="video-tag-info video-tag-info-ls topic-name-list-topic">
                                                                        <p className="subject-name-ls">+ {gradeState.grades.find((g) => {
                                                                            return grades === g.grade;
                                                                        })?.descriptionTh}</p>
                                                                    </div>

                                                                    : <></>
                                                            )}
                                                    </div>
                                                </div>
                                            </> :
                                        <div className="item-video col-12 col-sm-5 col-lg-4 col-xl-3" style={{ cursor: "pointer" }}>
                                            <div className="video-image-container" style={{ position: "relative" }}>
                                                <img className="video-image" src={blankvideo} />
                                            </div>
                                            <p className="topic-name topic-name-ls topic-name-list-topic">{livestream.subject}</p>
                                            <p className="livestream-date topic-name-list-topic">Coming soon - {dateStart}</p>
                                        </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div style={{ position: "absolute", margin: "26px auto auto 3%", cursor: "pointer" }} >
                <img style={{ width: "4vW", maxWidth: "40px" }} onClick={() => { history.goBack() }} src={backArrow} />
            </div>
        </div>
    </>;
};

export default LiveStreamShowTopic;