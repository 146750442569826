import React from 'react';
import { Link } from 'react-router-dom';
import logo from './../../images/logo/logo-app.png';
import logo2x from './../../images/logo/logo-app@2x.png';
import logo3x from './../../images/logo/logo-app@3x.png';



const HeaderBarBrand = () => {
    return (
        <Link to="/" className="d-flex can-click">
            <img
                alt=""
                width="50"
                height="50"
                src={logo}
                srcSet={`${logo2x}, ${logo3x}`}
                className="d-inline-block align-top"
            />
            <p id="nockacademy-text">NOCK<br />ACADEMY</p>
        </Link>
    );
};

export default HeaderBarBrand;
