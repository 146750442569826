import React from 'react';
import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import { setGradeFilter, setSubjectFilter, setTypeFilter } from '../../stores/filter/FilterState';
import clockIcon from '../../images/clock/clock-icon.svg';
import arrowBlack from '../../images/actions/arrow-black.svg';



export const LiveStreamPlaybackTopicRecentTableLoading = () => {
    const sizeText = Math.floor(Math.random() * 20) + 10;
    const sizeItemp = Math.floor(Math.random() * 4) + 10;

    return (
        <div className="row m-0">
            <h2 className='loading-header gradient-loading' style={{ width: `${sizeText}%` }}></h2>
            <div id="container">
                {Array.from({ length: sizeItemp }, (_, index) => {
                    const sizeTitle = Math.floor(Math.random() * 100) + 10;
                    return (
                        <div key={`temp-${index}`} className="item">
                            <div className="video-image-container-temp gradient-loading"></div>
                            <p className="topic-name gradient-loading" style={{ width: `${sizeTitle}%`, height: '20px' }}></p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};



export const LiveStreamPlaybackTopicRecentTableHeader = (props: { scrollContainer: (direction: number) => void }) => {
    return (
        <div className="w-100 p-0 lsList-container">
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <p className="lesson-list-title lsList-title rainbow_text_animated">
                        หัวข้อไลฟ์สดย้อนหลังล่าสุด
                    </p>
                    <p className="lesson-list-sub-title">กดย้อนดูคลิปที่เคยผ่านการไลฟ์สดไปแล้ว เพื่อทบทวนอีกครั้ง</p>
                </div>
                <div className="lsList-recent-buttons-container">
                    <div className="lesson-list-scroll-button-container">
                        <img className="lesson-list-scroll-left-button lsList-scroll-button" src={arrowBlack} alt="Left Arrow" onClick={() => { props.scrollContainer(0) }} />
                        <img className="lesson-list-scroll-right-button lsList-scroll-button" src={arrowBlack} alt="Right Arrow" onClick={() => { props.scrollContainer(1) }} />
                    </div>
                    <Link to="/playbacktopic">
                        <div className="view-all-recent" style={{ cursor: "pointer" }}>
                            <p className="view-all-text">View All</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};



export const LiveStreamPlaybackTopicRecentTableItems = (props: {
    liveStreamListContainerRef: React.RefObject<HTMLDivElement>,
    LiveStreamPlaybackTopicList: NaTypes.LiveStreamPlaybackTopicList,
    subjectState: NaTypes.SubjectState,
    gradeState: NaTypes.GradeState,
    dispatch: Dispatch<any>,
}) => {
    return (
        <div ref={props.liveStreamListContainerRef} className="ls-playback-list-container">
            {props.LiveStreamPlaybackTopicList.map((livestream, index) => {
                const sum = livestream.videos.reduce((acc, video) => acc + video.video_duration, 0);
                livestream.sum_duration = sum;

                return (
                    <div className="item-video col-6 col-sm-6 col-lg-4 col-xl-3 lsList-item" key={`lsHistoryPop-${index}`}>
                        <Link to={`/playbacktopic/${livestream.id}`}>
                            <div className="video-image-container" style={{ position: "relative" }}>
                                <img className="video-image" src={livestream.videos[0].video_thumbnail_hd_url} />
                                {livestream.new_lesson && <div className="new-lesson">บทเรียนใหม่</div>}
                            </div>
                            <div className="lsList-header-container">
                                <p className="topic-name topic-name-ls topic-name-ls-list">{livestream.name}</p>
                                <div className="video-tag-info lsList-time">
                                    <img src={clockIcon} className="video-duration-image-lsList" alt="Clock Icon" />
                                    <span>
                                        {Math.floor(livestream.sum_duration / 3600).toFixed(0) !== '0' &&
                                            <>{Math.floor(livestream.sum_duration / 3600).toFixed(0)} ชั่วโมง </>}
                                        {Math.floor((livestream.sum_duration % 3600) / 60).toFixed(0)} นาที
                                    </span>
                                </div>
                            </div>
                        </Link>

                        <div className="video-tag-group lsList-tag-container">
                            {props.subjectState.subjects.find((s) => s.subjectId === livestream.subject_id) && (
                                <div className="video-tag-info video-tag-info-ls">
                                    <Link to="" onClick={() => {
                                        if (window.innerWidth > 575) {
                                            props.dispatch(setSubjectFilter(livestream.subject_id));
                                        }
                                    }}>
                                        <p className="subject-name-ls">+ {props.subjectState.subjects.find((s) => s.subjectId === livestream.subject_id)?.descriptionTh}</p>
                                    </Link>
                                </div>
                            )}

                            {livestream.grade.split(",").map((grade) => {
                                const gradeInfo = props.gradeState.grades.find((g) => grade === g.grade);
                                if (!gradeInfo) return null;

                                const { descriptionTh } = gradeInfo;
                                return (
                                    <div key={`grade-${grade}`} onClick={() => {
                                        if (window.innerWidth > 575) {
                                            props.dispatch(setGradeFilter(grade));
                                            props.dispatch(setSubjectFilter(""));
                                            props.dispatch(setTypeFilter(""));
                                        }
                                    }}>
                                        <div className="video-tag-info video-tag-info-ls">
                                            <Link to="" onClick={() => {
                                                if (window.innerWidth > 575) {
                                                    props.dispatch(setGradeFilter(livestream.grade));
                                                }
                                            }}>
                                                <p className="subject-name-ls">+ {descriptionTh}</p>
                                            </Link>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
