import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { search } from '../../stores/lesson-list/SearchState';
import { MixpanelTracking } from '../../utility';
import searchIcon from './../../images/search-icon.svg';
import './SidebarFilter.css';

type DebouncedFunction<T extends (...args: any[]) => any> = T & { flush: () => void };



const SideBarFilterSearch = () => {
    const dispatch = useDispatch();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const filterState = useSelector((state: NaTypes.Store) => state.filter);

    const [searchText, setSearchText] = useState<string>("");



    const debounce = <T extends (...args: any[]) => any>(func: T, wait: number): DebouncedFunction<T> => {
        let timeout: ReturnType<typeof setTimeout> | null;

        const debouncedFunction = (...args: Parameters<T>) => {
            clearTimeout(timeout as ReturnType<typeof setTimeout>);
            timeout = setTimeout(() => func(...args), wait);
        };

        debouncedFunction.flush = () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
                func();
            }
        };

        return debouncedFunction as DebouncedFunction<T>;
    };

    const debouncedSearch = useCallback(debounce((text: string) => {
        const grade = accessData.account?.grade || filterState.gradeId;
        dispatch(search(text));
        MixpanelTracking.searchRecord(grade, text);
    }, 300), [accessData.account, filterState.gradeId, dispatch]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.currentTarget.value;
        setSearchText(text);
        debouncedSearch(text);
    };

    const handleSearchButtonClick = () => {
        debouncedSearch.flush && debouncedSearch.flush();
    };



    useEffect(() => {
        dispatch(search(""));
    }, [dispatch]);



    return (
        <div className="sidebar-card">
            <div className="col-12 filter-section">
                <p className="filter-title rainbow_text_animated">ค้นหาโดย:</p>
            </div>
            <div className="col-12 filter-section pb-3">
                <div className="row">
                    <div className="col-11">
                        <InputGroup id="search">
                            <FormControl
                                value={searchText}
                                onChange={handleSearchChange}
                                placeholder="ค้นหา"
                                aria-label="Search Input"
                            />
                            <InputGroup.Append>
                                <Button id="search-button" onClick={handleSearchButtonClick}>
                                    <img
                                        style={{ width: '20px', height: '20px' }}
                                        src={searchIcon}
                                        alt="Magnifying Glass Icon — Click to Search."
                                    />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideBarFilterSearch;
