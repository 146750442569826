import { generateToken } from '../../utility/Token';

const initialState: NaTypes.AnnouncementState = {
  pending: false,
  announcements: [],
  error: null,
};

const FETCH_ANNOUNCEMENTS_PENDING = 'FETCH_ANNOUNCEMENTS_PENDING';
const fetchAnnouncementsPending = () => {
  return {
    type: FETCH_ANNOUNCEMENTS_PENDING,
  };
};

const FETCH_ANNOUNCEMENTS_SUCCESS = 'FETCH_ANNOUNCEMENTS_SUCCESS';
const fetchAnnouncementsSuccess = (announcements: Array<NaTypes.Announcement>) => {
  return {
    type: FETCH_ANNOUNCEMENTS_SUCCESS,
    announcements,
  };
};

const FETCH_ANNOUNCEMENTS_ERROR = 'FETCH_ANNOUNCEMENTS_ERROR';
const fetchAnnouncementsError = (error: string) => {
  return {
    type: FETCH_ANNOUNCEMENTS_ERROR,
    error,
  };
};

export const fetchAnnouncements = (accessData: NaTypes.Authenticate) => {
  return (dispatch: any) => {
    let token = "";
    if (accessData.accountId) {
      token = generateToken(accessData.accountId!, accessData.accessToken!);
    }
    dispatch(fetchAnnouncementsPending());
    const url = `${process.env.REACT_APP_API_URL_V2}/v1/announcement/list`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "token": token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error === 0) {
          const announcements = res.data.map((announcement: any): NaTypes.Announcement => {
            return {
              id: announcement.id,
              displayOrder: announcement.display_order,
              imageUrl: announcement.image_url,
              action: announcement.action,
            };
          });
          dispatch(fetchAnnouncementsSuccess(announcements));
        } else {
          dispatch(fetchAnnouncementsError(res.error));
        }
      })
      .catch((err) => {
        dispatch(fetchAnnouncementsError(err));
      });
  };
};

/**
 * Announcement state
 * @param {NaTypes.AnnouncementState} state
 * @param {any} action
 * @return {NaTypes.AnnouncementState} current state
 */
export function AnnouncementState(
  state: NaTypes.AnnouncementState = initialState,
  action: any): NaTypes.AnnouncementState {
  switch (action.type) {
    case FETCH_ANNOUNCEMENTS_PENDING:
      return Object.assign({}, state, {
        pending: true,
        error: null,
      });
    case FETCH_ANNOUNCEMENTS_SUCCESS:
      return Object.assign({}, state, {
        pending: false,
        announcements: action.announcements,
      });
    case FETCH_ANNOUNCEMENTS_ERROR:
      return Object.assign({}, state, {
        pending: false,
        error: action.error,
      });
    default:
      return state;
  }
};
