const initialState: NaTypes.Filter = {
  gradeId: 'E5',
  subjectId: '',
  typeId: '',
};

const SET_GRADE_FILTER = 'SET_GRADE_FILTER';
const setGradeFilter = (gradeId: string) => ({
  type: SET_GRADE_FILTER,
  gradeId,
});

const SET_SUBJECT_FILTER = 'SET_SUBJECT_FILTER';
const setSubjectFilter = (subjectId: string) => ({
  type: SET_SUBJECT_FILTER,
  subjectId,
});

const SET_TYPE_FILTER = 'SET_TYPE_FILTER';
const setTypeFilter = (typeId: string) => ({
  type: SET_TYPE_FILTER,
  typeId,
});

export {
  setGradeFilter,
  setSubjectFilter,
  setTypeFilter,
};



export const FilterState = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case SET_GRADE_FILTER:
      return { ...state, gradeId: action.gradeId };
    case SET_SUBJECT_FILTER:
      return { ...state, subjectId: action.subjectId };
    case SET_TYPE_FILTER:
      return { ...state, typeId: action.typeId };
    default:
      return state;
  }
};
