import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import LessonListItem from './LessonListItem';
import './LessonList.css';
import blankvideo from '../../images/button/blankvideo.svg';



const LessonList = (props: {
    subject: string,
    grade: string,
    title: string,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const lessonSet = useSelector((state: NaTypes.Store) => state.lessonSetList.find((set: NaTypes.LessonSet) =>
        set.subject === props.subject &&
        set.grade === props.grade
    ));

    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const searchText = useSelector((state: NaTypes.Store) => state.searchText);
    const [redirect, setRedirect] = useState<any>();

    if (accessData.accessToken && redirect) {
        history.push(redirect);
        setRedirect(null);
    }



    const getLessonList = () => {
        return lessonSet?.listLesson.filter((l) => {
            const searchTextLower = searchText.toLowerCase();
            return (
                l.name.toLowerCase().includes(searchTextLower) ||
                l.chapterList.some((c) => c.description.toLowerCase().includes(searchTextLower))
            );
        });
    };



    return (
        getLessonList()?.length === 0 ? (
            <></>
        ) : (
            <div className="lesson-list-container my-card bg-white" style={{ borderRadius: '1vw' }}>
                <div className="w-100 p-0" style={{ marginLeft: '15px', marginTop: '2%' }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <p className="lesson-list-title rainbow_text_animated" style={{ marginBottom: '15px' }}>
                                ห้องเรียน {props.title}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                    {getLessonList()?.map((lesson, index) => (
                        (!accessData.account && index > 2) ? null : (
                            <LessonListItem
                                key={index}
                                lesson={lesson}
                                index={index}
                                accessData={accessData}
                            />
                        )
                    ))}

                    {!accessData.account && (
                        <Link to={``} onClick={() => { dispatch(requestLogin()) }} className="item-video col-12 col-sm-6 col-lg-4 col-xl-3">
                            <div className="video-image-container">
                                <img className="video-image" src={blankvideo} alt="" />
                            </div>
                        </Link>
                    )}
                </div>
            </div>
        )
    );
};

export default LessonList;
