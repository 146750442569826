import { Dispatch } from 'redux';
import { generateToken } from '../../utility/Token';
import NaTypes from '../../types';



const initialState: NaTypes.QuaterExamListState = {
  pending: false,
  quaterExams: [],
};



const FETCH_QUARTER_EXAM_PENDING = 'FETCH_QUARTER_EXAM_PENDING';
const fetchQuaterExamListPending = () => {
  return {
    type: FETCH_QUARTER_EXAM_PENDING,
  };
};

const RESET_QUARTER_EXAM = 'RESET_QUARTER_EXAMT';
const resetQuaterExamList = () => {
  return {
    type: RESET_QUARTER_EXAM,
  };
};

const FETCH_QUARTER_EXAM_SUCCESS = 'FETCH_QUARTER_EXAM_SUCCESS';
const fetchQuaterExamListSuccess = (quaterExams: NaTypes.QuaterExamList) => {
  return {
    type: FETCH_QUARTER_EXAM_SUCCESS,
    quaterExams,
  };
};



export const QuaterExamListState = (
  state: NaTypes.QuaterExamListState = initialState,
  action: any
): NaTypes.QuaterExamListState => {
  switch (action.type) {
    case FETCH_QUARTER_EXAM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_QUARTER_EXAM_SUCCESS:
      return {
        ...state,
        pending: false,
        quaterExams: action.quaterExams,
      };
    case RESET_QUARTER_EXAM:
      return initialState;
    default:
      return state;
  }
};



export const getQuarterExam = (accessData: NaTypes.Authenticate, grade: string) => async (dispatch: Dispatch<any>) => {
  dispatch(fetchQuaterExamListPending());

  const quarterExams = accessData.accountId && accessData.accessToken
    ? await fetchQuaterExamByGrade(accessData, grade)
    : await fetchQuaterExamNoLoginByGrade(grade);

  dispatch(fetchQuaterExamListSuccess(quarterExams));
};



export const fetchQuaterExamByGrade = async (accessData: NaTypes.Authenticate, grade: string) => {
  try {
    const url = `${process.env.REACT_APP_API_URL_V2}/v1/exam/mark/quarter/${grade}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': generateToken(accessData.accountId!, accessData.accessToken!),
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    return data.data.map((d: any): NaTypes.QuaterExam => ({
      quarter: d.quarter,
      sectionId: d.section_id,
      title: d.title,
      sectionIsFree: d.section_is_free,
      questionNum: d.question_num,
      complete: d.complete,
      image: d.image,
      subjectNames: d.subject_names,
      grades: d.grades,
      grade: d.grade
    }));
  } catch (error) {
    console.error(error);
  }
};



export const fetchQuaterExamNoLoginByGrade = async (grade: string) => {
  try {
    const url = `${process.env.REACT_APP_API_URL_V2}/v1/exam/mark/quarter/${grade}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    return data.data.map((d: any): NaTypes.QuaterExam => ({
      quarter: d.quarter,
      sectionId: d.section_id,
      title: d.title,
      sectionIsFree: d.section_is_free,
      questionNum: d.question_num,
      complete: d.complete,
      image: d.image,
      subjectNames: d.subject_names,
      grades: d.grades,
      grade: d.grade
    }));
  } catch (err) {
    console.error(err);
  }
};
