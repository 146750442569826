import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import { getQuarterExam } from '../../stores/exam/ExamListState';
import { MixpanelTracking } from '../../utility';
import { ToastContainer } from 'react-toastify';
import SideBarFilter from '../../components/sidebar-filter/SidebarFilter';
import SideBarFilterSearch from '../../components/sidebar-filter/SidebarFilterSearch';
import ExamListItem from '../../components/exam/ExamListItem';
import './ExamList.css';



const ExamList = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const filterState = useSelector((state: NaTypes.Store) => state.filter);
    const quarterExamState = useSelector((state: NaTypes.Store) => state.quarterExams);
    const searchText = useSelector((state: NaTypes.Store) => state.searchText);
    const subjectState = useSelector((state: NaTypes.Store) => state.subject);

    const [examsFilter, setExamsFilter] = useState<NaTypes.QuaterExamList>([]);



    const onExamItemClick = (quarterExam: NaTypes.QuaterExam) => {
        const to = `/exam/${filterState.gradeId}/${quarterExam.quarter}`;

        if (!accessData.accessToken) {
            dispatch(requestLogin());
        } else {
            const { quarter, title } = quarterExam;
            MixpanelTracking.examSelected(quarter, title, accessData.account.grade);
            history.push(to);
        }
    };



    useEffect(() => {
        dispatch(getQuarterExam(accessData, filterState.gradeId));
    }, [accessData, dispatch, filterState.gradeId]);

    useEffect(() => {
        if (quarterExamState.quaterExams) {
            const filteredExams = quarterExamState.quaterExams.filter(exam =>
                (!filterState.subjectId || exam.subjectNames?.includes(filterState.subjectId)) &&
                (!searchText || exam.title.toLowerCase().includes(searchText.toLowerCase()))
            );

            setExamsFilter(filteredExams);
        }
    }, [quarterExamState.quaterExams, filterState.subjectId, searchText]);



    return (
        <>
            <ToastContainer
                autoClose={3000} closeOnClick draggable hideProgressBar newestOnTop
                pauseOnFocusLoss pauseOnHover position="top-center" rtl={false}
            />

            <div className="row m-0" id="topic-list-main">
                <div className='col-sm-4 col-md-3 col-lg-2 d-none d-sm-block'>
                    <SideBarFilterSearch />
                    <SideBarFilter />
                </div>

                <div className='col-sm-8 col-md-9 col-lg-10' id="topic-list-container" >
                    <div className='content-ls-topic-list'>
                        <h3 className="rainbow_text_animated">ห้องสอบ</h3>

                        <div className="ls-playback-list-container exam-items-container exam-items-container-page">
                            {examsFilter.map((quarterExam, index) => (
                                <ExamListItem
                                    key={index}
                                    quarterExam={quarterExam}
                                    subjectState={subjectState}
                                    filterState={filterState}
                                    index={index}
                                    onExamItemClick={() => onExamItemClick(quarterExam)}
                                />
                            ))}
                        </div>

                        {examsFilter.length <= 0 ?
                            <div className="row" id="topic-list-empty">
                                <div className="col-12">
                                    ขออภัย ขณะนี้ยังไม่มีข้อสอบที่เกี่ยวข้องกับการค้นหา <br />
                                    กรุณาเลือกระดับชั้นหรือรายวิชาอื่น
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExamList;
