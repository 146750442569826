import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { skipToQuestion } from '../../stores/skip-question/SkipQuestion';
import './SkipQuestion.css';



const SkipQuestion = (props: {
  questionList: NaTypes.QuestionList | undefined
}) => {
  const dispatch = useDispatch();
  const skipQuestionState: NaTypes.SkipQuestion = useSelector((state: NaTypes.Store) => state.skipQuestion);



  useEffect(() => {
    dispatch(skipToQuestion(-1, -1, true));
  }, [dispatch, props.questionList]);



  return (
    <div className="skip-question-container">
      {
        props.questionList?.map((question: NaTypes.Question, index: number) => (
          <button
            key={question.id}
            className={`skip-question-button ${skipQuestionState.questionIndex === index ? 'active' : ''}`}
            disabled={!skipQuestionState.isEnabled}
            onClick={() => {
              dispatch(skipToQuestion(index, question.showAt, false));
            }}
          >
            ข้อที่ {index + 1}
          </button>
        ))
      }
    </div>
  );
};

export default SkipQuestion;
