import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LiveStreamPlaybackTopicList } from '../../types';

import {
  LiveStreamPlaybackTopicPopularTableLoading,
  LiveStreamPlaybackTopicPopularTableHeader,
  LiveStreamPlaybackTopicPopularTableItems,
} from './LiveStreamPlaybackTopicPopularTableRenders';

import { getLSPlaybackPopularList } from './LiveStreamPlaybackTopicPopularTableUtils';
import './LiveStreamPlaybackTopicPopularTable.css';



const LiveStreamPlaybackTopicPopularTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const gradeState = useSelector((state: NaTypes.Store) => state.grade);
  const subjectState = useSelector((state: NaTypes.Store) => state.subject);
  const accessData: NaTypes.Authenticate = useSelector((state: NaTypes.Store) => state.authenticate);
  const filterState: NaTypes.Filter = useSelector((state: NaTypes.Store) => state.filter);
  const liveStreamListContainer = useRef<HTMLDivElement>(null);
  const [scroll, setScroll] = useState(0);
  const [LiveStreamPlaybackTopicPopularList, setLiveStreamPlaybackTopicPopularList] = useState<LiveStreamPlaybackTopicList>([]);
  const [redirect, setRedirect] = useState<any>();



  const scrollContainer = (direction: number) => {
    const container = liveStreamListContainer.current;
    if (!container) return;

    const scrollWidth = container.scrollWidth;
    const clientWidth = container.clientWidth;
    const maxScroll = scrollWidth - clientWidth;
    let newScroll = container.scrollLeft;

    if (direction === 0) {
      newScroll -= 300;
    } else if (direction === 1) {
      newScroll = Math.min(newScroll + clientWidth, maxScroll);
    }

    container.scroll({ left: newScroll });
    setScroll(newScroll);
  };



  useEffect(() => {
    const searchParam = `?page=1${filterState.gradeId ? `&grade=${filterState.gradeId}` : ''}${filterState.subjectId ? `&subject=${filterState.subjectId}` : ''}`;
    getLSPlaybackPopularList(accessData, setLiveStreamPlaybackTopicPopularList, searchParam);
  }, [filterState.gradeId]);

  useEffect(() => {
    const container = liveStreamListContainer.current;

    if (!container) return;

    const { scrollWidth, clientWidth } = container;
    const finalWidth = scrollWidth - clientWidth;
    const interval = setInterval(() => {
      if (scroll === finalWidth) {
        container.scrollLeft = 0;
        setScroll(0);
      } else {
        scrollContainer(1);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [liveStreamListContainer, scroll, setScroll, scrollContainer]);



  if (accessData.accessToken) {
    if (redirect) {
      history.push(redirect);
      setRedirect(null);
    }
  }



  return !LiveStreamPlaybackTopicPopularList ? (
    <LiveStreamPlaybackTopicPopularTableLoading />
  ) : LiveStreamPlaybackTopicPopularList.length === 0 ? (
    <></>
  ) : (
    <div className="lesson-list-container my-card bg-white" style={{ borderRadius: '1vw' }}>
      <LiveStreamPlaybackTopicPopularTableHeader scrollContainer={scrollContainer} />
      <LiveStreamPlaybackTopicPopularTableItems
        liveStreamListContainerRef={liveStreamListContainer}
        LiveStreamPlaybackTopicPopularList={LiveStreamPlaybackTopicPopularList}
        subjectState={subjectState}
        gradeState={gradeState}
        dispatch={dispatch}
      />
    </div>
  );
};

export default LiveStreamPlaybackTopicPopularTable;
