import React, { useEffect } from 'react';
import './PaymentManagement.css';
import PaymentCardList from '../../components/payment-card-list/PaymentCardList';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerChat } from '../../stores/facebookModule/FbModule';


const PaymentManagementPage = () => {
    const dispatch = useDispatch();
    const fbModule: NaTypes.FbModule = useSelector((state: NaTypes.Store) => state.facebookModule);

    useEffect(() => {
        if (fbModule.sdk) {
            if (!fbModule.customerChat) {
                (window as any).FB.Event.subscribe('customerchat.load', () => { dispatch(setCustomerChat(true)) });
                (window as any).FB.XFBML.parse();
            } else {
                (window as any).FB.CustomerChat.show();
            }
        }
        return () => {
            if (fbModule.customerChat) (window as any).FB.CustomerChat.hide();
        };
    }, [dispatch, fbModule.customerChat, fbModule.sdk]);

    return (
        <div className="payment-management-content">
            <h1 className="payment-management-title">จัดการข้อมูลการชำระเงิน</h1>
            <PaymentCardList />
        </div>
    );
};

export default PaymentManagementPage;
