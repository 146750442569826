import React from 'react';
import Question from './Question';



const QuestionList = (props: {
  test: NaTypes.Test,
}) => {
  return (
    <div className="row p-0 m-0">
      {[0, 1].map((column) => (
        <div key={`column-${column}`} className="col-6 p-0">
          {[0, 1].map((row) => {
            const index = column * 2 + row;
            const question = props.test.testQuestionList[index];
            return question ? (
              <div key={question.testId + index} className="col-12 p-0">
                <div className="mini-test-question-card">
                  <Question index={index} question={question} />
                </div>
              </div>
            ) : null;
          })}
        </div>
      ))}
    </div>
  );
};

export default QuestionList;
