import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setGradeFilter, setSubjectFilter, setTypeFilter } from '../../stores/filter/FilterState';
import clockIcon from '../../images/clock/clock-icon.svg';
import playListIcon from '../../images/playlist/playlist-icon.svg';
import './LiveStreamShowTopicList.css';



const LiveStreamShowTopicListRender = (props: {
    liveStreamHistoryFilter: NaTypes.LiveStreamPlaybackTopicList,
}) => {
    const dispatch = useDispatch();
    const gradeState = useSelector((state: NaTypes.Store) => state.grade);
    const subjectState = useSelector((state: NaTypes.Store) => state.subject);

    return (
        <div className="row" id="topic-list-item-container">
            {props.liveStreamHistoryFilter?.map((livestream) => {
                const sum = livestream.videos.reduce((acc, video) => acc + video.video_duration, 0);
                livestream.sum_duration = sum;

                const subject = subjectState.subjects.find((s) => s.subjectId === livestream.subject_id);

                const gradeTags = livestream.grade.split(",").map((grades) => {
                    const grade = gradeState.grades.find((g) => grades === g.grade);
                    return grade && (
                        <div
                            className="video-tag-info video-tag-info-ls topic-name-list"
                            onClick={() => {
                                dispatch(setGradeFilter(grades));
                                dispatch(setSubjectFilter(""));
                                dispatch(setTypeFilter(""));
                            }}
                        >
                            <p className="subject-name-ls">+ {grade.descriptionTh}</p>
                        </div>
                    );
                });

                return (
                    <div className="item-video col-12 col-sm-6 col-lg-3 col-xl-2">
                        <Link to={`/playbacktopic/${livestream.id}`}>
                            <div className="video-image-container">
                                <img className="video-image" src={livestream.videos[0].video_thumbnail_hd_url} />
                                {livestream.new_lesson && <div className="new-lesson-list">บทเรียนใหม่</div>}
                            </div>
                            <div style={{ marginTop: "5px" }}>
                                <p className="topic-name topic-name-ls topic-name-list" style={{ display: "inline" }}>{livestream.name}</p>
                                <div className="video-tag-info topic-name-list" style={{ display: "inline", whiteSpace: "nowrap" }}>
                                    <img src={clockIcon} className="video-duration-image" alt="" />
                                    <span>
                                        {Math.floor(livestream.sum_duration / 3600).toFixed(0) !== '0' && (
                                            <>{Math.floor(livestream.sum_duration / 3600).toFixed(0)} ชั่วโมง </>
                                        )}
                                        {Math.floor((livestream.sum_duration % 3600) / 60).toFixed(0)} นาที
                                    </span>
                                </div>
                            </div>
                        </Link>
                        <div className="video-tag-group">
                            {subject && (
                                <div
                                    className="video-tag-info video-tag-info-ls topic-name-list"
                                    onClick={() => dispatch(setSubjectFilter(livestream.subject_id))}
                                >
                                    <p className="subject-name-ls">+ {subject.descriptionTh}</p>
                                </div>
                            )}
                            {gradeTags}
                        </div>
                        <Link to={`/playbacktopic/${livestream.id}`}>
                            <div className='video-playlist'>
                                <img className='img-playlist' src={playListIcon} />
                                Playlist {
                                    livestream.video_count > 0
                                        ? livestream.video_count
                                        : livestream.videos.filter(video => video.enabled == 1).length
                                } clip{
                                    livestream.video_count > 1 || livestream.videos.filter(video => video.enabled == 1).length > 1
                                        ? "s"
                                        : ""
                                }
                            </div>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};

export default LiveStreamShowTopicListRender;
