import React, { useEffect, useState } from 'react';
import closeImg from '../../images/actions/close.svg';
import './PopUp.css';
import { isMobile } from 'react-device-detect';

const PopUp = () => {
  const [popupStatus, setPopupStatus] = useState<boolean>(false);
  const [popupLink, setPopupLink] = useState<string>("");
  const [popupImage, setPopupImage] = useState<string>("");

  const checkPopUp = async () => {
    const url = `${process.env.REACT_APP_API_URL_V2}/v1/announcement/popup`;
    const popup = await fetch(
      url,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((res): any => { return res.json() })
      .then((res): any => {
        return res.data
      })
      .catch((err) => console.log(err));
    setPopupStatus(popup.status);
    setPopupLink(popup.link);
    setPopupImage(popup.image);
  }

  useEffect(() => {
    if (!popupImage) {
      checkPopUp();
    }
  }, []);

  return (
    <div className="position-absolute login-layout" style={{background:"rgba(0,0,0,0.5)"}} hidden={!popupStatus || !popupImage || isMobile}
    onClick={() => {
      setPopupStatus(false);
    }}>
        <div className="popup-bg">
          {popupLink ?
            <img className='popup-img can-click' src={popupImage} onClick={() => window.open(popupLink)} ></img>
            :
            <img className='popup-img' src={popupImage} ></img>
          }
          <img className="popup-close can-click" src={closeImg} onClick={() => {
            setPopupStatus(false);
          }} />
      </div>
    </div>
  );
};
PopUp.displayName = 'PopUp';
export default PopUp;
