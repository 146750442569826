import React, { useRef, useEffect, useState, } from 'react';
import { useParams } from 'react-router';
import './ExamAnswerOld.css'
import NaTypes, { ExamList, ExamSubmitAnswer, ExplanationVideo } from '../../types';
import { generateToken } from '../../utility/Token';
import { useSelector, useDispatch } from 'react-redux';
import Latex from 'react-latex';
import { ImageUtility } from '../../utility';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import VideoExplain from '../../components/video-explain/VideoExplain';


const ExamAnswerOld = () => {
  const { grade, quarter, id, examId }: any = useParams();
  const accessData: NaTypes.Authenticate = useSelector((state: NaTypes.Store) => state.authenticate);
  const [exams, setExams] = useState<Array<ExamList>>([]);
  const [exam, setExam] = useState<ExamList>();
  const gradeState = useSelector((state: NaTypes.Store) => state.grade);
  const examContainer = useRef<HTMLDivElement>(null);
  const [currentSection, setCurrentSection] = useState<number>(0);
  const [examResult, setExamResult] = useState<ExamSubmitAnswer>();
  const [isStaying, setIsStaying] = useState<boolean>(false);
  const [videoState, setVideoState] = useState<any>(null);
  const [isWatching, setIsWatching] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [currentExplain, setCurrentExplain] = useState<number>(0);
  const [lastPosition, setLastPosition] = useState<number>(1);

  useEffect(() => {
    if (!exam) {
      const fetch = async () => {
        setExams(await getExamList());
      };
      fetch().catch(console.error);
    }
  }, []);

  useEffect(() => {
    if (!examResult) {
      const fetch = async () => {
        const quest = await getAnswerExam();
        if (quest) {
          setExamResult(quest);
        }
      };
      fetch().catch(console.error);
    }
  }, []);

  useEffect(() => {
    setExam(exams.filter(ex => ex.questionSetId == id)[0]);
  });

  const canPlayHandler = (player: any) => {
    setIsStaying(true);
    setVideoState(player);
  };

  const playHandler = () => {
    setIsWatching(true);
  };

  const pauseHandler = () => {
    setIsWatching(false);
  };

  const lockHandle = () => {
    dispatch(requestLogin());
  };

  const currentTimeHandler = (sec: number) => {
  };

  function getExamList() {
    const url = `${process.env.REACT_APP_API_URL_V2}/v1/exam/${grade}/${quarter}`;
    return fetch(
      url,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': generateToken(accessData.accountId!, accessData.accessToken!),
        },
      })
      .then((res) => res.json())
      .then((res) => {
        return res.data.map((d: any): NaTypes.ExamList => {
          return {
            duration: d.duration,
            enable: d.enable,
            examYear: d.exam_year,
            grade: d.grade,
            id: d.id,
            lsId: d.ls_id,
            name: d.name,
            quarter: d.quarter,
            questionSetId: d.question_set_id,
            sectionId: d.section_id,
            subject: d.subject,
            subjectName: d.subject_name,
            subjectTHName: d.subject_th_name,
            pdfFile:d.pdf_exam
          }
        })
      })
      .catch((err) => console.log(err));
  };

  function getAnswerExam() {
    const url = `${process.env.REACT_APP_API_URL_V2}/v1/exam/answer/${examId}`;
    return fetch(
      url,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': generateToken(accessData.accountId!, accessData.accessToken!),
        },
      })
      .then((res) => res.json())
      .then((res): NaTypes.ExamSubmitAnswer => {
        const examAnswerSummit: NaTypes.ExamSubmitAnswer = {
          examSubmitDetail: {
            id: res.data.exam_submit_detail.id,
            userId: res.data.exam_submit_detail.user_id,
            questionSetId: res.data.exam_submit_detail.question_set_id,
            startTime: res.data.exam_submit_detail.start_time,
            endTime: res.data.exam_submit_detail.end_time,
            submitTime: res.data.exam_submit_detail.submit_time
          },
          answerDetail: {
            id: res.data.answer_detail.id,
            name: res.data.answer_detail.name,
            duration: res.data.answer_detail.duration,
            isEnable: res.data.answer_detail.is_enabled,
            questionList: []
          },
          explanationVideo: []
        }

        examAnswerSummit.answerDetail.questionList =
          res.data.answer_detail.question_list.map((d: any): NaTypes.ExamQuestion => {
            let choice =
              d.choice_list.map((c: any): NaTypes.Answer => {
                return {
                  id: c.id,
                  imagePath: c.image_url,
                  value: c.text,
                  isCorrect: c.is_correct == 1,
                  questionId: c.question_id,
                }
              })
            return {
              questionSetId: d.question_set_id,
              id: d.id,
              displayOrder: d.display_order,
              description: d.description,
              imageUrl: d.image_url,
              choiceType: d.choice_type,
              isEnable: d.is_enable,
              reasonText: d.reason_text,
              reasonImageUrl: d.reason_image_url,
              choiceList: choice,
              questionAnswerId: d.question_answer_id
            }
          });

        examAnswerSummit.explanationVideo =
          res.data.explanation_video.map((d: any): NaTypes.ExplanationVideo => {
            return {
              id: d.id,
              position: d.position,
              videoId: d.video_id
            }
          });
        return examAnswerSummit;
      })
      .catch((err) => console.log(err));
  }

  function chooseSection(index: number) {
    setCurrentSection(index);
    scroll(index);
    if (examResult && examResult?.explanationVideo.length > 0) {
      let selectVideo = examResult?.explanationVideo.findIndex(v => index < v.position);
      if (selectVideo) {
        setCurrentExplain(selectVideo);
        if (examResult)
          setLastPosition(examResult?.explanationVideo[selectVideo - 1].position + 1);
      } else {
        setCurrentExplain(0);
        setLastPosition(1);
      }
    }
  }

  const scroll = (index: number) => {
    if (examContainer != null) {
      examContainer.current?.scroll({ left: (index) * 421 });
    }
  };


  return <>
    <div className='col-12 bg-grey' style={{ display: "flex", margin: "auto", justifyContent: "center", flexWrap: "wrap" }}>
      <div className='bg-white col-11'
        style={{ display: "flex", justifyContent: "center", marginTop: "20px", borderRadius: "10px", paddingTop: "20px", paddingBottom: "20px", marginBottom: "20px" }}>
        <div className='col-12'>
          <div style={{ display: "inline-flex" }}>
            <div className='start-text'>เริ่มสอบ</div>
            <div className="video-tag-info exam-tag-info">
              {
                gradeState.grades.find((g) => {
                  return exam?.grade === g.grade;
                })?.descriptionTh
              }
            </div>
            <div className="video-tag-info exam-tag-info">{exam?.subjectTHName}</div>
            {/* <div className="video-tag-info exam-tag-info">เทอม 2</div> */}
            <div className="video-tag-info exam-tag-info">{examResult?.answerDetail.questionList.length} ข้อ</div>
          </div>
          <div className='question-count-group'>
            {
              examResult?.answerDetail.questionList.map((exam, index) => {
                return (
                  <div className={currentSection == index ? exam.choiceList.find(e => e.isCorrect)?.id === exam.questionAnswerId ? 'question-count select-count select-count-right' : "question-count select-count select-count-wrong" : "question-count"}
                    onClick={() => {
                      chooseSection(index);
                    }}>
                    <div>{index + 1}</div>
                  </div>
                )
              })
            }
          </div>
          <div ref={examContainer} className="exam-section-container">
            {
              examResult?.answerDetail.questionList.map((exam, index) => {
                return (
                  <div className={currentSection == index ? 'exam-section exam-section-select' : "exam-section"}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      chooseSection(index);
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div className={exam.choiceList.find(e => e.isCorrect)?.id === exam.questionAnswerId ? 'select-count select-count-right' : "select-count select-count-wrong"}>
                        {index + 1}
                      </div>
                      <div className="exam-question-text">
                        <Latex>{exam.description != null ? exam.description : ''}</Latex>
                      </div>
                    </div>
                    {ImageUtility.isImage(exam.imageUrl) ? (
                      <div className="question-image">
                        <img className="w-100" src={exam.imageUrl} />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div>
                      {exam.choiceList.map((a, i: number) => (
                        <div className='exam-choice' style={{ display: "flex" }}>
                          <div
                            className={a.isCorrect ? "choice-number select-count-right" :
                              a.id === exam.questionAnswerId ? "choice-number select-count-wrong" : "choice-number"}>
                            {i + 1}</div>
                          <div className="choice-exam-value"><Latex>{a.value}</Latex></div>
                          <div className='choice-exam-value'>{ImageUtility.isImage(a.imagePath) ? (
                            <img className="image-choice w-100"
                              src={a.imagePath} alt="" />
                          ) : (
                            <></>
                          )}</div>
                        </div>
                      ))}
                    </div>
                    {exam.reasonText != "" && exam.reasonText != null ? <div className='exam-choice' style={{ border: "1px grey solid", borderRadius: "15px", padding: "10px", marginTop: "30px" }}>
                      คำอธิบาย <br />
                      {exam.reasonText}
                    </div> : <></>}

                  </div>
                )
              })
            }
            <div className="exam-section-none"></div>
            <div className="exam-section-none"></div>
          </div>
          {examResult && examResult.explanationVideo.length > 0 ?
            <>
              <div className='start-text' style={{ textAlign: "center", marginTop: "20px", marginBottom: "10px" }}>
                วีดีโอเฉลย
                {/* {lastPosition}-{examResult.explanationVideo[currentExplain].position} */}
              </div>
              <div style={{ width: "100%", textAlign: "center", marginBottom: "10px" }}>
                <div style={{ textAlign: "center", margin: "auto", display: "inline-flex" ,flexFlow: "wrap"}}>
                  {examResult.explanationVideo.map((video, i) =>
                    <>
                      <div className={currentExplain == i ?'choice-number select-count-right':'choice-number'} onClick={() => { setCurrentExplain(i) }}>
                        {i > 0 ? examResult.explanationVideo[i - 1].position + 1 : i + 1} - {examResult.explanationVideo[i].position}</div>
                    </>
                  )}
                </div>
              </div> 
              <div className="explain-video-container col-12 col-sm-6 p-0">
                <VideoExplain
                  lsVideoExplain={examResult.explanationVideo[currentExplain]}
                  currentTimeHandler={currentTimeHandler}
                  canPlayHandler={canPlayHandler}
                  playHandler={playHandler}
                  pauseHandler={pauseHandler}
                  lock={!accessData.accessToken}
                  lockHandle={lockHandle}
                  enablePopQuiz={false}
                />
              </div>
            </>
            :
            <></>}
          <div className="col-12 exam-summit">
            <div className={'exam-summit-btn col-2'}
              onClick={() => {
                window.location.href = '/exam/' + grade + "/" + quarter
              }
              }
            >เสร็จสิ้น
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;



}

export default ExamAnswerOld;