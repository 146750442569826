import React from 'react';
import Modal from 'react-bootstrap/Modal';



const LiveStreamPagePaymentOffer = (props: {
    showPayment: boolean;
    transaction: Transaction
    setShowConfirmPayment: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    return (
        <Modal
            dialogClassName="modal-payment"
            show={props.showPayment && !props.transaction.waitingPayment}
            centered
            onHide={() => props.setShowConfirmPayment(false)}
        >
            <Modal.Body>
                <div className="row p-2">
                    <div className="col-12 mb-4 na-no-hover-text-normal">
                        ต้องการสมัครใช้บริการหรือไม่
                    </div>
                    <div className="col-6 na-text-accept can-click" onClick={() => window.location.href = `${process.env.REACT_APP_PAY_PORTAL}`}>
                        สมัคร
                    </div>
                    <div className="col-6 na-text-cancel can-click" onClick={() => window.location.href = `${process.env.REACT_APP_WEB_URL}`}>
                        ยกเลิก
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LiveStreamPagePaymentOffer;
