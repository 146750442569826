import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import LiveStreamTableListItem from './LiveStreamTableListItem';
import { scroll, LiveStreamTableListLoading } from './LiveStreamTableListUtils';
import { liveStreamSelect } from '../../stores/live-stream-table-list/LiveStreamTableListState';
import './LiveStreamTableList.css';
import arrowBlack from '../../images/actions/arrow-black.svg';



const LiveStreamTableList = () => {
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const liveStream = useSelector((state: NaTypes.Store) => state.liveStreamTableList);
    const scheduleContainer = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();



    useEffect(() => {
        const timeout = setInterval(() => {
            if (isMobile && !accessData.accountId && scheduleContainer.current) {
                const container = scheduleContainer.current;
                const scrollLeft = container.scrollLeft;
                const scrollWidth = container.scrollWidth;
                const clientWidth = container.clientWidth;

                if (scrollLeft < scrollWidth - clientWidth) {
                    scroll(scheduleContainer, 'right');
                } else {
                    container.scroll({ left: 0 });
                }
            }
        }, 5000);

        return () => {
            clearInterval(timeout);
        };
    });



    return liveStream.list.length === 0 ? (
        <LiveStreamTableListLoading />
    ) : (
        <div className="my-card bg-white" style={{ borderRadius: '1vw', width: '100%', marginTop: '2%', paddingTop: '15px' }}>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <div className="livestream-schedule-table-title rainbow_text_animated">ตารางการไลฟ์สดวันต่อไป</div>
                    <div className="livestream-schedule-table-sub-title">ไลฟ์สดทุกวัน จันทร์ — ศุกร์</div>
                </div>
                <div className="livestream-schedule-scroll-button-container">
                    <img className="livestream-schedule-scroll-left-button" style={{ width: "44px", height: "44px" }} src={arrowBlack} alt="" onClick={() => scroll(scheduleContainer, 'left')} />
                    <img className="livestream-schedule-scroll-right-button" style={{ width: "44px", height: "44px" }} src={arrowBlack} alt="" onClick={() => scroll(scheduleContainer, 'right')} />
                </div>
            </div>

            <div className="live-stream-schedule" ref={scheduleContainer}>
                {liveStream.list.map((ls) => (
                    <LiveStreamTableListItem
                        key={ls.id}
                        liveStream={ls}
                        isSelected={ls.id === liveStream.currentLS?.id}
                        isLive={ls.urlVideo !== ''}
                        onClick={() => dispatch(liveStreamSelect(ls))}
                    />
                ))}
            </div>
        </div>
    );
};

export default LiveStreamTableList;
