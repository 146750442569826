import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { generateToken } from '../../../utility/Token';
import { FirebaseContext } from '../../../firebase/Firebase';
import { requestLogin } from '../../../stores/authorization/AuthorizationState';
import VideoPlayer from '../../../components/video-player/VideoPlayer';
import LiveQuizPopUp from '../../../components/live-quiz-pop-up/LiveQuizPopUp';
import RankingIcon from "../../../images/button/rank-icon.svg";

const LiveStreamPageVideo = (props: {
    isShowLiveQuizButton: boolean;
    perfectScoreShow: boolean;
    rankingScoreShow: boolean;
    streamClose: boolean;
    videoList: Array<any>;
    showPayment: boolean;
    setIsShowLiveQuizButton: React.Dispatch<React.SetStateAction<boolean>>;
    setPerfectScoreShow: React.Dispatch<React.SetStateAction<boolean>>;
    setRankingScoreShow: React.Dispatch<React.SetStateAction<boolean>>;
    setStreamClose: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { database } = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const { id }: { id?: string } = useParams();
    const liveQuizComponent = useRef<LiveQuestionRefs | null>(null);
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const liveStream = useSelector((state: NaTypes.Store) => state.liveStreamTableList);
    const [perfectScoreShown, setPerfectScoreShown] = useState(false);
    const [rankingScoreShown, setRankingScoreShown] = useState(false);
    const ls = liveStream.currentLS;



    const currentTimeHandler = (sec: number) => {
        const { currentLS } = liveStream;
        if (currentLS?.type === "Auto" && currentLS?.duration != null && sec >= currentLS.duration) props.setStreamClose(true);
    };

    const lockHandle = () => {
        dispatch(requestLogin());
    };

    const seektime = (): number => {
        const lsTime = ls?.startTime ? new Date(ls.startTime) : null;
        const duration = ls?.duration || 0;
        return lsTime ? ((new Date().getTime() - lsTime.getTime()) / 1000) % duration : 0;
    };

    const sendCertificate = async () => {
        if (accessData.accessToken) {
            const token = generateToken(accessData.accountId!, accessData.accessToken!);
            try {
                await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/email/send/LSCertificate`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': token,
                    },
                    body: JSON.stringify({ 'ls_id': liveStream.currentLS?.id }),
                });
            } catch (err) {
                console.log(err);
            }
        }
    };



    useEffect(() => {
        if (!database) return;
        const ref = database.ref(`${process.env.REACT_APP_DEVELOP === 'true' ? '/debug' : ''}/liveQuestion/${id}/question`);

        const handleChildChanged = (snapshot: firebase.database.DataSnapshot) => {
            const val = snapshot.val();
            const q = ls?.questions.find((f) => f.id === val.toString());

            if (q) {
                const currentQuestion = liveQuizComponent.current?.getCurrentQuestion();
                if (!currentQuestion || currentQuestion.id !== val.toString()) {
                    props.setIsShowLiveQuizButton(true);
                    liveQuizComponent.current?.reset();
                    liveQuizComponent.current?.setQuestion(q);
                    liveQuizComponent.current?.showQuestion();
                }
            }
        };

        ref.on('child_changed', handleChildChanged);
        return () => ref.off('child_changed', handleChildChanged);

    }, [database, id, ls]);

    useEffect(() => {
        if (!database) return;
        const ref = database.ref(`${process.env.REACT_APP_DEVELOP === 'true' ? '/debug' : ''}/liveQuestion/${id}/answer`);

        const handleChildChanged = (snapshot: firebase.database.DataSnapshot) => {
            const val = snapshot.val();
            const currentQuestion = liveQuizComponent.current?.getCurrentQuestion();
            if (currentQuestion?.id === val.toString()) liveQuizComponent.current?.showAnswer();
        };

        ref.on('child_changed', handleChildChanged);
        return () => ref.off('child_changed', handleChildChanged);

    }, [database, id, ls]);

    useEffect(() => {
        if (props.perfectScoreShow) setPerfectScoreShown(true);
    }, [props.perfectScoreShow]);

    useEffect(() => {
        if (props.rankingScoreShow) setRankingScoreShown(true);
    }, [props.rankingScoreShow]);



    return (
        <div className="video-layout p-0">
            <div className="ls-top-right-buttons">
                {(!props.streamClose && props.isShowLiveQuizButton) && (
                    <div className="ls-quiz-button" onClick={() => liveQuizComponent.current?.showQuestion()}>
                        Quiz
                    </div>
                )}

                {(!props.streamClose && perfectScoreShown) && (
                    <div className="ls-perfect-button" onClick={() => props.setPerfectScoreShow(true)}>
                        Perfect
                    </div>
                )}

                {(!props.streamClose && rankingScoreShown) && (
                    <div className="ls-ranking-button" onClick={() => props.setRankingScoreShow(true)}>
                        <img src={RankingIcon} alt={`Ranking Icon`} />
                        Rank
                    </div>
                )}
            </div>

            <LiveQuizPopUp questions={ls?.questions} ref={liveQuizComponent} sendCertificate={sendCertificate} />

            {(!props.streamClose && ls?.urlVideo !== '') ? (
                <VideoPlayer
                    fileList={ls?.type === "Auto" ? props.videoList : [{ link: ls?.urlVideo, quality: 'hls' }]}
                    canPlayHandler={() => { }} currentTimeHandler={currentTimeHandler}
                    playHandler={() => { }} pauseHandler={() => { }} isLiveStream={true} miniSize={true}
                    imagePreview={ls?.scheduleImagePath} lock={!accessData.accessToken || props.showPayment}
                    lockHandle={lockHandle} isAutoPlay={ls?.type === "Auto"}
                    seekTime={ls?.type === "Auto" ? seektime() : undefined}
                />
            ) : (
                <img style={{ width: '100%' }} src={ls?.scheduleImagePath} alt="Livestream Scheduled Thumbnail" />
            )}
        </div>
    );
};

export default LiveStreamPageVideo;
