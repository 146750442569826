import React from 'react';
import moment from 'moment';
import { Dispatch } from 'redux';
import { generateToken } from '../../utility/Token';
import { MixpanelTracking } from '../../utility/MixpanelTracking';
import { requestLogin } from '../../stores/authorization/AuthorizationState';



export const scroll = (
    scheduleContainer: React.RefObject<HTMLDivElement>,
    direction: string,
) => {
    if (scheduleContainer != null) {
        const clientWidth = scheduleContainer.current!.clientWidth;
        const scrollAmount = direction === 'left' ? -clientWidth : clientWidth;
        scheduleContainer.current?.scroll({ left: scheduleContainer.current?.scrollLeft + scrollAmount });
    }
};



export const getTimeString = (date: string) => {
    const today = moment().format('YYYY-MM-DD');
    const targetDate = moment(date).format('YYYY-MM-DD');

    if (targetDate === today) {
        return 'วันนี้';
    } else if (moment(today).add(1, 'days').format('YYYY-MM-DD') === targetDate) {
        return 'พรุ่งนี้';
    }

    const monthAbbreviations = ["", "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
    return moment(date).format('DD') + ' ' + monthAbbreviations[parseInt(moment(date).format('MM'))];
};



export const toggleNotificationLS = async (
    liveStream: NaTypes.LiveStream,
    setNotificationValue: boolean,
    accessData: NaTypes.Authenticate,
    dispatch: Dispatch<any>,
    setNotification: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    if (!accessData.accessToken) {
        dispatch(requestLogin());
        return;
    }

    const url = setNotificationValue
        ? `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/insert/notification`
        : `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/delete/notification`;

    const token = generateToken(accessData.accountId!, accessData.accessToken);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': token,
            },
            body: JSON.stringify({
                "ls_id": liveStream.id,
                "user_id": accessData.accountId,
            }),
        });

        if (setNotificationValue) {
            setNotification(true);
            MixpanelTracking.lsBookmark(
                liveStream.subjectId,
                liveStream.subjectDescriptionTh,
                liveStream.id,
                liveStream.subject,
                liveStream.grade,
                accessData.account.grade
            );
        } else {
            setNotification(false);
        }

        const data = await response.json();
        return data;
    } catch (err) {
        console.error(err);
    }
};



export const checkNotification = async (
    accessData: NaTypes.Authenticate,
    lsId: string,
    setNotification: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    if (!accessData.accessToken) return;

    const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/notification?ls_id=${lsId}&user_id=${accessData.accountId}`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': generateToken(accessData.accountId!, accessData.accessToken!),
    };

    try {
        const response = await fetch(url, { method: 'GET', headers });
        const { data } = await response.json();
        setNotification(data && data.length > 0);
    } catch (err) {
        console.log(err);
    }
}



export const LiveStreamTableListLoading = () => {
    const sizeItemp = 2;
    const sizeText = Math.floor(Math.random() * 20) + 10;

    const itemp = Array.from({ length: sizeItemp });

    return (
        <div className="row m-0">
            <h2
                className='gradient-loading'
                style={{
                    width: sizeText + '%',
                    height: '35px',
                }}>
            </h2>
            <div id="container">
                {itemp.map((_, index) => (
                    <div key={'temp-' + index} className="item">
                        <div className="gradient-loading"
                            style={{
                                width: '300px',
                                height: '170px',
                            }}>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
