import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import BeatLoader from 'react-spinners/BeatLoader';
import './JoinSchool.css';
import dropDown from '../../images/other/dropdown.svg';
import successIcon from '../../images/other/payment-success.svg';

interface SchoolInfoState {
    loading: boolean;
    info: any | null;
    rooms: [] | null;
}



const JoinSchoolPage = () => {
    const { token }: any = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);

    const [buttonLoading, setButtonLoading] = useState(false);
    const [completejoin, setCompleteJoin] = useState(false);
    const [error, setError] = useState<Error | null>();
    const [errorPage, setErrorPage] = useState<Error | null>();
    const [grade, setGrade] = useState<String>('');
    const [room, setRoom] = useState<any>();
    const [schoolInfo, setSchoolInfo] = useState<SchoolInfoState>({ loading: false, info: null, rooms: null });



    function joinSchool(grade: String, room: Number, userId: Number, token: String) {
        fetch(
            `${process.env.REACT_APP_API_URL_V2}/v1/join/school/${token}`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    grade,
                    room,
                    user_id: userId,
                }),
            }).then(async (res) => {
                setButtonLoading(false);
                if (res.status !== 200) {
                    const errorMessage = await res.text();
                    console.log(errorMessage);
                    throw new Error(errorMessage);
                } else {
                    const data = await res.json();
                    if (data.error === 0) {
                        setCompleteJoin(true);
                    } else {
                        throw new Error('กรุณาลองใหม่อีกครั้ง');
                    }
                }
            }).catch((err) => {
                setButtonLoading(false);
                if (err.message === 'Invalid parameter') {
                    setError(err);
                } else if (err.message === 'Token expired') {
                    setErrorPage(err);
                } else {
                    setError(err);
                }
            });
    }



    useEffect(() => {
        setSchoolInfo({ loading: true, info: null, rooms: null });
        fetch(
            `${process.env.REACT_APP_API_URL_V2}/v1/join/school/detail/${token}`,
            {
                method: 'GET',
            }).then(async (res) => {
                if (res.status !== 200) {
                    throw new Error(await res.text());
                } else {
                    const data = await res.json();
                    setSchoolInfo({
                        loading: false,
                        info: data.data.info,
                        rooms: data.data.rooms,
                    });
                }
            }).catch((err) => {
                setErrorPage(new Error(err.message));
            });
    }, [token]);



    dispatch(requestLogin());

    if (completejoin) {
        setTimeout(() => {
            history.push('/');
        }, 3000);
    }

    if (!accessData.accessToken) return <></>;

    if (errorPage != null) {
        return <>
            <div className="d-flex justify-content-center mt-5">
                <div style={{
                    width: '80%',
                    maxWidth: '450px',
                }}>
                    <div className="alert alert-danger" role="alert">
                        {
                            errorPage.message === 'Token expired' ? <>ลิ้งหมดอายุ</> : <></>
                        }
                    </div>
                </div>
            </div>
        </>;
    }

    return <>
        <div className="d-flex justify-content-center mt-5">
            <div style={{
                width: '80%',
                maxWidth: '450px',
            }}>
                <div className="join-school-title">
                    ยืนยันการเข้าโรงเรียน
                </div>
                {
                    schoolInfo.loading ? <>
                        <div>
                            loading school info
                        </div>
                    </> : <>
                        <div className="join-school-name" style={{ marginTop: '8px' }}>
                            {schoolInfo.info?.name}
                        </div>
                        <div className="d-flex align-items-center" style={{ marginTop: '8px' }}>
                            <img className="join-profile-image" src={accessData.account.lowPhotoUrl} alt=""></img> <div style={{ marginLeft: '8px' }}>{accessData.account.fullname}</div>
                        </div>

                        <div className="d-flex w-100" style={{ marginTop: '8px' }}>
                            <div style={{ width: '40%' }}>
                                <select className="join-input-filter" onChange={(e) => {
                                    setGrade(e.currentTarget.value);
                                }}>
                                    <option value="">ชั้น</option>
                                    {
                                        schoolInfo.rooms
                                            ?.reduce((p: Array<any>, c: any) => {
                                                if (p.findIndex((v) => v === c.grade) === -1) {
                                                    p.push(c.grade);
                                                }
                                                return p;
                                            }, [])
                                            ?.map((g, i) => <option key={i} value={g}>
                                                {g}
                                            </option>)
                                    }
                                </select>
                                <img className="join-input-filter-drop" src={dropDown} alt="" />
                            </div>

                            <div style={{ marginLeft: '8px', width: '40%' }} >
                                <select className="join-input-filter" onChange={(e) => {
                                    setRoom(e.currentTarget.value);
                                }}>
                                    <option value="0">ห้อง</option>
                                    {
                                        schoolInfo.rooms
                                            ?.filter((r: any) => grade === r.grade)
                                            ?.map((r: any, i) => <option key={i} value={r.id}>
                                                {r.name}
                                            </option>)
                                    }
                                </select>
                                <img className="join-input-filter-drop" src={dropDown} alt="" />
                            </div>
                        </div>

                        <div style={{ color: 'red' }}>
                            {
                                error ? <div style={{ width: '100%', marginTop: '8px' }}>
                                    {error.message === 'Invalid parameter' ? <>
                                        * กรุณาเลือกชั้น และห้องเรียน
                                    </> : <>
                                        {error.message}
                                    </>}
                                </div> : <></>
                            }
                        </div>

                        <div className="d-flex flex-column justify-content-center" style={{ marginTop: '8px' }}>
                            {
                                completejoin ? <>
                                    <button className="join-button-complete"> <img height="30" src={successIcon} alt="" /> เข้าโรงเรียนเรียบร้อย</button>
                                </> : <>
                                    <button className="join-button" onClick={() => {
                                        if (accessData.accountId && token) {
                                            setButtonLoading(true);
                                            setError(null);
                                            joinSchool(grade, room, parseInt(accessData.accountId), token);
                                        }
                                    }}>
                                        {
                                            buttonLoading ? <BeatLoader color="#FFF"></BeatLoader> : <>เข้าโรงเรียน</>
                                        }
                                    </button>
                                </>
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    </>;
};

export default JoinSchoolPage;
