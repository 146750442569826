import React from 'react';
import CloseIcon from '../../../images/close/close.svg';
import UserProfileIcon from '../../../images/user/user.svg';

const LiveStreamPagePerfectScore = (props: {
    perfectScoreShow: boolean;
    setPerfectScoreShow: React.Dispatch<React.SetStateAction<boolean>>;
    studentPerfectScore: Array<{
        bigPhotoUrl: string;
        color: string;
        fullname: string;
    }>;
}) => {
    return (
        <div className='perfect-score-board' hidden={!props.perfectScoreShow}>
            <div className='perfect-score-close'>
                <img
                    src={CloseIcon}
                    onClick={() => props.setPerfectScoreShow(false)}
                    alt="Close"
                />
            </div>

            <div className='perfect-score-title'>
                ได้คะแนนเต็ม
            </div>

            <div className='perfect-student-score-board'>
                {props.studentPerfectScore.map((student, i) => (
                    <div className='perfect-score-rank' key={i}>
                        <img
                            className='perfect-score-rank-img'
                            style={{ borderColor: student.color }}
                            src={student.bigPhotoUrl || UserProfileIcon}
                            alt={student.fullname}
                        />
                        <div className='perfect-score-rank-name'>{student.fullname}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LiveStreamPagePerfectScore;
