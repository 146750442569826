import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { FirebaseContext } from '../../firebase/Firebase';
import { recordStudyTime, recordVideoWatched } from '../../api/VideoOnDemand';
import { useInterval } from '../../utility';
import { generateToken } from '../../utility/Token';
import { isMobile } from 'react-device-detect';
import { MixpanelTracking } from '../../utility/MixpanelTracking';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import Latex from 'react-latex';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import SplitPane from 'react-split-pane';
import ChatHistory from '../../components/chat-history/ChatHistory';
import LiveHistoryQuizPopUp from '../../components/live-history-quiz-pop-up/LiveHistoryQuizPopUp';
import VideoHistory from '../../components/video-history/VideoHistory';
import slideIcon from '../../images/document/slide-icon.svg';
import downloadIcon from '../../images/document/download.svg';
import './LiveStreamHistoryPage.css';



const LiveStreamHistoryPage = () => {
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const gradeState = useSelector((state: NaTypes.Store) => state.grade);
    const profileState = useSelector((state: NaTypes.Store) => state.profile);
    const transaction = useSelector((state: NaTypes.Store) => state.transaction);

    const chatComponent = useRef<ChatRef>();
    const liveQuizComponent = useRef<LiveQuestionRefs>();
    const refSectionElement = useRef<HTMLElement>(null);

    const { database } = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const { id }: any = useParams();
    const pdf = React.createRef<HTMLIFrameElement>();

    const [countStayingTime, setCountStayingTime] = useState(0);
    const [videoWatched, setVideoWatched] = useState(false);

    const [activeQuestion, setActiveQuestion] = useState<number | null>(null);
    const [allMessage, setAllMessage] = useState<MessageItem[]>([]);
    const [enablePopQuiz, setEnablePopQuiz] = useState<boolean>(false);
    const [liveStreamHistory, setLSPlayback] = useState<any>({});
    const [questionList, setQuestionList] = useState<any[]>([]);
    const [resizing, setResizing] = useState<boolean>(false);
    const [sameQuestion, setSameQuestion] = useState<boolean>(false);
    const [showConfirmPayment, setShowConfirmPayment] = useState<boolean>(false);
    const [splitAxis, setSplitAxis] = useState<'vertical' | 'horizontal'>();
    const [splitSize, setSplitSize] = useState<'70%' | '0' | ''>();

    const [showQuiz, setShowQuiz] = useState(false);
    const [showAns, setShowAns] = useState(false);

    let showPayment = showConfirmPayment;



    if (accessData.accessToken && !transaction.isPremium && liveStreamHistory.is_free === 0) {
        showPayment = true;
    }

    if (isMobile) {
        refSectionElement.current?.scrollIntoView();
    }



    const getLSPlayback = async (id: string) => {
        try {
            const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/view/${id}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const res = await response.json();
            setLSPlayback(res);

            if (res.questions && res.questions.length > 0) {
                setEnablePopQuiz(true);
                setQuestionList(res.questions);
            } else {
                setEnablePopQuiz(false);
                setQuestionList([]);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const lockHandle = () => {
        dispatch(requestLogin());
    };

    const currentTimeHandler = (sec: number) => {
        const index = liveStreamHistory?.questions.findIndex((q: any) => sec >= q.start && sec <= q.answer + 10);

        if (index !== undefined && index >= 0) {
            const liveQuest = liveStreamHistory.questions[index];
            if (sec >= liveQuest.start && sec < liveQuest.answer) {
                if (!showQuiz) {
                    liveQuizComponent.current?.setQuestion(liveQuest);
                    liveQuizComponent.current?.showQuestion();
                    setShowQuiz(true);
                    setShowAns(false);
                }
            } else if (!showAns && showQuiz) {
                setShowAns(true);
                setShowQuiz(false);
                liveQuizComponent.current?.showAnswer();
            }
        } else {
            liveQuizComponent.current?.reset();
            setShowAns(false);
            setShowQuiz(false);
        }

        const start = liveStreamHistory?.actual_starttime ? new Date(liveStreamHistory.actual_starttime) : new Date();
        const messageListShow = allMessage.filter(message => message.datetime <= (start.getTime() / 1000) + sec);
        chatComponent.current?.fetchMessageList(messageListShow);
    };

    const onClickDownloadDocument = (url: string, documentType: string) => {
        if (!accessData.accountId || !accessData.accessToken) {
            dispatch(requestLogin());
        } else {
            window.open(url);
            MixpanelTracking.downloadDocumentOnLiveStreamHistory(
                liveStreamHistory.subject_id,
                liveStreamHistory.detail,
                liveStreamHistory.id,
                liveStreamHistory.subject,
                liveStreamHistory.grade,
                accessData.account.grade,
                documentType);

        }
    };

    const handleQuestionClick = (index: number) => {
        setShowQuiz(false);
        setShowAns(false);
        setActiveQuestion(index);
        setSameQuestion(index === activeQuestion ? prevSameQuestion => !prevSameQuestion : false);
        liveQuizComponent.current?.reset();
    };



    useInterval(() => {
        const increment = process.env.NODE_ENV === 'production' ? 1 : 60;
        setCountStayingTime(prev => prev + increment);
    }, 1000);



    useEffect(() => {
        if (countStayingTime >= 20 * 60) {
            recordStudyTime(accessData, liveStreamHistory.subject_id, countStayingTime);
            setCountStayingTime(0);

            if (!videoWatched) {
                recordVideoWatched(accessData);
                setVideoWatched(true);
            }
        }
    }, [countStayingTime, accessData, liveStreamHistory.subject_id, videoWatched, setVideoWatched]);

    useEffect(() => {
        if (accessData.accessToken && liveStreamHistory && (liveStreamHistory.is_free === 1 || transaction.isPremium)) {
            const token = generateToken(accessData.accountId!, accessData.accessToken!);
            fetch(`${process.env.REACT_APP_API_URL_V2}/v1/live-stream/lsplayback/view/tracking`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': token,
                    },
                    body: JSON.stringify({
                        'user_id': accessData.accountId,
                        'ls_id': liveStreamHistory.id,
                        'device': "WEB",
                    }),
                })
                .then((res) => {
                })
                .catch((err) => console.log(err));
            if (liveStreamHistory.subject) {
                MixpanelTracking.lsplaybackselected(liveStreamHistory.subject_id,
                    liveStreamHistory.subject_name,
                    liveStreamHistory.id,
                    liveStreamHistory.subject,
                    liveStreamHistory.grade,
                    accessData.account.grade);
            }

        }
    }, [accessData.accessToken, liveStreamHistory]);

    useEffect(() => {
        getLSPlayback(id);
    }, [id]);

    useEffect(() => {
        if (database) {
            const ref = database.ref(`${process.env.REACT_APP_DEVELOP === 'true' ? '/debug' : ''}/${id}/`);
            ref.orderByKey().once('value', (snapshot: firebase.database.DataSnapshot) => {
                const vals = snapshot.val();
                if (vals) {
                    const messageList: MessageItem[] = Object.keys(vals).map(function (key) {
                        const date = new Date(vals[key].createdTime * 1000);
                        return {
                            key: key,
                            self: accessData.accountId === vals[key].Id.toString(),
                            message: vals[key].Message,
                            userId: vals[key].Id.toString(),
                            userName: vals[key].UserName,
                            userImageUrl: vals[key].UrlProfile,
                            datetime: vals[key].createdTime,
                            datetimeDisplay: moment(date).format('HH:mm'),
                            type: vals[key].type,
                            admin: accessData.accountId === process.env.REACT_APP_ADMIN_ACCOUNT
                        };
                    });
                    setAllMessage(messageList);
                }
            });
            return () => {
                ref.off();
            };
        }
    }, [database]);

    useEffect(() => {
        const handleResizeScreen = () => {
            if (window.innerWidth < 1024) {
                setSplitAxis('horizontal');
                setSplitSize('');
            } else {
                setSplitAxis('vertical');
                setSplitSize('70%');
            }
        };
        handleResizeScreen();
        window.addEventListener('resize', handleResizeScreen);
        return () => {
            window.removeEventListener('resize', handleResizeScreen);
        };
    });



    if (!liveStreamHistory) {
        return <p>Loading</p>;
    }

    return (
        <>
            <div className="row livestream-page-container">

                <aside className="order-2 order-sm-1 col-12 col-sm-5 col-md-4 col-lg-3 p-0 d-none d-sm-block">
                    <ChatHistory ref={chatComponent}
                        senderProfile={accessData.account ? {
                            fullname: accessData.account.fullname,
                            nickname: accessData.account.login,
                            imageUrl: profileState.profile?.normalPhotoUrl,
                        } : null}
                        send={() => { }}
                        delete={() => { }} />
                </aside>

                <section className="h-100 order-1 order-sm-2 col-12 col-sm-7 col-md-8 col-lg-9 no-padding">
                    <SplitPane
                        split={splitAxis}
                        size={splitSize}
                        onDragStarted={() => setResizing(true)}
                        onDragFinished={() => setResizing(false)}
                        pane1Style={{ overflowY: "auto" }}
                        style={isMobile ? { overflowY: "auto" } : undefined}
                    >
                        <div className="w-100" style={{ backgroundColor: "#FFFFFF" }}>
                            <div className="video-layout">
                                {
                                    <LiveHistoryQuizPopUp
                                        livesteamHistory={liveStreamHistory}
                                        ref={liveQuizComponent}
                                    />
                                }{
                                    <VideoHistory
                                        activeQuestion={activeQuestion}
                                        enablePopQuiz={enablePopQuiz}
                                        lock={!accessData.accessToken || showPayment}
                                        lsVideoHistory={liveStreamHistory}
                                        questionList={questionList}
                                        sameQuestion={sameQuestion}
                                        currentTimeHandler={currentTimeHandler}
                                        lockHandle={lockHandle}
                                    />
                                }
                            </div>

                            <div className="live-title-container">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="live-title-text">
                                        <h1 className='live-title-text rainbow_text_animated'>
                                            {liveStreamHistory.subject}
                                        </h1>
                                    </div>
                                </div>

                                <div>
                                    <Latex>{liveStreamHistory.detail}</Latex>
                                    <br />
                                    {
                                        gradeState.grades.find((s) => {
                                            return s.grade === liveStreamHistory.grade!;
                                        })?.descriptionTh
                                    }
                                    {
                                        liveStreamHistory.teacher_name ? " (" + liveStreamHistory.teacher_name + ")" : null
                                    }
                                </div>

                                <div className="history-questions">
                                    {
                                        liveStreamHistory.questions && liveStreamHistory.questions.length > 0 && (
                                            liveStreamHistory.questions.map((question: NaTypes.Question, index: number) => (
                                                <button
                                                    key={index}
                                                    className={`history-question-button ${index === activeQuestion ? 'active' : ''}`}
                                                    onClick={() => handleQuestionClick(index)}
                                                >
                                                    ข้อที่ {index + 1}
                                                </button>
                                            ))
                                        )
                                    }
                                </div>

                                {
                                    liveStreamHistory.slide_document ? (
                                        <>
                                            <div className="document-title-header-container">
                                                <span className="document-title-header rainbow_text_animated">เอกสารประกอบการเรียน</span>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-lg-6 document-download-container">
                                                    <div className="document-download-button" onClick={() => onClickDownloadDocument(`${process.env.REACT_APP_API_URL}/api/v7/method/doclesson?path=${liveStreamHistory.slide_document}`, 'Slide')}>
                                                        <img className="document-thumbnail-icon" src={slideIcon} alt="Slides Icon" />
                                                        <span className="document-download-text">สไลด์ประกอบการเรียน</span>
                                                        <img className="document-download-icon" src={downloadIcon} alt="Download Icon" />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null
                                }
                            </div>

                        </div>
                        <div style={{ backgroundColor: "#E9E9F2", height: "100%" }}>
                            {
                                resizing ? (
                                    <div className="h-100 w-100 position-absolute"></div>
                                ) : null
                            }
                            {
                                liveStreamHistory.img_document && (
                                    <iframe
                                        ref={pdf}
                                        className="w-100 h-100 doc-layout"
                                        title="document"
                                        src={`https://docs.google.com/gview?embedded=true&url=${process.env.REACT_APP_API_URL}/api/v7/method/doclesson?path=${liveStreamHistory.img_document}`}
                                        sandbox={accessData.accountId ? "" : "allow-scripts allow-same-origin"}
                                    >
                                    </iframe>
                                ) || (
                                    <iframe
                                        className="w-100 h-100 doc-layout"
                                        title="no document"
                                    />
                                )
                            }
                        </div>
                    </SplitPane>
                </section>

            </div>

            <Modal dialogClassName="modal-payment" show={showPayment && !transaction.waitingPayment} centered onHide={() => setShowConfirmPayment(false)}>
                <Modal.Body>
                    <div className="row p-2">
                        <div className="col-12 mb-4 na-no-hover-text-nomal">ต้องการสมัครใช้บริการหรือไม่</div>
                        <div className="col-6 na-text-accept can-click" onClick={() => {
                            window.location.href = `${process.env.REACT_APP_PAY_PORTAL}`;
                        }}>สมัคร</div>
                        <div className="col-6 na-text-cancel can-click" onClick={() => {
                            window.location.href = `${process.env.REACT_APP_WEB_URL}`;
                        }}>ยกเลิก</div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal dialogClassName="modal-payment" show={showPayment && transaction.waitingPayment} centered onHide={() => setShowConfirmPayment(false)}>
                <Modal.Body>
                    <div className="row p-2">
                        <div className="col-12 mb-4 na-no-hover-text-nomal">รอยืนยันการชำระเงิน</div>
                        <div className="col-6"></div>
                        <div className="col-3"></div>
                        <div className="col-3 na-text-cancel can-click" onClick={() => {
                            window.location.href = `${process.env.REACT_APP_WEB_URL}`;
                        }}>ตกลง</div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default LiveStreamHistoryPage;
