import React from 'react';
import { useDispatch } from 'react-redux';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import sendButton from '../../images/chat/send-button.svg';



const ChatInput = (props: {
    senderProfile: SenderProfile | null,
    message: string,
    setMessage: React.Dispatch<React.SetStateAction<string>>,
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    send: () => void,
}) => {
    const dispatch = useDispatch();



    if (!props.senderProfile) {
        return (
            <div style={{ margin: 'auto' }}>
                <button
                    className="chat-login-button"
                    onClick={() => dispatch(requestLogin())}
                >
                    เข้าสู่ระบบเพื่อใช้งานแชท
                </button>
            </div>
        );
    }



    return (
        <>
            <img
                className="chat-message-item-image-profile"
                src={props.senderProfile.imageUrl}
                alt=""
            />

            <div className="chat-message-item-message-container" style={{ width: '100%' }}>
                <div className="chat-message-item-username">{props.senderProfile.nickname}</div>

                <div className="chat-message-item-text-container">
                    <input
                        className="chat-input"
                        type="text"
                        style={{ width: '100%', marginRight: '10px' }}
                        value={props.message}
                        onKeyDownCapture={props.handleKeyDown}
                        onChange={(e) => {
                            if (e.currentTarget.value.length <= 200) {
                                props.setMessage(e.currentTarget.value.substr(0, 200));
                            }
                        }}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginRight: '20px',
                        marginTop: '5px',
                    }}
                >
                    <div></div>
                    <div>
                        <span className="chat-input-maximum">{`${props.message.length}/200`}</span>
                        <img
                            className="chat-send-button"
                            src={sendButton}
                            alt="send"
                            onClick={props.send}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChatInput;
