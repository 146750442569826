import React, {
    useEffect, useState, useRef, forwardRef, useImperativeHandle,
} from 'react';
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton,
    BigPlayButton,
    PlayToggle,
    ProgressControl,
    DurationDisplay,
} from 'video-react';
import '../../../node_modules/video-react/dist/video-react.css';
import logo from '../../images/logo/new_logo_nock.png';
import playWhite from '../../images/play/play_white.svg';
import HLSSource from './HLSSource';

export type currentTimeCallback = (sec: number) => void
export interface VideoPlayerProps {
    fileList: any,
    currentTimeHandler: currentTimeCallback,
    canPlayHandler: Function,
    playHandler: Function,
    pauseHandler: Function,
    isLiveStream?: boolean,
    miniSize?: boolean,
    muted?: boolean,
    imagePreview?: string,
    lock?: boolean,
    lockHandle?: Function,
    isAutoPlay?: boolean,
    seekTime?: number,
    mainPage?: boolean
}

/**
 * class for handle second
 */
class VideoTime {
    private second: number = -1;

    /**
     * constructor for create with callback
     * @param {Function} onUpdate callback when time update
     */
    constructor(private onUpdate: (sec: number) => void) { }

    /**
     * update second when second not same
     * @param {number} sec second
     */
    updateTime(sec: number) {
        if (this.second !== sec) {
            this.second = sec;
            this.onUpdate(this.second);
        }
    }
}

const VideoPlayer = forwardRef((
    props: VideoPlayerProps,
    ref) => {
    const [currentVideo, setCurrentVideo] = useState<any>(null);
    const [videoList, setVideoList] = useState<Array<any>>([]);
    const player = useRef<VideoPlayerRefs>(null);

    useEffect(() => {
        setCurrentVideo(props.fileList.find((f: any) => f.quality === 'hls'));
        setVideoList(props.fileList);
        return () => { };
    }, [props.fileList]);

    useEffect(() => {
        if (player.current && props.isAutoPlay && props.seekTime && !props.mainPage) {
            player.current.seek(props.seekTime);
            // setSeek(true);
        }
    }, [player.current]);

    useEffect(() => {
        if (player.current && props.isAutoPlay && props.seekTime && props.mainPage) {
            if (player.current) {
                player.current.seek(props.seekTime);
            }
        }
    });

    useImperativeHandle(ref, (): VideoPlayerRefs => ({
        pause: () => {
            player.current?.pause();
        },
        play: () => {
            player.current?.play();
        },
        getState: () => {
            return player.current?.getState();
        },
        seek: (seconds) => {
            player.current?.seek(seconds);
        },
        subscribeToStateChange: (listener) => {
            player.current?.subscribeToStateChange(listener);
        }
    }));

    if (player && props.lock) {
        player.current?.subscribeToStateChange((state, prevState) => {
            if (!state.paused && prevState.paused) {
                player.current?.pause();
                if (props.lockHandle) {
                    props.lockHandle();
                }
            }
        });
    }

    const time = new VideoTime((sec) => {
        props.currentTimeHandler(sec);
    });

    const getSource = () => {
        if (props.isLiveStream && props.isLiveStream === true) {
            return <HLSSource
                isVideoChild
                src={currentVideo.link ? currentVideo.link : ''}
            />;
        } else {
            return videoList.map((v: any, index: any) => <source
                key={index}
                src={v.link}
                type={v.type}
            />);
        }
    };


    return (
        <>
            {props.lock ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                    player?.current?.play();
                }}>
                    <Player ref={player}
                        poster={logo}>
                        <ControlBar disableCompletely={true}></ControlBar>
                    </Player>
                    <div style={{ position: 'absolute' }}>
                        <img style={{ width: '50px', height: '50px', cursor: 'pointer' }} src={playWhite} alt="" />
                    </div>
                </div>
            ) : currentVideo ? (
                <>
                    <Player key={currentVideo.link} ref={player} width="100%" height="100%" autoPlay
                        poster={props.imagePreview}
                        onTimeUpdate={(e: { currentTarget: { currentTime: { toString: () => string; }; }; }) => {
                            time.updateTime(parseInt(e.currentTarget.currentTime.toString()));
                        }}
                        onCanPlay={() => {
                            props.canPlayHandler(player.current?.getState());
                        }}
                        onPlay={props.playHandler}
                        onPause={props.pauseHandler}
                        muted={props.muted}
                    >
                        {
                            getSource()
                        }

                        {props.miniSize ?
                            <ControlBar>
                                <PlayToggle />
                                <VolumeMenuButton />
                                <ReplayControl disabled />
                                <ForwardControl disabled />
                                <CurrentTimeDisplay disabled />
                                <TimeDivider disabled />
                                <DurationDisplay disabled />
                                <ProgressControl disabled />
                            </ControlBar> :
                            <ControlBar>
                                <ReplayControl seconds={10} order={1.1} />
                                <ForwardControl seconds={10} order={1.2} />
                                <CurrentTimeDisplay order={4.1} />
                                <TimeDivider order={4.2} />
                                <PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.75, 0.5]} order={7.1} />
                            </ControlBar>
                        }
                        <BigPlayButton position="center" />
                    </Player>
                </>
            ) : (
                <></>
            )}
        </>
    );
});
VideoPlayer.displayName = 'VideoPlayer';
export default VideoPlayer;
