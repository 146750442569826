import React from 'react';
import arrowBlack from '../../images/actions/arrow-black.svg';



const ContinueWatchingListHeader = (props: {
    title: string,
    scroll: (direction: string) => void,
}) => {
    return (
        <div className="w-100 p-0" style={{ marginLeft: '15px', marginTop: '2%' }}>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <p className="lesson-list-title rainbow_text_animated" style={{ marginTop: '5%', marginBottom: '2%' }}>
                        {props.title}
                    </p>
                    <p className="lesson-list-sub-title">กดย้อนดูคลิปที่เคยดูไปแล้ว เพื่อทบทวนอีกครั้ง</p>
                </div>
                <div className="lesson-list-scroll-button-container">
                    <img
                        className="lesson-list-scroll-left-button"
                        style={{ width: '44px', height: '44px' }}
                        src={arrowBlack}
                        alt=""
                        onClick={() => props.scroll('left')}
                    />
                    <img
                        className="lesson-list-scroll-right-button"
                        style={{ width: '44px', height: '44px' }}
                        src={arrowBlack}
                        alt=""
                        onClick={() => props.scroll('right')}
                    />
                </div>
            </div>
        </div>
    );
};

export default ContinueWatchingListHeader;
