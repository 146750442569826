import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { generateToken } from '../../../utility/Token';
import { MixpanelTracking } from '../../../utility';
import { reloadTransaction } from '../../../stores/transaction/Transaction';



const PaymentCancelButton = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const transaction = useSelector((state: NaTypes.Store) => state.transaction);
    const currentTransaction = transaction.transactionList.find((t) => t.status === '3' || t.status === '4');

    return (
        <div className="col-12 mt-4 p-0">
            <button
                className="w-100 premium-button"
                id="payment-cancel-button"
                type="button"
                onClick={async () => {
                    const formData = new URLSearchParams();
                    const at = accessData.accessToken ? accessData.accessToken : '';
                    const aid = accessData.accountId ? accessData.accountId : '';
                    formData.append('access_token', generateToken(aid, at));
                    formData.append('accountId', aid);
                    formData.append('transactionId', currentTransaction.transaction_id);

                    const apiRes = await fetch(`${process.env.REACT_APP_API_URL}/api/v7/method/paymentSystem.banktranfer.cancel`, {
                        method: 'POST',
                        body: formData,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    }).then((res) => res.json());

                    if (apiRes.transaction_id) {
                        MixpanelTracking.premiumServiceCanceled(apiRes.transaction_id);
                        dispatch(reloadTransaction(at, aid));
                        history.push('/');
                    }
                }}
            >
                <p className="m-0">ยกเลิก</p>
            </button>
        </div>
    );
};

export default PaymentCancelButton;
