const initialState = '';

const SEARCH_INPUT_CHANGE = 'SEARCH_INPUT_CHANGE';
const search = (text: string) => {
  return {
    type: SEARCH_INPUT_CHANGE,
    text,
  };
};

export {
  search,
};

/**
 * Lesson list state
 * @param {string} state
 * @param {any} action
 * @return {string} current state
 */
export function SearchState(
    state: string = initialState,
    action: any): string {
  switch (action.type) {
    case SEARCH_INPUT_CHANGE:
      return action.text;
    default:
      return state;
  }
};
