import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAnnouncements } from '../../stores/announcement/AnnouncementState';
import './AnnouncementList.css';
import rightArrow from '../../images/announcement/right.svg';



const Announcement = () => {
    const dispatch = useDispatch();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const announcementState = useSelector((state: NaTypes.Store) => state.announcement);
    const announcementListContainer = useRef<HTMLDivElement>(null);
    const [scroll, setScroll] = useState(0);
    const [scrollIndex, setScrollIndex] = useState(0);
    const [scrollTotalPage, setScrollTotalPage] = useState(0);
    const [touchStartX, setTouchStartX] = useState<number | null>(null);



    const scrollBy = (direction: number) => {
        if (!announcementListContainer.current) return;

        const clientWidth = announcementListContainer.current.clientWidth;
        const maxScroll = (scrollTotalPage - 1) * clientWidth;

        let newScroll = scroll + direction * clientWidth;
        if (newScroll < 0) {
            newScroll = maxScroll;
            setScrollIndex(scrollTotalPage - 1);
        } else if (newScroll > maxScroll) {
            newScroll = 0;
            setScrollIndex(0);
        } else {
            const newScrollIndex = Math.floor(newScroll / clientWidth);
            setScrollIndex(newScrollIndex);
        }

        announcementListContainer.current.scroll({ left: newScroll });
        setScroll(newScroll);
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        setTouchStartX(event.touches[0].clientX);
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        if (!touchStartX) return;

        const touchDeltaX = event.touches[0].clientX - touchStartX;
        const direction = touchDeltaX > 0 ? -1 : 1;

        if (Math.abs(touchDeltaX) >= 50) {
            scrollBy(direction);
            setTouchStartX(null);
        }
    };



    useEffect(() => {
        dispatch(fetchAnnouncements(accessData));
    }, [dispatch]);

    useEffect(() => {
        if (announcementListContainer) {
            const childElementCount = announcementListContainer.current!.childElementCount;
            const columnsPerPage = window.innerWidth >= 1200 ? 3 : window.innerWidth >= 768 ? 2 : 1;
            const totalPageRoundUp = Math.ceil(childElementCount / columnsPerPage);
            setScrollTotalPage(totalPageRoundUp);
        }
    }, [scroll, announcementState]);

    useEffect(() => {
        const interval = setInterval(() => {
            scrollBy(1);
        }, 6000);

        return () => clearInterval(interval);
    }, [scrollBy]);



    return (
        <div className="announcement-card">
            <div className="announcement-scrollable-container" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>

                <div className="announcement-left-button" onClick={() => scrollBy(-1)}>
                    <img className="announcement-left-icon" src={rightArrow} alt="Left Arrow" />
                </div>

                <div className="row announcement-list-container" ref={announcementListContainer}>
                    {announcementState.announcements.map((a) => (
                        <div className="col-12 col-md-6 col-xl-4 p-0 announcement-item" key={a.id}>
                            <img
                                className={`announcement-item-image ${a.action !== '' && 'can-click'}`}
                                src={a.imageUrl}
                                alt={a.imageUrl ? "Announcement Image" : ""}
                                onClick={() => a.action && window.open(a.action)}
                            />
                        </div>
                    ))}
                </div>

                <div className="announcement-right-button" onClick={() => scrollBy(1)}>
                    <img className="announcement-right-icon" src={rightArrow} alt="Right Arrow" />
                </div>

            </div>

            <div className="announcement-scroll-index-container">
                {Array.from({ length: scrollTotalPage }, (_, i) => (
                    <div key={i} className={`announcement-scroll-index-point${scrollIndex === i ? '-active' : ''}`} />
                ))}
            </div>

        </div>
    );
};

export default Announcement;
