import { generateToken } from '../../../utility/Token';

interface LiveStreamPageRankingProps {
    setRankingScoreShow: React.Dispatch<React.SetStateAction<boolean>>;
    studentRankingScore: Array<{
        bigPhotoUrl: string;
        color: string;
        fullname: string;
        score: string;
    }>;
}

const getRankingScore = async (props: {
    accessData: NaTypes.Authenticate;
    liveStream: NaTypes.Store['liveStreamTableList'];
    rankingAmount: number,
    setStudentRankingScore: React.Dispatch<React.SetStateAction<Array<any>>>;
    setRankingScoreShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    try {
        const { accessToken, accountId } = props.accessData;
        if (!accessToken) return;

        const COLORS = ["#FF3B30", "#FF9500", "#FFCC00", "#4CD964", "#5AC8FA", "#007AFF", "#5856D6", "#FF2D55"];
        const randomColor = () => COLORS[Math.floor(Math.random() * COLORS.length)];

        const token = generateToken(accountId!, accessToken!);
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/${props.liveStream.currentLS?.id}/score/ranking/${props.rankingAmount}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': token,
            },
        });

        if (!response.ok) throw new Error(`HTTP Error: ${response.status}`);

        const responseData = await response.json();
        if (responseData.data) {
            const studentsWithColors = responseData.data.map((student: LiveStreamPageRankingProps) => ({
                ...student,
                color: randomColor(),
            }));
            props.setStudentRankingScore(studentsWithColors);
        }

        props.setRankingScoreShow(true);
    } catch (err) {
        console.error(err);
    }
};

export default getRankingScore;
