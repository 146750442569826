import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import logo from './../../images/logo/logo-app.png';
import logo2x from './../../images/logo/logo-app@2x.png';
import logo3x from './../../images/logo/logo-app@3x.png';
import './UseApp.css';
import { isAndroid, isIOS, isBrowser } from 'react-device-detect';
import { MixpanelTracking } from '../../utility';

const UseApp = () => {
    const history = useHistory();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);

    const navigateToPayMobile = () => history.push(accessData.accessToken ? '/pay-mobile' : '/pay-mobile-guest');

    const pathname = window.location.pathname;
    const isOnMobilePage = pathname.endsWith("/mobile");

    const isOnExcludedPage = () => {
        const excludedPaths = [
            '/mobile',
            '/pay/',
            '/pay-mobile',
            '/pay-mobile-guest',
            '/pay-mobile-premium'
        ];
        return excludedPaths.some(path => pathname.endsWith(path) || pathname.includes(path));
    };

    if (isBrowser || isOnExcludedPage()) return null;

    return (
        <div className="container-open-app">
            <div className={`layout-open-app ${!isOnMobilePage ? 'regular' : ''}`}>
                {isOnMobilePage ? (
                    <button id="mobile-register-button" onClick={navigateToPayMobile} className="navigate-button">
                        สมัครใช้บริการ
                    </button>
                ) : (
                    <>
                        <div className="d-flex align-items-center">
                            <img
                                alt=""
                                width="25"
                                height="25"
                                src={logo}
                                srcSet={`${logo2x}, ${logo3x}`}
                                className="d-inline-block align-top" />
                            <div className="ml-2">
                                <div className="text1-open-app m-0">NockAcademy</div>
                                <div className="text2-open-app m-0">ใช้งานผ่านแอป “NockAcademy”</div>
                            </div>
                        </div>
                        <span className="button-open-app" onClick={() => {
                            if (isAndroid) {
                                MixpanelTracking.openStore('Android');
                                window.location.replace('market://details?id=thai.nockacademy');
                            } else if (isIOS) {
                                MixpanelTracking.openStore('iOS');
                                window.location.replace('https://apple.co/2SKdksn');
                            }
                        }}>ดาวน์โหลด</span>
                    </>
                )}
            </div>
        </div>
    );
};

export default UseApp;
