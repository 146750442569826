import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelRequestLogin, setAccessData } from '../../stores/authorization/AuthorizationState';
import { resetLessonSet } from '../../stores/lesson-list/LessonSetListState';
import { MixpanelTracking } from '../../utility/MixpanelTracking';
import { sign } from 'jsonwebtoken';
import packageJson from '../../..//package.json';
import NaLoginStepLogin from './NaLoginRenders/NaLoginStepLogin';
import NaLoginStepVerifyOTP from './NaLoginRenders/NaLoginStepVerifyOTP';
import NaLoginStepSignUp from './NaLoginRenders/NaLoginStepSignUp';
import NaLoginStepOffer from './NaLoginRenders/NaLoginStepOffer';
import Snackbar from '@material-ui/core/Snackbar';
import './NaLogin.css';



const NaLogin = () => {
    const jwtSecret = process.env.REACT_APP_JWT_SECRET;
    const dispatch = useDispatch();
    const accessToken = useSelector((state: NaTypes.Store) => state.authenticate.accessToken);
    const gradeState = useSelector((state: NaTypes.Store) => state.grade);
    const loginState = useSelector((state: NaTypes.Store) => state.authenticate.requestLogin);

    const [state, setState] = useState(('LOGIN'));
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [facebookId, setFacebookId] = useState('');
    const [sendSubmit, setSendSubmit] = useState(false);

    const [userName, setUserName] = useState('');
    const [userSurName, setUserSurName] = useState('');
    const [userNickName, setUserNickName] = useState('');
    const [userProvinceCode, setUserProvinceCode] = useState('');
    const [userGrade, setUserGrade] = useState('');
    const [userParentEmail, setParentEmail] = useState('');
    const [userPictureUrl, setUserPictureUrl] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const [isValidUserEmail, setIsValidUserEmail] = useState(true);
    const [isValidParentEmail, setIsValidParentEmail] = useState(true);

    const [snackBar, setSnackbar] = useState({
        open: false,
        msg: '',
    });



    if (!jwtSecret) {
        throw new Error("JWT_SECRET is not defined in environment variables");
    }



    const requestOtp = async (phoneNumber: string) => {
        const token = sign({}, Buffer.from(jwtSecret), {
            issuer: 'webnaschool',
            algorithm: 'HS256',
            noTimestamp: true,
            jwtid: phoneNumber,
            subject: phoneNumber,
        });

        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v3/method/smsSignUp`, {
            method: 'POST',
            body: `access_token=${token}&phoneNumber=${phoneNumber}&action=requestOTP`,
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        });

        return res.json().catch((e) => e);
    }

    const verifyOtp = async (phoneNumber: string, otp: string) => {
        const token = sign({}, Buffer.from(jwtSecret), {
            issuer: 'webnaschool',
            algorithm: 'HS256',
            noTimestamp: true,
            jwtid: phoneNumber,
            subject: otp,
        });

        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v3/method/smsSignUp`, {
            method: 'POST',
            body: `access_token=${token}&phoneNumber=${phoneNumber}&action=verifyOTP`,
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        });

        return res.json().catch((e) => e);
    }

    const trySignInWithPhoneNumberAndOTP = async (phoneNumber: string, otp: string) => {
        const token = sign({}, Buffer.from(jwtSecret), {
            issuer: 'webnaschool',
            algorithm: 'HS256',
            noTimestamp: true,
            jwtid: phoneNumber,
            subject: otp,
        });

        const formData = new URLSearchParams();
        formData.append('access_token', token);
        formData.append('phoneNumber', phoneNumber);
        formData.append('appversion', `${packageJson.name} ${packageJson.version}`);
        formData.append('clientId', '2016');

        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v7/method/account.signIn`, {
            method: 'POST',
            body: formData,
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        });

        return res.json().catch((e) => e);
    }

    async function trySignInWithFacebook(facebookId: string): Promise<any> {
        try {
            if (!jwtSecret) {
                throw new Error("JWT_SECRET is not defined in environment variables");
            }
            const token = sign({}, Buffer.from(jwtSecret), {
                issuer: 'webnaschool',
                algorithm: 'HS256',
                noTimestamp: true,
                jwtid: facebookId,
                subject: facebookId,
            });

            const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v7/method/account.signIn`, {
                method: 'POST',
                body: `access_token=${token}` +
                    `&facebookId=${facebookId}` +
                    `&appversion=${packageJson.name + ' ' + packageJson.version}` +
                    `&clientId=2016`,
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
            });
            return await res.json();
        } catch (e) {
            return e;
        }
    }

    const signUpWithFacebook = async (facebookId: string, param: any) => {
        const token = sign({}, Buffer.from(jwtSecret), {
            issuer: 'webnaschool',
            algorithm: 'HS256',
            noTimestamp: true,
            jwtid: facebookId,
            subject: 'password',
        });

        const formData = new URLSearchParams({
            access_token: token,
            facebookId,
            appversion: `${packageJson.name} ${packageJson.version}`,
            clientId: '2016',
            fullname: param.fullname,
            username: param.username,
            email: param.email,
            province: param.province.province_code,
            country: param.province.country_code,
            language: 'TH',
            grade: param.grade,
            parent_email: param.parentEmail,
            photo_url: userPictureUrl,
        });

        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v7/method/account.signUp`, {
            method: 'POST',
            body: formData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });

        return res.json().catch((e) => e);
    }

    async function signUpWithPhoneNumber(
        phoneNumber: string,
        otp: string,
        param: any): Promise<any> {
        try {
            if (!jwtSecret) {
                throw new Error("JWT_SECRET is not defined in environment variables");
            }
            const token = sign({}, Buffer.from(jwtSecret), {
                issuer: 'webnaschool',
                algorithm: 'HS256',
                noTimestamp: true,
                jwtid: phoneNumber,
                subject: otp,
            });

            const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v7/method/account.signUp`, {
                method: 'POST',
                body: `access_token=${token}` +
                    `&phoneNumber=${phoneNumber}` +
                    `&appversion=${packageJson.name + ' ' + packageJson.version}` +
                    `&clientId=2016` +
                    `&fullname=${param.fullname}` +
                    `&username=${param.username}` +
                    `&province=${param.province.province_code}` +
                    `&country=${param.province.country_code}` +
                    `&language=TH` +
                    `&grade=${param.grade}` +
                    `&parent_email=${param.parentEmail}` +
                    `&email=${param.email}` +
                    `&sex=${param.sex}`,
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
            });
            return await res.json();
        } catch (e) {
            return e;
        }
    }

    function responseFacebook(response: any) {
        trySignInWithFacebook(response.id).then((res) => {
            if (res.error_code === 404) {
                console.log('response', response);
                setFacebookId(response.id);
                setUserName(response.first_name);
                setUserSurName(response.last_name);
                if (response.email !== undefined) {
                    setUserEmail(response.email);
                }
                setUserPictureUrl(response.picture.data.url);
                setState('SIGN_UP');
            } else if (res.error_code === 0) {
                MixpanelTracking.identify(res.accountId);
                MixpanelTracking.signIn();
                dispatch(resetLessonSet());
                dispatch(setAccessData({
                    requestLogin: false,
                    accessToken: res.accessToken,
                    accountId: res.accountId,
                    account: res.account[0],
                }));
            } else {
                setSnackbar({
                    open: true,
                    msg: `Unhandle error code ${res.error_code}`,
                });
            }
        });
    }

    function logInFacebook() {
        (window as any).FB.getLoginStatus(function (response: any) {
            if (response.authResponse) {
                (window as any).FB.api('/me?fields=first_name,last_name,email,picture.width(500).height(500)', function (response: any) {
                    responseFacebook(response);
                });
            } else {
                (window as any).FB.login(function (response: any) {
                    if (response.authResponse) {
                        (window as any).FB.api('/me?fields=first_name,last_name,email,picture.width(500).height(500)', function (response: any) {
                            responseFacebook(response);
                        });
                    } else {
                        setSnackbar({
                            open: true,
                            msg: 'facebook login error',
                        });
                    }
                });
            }
        });
    }

    const isValidEmailFormat = (email: string) => {
        const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        return emailPattern.test(email);
    };

    const handleUserEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setUserEmail(newValue);
        setIsValidUserEmail(newValue === '' || isValidEmailFormat(newValue));
    };

    const handleParentEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setParentEmail(newValue);
        setIsValidParentEmail(newValue === '' || isValidEmailFormat(newValue));
    };



    useEffect(() => setState('LOGIN'), [loginState]);

    if (loginState && state === 'OFFER') {
        return (
            <div className="position-absolute login-layout">
                <NaLoginStepOffer />
                <div className="wraper" onClick={() => dispatch(cancelRequestLogin())} />
            </div>
        );
    }

    return (
        !loginState || accessToken !== null ? null : (
            <div className="position-absolute login-layout">

                {state === 'LOGIN' && (
                    <NaLoginStepLogin
                        phone={phone}
                        setPhone={setPhone}
                        setState={setState}
                        requestOtp={requestOtp}
                        logInFacebook={logInFacebook}
                    />
                )}

                {state === 'VERIFY_OTP' && (
                    <NaLoginStepVerifyOTP
                        phone={phone}
                        otp={otp}
                        setOtp={setOtp}
                        setState={setState}
                        requestOtp={requestOtp}
                        verifyOtp={verifyOtp}
                        logInFacebook={logInFacebook}
                        trySignInWithPhoneNumberAndOTP={trySignInWithPhoneNumberAndOTP}
                    />
                )}

                {state === 'SIGN_UP' && (
                    <NaLoginStepSignUp
                        userName={userName}
                        userSurName={userSurName}
                        userNickName={userNickName}
                        userEmail={userEmail}
                        userGrade={userGrade}
                        userProvinceCode={userProvinceCode}
                        userParentEmail={userParentEmail}

                        phone={phone}
                        otp={otp}
                        facebookId={facebookId}
                        userPictureUrl={userPictureUrl}
                        gradeState={gradeState}
                        sendSubmit={sendSubmit}
                        isValidUserEmail={isValidUserEmail}
                        isValidParentEmail={isValidParentEmail}

                        setSendSubmit={setSendSubmit}
                        setUserName={setUserName}
                        setUserSurName={setUserSurName}
                        setUserNickName={setUserNickName}
                        setUserEmail={setUserEmail}
                        setUserGrade={setUserGrade}
                        setUserProvinceCode={setUserProvinceCode}
                        setParentEmail={setParentEmail}

                        setState={setState}
                        signUpWithFacebook={signUpWithFacebook}
                        signUpWithPhoneNumber={signUpWithPhoneNumber}
                        handleUserEmailChange={handleUserEmailChange}
                        handleParentEmailChange={handleParentEmailChange}
                    />
                )}

                <div className="wraper" onClick={() => dispatch(cancelRequestLogin())} />

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={snackBar.open}
                    autoHideDuration={3000}
                    onClose={() => setSnackbar({ open: false, msg: '' })}
                    message={snackBar.msg}
                />

            </div>
        )
    );
};

export default NaLogin;
