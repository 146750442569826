import React from "react";

const ContinueWatchingListLoading = () => {
    const sizeText = Math.floor(Math.random() * 20) + 10;
    const sizeItemp = 1;

    return (
        <div className="row m-0">
            <h2
                className='loading-header gradient-loading'
                style={{ width: sizeText + '%' }}>
            </h2>
            <div id="container">
                {Array.from({ length: sizeItemp }, (_, index) => {
                    const sizeTitle = Math.floor(Math.random() * 100) + 10;
                    return (
                        <div key={'temp-' + index} className="item">
                            <div className="video-image-container-temp gradient-loading"></div>
                            <p className="topic-name gradient-loading" style={{ width: sizeTitle + '%', height: '20px' }}></p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ContinueWatchingListLoading