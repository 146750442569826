import mixpanel from 'mixpanel-browser';

/**
 * Mixpanel Tracking utility
 */
class MixpanelTracking {
    /**
     * initial
     */
    static init() {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
    }

    /**
     * Alias
     * @param {string} accountId accountId
     */
    static alias(accountId: String) {
        mixpanel.alias(accountId);
    }

    /**
     * Identify
     * @param {string} accountId accountId
     */
    static identify(accountId: String) {
        mixpanel.identify(accountId);
    }

    /**
     * Set Profile
     * @param {any} account Account
     */
    static setProfile(account: any) {
        mixpanel.people.set({ 'Account ID': account.id });
        mixpanel.people.set({ '$name': account.fullname });
        mixpanel.people.set({ 'Student Grade': account.grade });
        mixpanel.people.set({ 'Photo Path': account.normalPhotoUrl });
    }

    /**
     * Set Plan Type
     * @param {boolean} isPremium Premium
     */
    static setPlanType(isPremium: boolean) {
        let planType: string = '';
        if (isPremium) {
            planType = 'Premium';
        } else {
            planType = 'Free';
        }
        mixpanel.people.set({ 'Plan Type': planType });
    }

    /**
     * Tracking sign-in event
     */
    static signIn() {
        mixpanel.track('Sign In');
    }

    /**
     * Tracking sign-up event
     * @param {string} signUpType Sign-up type
     * @param {any} account Account
     */
    static signUp(signUpType: string, account: any) {
        mixpanel.track('Sign Up', {
            'Fullname': account.fullname,
            'Nickname': account.login,
            'Grade': account.grade,
            'Type': signUpType,
        });
    }

    /**
     * Tracking sign-out event
     */
    static signOut() {
        mixpanel.track('Sign Out');
        mixpanel.reset();
    }

    /**
     * Tracking Video Selected event
     * @param {NaTypes.Chapter} chapter Chapter
     * @param {string} subjectName Subject name
     */
    static videoSelected(chapter: NaTypes.Chapter, subjectName: string) {
        mixpanel.track('Video Selected', {
            'Subject ID': chapter.subject,
            'Subject Name': subjectName,
            'Topic Name': chapter.topicDescription,
            'Chapter Name': chapter.description,
            'Grade': chapter.grade,
        });
    }

    /**
     * Tracking Intro Watched event
     * @param {NaTypes.Chapter} chapter Chapter
     * @param {string} subjectName Subject name
     * @param {number} durationTime Duration Time
     * @param {number} stayingTime Staying Time
     * @param {number} watchingTime Watching Time
     */
    static introWatched(chapter: NaTypes.Chapter, subjectName: string, durationTime: number, stayingTime: number, watchingTime: number) {
        mixpanel.track('Intro Watched', {
            'Subject ID': chapter.subject,
            'Subject Name': subjectName,
            'Topic Name': chapter.topicDescription,
            'Chapter Name': chapter.description,
            'Grade': chapter.grade,
            'Duration Time': durationTime,
            'Stay Time': stayingTime,
            'Watch Time': watchingTime,
        });
    }

    /**
     * Tracking Exercise Watched event
     * @param {NaTypes.Chapter} chapter Chapter
     * @param {string} subjectName Subject name
     * @param {number} durationTime Duration Time
     * @param {number} stayingTime Staying Time
     * @param {number} watchingTime Watching Time
     */
    static exerciseWatched(chapter: NaTypes.Chapter, subjectName: string, durationTime: number, stayingTime: number, watchingTime: number) {
        mixpanel.track('Exercise Watched', {
            'Subject ID': chapter.subject,
            'Subject Name': subjectName,
            'Topic Name': chapter.topicDescription,
            'Chapter Name': chapter.description,
            'Grade': chapter.grade,
            'Duration Time': durationTime,
            'Stay Time': stayingTime,
            'Watch Time': watchingTime,
        });
    }

    /**
     * Tracking Mini Test Finished event
     * @param {NaTypes.Chapter} chapter Chapter
     * @param {string} subjectName Subject name
     * @param {number} score score
     * @param {number} maxScore maxScore
     * @param {number} stayingTime stayingTime
     * @param {string} status status
     */
    static miniTestFinished(chapter: NaTypes.Chapter, subjectName: string, score: number, maxScore: number, stayingTime: number, status: string) {
        mixpanel.track('Mini Test Finished', {
            'Subject ID': chapter.subject,
            'Subject Name': subjectName,
            'Topic Name': chapter.topicDescription,
            'Chapter Name': chapter.description,
            'Grade': chapter.grade,
            'Score': score,
            'Max Score': maxScore,
            'Stay Time': stayingTime,
            'Status': status,
        });
    }

    /**
     * Tracking Question Answered event
     * @param {NaTypes.Chapter} chapter Chapter
     * @param {string} subjectName Subject name
     * @param {string} questionId questionId
     * @param {string} questionText questionText
     * @param {string} questionImage questionImage
     * @param {string} answerText answerText
     * @param {string} answerImage answerImage
     * @param {boolean} isCorrect isCorrect
     */
    static questionAnswered(chapter: NaTypes.Chapter, subjectName: string, questionId: string, questionText: string, questionImage: string, answerText: string, answerImage: string, isCorrect: boolean) {
        mixpanel.track('Question Answered', {
            'Subject ID': chapter.subject,
            'Subject Name': subjectName,
            'Topic Name': chapter.topicDescription,
            'Chapter Name': chapter.description,
            'Grade': chapter.grade,
            'Question ID': questionId,
            'Question Text': questionText,
            'Question Image': questionImage,
            'Answer Text': answerText,
            'Answer Image': answerImage,
            'Is Correct': isCorrect,
        });
    }

    static questionLSAnswered(lsHistory: NaTypes.LiveStreamHistory, subjectName: string, questionId: string, questionText: string, questionImage: string, answerText: string, answerImage: string, isCorrect: boolean) {
        mixpanel.track('Question Answered', {
            'Subject ID': lsHistory.subject,
            'Subject Name': subjectName,
            'Topic Name': lsHistory.subject,
            'Chapter Name': lsHistory.detail,
            'Grade': lsHistory.grade,
            'Question ID': questionId,
            'Question Text': questionText,
            'Question Image': questionImage,
            'Answer Text': answerText,
            'Answer Image': answerImage,
            'Is Correct': isCorrect,
        });
    }

    /**
     * Tracking Open Store event
     * @param {string} platformType platformType
     */
    static openStore(platformType: String) {
        mixpanel.track('Open Store', {
            'Platform Type': platformType,
        });
    }

    /**
     * Tracking Premium Service Registered event
     * @param {string} transactionId transactionId
     * @param {string} firstName firstName
     * @param {string} lastName lastName
     * @param {string} email email
     * @param {string} province province
     * @param {string} phoneNumber phoneNumber
     */
    static premiumServiceRegistered(transactionId: string, firstName: string, lastName: string, email: string, province: string, phoneNumber: string) {
        mixpanel.track('Premium Service Registered', {
            'Transaction ID': transactionId,
            'First Name': firstName,
            'Last Name': lastName,
            'Email': email,
            'Province': province,
            'Phone Number': phoneNumber,
        });
    }

    /**
     * Tracking Premium Service Purchased event
     * @param {string} transactionId transactionId
     * @param {string} productName productName
     * @param {string} productPrice productPrice
     * @param {string} transferType transferType
     * @param {string} purchaseStatus purchaseStatus
     */
    static premiumServicePurchased(transactionId: string, productName: string, productPrice: number, transferType: string, purchaseStatus: string) {
        mixpanel.track('Premium Service Purchased', {
            'Transaction ID': transactionId,
            'Product Name': productName,
            'Product Price': productPrice,
            'Transfer Type': transferType,
            'Purchase Status': purchaseStatus,
        });
    }

    /**
     * Tracking Premium Service Canceled event
     * @param {string} transactionId transactionId
     */
    static premiumServiceCanceled(transactionId: string) {
        mixpanel.track('Premium Service Canceled', {
            'Transaction ID': transactionId,
        });
    }

    /**
     * Tracking Join Live Stream event
     * @param {string} subjectId Subject ID
     * @param {string} subjectName Subject Name subject_description_th
     * @param {string} liveId Live Stream ID id
     * @param {string} liveTopic Live Stream Topic subject
     * @param {string} grade Live Stream Grade grade
     * @param {string} videoType Live Stream Status video_type
     * @param {string} userGrade User Grade
     * @param {string} startTime Start Time starttime
     * @param {string} endTime End Time endtime
     * @param {string} joinTime Join Time
     */
    static joinLiveStream(
        subjectId: string,
        subjectName: string,
        liveId: string,
        liveTopic: string,
        grade: string,
        videoType: string,
        userGrade: string,
        startTime: string,
        endTime: string,
        joinTime: string) {
        mixpanel.track('Join Live Stream', {
            'Subject ID': subjectId,
            'Subject Name': subjectName,
            'Live Stream ID': liveId,
            'Live Stream Topic': liveTopic,
            'Live Stream Grade': grade,
            'Live Stream Status': videoType,
            'User Grade': userGrade,
            'Start Time': startTime,
            'End Time': endTime,
            'Join Time': joinTime,
        });
    }

    /**
     * Tracking Exit Live Stream event
     * @param {String} subjectId Subject ID
     * @param {String} subjectName Subject Name
     * @param {String} liveId Live Stream ID
     * @param {String} liveTopic Live Stream Topic
     * @param {String} grade Live Stream Grade
     * @param {String} exitStatus User Grade
     * @param {String} userGrade Join Time
     * @param {String} joinTime Exit Time
     * @param {String} exitTime Exit Status
     * @param {String} stayTime Stay Time
     */
    static exitLiveStream(
        subjectId: string,
        subjectName: string,
        liveId: string,
        liveTopic: string,
        grade: string,
        exitStatus: string,
        userGrade: string,
        joinTime: string,
        exitTime: string,
        stayTime: number) {
        mixpanel.track('Exit Live Stream', {
            'Subject ID': subjectId,
            'Subject Name': subjectName,
            'Live Stream ID': liveId,
            'Live Stream Topic': liveTopic,
            'Live Stream Grade': grade,
            'Exit Status': exitStatus,
            'User Grade': userGrade,
            'Join Time': joinTime,
            'Exit Time': exitTime,
            'Stay Time': stayTime,
        });
    }

    /**
     * Tracking Comment On Live Stream event
     * @param {string} subjectId Subject ID
     * @param {string} subjectName Subject Name
     * @param {string} liveId Live Stream ID
     * @param {string} liveTopic Live Stream Topic
     * @param {string} grade Live Stream Grade
     * @param {string} userGrade User Grade
     * @param {string} msg Message
     */
    static commendLiveStream(
        subjectId: string,
        subjectName: string,
        liveId: string,
        liveTopic: string,
        grade: string,
        userGrade: string,
        msg: string) {
        mixpanel.track('Comment On Live Stream', {
            'Subject ID': subjectId,
            'Subject Name': subjectName,
            'Live Stream ID': liveId,
            'Live Stream Topic': liveTopic,
            'Live Stream Grade': grade,
            'User Grade': userGrade,
            'Message': msg,
        });
    }

    /**
     * Tracking Download Document On Live Stream event
     * @param {string} subjectId Subject ID
     * @param {string} subjectName Subject Name
     * @param {string} liveId Live Stream ID
     * @param {string} liveTopic Live Stream Topic
     * @param {string} grade Live Stream Grade
     * @param {string} userGrade User Grade
     * @param {string} documentType Document Type 'Exercise' or 'Slide'
     */
    static downloadDocumentOnLiveStream(
        subjectId: string,
        subjectName: string,
        liveId: string,
        liveTopic: string,
        grade: string,
        userGrade: string,
        documentType: string) {
        mixpanel.track('Download Document On Live Stream', {
            'Subject ID': subjectId,
            'Subject Name': subjectName,
            'Live Stream ID': liveId,
            'Live Stream Topic': liveTopic,
            'Live Stream Grade': grade,
            'User Grade': userGrade,
            'Document Type': documentType,
        });
    }

    static downloadDocumentOnLiveStreamHistory(
        subjectId: string,
        subjectName: string,
        liveId: string,
        liveTopic: string,
        grade: string,
        userGrade: string,
        documentType: string) {
        mixpanel.track('Download Document On Live Stream History', {
            'Subject ID': subjectId,
            'Subject Name': subjectName,
            'Live Stream ID': liveId,
            'Live Stream Topic': liveTopic,
            'Live Stream Grade': grade,
            'User Grade': userGrade,
            'Document Type': documentType,
        });
    }

    static lsplaybackselected(
        subjectId: string,
        subjectName: string,
        liveId: string,
        liveTopic: string,
        grade: string,
        userGrade: string) {
        mixpanel.track('LS playback selected', {
            'Subject ID': subjectId,
            'Subject Name': subjectName,
            'Live Stream ID': liveId,
            'Live Stream Topic': liveTopic,
            'Live Stream Grade': grade,
            'User Grade': userGrade,
        });
    }

    static lsBookmark(
        subjectId: string,
        subjectName: string,
        liveId: string,
        liveTopic: string,
        grade: string,
        userGrade: string) {
        mixpanel.track('LS bookmark', {
            'Subject ID': subjectId,
            'Subject Name': subjectName,
            'Live Stream ID': liveId,
            'Live Stream Topic': liveTopic,
            'Live Stream Grade': grade,
            'User Grade': userGrade,
            "Device": "Website"
        });
    }

    static examSelected(
        examId: number,
        examTitle: string,
        userGrade: string) {
        mixpanel.track('Exam selected', {
            'Exam ID': examId,
            'Exam Title': examTitle,
            'User Grade': userGrade
        });
    }

    static startExam(
        examId: number,
        examTitle: string = "",
        examSubjectId: string,
        examSubjectTitle: string,
        questionSetId: number = 0,
        userGrade: string) {
        mixpanel.track('Start exam', {
            'Exam ID': examId,
            'Exam Title': examTitle,
            'Exam Subject ID': examSubjectId,
            'Exam Subject Title': examSubjectTitle,
            'Question Set ID': questionSetId,
            'User Grade': userGrade
        });
    }

    static completeExam(
        examId: number = 0,
        examTitle: string = "",
        examSubjectId: string = "",
        examSubjectTitle: string = "",
        questionSetId: number = 0,
        userGrade: string) {
        mixpanel.track('Complete exam', {
            'Exam ID': examId,
            'Exam Title': examTitle,
            'Exam Subject ID': examSubjectId,
            'Exam Subject Title': examSubjectTitle,
            'Question Set ID': questionSetId,
            'User Grade': userGrade
        });
    }

    static watchAnswerExam(
        examId: number = 0,
        examTitle: string = "",
        examSubjectId: string = "",
        examSubjectTitle: string = "",
        questionSetId: number = 0,
        userGrade: string) {
        mixpanel.track('Watch answer in exam', {
            'Exam ID': examId,
            'Exam Title': examTitle,
            'Exam Subject ID': examSubjectId,
            'Exam Subject Title': examSubjectTitle,
            'Question Set ID': questionSetId,
            'User Grade': userGrade
        });
    }

    static searchRecord(userGrade: string, search: string) {
        mixpanel.track('User web search', {
            'User Grade': userGrade,
            'Search': search
        });
    }

}
export { MixpanelTracking };
