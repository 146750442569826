import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ContinueWatchingListLoading from './ContinueWatchingListLoading';
import ContinueWatchingListHeader from './ContinueWatchingListHeader';
import ContinueWatchingListItem from './ContinueWatchingListItems';
import '../lesson-list/LessonList.css';



const ContinueWatchingList = (props: NaTypes.ContinueWatchingListProps) => {
  const history = useHistory();
  const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
  const searchText = useSelector((state: NaTypes.Store) => state.searchText);
  const [redirect] = useState<string>();
  const scrollContainer = useRef<HTMLDivElement>(null);

  if (accessData.accessToken && redirect) {
    history.push(redirect);
  }



  const getLessonList = () => props.list?.filter(l => l.name.includes(searchText));

  const scroll = (direction: string) => {
    if (scrollContainer) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      scrollContainer.current?.scroll({ left: scrollContainer.current.scrollLeft + scrollAmount });
    }
  };



  return (
    <>
      {props.pending ? (
        <ContinueWatchingListLoading />
      ) : (
        getLessonList()?.length > 0 && (
          <div className="lesson-list-container my-card bg-white" style={{ borderRadius: '1vw' }}>
            <ContinueWatchingListHeader title={props.title} scroll={scroll} />
            <div ref={scrollContainer} className="continue-watch-container">
              {getLessonList()?.map((lesson, index) => (
                <ContinueWatchingListItem
                  key={index}
                  lesson={lesson}
                  index={index}
                  accessData={accessData}
                />
              ))}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default ContinueWatchingList;
