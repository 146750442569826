import { generateToken } from '../../utility/Token';



export const getLSPlaybackPopularList = async (
    accessData: NaTypes.Authenticate,
    setLiveStreamPlaybackTopicPopularList: React.Dispatch<React.SetStateAction<Array<any>>>,
    sort: string
) => {
    try {
        const token = accessData.accountId ? generateToken(accessData.accountId!, accessData.accessToken!) : '';
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/lsplayback/topicpopular${sort}`;

        await new Promise(resolve => setTimeout(resolve, 500));

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': token,
            },
        });

        if (response.ok) {
            const res = await response.json();
            setLiveStreamPlaybackTopicPopularList(res.data);
        } else {
            console.log('Cannot load information');
        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
};
