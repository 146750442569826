import React, { useEffect, useState, } from 'react';
import './ExamResult.css'
import { MixpanelTracking } from '../../utility/MixpanelTracking';
import NaTypes from '../../types';
import rankMedal1 from '../../images/prize/rank_medal_1.svg';
import rankMedal2 from '../../images/prize/rank_medal_2.svg';
import Chart from "react-apexcharts";
import next from '../../images/arrow/next_blue.svg';
import { useParams } from 'react-router';
import { generateToken } from '../../utility/Token';
import { useSelector } from 'react-redux';

const ExamResult = () => {
  const [subject, setSubject] = useState<string>("MA");
  const { grade, quarter }: any = useParams();
  const accessData: NaTypes.Authenticate = useSelector((state: NaTypes.Store) => state.authenticate);
  const [resultSummaryList, setResultSummaryList] = useState<Array<NaTypes.ResultSummary>>([]);
  const [resultSummary, setResultSummary] = useState<NaTypes.ResultSummary>();
  const [dataGraph, setDataGraph] = useState<Array<number>>([0, 0, 0]);
  const [resultMA, setResultMA] = useState<NaTypes.Result>();
  const [resultEN, setResultEN] = useState<NaTypes.Result>();
  const [resultTH, setResultTH] = useState<NaTypes.Result>();
  const [categoriesMA, setCategoriesMA] = useState<Array<string>>([""]);
  const [categoriesEN, setCategoriesEN] = useState<Array<string>>([""]);
  const [categoriesTH, setCategoriesTH] = useState<Array<string>>([""]);
  const [seriesMA, setSeriesMA] = useState<Array<number>>([0]);
  const [seriesEN, setSeriesEN] = useState<Array<number>>([0]);
  const [seriesTH, setSeriesTH] = useState<Array<number>>([0]);
  const [selectResult, setSelectResult] = useState<NaTypes.Result>(
    {
      ranking: {
        rank: 0,
        total: 0
      },
      topicsDetail: []
    }
  );
  const [checkShow, setCheckShow] = useState<Boolean>(false);

  useEffect(() => {
    MixpanelTracking.init();
  });

  useEffect(() => {
    if (resultSummaryList && resultSummaryList.length <= 0) {
      const fetch = async () => {
        setResultSummaryList(await getResultSummary());
      };
      fetch().catch(console.error);
    }
  }, []);


  useEffect(() => {
    if (resultSummary) {
      let data = dataGraph;
      resultSummary?.list.math?.average ? data[0] = resultSummary?.list.math?.average : data[0] = 0;
      resultSummary?.list.english?.average ? data[1] = resultSummary?.list.english?.average : data[1] = 0;
      resultSummary?.list.thai?.average ? data[2] = resultSummary?.list.thai?.average : data[2] = 0;
      setDataGraph(data);
    }
  });

  useEffect(() => {
    if (resultSummary && !resultMA) {
      const fetchMA = async () => {
        if (resultSummary?.list.math?.latestExamSubmitId)
          await getDetail(resultSummary?.list.math?.latestExamSubmitId, "MA");
        else
          setResultMA({
            ranking: {
              rank: 0,
              total: 0
            },
            topicsDetail: []
          })
      };
      fetchMA().catch(console.error);
    }
  }, [resultSummary]);

  useEffect(() => {
    if (resultSummary && !resultEN) {
      const fetchEN = async () => {
        if (resultSummary?.list.english?.latestExamSubmitId)
          await getDetail(resultSummary?.list.english?.latestExamSubmitId, "EN");
        else
          setResultEN({
            ranking: {
              rank: 0,
              total: 0
            },
            topicsDetail: []
          })
      };
      fetchEN().catch(console.error);
    }
  }, [resultSummary]);

  useEffect(() => {
    if (resultSummary && !resultTH) {
      const fetchTH = async () => {
        if (resultSummary?.list.thai?.latestExamSubmitId)
          await getDetail(resultSummary?.list.thai?.latestExamSubmitId, "TH");
        else
          setResultTH({
            ranking: {
              rank: 0,
              total: 0
            },
            topicsDetail: []
          })
      };
      fetchTH().catch(console.error);
    }
  }, [resultSummary]);


  useEffect(() => {
    if (resultSummaryList && resultSummaryList.length > 0 && !resultSummary) {
      setResultSummary(resultSummaryList.filter(result => result.quarter === quarter)[0]);
    }
  });

  function getResultSummary() {
    const url = `${process.env.REACT_APP_API_URL_V2}/v1/exam/report/summary/${grade}`;
    return fetch(
      url,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': generateToken(accessData.accountId!, accessData.accessToken!),
        },
      })
      .then((res) => res.json())
      .then((res) => {
        return res.data.map((d: any): NaTypes.ResultSummary => {
          const resultSummary: NaTypes.ResultSummary =
          {
            quarter: d.quarter,
            quarterName: d.quarter_name,
            section: {
              id: d.section.id,
              description: d.section.description,
              isFree: d.section.is_free
            },
            list: {
              english: {
                id: null,
                name: null,
                lsId: null,
                subject: null,
                grade: null,
                quarter: null,
                sectionId: null,
                questionSetId: null,
                examYear: null,
                createdTime: null,
                enable: null,
                duration: null,
                subjectName: null,
                subjectTHName: null,
                latestExamSubmitId: null,
                score: 0,
                total: 0,
                average: 0,
                rank: 0
              },
              math: {
                id: null,
                name: null,
                lsId: null,
                subject: null,
                grade: null,
                quarter: null,
                sectionId: null,
                questionSetId: null,
                examYear: null,
                createdTime: null,
                enable: null,
                duration: null,
                subjectName: null,
                subjectTHName: null,
                latestExamSubmitId: null,
                score: 0,
                total: 0,
                average: 0,
                rank: 0
              },
              thai: {
                id: null,
                name: null,
                lsId: null,
                subject: null,
                grade: null,
                quarter: null,
                sectionId: null,
                questionSetId: null,
                examYear: null,
                createdTime: null,
                enable: null,
                duration: null,
                subjectName: null,
                subjectTHName: null,
                latestExamSubmitId: null,
                score: 0,
                total: 0,
                average: 0,
                rank: 0
              }
            }
          }
          if (d.list.english) {
            resultSummary.list.english = {
              id: d.list.english.id,
              name: d.list.english.name,
              lsId: d.list.english.li_id,
              subject: d.list.english.subject,
              grade: d.list.english.grade,
              quarter: d.list.english.quarter,
              sectionId: d.list.english.section,
              questionSetId: d.list.english.question_set_id,
              examYear: d.list.english.exam_year,
              createdTime: d.list.english.created_time,
              enable: d.list.english.enable,
              duration: d.list.english.duration,
              subjectName: d.list.english.subject_name,
              subjectTHName: d.list.english.subject_th_name,
              latestExamSubmitId: d.list.english.latest_exam_submit_id,
              score: d.list.english.score,
              total: d.list.english.total,
              average: d.list.english.average,
              rank: d.list.english.rank
            }
          }
          if (d.list.math) {
            resultSummary.list.math = {
              id: d.list.math.id,
              name: d.list.math.name,
              lsId: d.list.math.li_id,
              subject: d.list.math.subject,
              grade: d.list.math.grade,
              quarter: d.list.math.quarter,
              sectionId: d.list.math.section,
              questionSetId: d.list.math.question_set_id,
              examYear: d.list.math.exam_year,
              createdTime: d.list.math.created_time,
              enable: d.list.math.enable,
              duration: d.list.math.duration,
              subjectName: d.list.math.subject_name,
              subjectTHName: d.list.math.subject_th_name,
              latestExamSubmitId: d.list.math.latest_exam_submit_id,
              score: d.list.math.score,
              total: d.list.math.total,
              average: d.list.math.average,
              rank: d.list.math.rank
            }
          }

          if (d.list.thai) {
            resultSummary.list.thai = {
              id: d.list.thai.id,
              name: d.list.thai.name,
              lsId: d.list.thai.li_id,
              subject: d.list.thai.subject,
              grade: d.list.thai.grade,
              quarter: d.list.thai.quarter,
              sectionId: d.list.thai.section,
              questionSetId: d.list.thai.question_set_id,
              examYear: d.list.thai.exam_year,
              createdTime: d.list.thai.created_time,
              enable: d.list.thai.enable,
              duration: d.list.thai.duration,
              subjectName: d.list.thai.subject_name,
              subjectTHName: d.list.thai.subject_th_name,
              latestExamSubmitId: d.list.thai.latest_exam_submit_id,
              score: d.list.thai.score,
              total: d.list.thai.total,
              average: d.list.thai.average,
              rank: d.list.thai.rank
            }
          }
          return resultSummary
        })
      })
      .catch((err) => console.log(err));
  };

  function getDetail(examId: number, subject: string) {
    const url = `${process.env.REACT_APP_API_URL_V2}/v1/exam/report/summary/detail/${examId}`;
    return fetch(
      url,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': generateToken(accessData.accountId!, accessData.accessToken!),
        },
      })
      .then((res) => res.json())
      .then((res) => {
        const result: NaTypes.Result = {
          ranking: res.data.ranking,
          topicsDetail: []
        }
        let catagory: Array<string> = [];
        let serie: Array<number> = [];
        result.topicsDetail =
          res.data.topics_detail.map((d: any): NaTypes.topicsDetail => {
            catagory.push(d.topic_name);
            serie.push(Number((d.score / d.total * 100).toFixed(0)));
            return {
              total: d.total,
              topicName: d.topic_name,
              score: d.score,
              average: d.average,
              ratio: d.ratio
            }
          })
        switch (subject) {
          case "MA": {
            setResultMA(result);
            setCategoriesMA(catagory);
            setSeriesMA(serie);
            break;
          }
          case "EN":
            {
              setResultEN(result);
              setCategoriesEN(catagory);
              setSeriesEN(serie);
              break;
            }
          case "TH": {
            setResultTH(result);
            setCategoriesTH(catagory);
            setSeriesTH(serie);
            break;
          }
          default:
        }
      })
      .catch((err) => console.log(err));
  };

  function toAnswer() {
    let subjectId, subjectName = "";
    let questionSetId = 0;
    switch (subject) {
      case "MA": {
        if (resultSummary?.list.math?.latestExamSubmitId) {
          if (resultSummary?.list.math?.subject) {
            subjectId = resultSummary?.list.math?.subject;
          }
          if (resultSummary?.list.math?.subjectName) {
            subjectName = resultSummary?.list.math?.subjectName;
          }
          if (resultSummary?.list.math?.questionSetId) {
            questionSetId = resultSummary?.list.math?.questionSetId;
          }
          MixpanelTracking.watchAnswerExam(Number(resultSummary.quarter), resultSummary.quarterName, subjectId, subjectName, questionSetId, accessData.account.grade);
          window.location.href = '/examanswer/' + grade + "/" + quarter + "/" + resultSummary?.list.math?.questionSetId + "/" + resultSummary?.list.math?.latestExamSubmitId;
        }
        break;
      }
      case "EN":
        {
          if (resultSummary?.list.english?.latestExamSubmitId) {
            if (resultSummary?.list.english?.subject) {
              subjectId = resultSummary?.list.english?.subject;
            }
            if (resultSummary?.list.english?.subjectName) {
              subjectName = resultSummary?.list.english?.subjectName;
            }
            if (resultSummary?.list.english?.questionSetId) {
              questionSetId = resultSummary?.list.english?.questionSetId;
            }
            MixpanelTracking.watchAnswerExam(Number(resultSummary.quarter), resultSummary.quarterName, subjectId, subjectName, questionSetId, accessData.account.grade);
            window.location.href = '/examanswer/' + grade + "/" + quarter + "/" + resultSummary?.list.english?.questionSetId + "/" + resultSummary?.list.english?.latestExamSubmitId;
          }
        }
        break;
      case "TH": {
        if (resultSummary?.list.thai?.latestExamSubmitId) {
          if (resultSummary?.list.thai?.subject) {
            subjectId = resultSummary?.list.thai?.subject;
          }
          if (resultSummary?.list.thai?.subjectName) {
            subjectName = resultSummary?.list.thai?.subjectName;
          }
          if (resultSummary?.list.thai?.questionSetId) {
            questionSetId = resultSummary?.list.thai?.questionSetId;
          }
          MixpanelTracking.watchAnswerExam(Number(resultSummary.quarter), resultSummary.quarterName, subjectId, subjectName, questionSetId, accessData.account.grade);
          window.location.href = '/examanswer/' + grade + "/" + quarter + "/" + resultSummary?.list.thai?.questionSetId + "/" + resultSummary?.list.thai?.latestExamSubmitId;
        }
        break;
      }
      default:
    }
  }

  return <>
    <div className='col-12 bg-grey' style={{ display: "flex", margin: "auto", justifyContent: "center", flexWrap: "wrap" }}>
      <div className='bg-white col-11'
        style={{ display: "flex", justifyContent: "center", marginTop: "20px", borderRadius: "10px", paddingTop: "20px", paddingBottom: "20px" }}>
        <div className='ranking col-3'>
          <p className='score' style={{ fontSize: "24px" }}>อันดับคะแนนสอบ</p>
          <div style={{ display: "inline-flex", margin: "auto" }}>
            <img src={rankMedal1} alt=""></img>
            <div className="ranking-detail">
              <div>อันดับที่</div>
              <div style={{ color: "#FFCC00", fontSize: "60px" }}>
                {subject === "EN" ?
                  resultEN?.ranking.rank : <></>
                }
                {subject === "TH" ?
                  resultTH?.ranking.rank : <></>
                }
                {subject === "MA" ?
                  resultMA?.ranking.rank : <></>
                }
              </div>
              <div>จาก
                {subject === "EN" ?
                  " " + resultEN?.ranking.total + " " : <></>
                }
                {subject === "TH" ?
                  " " + resultTH?.ranking.total + " " : <></>
                }
                {subject === "MA" ?
                  " " + resultMA?.ranking.total + " " : <></>
                }
                คน</div>
            </div>
            <img src={rankMedal2} alt=""></img>
          </div>
          <div className="answer-btn col-7" onClick={() => { toAnswer() }}>ดูเฉลย</div>
        </div>
        <div className='col-1'></div>
        <div className='graph-point col-3'>
          <div style={{ margin: "auto" }}>
            <div>กราฟแสดงผลคะแนน</div>
            {resultSummary ? <Chart type="radar"
              options={{
                xaxis: {
                  categories: ['คณิตศาสตร์', 'ภาษาอังกฤษ', 'ภาษาไทย']
                },
                yaxis: {
                  show: false,
                  max: 100
                },

                plotOptions:
                {
                  radar: {
                    polygons: {
                      strokeColors: "#C6E0FF"
                    }
                  }
                },
                dataLabels: {
                  enabled: true,
                  formatter: function (value, opt) {
                    return "• " + value + "%"
                  }
                },
                tooltip: {
                  enabled: false
                }

              }}
              series={[{
                data: dataGraph,
              }]
              }
              height={270}
              width={300}
            ></Chart> : <></>}
          </div>
        </div>
        <div className='col-1'></div>
        <div className='exam-point col-3'>
          <div><b className='score'>คะแนนสอบ</b> </div>
          <div style={{ display: "flex", flexFlow: "row wrap" }}>
            <div style={{ paddingRight: "35px", paddingBottom: "15px" }} onClick={() => {
              setSubject("MA");
              setCheckShow(true);
              resultMA ?
                setSelectResult(resultMA) : setSelectResult({
                  ranking: {
                    rank: 0,
                    total: 0
                  },
                  topicsDetail: []
                });
            }
            }>
              <div>คณิตศาสตร์</div>
              <div className='score-table'>
                <div className='score-score'>
                  <div className='score-title'>Score</div>
                  <div className='score-point'>{resultSummary?.list.math?.score}</div>
                </div>
                <div className='score-rank'>
                  <div className='score-title'>Rank</div>
                  <div className='score-point'>{resultSummary?.list.math?.rank}</div>
                </div>
                <div className='score-arrow'>
                  <img className="next-arrow" alt="" src={next}>
                  </img></div>
                <div className='score-avg'>
                  <div className='score-title'>Average</div>
                  <div className='score-point'>{resultSummary?.list.math?.average}%</div>
                </div>
              </div>
            </div>
            <div onClick={() => {
              setSubject("EN");
              setCheckShow(true);
              resultEN ?
                setSelectResult(resultEN) : setSelectResult({
                  ranking: {
                    rank: 0,
                    total: 0
                  },
                  topicsDetail: []
                });
            }}>
              <div>ภาษาอังกฤษ</div>
              <div className='score-table'>
                <div className='score-score'>
                  <div className='score-title'>Score</div>
                  <div className='score-point'>{resultSummary?.list.english?.score}</div>
                </div>
                <div className='score-rank'>
                  <div className='score-title'>Rank</div>
                  <div className='score-point'>{resultSummary?.list.english?.rank}</div>
                </div>
                <div className='score-arrow'>
                  <img className="next-arrow" src={next} alt="">
                  </img></div>
                <div className='score-avg'>
                  <div className='score-title'>Average</div>
                  <div className='score-point'>{resultSummary?.list.english?.average}%</div>
                </div>
              </div>
            </div>
            <div style={{ paddingRight: "35px" }} onClick={() => {
              setSubject("TH");
              setCheckShow(true);
              resultTH ?
                setSelectResult(resultTH) : setSelectResult({
                  ranking: {
                    rank: 0,
                    total: 0
                  },
                  topicsDetail: []
                });
            }}>
              <div>ภาษาไทย</div>
              <div className='score-table'>
                <div className='score-score'>
                  <div className='score-title'>Score</div>
                  <div className='score-point'>{resultSummary?.list.thai?.average}</div>
                </div>
                <div className='score-rank'>
                  <div className='score-title'>Rank</div>
                  <div className='score-point'>{resultSummary?.list.thai?.rank}</div>
                </div>
                <div className='score-arrow'>
                  <img className="next-arrow" src={next} alt="">
                  </img></div>
                <div className='score-avg'>
                  <div className='score-title'>Average</div>
                  <div className='score-point'>{resultSummary?.list.thai?.average}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-white col-11'
        style={{ display: "flex", justifyContent: "center", marginTop: "20px", borderRadius: "10px", paddingTop: "20px", paddingBottom: "20px", marginBottom: "20px" }}>
        <div className='graph-point col-3'>
          <div>กราฟแสดงผลคะแนนต่อบท (
            {subject === "EN" && checkShow ?
              "ภาษาอังกฤษ" : <></>
            }
            {subject === "TH" && checkShow ?
              "ภาษาไทย" : <></>
            }
            {subject === "MA" && checkShow ?
              "คณิตศาสตร์" : <></>
            }
            {checkShow ?
              <></> : "-"}
            )

            {subject === "MA" && categoriesMA && checkShow ?
              <Chart type="radar"
                options={{
                  xaxis: {
                    categories: categoriesMA
                  },
                  yaxis: {
                    show: false,
                    max: 100
                  },

                  plotOptions:
                  {
                    radar: {
                      polygons: {
                        strokeColors: "#C6E0FF"
                      }
                    }
                  },
                  dataLabels: {
                    enabled: true,
                    formatter: function (value, opt) {
                      return "• " + value + "%"
                    }
                  },
                  tooltip: {
                    enabled: false
                  }

                }}
                series={[{
                  data: seriesMA,
                }]
                }
                height={270}
                width={300}
              ></Chart> : <></>
            }

            {subject === "EN" && categoriesEN && checkShow ?
              <Chart type="radar"
                options={{
                  xaxis: {
                    categories: categoriesEN
                  },
                  yaxis: {
                    show: false,
                    max: 100
                  },

                  plotOptions:
                  {
                    radar: {
                      polygons: {
                        strokeColors: "#C6E0FF"
                      }
                    }
                  },
                  dataLabels: {
                    enabled: true,
                    formatter: function (value, opt) {
                      return "• " + value + "%"
                    }
                  },
                  tooltip: {
                    enabled: false
                  }

                }}
                series={[{
                  data: seriesEN,
                }]
                }
                height={270}
                width={300}
              ></Chart> : <></>
            }

            {subject === "TH" && categoriesTH && checkShow ?
              <Chart type="radar"
                options={{
                  xaxis: {
                    categories: categoriesTH
                  },
                  yaxis: {
                    show: false,
                    max: 100
                  },

                  plotOptions:
                  {
                    radar: {
                      polygons: {
                        strokeColors: "#C6E0FF"
                      }
                    }
                  },
                  dataLabels: {
                    enabled: true,
                    formatter: function (value, opt) {
                      return "• " + value + "%"
                    }
                  },
                  tooltip: {
                    enabled: false
                  }

                }}
                series={[{
                  data: seriesTH,
                }]
                }
                height={270}
                width={300}
              ></Chart> : <></>
            }
          </div>
          {/* <Chart type="radar" series={state.series} /> */}
        </div>
        <div className='col-1'></div>
        <div className='graph-point col-3'>
          <div>คะแนนต่อบท</div>
          <div>
            {selectResult?.topicsDetail.map((topicDetail, index) => {
              if (index < 3) {
                return (
                  <>
                    <div style={{ margin: "10px auto" }}>
                      <div>บทที่ {index + 1}: {topicDetail.topicName}</div>
                      <div style={{ display: "flex" }}>
                        <div className='score-score'>
                          <div className='score-title'>Score</div>
                          <div className='score-point'>{topicDetail.score}</div>
                        </div>
                        <div className='score-score'>
                          <div className='score-title'>Ratio</div>
                          <div className='score-point'>
                            {(topicDetail.score / topicDetail.total * 100).toFixed(0)}%</div>
                        </div>
                        <div className='score-score'>
                          <div className='score-title'>Average</div>
                          <div className='score-point'>{topicDetail.average}</div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            })}
          </div>
        </div>
        <div className='col-1'></div>
        <div className='graph-point col-3'>
          <div>
            {selectResult?.topicsDetail.map((topicDetail, index) => {
              if (index >= 3 && index < 6) {
                return (
                  <>
                    <div style={{ margin: "10px auto" }}>
                      <div>บทที่ {index + 1}: {topicDetail.topicName}</div>
                      <div style={{ display: "flex" }}>
                        <div className='score-score'>
                          <div className='score-title'>Score</div>
                          <div className='score-point'>{topicDetail.score}</div>
                        </div>
                        <div className='score-score'>
                          <div className='score-title'>Ratio</div>
                          <div className='score-point'>
                            {(topicDetail.score / topicDetail.total * 100).toFixed(0)}%</div>
                        </div>
                        <div className='score-score'>
                          <div className='score-title'>Average</div>
                          <div className='score-point'>{topicDetail.average}</div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            })}
          </div>
        </div>
      </div>
    </div>
  </>;

}

export default ExamResult;