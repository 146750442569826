import React from 'react';
import Latex from 'react-latex';
import { ImageUtility } from '../../utility';
import { renderAnswerPercentage, renderImageChoice } from './LiveQuizAnswerRendering';
import closeImg from '../../images/actions/close.svg';



const LiveQuizAnswer = (props: {
    accessData: NaTypes.Authenticate,
    choice: string | undefined,
    liveQuizPercentage: NaTypes.LiveQuizPercentage,
    question: LiveQuestion,
    sendingSubmit: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>,
    showAnswer: boolean,
    submitAnswer(questionId: string, answerId: string): void,
}) => {



    const isCorrect = (answer: LiveQuestionAnswer, i: number): JSX.Element => {
        const isChosen = props.choice === answer.id;
        const isCorrectAnswer = answer.isCorrect === '1';
        const isCorrectNotChosenChoice = !isChosen && isCorrectAnswer;
        const isIncorrectChoice = isChosen && !isCorrectAnswer;

        return (
            <div className={`choice-default ${props.showAnswer
                ? isIncorrectChoice || isCorrectNotChosenChoice
                    ? isCorrectNotChosenChoice
                        ? 'correct'
                        : 'incorrect'
                    : isChosen && isCorrectAnswer
                        ? 'correct'
                        : ''
                : isChosen
                    ? 'choice'
                    : ''
                }`}>
                {i + 1}
            </div>
        );
    };



    return (
        <div className="col-6 h-100 p-2" id="answer-container">
            <img
                className="quiz-close can-click"
                id="answer-side-close"
                src={closeImg}
                alt=""
                onClick={() => props.setShow(false)}
            />

            {props.question && (
                <div
                    className={`row m-0 h-100 ${props.question.answers.every(a => !ImageUtility.isImage(a.imagePath))
                        ? ''
                        : 'w-100 p-2'
                        }`}
                    id="answer-container-1"
                >
                    {props.question && (
                        <div
                            className={`row m-0 h-100 ${props.question.answers.every(a => !ImageUtility.isImage(a.imagePath)) ? 'align-content-center' : 'w-100 p-2'}`}
                            id="answer-container-2"
                        >
                            {props.question.answers.map((a, i) => (
                                <div
                                    key={i}
                                    className={`answer-container ${ImageUtility.isImage(a.imagePath) ? 'image col-6' : ''} can-click align-content-center`}
                                    onClick={() => {
                                        !props.showAnswer && props.accessData && props.submitAnswer(props.question.id, a.id);
                                    }}
                                >
                                    {!ImageUtility.isImage(a.imagePath) && renderAnswerPercentage(i, props.liveQuizPercentage, props.showAnswer)}

                                    {ImageUtility.isImage(a.imagePath) ? (
                                        <div className="col-12 p-0 answer-image-container">
                                            {renderImageChoice(a, i, isCorrect, props.liveQuizPercentage, props.showAnswer)}
                                        </div>
                                    ) : (
                                        <div className={`col-12 p-0 answer-text-container answer-text can-click`}>
                                            {isCorrect(a, i)}
                                            <p className="pl-2 m-0 w-100" style={{ whiteSpace: "break-spaces" }}>
                                                <Latex>{a.value != null ? a.value : ''}</Latex>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}

            {props.sendingSubmit && (
                <div className="loading-submit-layout">
                    <div className="spinner-border" style={{ margin: 'auto' }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LiveQuizAnswer;
