import { sign } from 'jsonwebtoken';

const jwtSecret = process.env.REACT_APP_JWT_SECRET;

class ImageUtility {
    static isImage(value: string): boolean {
        const imageExtensions = ['.png', '.jpg', '.PNG', '.JPG'];
        return imageExtensions.some(ext => value?.endsWith(ext));
    }

    static imageUrlTransform(accessData: NaTypes.Authenticate, value: string): string {
        if (value.startsWith('http')) {
            return value;
        }

        const baseUrl = process.env.REACT_APP_API_URL || '';
        const path = value.startsWith('test/') ? value : 'test/' + value;

        if (!jwtSecret) {
            throw new Error("JWT_SECRET is not defined in environment variables");
        }

        const token = sign({}, Buffer.from(jwtSecret), {
            issuer: 'webnaschool',
            algorithm: 'HS256',
            noTimestamp: true,
            jwtid: accessData.accountId!,
            subject: accessData.accessToken!,
        });

        const encodedPath = encodeURIComponent(path);
        const queryParams = `access_token=${token}&accountId=${accessData.accountId}`;

        return `${baseUrl}/api/v3/method/doclesson?path=${encodedPath}&${queryParams}`;
    }
}

export { ImageUtility };
