import {generateToken} from '../utility/Token';

export const postTestAnswer = async (accessData: NaTypes.Authenticate, testId: String, questionNoList: String) => {
  const token = generateToken(accessData.accountId!, accessData.accessToken!);

  const url = `${process.env.REACT_APP_API_URL}/api/v5/method/testAnswer`;
  const form = new FormData();
  form.append('testId', testId.toString());
  form.append('testAttemptNo', '0');
  form.append('questionNoList', questionNoList.toString());
  form.append('score', '0');
  form.append('status', '0');
  form.append('accountId', accessData.accountId!);
  form.append('access_token', token);

  return await fetch(
      url,
      {
        method: 'POST',
        headers: new Headers({}),
        body: form,
      })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
};

export const postTestAnswerDetail = async (accessData: NaTypes.Authenticate, testId: String, testAttemptNo: String, questionNo: String, choiceId: string) => {
  const token = generateToken(accessData.accountId!, accessData.accessToken!);

  const url = `${process.env.REACT_APP_API_URL}/api/v5/method/testAnswerDetail`;
  const form = new FormData();
  form.append('accountId', accessData.accountId!);
  form.append('access_token', token);
  form.append('testId', testId.toString());
  form.append('testAttemptNo', testAttemptNo.toString());
  form.append('questionNo', questionNo.toString());
  form.append('answers', '0');
  form.append('answer_id', choiceId);

  return await fetch(
      url,
      {
        method: 'POST',
        headers: new Headers({}),
        body: form,
      })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
};

export const calculateTest = async (accessData: NaTypes.Authenticate, testId: String, testAttemptNo: String) => {
  const token = generateToken(accessData.accountId!, accessData.accessToken!);

  const url = `${process.env.REACT_APP_API_URL}/api/v5/method/testResult`;
  const form = new FormData();
  form.append('testId', testId.toString());
  form.append('testAttemptNo', testAttemptNo.toString());
  form.append('accountId', accessData.accountId!);
  form.append('access_token', token);

  return await fetch(
      url,
      {
        method: 'POST',
        headers: new Headers({}),
        body: form,
      })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
};
