import React from 'react';
import { Link } from 'react-router-dom';
import paymentSuccess from '../../../images/other/payment-success.svg';



const PaymentStepThree = (props: {
    email: string,
    method: number
}) => {
    return (
        <>
            {(props.method === 1 || props.method === 2) &&
                <>
                    <div className="mt-4 premium-title text-center">
                        ยืนยันการโอนเรียบร้อย
                    </div>
                    <div className="d-flex justify-content-center m-2">
                        <img src={paymentSuccess} alt="" width="200px" height="200px" />
                    </div>
                    <p className="text-center">
                        สามารถตรวจสอบสถานะการโอนเงินได้จาก link
                        <br />ที่ส่งให้ทางอีเมล {props.email} เจ้าหน้าที่จะยืนยัน
                        <br />การโอนภายใน 2 วันทำการ
                    </p>
                </>
            }
            {props.method === 3 &&
                <>
                    <div className="mt-4 premium-title text-center">
                        ทำรายการเรียบร้อย
                    </div>
                    <div className="d-flex justify-content-center m-2">
                        <img src={paymentSuccess} alt="" width="200px" height="200px" />
                    </div>
                    <p className="text-center">
                        กรุณารอสักครู่ ระบบกำลังทำการตรวจสอบการชำระเงินของคุณ
                        <br /> หากเสร็จสิ้นคุณจะสามารถใช้บริการพรีเมียมได้
                        <br />สามารถตรวจสอบสถานะได้ที่ <Link to="/payment-history">ประวัติการชำระค่าบริการ</Link>
                    </p>
                </>
            }
            {(props.method === 4 || props.method === 5) &&
                <>
                    <div className="mt-4 premium-title text-center">
                        ทำรายการเรียบร้อย
                    </div>
                    <div className="d-flex justify-content-center m-2">
                        <img src={paymentSuccess} alt="" width="200px" height="200px" />
                    </div>
                    <p className="text-center">
                        การสมัครใช้บริการพรีเมียมของคุณ สำเร็จแล้ว!
                        <br />สามารถตรวจสอบสถานะได้ที่ <Link to="/payment-history">ประวัติการชำระค่าบริการ</Link>
                    </p>
                </>
            }
        </>
    );
};

export default PaymentStepThree;
