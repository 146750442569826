import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { generateToken } from '../../utility/Token';
import { toast } from 'react-toastify';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import userProfile from '../../images/user/user.svg';



const Message = (props: {
    message: MessageItem,
    delete: (key: string) => void,
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLButtonElement | null>(null);
    const [showMiniProfile, setShowMiniProfile] = useState({
        show: false,
        position: [0, 0],
    });

    const ITEM_HEIGHT = 48;
    const open = Boolean(anchorEl);

    const options = [
        { value: 'delete', text: 'ลบ' },
    ];

    const adminOptions = [
        { value: 'delete', text: 'ลบ' },
        { value: 'ban', text: 'แบน' },
    ];



    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);

    const handleClick = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        !open ? setAnchorEl(event.currentTarget) : handleClose();
    };

    const handleSelect = (value: string, key: string, userId: string) => {
        switch (value) {
            case 'delete':
                props.delete(key);
                break;
            case 'ban':
                banUser(userId);
                break;
        }
    };

    const handleClose = () => setAnchorEl(null);

    const banUser = async (userId: string) => {
        if (!accessData.accessToken) return;

        const token = generateToken(accessData.accountId!, accessData.accessToken!);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/user/ban`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': token,
                },
                body: JSON.stringify({
                    user_id: userId,
                }),
            });

            if (response.ok) {
                toast(`Ban user_id: ${userId}. Don't forget to unban later.`);
            } else {
                console.log(`Error banning user_id: ${userId}`);
            }
        } catch (err) {
            console.error(err);
        }
    };



    return (
        <>
            {props.message.type === "JOIN" ? (
                <div className='chat-message-joined'>
                    {props.message.userName} joined
                </div>
            ) : (
                <>
                    <div
                        className="chat-message-item"
                        onClick={handleClick}
                        onMouseEnter={(e) => {
                            const bounding = e.currentTarget.getBoundingClientRect();
                            setShowMiniProfile(Object.assign(showMiniProfile, {
                                show: true,
                                position: [(bounding.x + bounding.width) * 0.2, bounding.y - (bounding.height)],
                            }));
                        }}
                        onMouseLeave={() => setShowMiniProfile(Object.assign(showMiniProfile, { show: false }))}
                    >
                        <img
                            className={`chat-message-item-image-profile`}
                            src={props.message.userImageUrl === '' ? userProfile : props.message.userImageUrl}
                            alt=""
                        />

                        <div className="chat-message-item-message-container">
                            <div>
                                <div className="chat-message-item-text-container">
                                    <div
                                        className={`chat-message-item-text ${props.message.userId === process.env.REACT_APP_ADMIN_ACCOUNT
                                            ? 'admin'
                                            : props.message.self
                                                ? 'self'
                                                : 'other'
                                            }`}
                                    >
                                        <div
                                            className={`chat-message-item-username-${props.message.userId === process.env.REACT_APP_ADMIN_ACCOUNT
                                                ? 'admin'
                                                : props.message.self
                                                    ? 'self'
                                                    : 'other'
                                                }`}
                                        >
                                            {props.message.userName}
                                        </div>
                                        {props.message.message}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {props.message.self || props.message.admin ? (
                            <div className="chat-message-item-three-dots-menu-container">
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: '10ch',
                                        },
                                    }}
                                >
                                    {props.message.self ? (
                                        options.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                                onClick={() => {
                                                    handleClose();
                                                    handleSelect(option.value, props.message.key, props.message.userId);
                                                }}
                                            >
                                                {option.text}
                                            </MenuItem>
                                        ))
                                    ) : props.message.admin ? (
                                        adminOptions.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                                onClick={() => {
                                                    handleClose();
                                                    handleSelect(option.value, props.message.key, props.message.userId);
                                                }}
                                            >
                                                {option.text}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </Menu>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default Message;
