import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isBrowser } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router';

import AnnouncementList from '../../components/announcement-list/AnnouncementList';
import ContinueWatchingList from '../../components/continue-watching-list/ContinueWatchingList';
import CoursePrice from '../../components/course-price/CoursePrice';
import Exam from '../../components/exam/Exam';
import LessonList from '../../components/lesson-list/LessonList';
import LiveStream from '../../components/live-stream/LiveStream';
import LiveStreamPlaybackTopicRecentTable from '../../components/live-stream-playback-topic/LiveStreamPlaybackTopicRecentTable';
import LiveStreamPlaybackTopicPopularTable from '../../components/live-stream-playback-topic-popular/LiveStreamPlaybackTopicPopularTable';
import LiveStreamTableList from '../../components/live-stream-table-list/LiveStreamTableList';
import NoticeBoard from '../../components/notice-board/NoticeBoard';
import SampleVideos from '../../components/sample-videos/SampleVideos';
import SideBarFilter from '../../components/sidebar-filter/SidebarFilter';
import SideBarFilterSearch from '../../components/sidebar-filter/SidebarFilterSearch';
import Testimonal from '../../components/testimonal/Testimonal';

import { fetchLiveStreamHistoryRecent } from '../../stores/live-stream-history/LiveStreamHistoryRecentState';
import { fetchLiveStreamTableList } from '../../stores/live-stream-table-list/LiveStreamTableListState';
import { getContinueWatching } from '../../stores/continue-watching/ContinueWatchingState';
import { getLessonListSet } from '../../stores/lesson-list/LessonSetListState';
import { getQuarterExam } from '../../stores/exam/ExamListState';
import { setGradeFilter, setSubjectFilter } from '../../stores/filter/FilterState';

import './MainPage.css';
import 'react-toastify/dist/ReactToastify.css';



const MainPage = () => {
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const continueWatching = useSelector((state: NaTypes.Store) => state.continueWatching);
    const filterState = useSelector((state: NaTypes.Store) => state.filter);
    const gradeState = useSelector((state: NaTypes.Store) => state.grade);
    const profileState = useSelector((state: NaTypes.Store) => state.profile);
    const quarterExamState = useSelector((state: NaTypes.Store) => state.quarterExams);
    const subjectState = useSelector((state: NaTypes.Store) => state.subject);
    const transaction = useSelector((state: NaTypes.Store) => state.transaction);

    const dispatch = useDispatch();
    const location = useLocation();
    const [checkCall, setCheckCall] = useState<boolean>(false);
    const [isSampleVideosEnabled, setIsSampleVideosEnabled] = useState<boolean>(false);
    const [totalSet, setTotalSet] = useState(5);

    const lessonSetList = useSelector((state: NaTypes.Store) => state.lessonSetList
        .filter((ls) => {
            return (filterState.subjectId === '' || filterState.subjectId === 'none' || filterState.subjectId === ls.subject) &&
                (filterState.gradeId === '' || filterState.gradeId === 'none' || filterState.gradeId === ls.grade);
        })
    );

    const sampleIndex = new URLSearchParams(location.search).get('sample');



    const fetchData = () => {
        dispatch(getContinueWatching(accessData));
        dispatch(getQuarterExam(accessData, filterState.gradeId));
        dispatch(fetchLiveStreamHistoryRecent(accessData, `?grade=${filterState.gradeId}`));
    };

    const checkSampleVideosEnabled = (location: any) => {
        return location.search.includes('sample');
    };



    useEffect(() => {
        dispatch(fetchLiveStreamTableList());
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [accessData, dispatch, filterState.gradeId]);

    useEffect(() => {
        if (profileState.profile) {
            dispatch(setGradeFilter(profileState.profile.grade));
        }
    }, [profileState]);

    useEffect(() => {
        lessonSetList
            .filter(ls => !ls.isLoaded)
            .forEach(ls => {
                dispatch(getLessonListSet(ls.subject, ls.grade, accessData.accountId));
            });
    }, [filterState.gradeId]);

    useEffect(() => {
        const unloadedLessonSets = lessonSetList.filter(ls => !ls.isLoaded);

        if (!checkCall && unloadedLessonSets.length > 0) {
            unloadedLessonSets.forEach(ls => {
                dispatch(getLessonListSet(ls.subject, ls.grade, accessData.accountId));
            });
            setCheckCall(true);
        }
    });

    useEffect(() => {
        const handleQueryParams = () => {
            const params = new URLSearchParams(location.search);
            const grade = params.get('grade');
            const subject = params.get('subject');

            if (grade) dispatch(setGradeFilter(grade));
            if (subject) dispatch(setSubjectFilter(subject));
        };

        if (location.search) handleQueryParams();
        if (profileState.profile?.grade && !filterState.gradeId) {
            dispatch(setGradeFilter(profileState.profile.grade));
        }
    }, [dispatch, location.search]);

    useEffect(() => {
        const { body, documentElement } = document;
        const handleScroll = () => {
            const windowHeight = window.innerHeight || documentElement.clientHeight;
            const docHeight = Math.max(
                body.scrollHeight, body.offsetHeight,
                documentElement.clientHeight, documentElement.scrollHeight, documentElement.offsetHeight
            );
            const windowBottom = windowHeight + window.scrollY;
            if (windowBottom >= docHeight) {
                setTotalSet(totalSet + 4);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [setTotalSet, totalSet]);

    useEffect(() => {
        setIsSampleVideosEnabled(checkSampleVideosEnabled(location));
    }, [location]);



    return (
        <>
            <NoticeBoard />

            {isSampleVideosEnabled && <SampleVideos
                setIsSampleVideosEnabled={setIsSampleVideosEnabled}
                index={sampleIndex ? parseInt(sampleIndex) : -1}
            />}

            <div className="row m-0">
                <div className="col-sm-3 col-md-3 col-lg-2 d-none d-sm-block" id="sidebar-container-main">
                    <SideBarFilterSearch />
                    <SideBarFilter />
                </div>

                <div className="col-sm-9 col-md-9 col-lg-10 p-0">
                    <div className="d-flex justify-content-center bg-content">
                        <div id="content" className="row">

                            <ToastContainer
                                autoClose={3000} closeOnClick draggable hideProgressBar newestOnTop
                                pauseOnFocusLoss pauseOnHover position="top-center" rtl={false}
                            />

                            <AnnouncementList />

                            <div className="lesson-list-container">
                                <LiveStream />
                                <LiveStreamTableList />
                            </div>

                            <LiveStreamPlaybackTopicRecentTable />
                            <LiveStreamPlaybackTopicPopularTable />

                            {accessData.account ? <></> : <Testimonal />}
                            {transaction.isPremium ? <></> : <CoursePrice />}

                            <Exam title={'ห้องสอบ'} list={quarterExamState.quaterExams} />
                            <ContinueWatchingList
                                title={'ดูต่อจากครั้งที่แล้ว'}
                                pending={continueWatching.pending}
                                list={continueWatching.topics}
                            />

                            {lessonSetList.map((set: NaTypes.LessonSet) => (
                                <LessonList key={'lesson-list-' + set.subject + '-' + set.grade}
                                    subject={set.subject} grade={set.grade}
                                    title={`${subjectState.subjects.find((s) => s.subjectId === set.subject)?.descriptionTh} / ${gradeState.grades.find((s) => s.grade === set.grade)?.descriptionTh}`}
                                />
                            ))}

                            {isBrowser ? <></> : <div className='box-mobile'>Nock Academy</div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainPage;
