import React from 'react';
import { Link } from 'react-router-dom';
import arrowBlack from '../../images/actions/arrow-black.svg';



const ExamListHeader = (props: {
    title: string,
    showArrows: boolean,
    onScrollLeft: () => void,
    onScrollRight: () => void,
}) => {
    return (
        <div className="exam-list-header-container">
            <p className="lesson-list-title exam-list-title rainbow_text_animated">
                {props.title}
            </p>

            <div className="exam-list-buttons">
                <Link to="/exam-list">
                    <div className="view-all-recent" id="exam-list-button-top" style={{ cursor: "pointer" }}>
                        <p className="view-all-text">View All</p>
                    </div>
                </Link>

                {props.showArrows && (
                    <div className="lesson-list-scroll-button-container exam-arrow-large exam-arrows">
                        <img className="lesson-list-scroll-left-button" src={arrowBlack} alt="Left Arrow" onClick={props.onScrollLeft} />
                        <img className="lesson-list-scroll-right-button" src={arrowBlack} alt="Right Arrow" onClick={props.onScrollRight} />
                    </div>
                )}

                {props.showArrows && (
                    <div className="lesson-list-scroll-button-container exam-arrow-mini exam-arrows">
                        <img className="lesson-list-scroll-left-button exam-arrow" src={arrowBlack} alt="Left Arrow" onClick={props.onScrollLeft} />
                        <img className="lesson-list-scroll-right-button exam-arrow" src={arrowBlack} alt="Right Arrow" onClick={props.onScrollRight} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExamListHeader;
