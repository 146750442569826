import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import PaymentContent from '../../components/payment/PaymentContent';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import jwt from 'jsonwebtoken';
import SweetAlert from 'react-bootstrap-sweetalert';
import './PaymentPage.css';

const PaymentPage = () => {
  const {
    packageId = '0',
    token = null,
  } = useParams<{
    packageId: string
    token: any
  }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const isPremium = useSelector((state: NaTypes.Store) => state.transaction.isPremium);
  const transactionList = useSelector((state: NaTypes.Store) => state.transaction.transactionList);
  const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
  const [decoded, setDecoded] = useState<any>();
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const productList = useSelector((state: NaTypes.Store) => state.transaction.productList);

  const url = new URL(window.location.href);
  let from = url.searchParams.get("from") ? url.searchParams.get("from") : "";

  const goToMainPage = () => {
    history.push('/');
  };

  useEffect(() => {
    if (isPremium &&
      transactionList.findIndex((t) => t.status === '4') === -1 &&
      transactionList.findIndex((t) => t.status === '3') === -1 &&
      token == null) {
      history.push('/payment-history');
    }
  });

  // useEffect(() => {
  //   if (token) {
  //     try {
  //       setDecoded(jwt.verify(token, 'na_promotion_2021'));
  //     } catch (e) {
  //       if (e.name === 'TokenExpiredError') {
  //         setIsExpired(true);
  //       }
  //     }
  //   }
  // }, [token]);

  if (!accessData.accessToken) {
    dispatch(requestLogin());
    return <></>;
  }

  // if (productList && productList.length > 0 && packageId !== "0") {
  //   let product = productList.find(product => product.product_id === packageId);
  //   if (!product && packageId !== "21") {
  //     window.location.href = 'https://nockacademy.com/course/';
  //   }
  // }

  if (isExpired) {
    return <>
      <SweetAlert
        error
        title="แจ้งเตือน"
        onConfirm={() => goToMainPage()}
      >
        ระยะเวลาโปรโมชั่นหมดแล้ว
      </SweetAlert>
    </>;
  }

  if (decoded) {
    if (accessData.accountId !== decoded.forUser.toString()) {
      return <>
        <SweetAlert
          error
          title="แจ้งเตือน"
          onConfirm={() => goToMainPage()}
        >
          Account นี้ไม่สามารถใช้โปรโมชั่นได้ เนื่องจากรหัสผู้ใช้ไม่ตรงกัน
        </SweetAlert>
      </>;
    }
  }

  return <>
    <div className="d-flex justify-content-center mt-5">
      <div className='payment-content-body'>
        {
          decoded ?
            <PaymentContent packageId={packageId} previousTransactionId={decoded.transactionId} from={from} />
            :
            <PaymentContent packageId={packageId} from={from} />
        }
      </div>
    </div>
  </>;
};

export default PaymentPage;
