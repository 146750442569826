import React, { useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './QuarterExam.css'
import { MixpanelTracking } from '../../utility/MixpanelTracking';
import { useParams } from 'react-router';
import certificate from '../../images/certificate/certificate.svg';
import engIcon from '../../images/subject/eng_icon.svg';
import mathIcon from '../../images/subject/maths_icon.svg';
import thaiIcon from '../../images/subject/thai_icon.svg';
import sciIcon from '../../images/subject/sci_icon.svg';
import longArrowRight from '../../images/arrow/long_arrow_right.svg'
import { generateToken } from '../../utility/Token';
import NaTypes, { ExamList } from '../../types';
import Modal from 'react-bootstrap/Modal';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import { ToastContainer } from 'react-toastify';


const QuarterExamPage = () => {
    const [subject, setSubject] = useState<number>();
    const { grade, quarter }: any = useParams();
    const accessData: NaTypes.Authenticate = useSelector((state: NaTypes.Store) => state.authenticate);
    const [exams, setExams] = useState<Array<ExamList>>([]);
    const [duration, setDuration] = useState<number>(0);
    const [showConfirmPayment, setShowConfirmPayment] = useState<boolean>(false);
    const transaction: Transaction = useSelector((state: NaTypes.Store) => state.transaction);
    const quarterExamState = useSelector((state: NaTypes.Store) => state.quarterExams);
    const quarterExam = quarterExamState.quaterExams.find((d) => d.quarter == quarter);
    const dispatch = useDispatch();
    const [pdfFile, setPdfFile] = useState<string>("");


    if (!accessData.accountId) {
        dispatch(requestLogin());
    }



    const handleAccessCheck = () => {
        if (!accessData.accessToken) {
            dispatch(requestLogin());
            return false;
        }

        if (!transaction.isPremium) {
            setShowConfirmPayment(true);
            return false;
        }

        return true;
    };

    const onClickDownloadDocument = (url: string) => {
        if (!handleAccessCheck()) return;
        window.open(url);
    };

    function getExamList() {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/exam/${grade}/${quarter}`;
        return fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': generateToken(accessData.accountId!, accessData.accessToken!),
                },
            })
            .then((res) => res.json())
            .then((res) => {
                return res.data.map((d: any): NaTypes.ExamList => {
                    return {
                        duration: d.duration,
                        enable: d.enable,
                        examYear: d.exam_year,
                        grade: d.grade,
                        id: d.id,
                        lsId: d.ls_id,
                        name: d.name,
                        quarter: d.quarter,
                        questionSetId: d.question_set_id,
                        sectionId: d.section_id,
                        subject: d.subject,
                        subjectName: d.subject_name,
                        subjectTHName: d.subject_th_name,
                        pdfFile: d.pdf_exam
                    }
                })
            })
            .catch((err) => console.log(err));
    };

    const goToExamResult = (grade: string, quarter: string, subject: any) => {
        if (!handleAccessCheck()) return;
        window.location.href = `/examanswer/${grade}/${quarter}/${subject}/`;
    };

    const handleStartExam = () => {
        if (!handleAccessCheck()) return;
        const selectSubject = exams.find(e => e.questionSetId === subject) || {
            subject: "",
            subjectTHName: ""
        };

        MixpanelTracking.startExam(quarter, quarterExam?.title, selectSubject.subject, selectSubject.subjectTHName, subject, accessData.account.grade);
        window.location.href = `/examstart/${grade}/${quarter}/${subject}/`;
    };



    useEffect(() => {
        if (exams.length <= 0) {
            const fetch = async () => {
                setExams(await getExamList());
            };
            fetch().catch(console.error);
        }
    }, []);

    useEffect(() => {
        if (exams.length > 0 && !subject) {
            setSubject(exams[0].questionSetId);
            setPdfFile(exams[0].pdfFile);
        }
    });



    return <>
        <div className='menu-room col-12'>
            <div className='exam-room col-5'>
                <img src={certificate}></img>
                <div className="exam-room-text">
                    <b className="exam-room-title rainbow_text_animated">ห้องสอบ</b><br />
                    <p>น้องๆ มีเวลาทำข้อสอบทั้งหมด
                        {duration > 0 ? " <b>" + duration / 60 + "</b> นาที" : <b> ไม่จำกัดเวลา</b>} เมื่อทำข้อสอบเสร็จแล้ว เราจะคำนวณผลคะแนนของน้องๆ เพื่อเอาไปแสดงที่หน้า “ดูคะแนนสอบ”</p>
                </div>
            </div>
        </div>

        <div className='subject-room col-12'>
            <div> <b className="subject-room-title">วิชา</b></div>
            <div style={{ display: "flex", flexFlow: "row wrap" }}>
                {
                    exams.map((exam) => {
                        return (
                            <div className={subject == exam.questionSetId ? 'subject-room-subject select-exam col-2' : "subject-room-subject col-2"}
                                onClick={() => {
                                    setSubject(exam.questionSetId);
                                    setDuration(exam.duration);
                                    let examSubject = exams.filter(e => e.questionSetId === exam.questionSetId);
                                    if (examSubject[0].pdfFile) {
                                        setPdfFile(examSubject[0].pdfFile);
                                    } else {
                                        setPdfFile("");
                                    }
                                }
                                }
                            >
                                {exam.subject.startsWith("E") ?
                                    <img className='subject-icon' src={engIcon}></img> : <></>
                                }
                                {exam.subject.startsWith("T") ?
                                    <img className='subject-icon' src={thaiIcon}></img> : <></>
                                }
                                {exam.subject.startsWith("M") || exam.subject.startsWith("A") ?
                                    <img className='subject-icon' src={mathIcon}></img> : <></>
                                }
                                {exam.subject.startsWith("S") ?
                                    <img className='subject-icon' src={sciIcon}></img> : <></>
                                }
                                <input className='subject-radio' type='radio' value={exam.questionSetId} checked={subject == exam.questionSetId}></input>
                                <div style={{ maxWidth: "200px" }}> {exam.subjectTHName} </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>

        <div style={{ display: "Flex" }}>
            <div className='col-4'>
                {pdfFile ?
                    <div className='start-exam col-6'
                        onClick={() => {
                            onClickDownloadDocument(`${process.env.REACT_APP_API_URL}/api/v7/method/doclesson?path=${pdfFile}`)
                        }}>
                        <div style={{ paddingTop: "10px", paddingBottom: "10px", display: "flex" }}>
                            <img className='start-exam-img' src={certificate}></img>
                            <div style={{ margin: "auto" }}>เอกสารข้อสอบ</div>
                        </div>
                    </div> :
                    <></>
                }
            </div>
            <div className='col-4'>
                <div className='start-exam col-6'
                    onClick={() => { goToExamResult(grade, quarter, subject) }}>
                    <div style={{ paddingTop: "10px", paddingBottom: "10px", display: "flex" }}>
                        <div style={{ margin: "auto" }}>ดูเฉลย</div>
                    </div>
                </div>
            </div>
            <div className='col-4'>
                <div className='start-exam col-6' onClick={handleStartExam}>
                    <div style={{ paddingTop: "10px", paddingBottom: "10px", display: "flex", marginBottom: "30px" }}>
                        <img className='start-exam-img' src={certificate} alt="Certificate Icon"></img>
                        <div style={{ margin: "auto" }}>เริ่มสอบ</div>
                        <img className='start-exam-img' style={{ width: "23px", height: "14px", margin: "auto 0px" }} src={longArrowRight} alt="Right Arrow Icon"></img>
                    </div>
                </div>
            </div>
        </div>

        <Modal dialogClassName="w-25" show={showConfirmPayment && !transaction.waitingPayment} centered onHide={() => setShowConfirmPayment(false)}>
            <Modal.Body>
                <div className="row p-2">
                    <div className="col-12 mb-4 na-no-hover-text-nomal">ต้องการสมัครใช้บริการหรือไม่</div>
                    <div className="col-6"></div>
                    <div className="col-3 na-text-accept can-click" onClick={() => {
                        window.location.href = `${process.env.REACT_APP_PAY_PORTAL}`;
                    }}>สมัคร</div>
                    <div className="col-3 na-text-cancel can-click" onClick={() => { setShowConfirmPayment(false) }}>ยกเลิก</div>
                </div>
            </Modal.Body>
        </Modal>

        <Modal dialogClassName="w-25" show={showConfirmPayment && transaction.waitingPayment} centered onHide={() => setShowConfirmPayment(false)}>
            <Modal.Body>
                <div className="row p-2">
                    <div className="col-12 mb-4 na-no-hover-text-nomal">รอยืนยันการชำระเงิน</div>
                    <div className="col-6"></div>
                    <div className="col-3"></div>
                    <div className="col-3 na-text-cancel can-click" onClick={() => { setShowConfirmPayment(false) }}>ตกลง</div>
                </div>
            </Modal.Body>
        </Modal>

        <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>;
}

export default QuarterExamPage;
