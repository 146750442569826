import {MixpanelTracking} from '../../utility/MixpanelTracking';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID!);
MixpanelTracking.init();

let initialState: NaTypes.Authenticate = {
  requestLogin: false,
  accessToken: null,
  accountId: null,
  account: null,
};

const accessData = localStorage.getItem('accessData_v');
if (accessData) {
  initialState = JSON.parse(accessData);
  MixpanelTracking.identify(initialState.accountId!);
  MixpanelTracking.signIn();
  MixpanelTracking.setProfile(initialState.account);
}

const REQUEST_LOGIN = 'REQUEST_LOGIN';
const requestLogin = () => {
  return {
    type: REQUEST_LOGIN,
  };
};

const CANCEL_REQUEST_LOGIN = 'CANCEL_REQUEST_LOGIN';
const cancelRequestLogin = () => {
  return {
    type: CANCEL_REQUEST_LOGIN,
  };
};

const SET_ACCESS_DATA = 'SET_ACCESS_DATA';
const setAccessData = (data: NaTypes.Authenticate) => {
  return {
    type: SET_ACCESS_DATA,
    payload: data,
  };
};

const CLEAR_ACCESS_DATA = 'CLEAR_ACCESS_DATA';
const clearAccessData = () => {
  return {
    type: CLEAR_ACCESS_DATA,
  };
};

export {
  requestLogin,
  cancelRequestLogin,
  setAccessData,
  clearAccessData,
};

/**
 * Authorization state
 * @param {NaTypes.Authenticate} state
 * @param {any} action
 * @return {NaTypes.Authenticate} current state
 */
export function AuthorizationState(
    state: NaTypes.Authenticate = initialState,
    action: any): NaTypes.Authenticate {
  switch (action.type) {
    case REQUEST_LOGIN:
      return Object.assign(state, {requestLogin: true});
    case CANCEL_REQUEST_LOGIN:
      return Object.assign(state, {requestLogin: false});
    case SET_ACCESS_DATA:
      MixpanelTracking.setProfile(action.payload);
      localStorage.setItem('accessData_v', JSON.stringify(action.payload));
      return action.payload;
    case CLEAR_ACCESS_DATA:
      MixpanelTracking.signOut();
      localStorage.clear();
      return {
        requestLogin: false,
        accessToken: null,
        accountId: null,
        account: null,
      };
    default:
      return state;
  }
};

