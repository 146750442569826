export default {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: 'campsss-99c21.firebaseapp.com',
    databaseURL: 'https://campsss-99c21.firebaseio.com',
    projectId: 'campsss-99c21',
    storageBucket: 'campsss-99c21.appspot.com',
    messagingSenderId: '1066030699595',
    appId: '1:1066030699595:web:cb38de403cb78bbe9ee9ce',
    measurementId: 'G-SVN15VRDRE',
};
