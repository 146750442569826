import React from 'react';
import Latex from 'react-latex';
import closeImg from '../../images/actions/close.svg';
import { ImageUtility } from '../../utility';



const LiveQuizQuestion = (props: {
    question: {
        id: string,
        value: string,
        imagePath: string,
        answers: Array<LiveQuestionAnswer>,
        image_path: string,
    },
    setShow: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    return (
        <div className="col-6 h-100 p-2" id="question-container">
            <div className="question-name-layout h-100">

                <img
                    className="quiz-close can-click"
                    id="question-side-close"
                    src={closeImg}
                    alt=""
                    onClick={() => props.setShow(false)}
                />

                <div>
                    {ImageUtility.isImage(props.question.imagePath) ? (
                        <>
                            <p className="question-text">
                                <Latex>{props.question.value ?? ''}</Latex>
                            </p>
                            <div className="question-image">
                                <img
                                    src={props.question.imagePath}
                                    alt={`Illustration for this Question`} />
                            </div>
                        </>
                    ) : (
                        <p className="question-text question-text-alone">
                            <Latex>{props.question.value ?? ''}</Latex>
                        </p>
                    )}
                </div>

            </div>
        </div>
    );
};

export default LiveQuizQuestion;
