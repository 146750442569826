import {generateToken} from '../../utility/Token';

const initialState: NaTypes.LiveStreamHistoryRecentState =
{
    pending: false,
    liveStreamHistoryRecentList:[]
}


const FETCH_LSHISTORY_PENDING = 'FETCH_LSHISTORY_PENDING';
const FETCH_LSHISTORY_SUCCESS = 'FETCH_LSHISTORY_SUCCESS';

const fetchLiveStreamHistoryRecentPending = () => {
    return {
        type: FETCH_LSHISTORY_PENDING,
    };
};

const fetchLiveStreamHistoryRecentSuccess = (liveStreamHistoryRecentList: NaTypes.LiveStreamHistoryList) => {
    return {
        type: FETCH_LSHISTORY_SUCCESS,
        liveStreamHistoryRecentList
    };
};

function fetchLiveStreamHistoryRecent(accessData: NaTypes.Authenticate,sort:string): Function {
    return function (dispatch: any) {
        dispatch(fetchLiveStreamHistoryRecentPending());
        let token = "";
        if(accessData.accountId)
        {
            token = generateToken(accessData.accountId!, accessData.accessToken!);
        }
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/type/recent${sort}&page=1`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "token" : token
            },
        })
            .then((res) => res.json())
            .then((res) => {
                const liveStreamHistoryRecentList: NaTypes.LiveStreamHistoryList = res;
                return dispatch(fetchLiveStreamHistoryRecentSuccess(liveStreamHistoryRecentList));
            })
            .catch((error)=>
            {
                
            });
    };
}

export {
    fetchLiveStreamHistoryRecent,
    fetchLiveStreamHistoryRecentPending,
    fetchLiveStreamHistoryRecentSuccess,
};

export function LiveStreamHistoryRecentState(
    state: NaTypes.LiveStreamHistoryRecentState = initialState,
    action: any): NaTypes.LiveStreamHistoryRecentState {
    switch (action.type) {
        case FETCH_LSHISTORY_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_LSHISTORY_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                liveStreamHistoryRecentList: action.liveStreamHistoryRecentList,
            });
        default:
            return state;
    }
};