import { generateToken } from '../../utility/Token';



export const getLSPlaybackTopicList = async (
    accessData: NaTypes.Authenticate,
    setLiveStreamPlaybackTopicList: React.Dispatch<React.SetStateAction<Array<any>>>,
    sort: string
) => {
    try {
        const token = accessData.accountId ? generateToken(accessData.accountId!, accessData.accessToken!) : '';
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/lsplayback/topic${sort}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: token,
            },
        });

        const data = await response.json();
        setLiveStreamPlaybackTopicList(data.data);
    } catch (error) {
        console.log(`Cannot load information: ${error}`);
    }
};
