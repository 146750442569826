import React from 'react';
import Latex from 'react-latex';
import { useDispatch, useSelector } from 'react-redux';
import { selectAnswer } from '../../stores/mini-test/MiniTestState';
import { postTestAnswerDetail } from '../../api/MiniTest';
import 'katex/dist/katex.min.css';



const Answer = (props: {
  answerNo: number,
  questionNo: string,
  choice: NaTypes.TestChoice,
  choiceType: string,
  selectedAnswer: string,
}) => {
  const dispatch = useDispatch();
  const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
  const showScore = useSelector((state: NaTypes.Store) => state.miniTest.showScore);
  const testState = useSelector((state: NaTypes.Store) => state.miniTest);



  return !props.choice ? (
    <></>
  ) : (
    <div
      className={`mini-test-answer-container${props.choiceType === 'image' ? '-image' : '-text'}`}
      onClick={() => {
        if (showScore) return;
        postTestAnswerDetail(accessData, testState.test!.testId, testState.testAttempt.test_attempt_no, props.questionNo, props.choice.id)
          .then(() => dispatch(selectAnswer(props.questionNo, props.choice.id)));
      }}
    >
      <div className={`mini-test-answer-choice-index${showScore
        ? (props.selectedAnswer === props.choice.id
          ? (props.choice.isCorrect ? '-correct' : '-incorrect')
          : (props.choice.isCorrect ? '-correct' : ''))
        : (props.selectedAnswer === props.choice.id ? '-active' : '')}`}>
        <span>{props.answerNo}</span>
      </div>
      {props.choiceType === 'image' ? (
        <div className="mini-test-answer-image-container" style={{
          borderColor: (props.selectedAnswer === props.choice.id) ? 'black' : '#efeff4'
        }}>
          <img className="h-100 w-100" style={{ borderRadius: '2px' }} src={props.choice.description} alt="" />
        </div>
      ) : (
        <div className={`m-0 w-100${props.selectedAnswer === props.choice.id ? ' font-weight-bold' : ''}`}>
          <Latex>{props.choice.description}</Latex>
        </div>
      )}
    </div>
  );
};

export default Answer;
