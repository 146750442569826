import React from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { setAccessData } from '../../../stores/authorization/AuthorizationState';
import { resetLessonSet } from '../../../stores/lesson-list/LessonSetListState';
import { MixpanelTracking } from '../../../utility/MixpanelTracking';
import { toast } from 'react-toastify';

import userBlue from '../../../images/user/user-blue.svg';
import userOrange from '../../../images/user/user-orange.svg';
import userGreen from '../../../images/user/user-green.svg';
import location from '../../../images/other/location.svg';
import email from '../../../images/other/email.svg';
import grade from '../../../images/other/grade.svg';
import dropDown from '../../../images/other/dropdown.svg';
import loading from '../../../images/loading/loading_spinner.svg';
import provinces from '../../../json/province.json';



const NaLoginStepSignUp = (props: {
    userName: string,
    userSurName: string,
    userNickName: string,
    userEmail: string,
    userGrade: string,
    userProvinceCode: string,
    userParentEmail: string,

    phone: string,
    otp: string,
    facebookId: string,
    userPictureUrl: string,
    gradeState: NaTypes.GradeState,
    sendSubmit: boolean
    isValidUserEmail: boolean,
    isValidParentEmail: boolean,

    setSendSubmit: React.Dispatch<React.SetStateAction<boolean>>,
    setUserName: React.Dispatch<React.SetStateAction<string>>,
    setUserSurName: React.Dispatch<React.SetStateAction<string>>,
    setUserNickName: React.Dispatch<React.SetStateAction<string>>,
    setUserEmail: React.Dispatch<React.SetStateAction<string>>,
    setUserGrade: React.Dispatch<React.SetStateAction<string>>,
    setUserProvinceCode: React.Dispatch<React.SetStateAction<string>>,
    setParentEmail: React.Dispatch<React.SetStateAction<string>>,

    setState: React.Dispatch<React.SetStateAction<string>>,
    signUpWithFacebook: (facebookId: string, param: any, userPictureUrl: string) => Promise<any>,
    signUpWithPhoneNumber: (phoneNumber: string, otp: string, param: any) => Promise<any>,
    handleUserEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    handleParentEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
    const dispatch = useDispatch();

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            props.setSendSubmit(true);

            if (props.facebookId !== '') {
                props.signUpWithFacebook(props.facebookId, {
                    fullname: `${props.userName} ${props.userSurName}`.trim(),
                    username: props.userNickName,
                    email: props.userEmail,
                    grade: props.userGrade,
                    province: provinces.provinces.find((p) =>
                        p.province_code === props.userProvinceCode),
                    parentEmail: props.userParentEmail,
                    sex: "1",
                }, props.userPictureUrl).then((res) => {
                    if (res.error_code === 0) {
                        MixpanelTracking.alias(res.accountId);
                        MixpanelTracking.identify(res.accountId);
                        MixpanelTracking.signUp('Facebook', res.account[0]);
                        ReactGA.event({
                            category: 'Account',
                            action: 'Sign Up',
                        });
                        dispatch(resetLessonSet());
                        props.setState('OFFER');
                        dispatch(setAccessData({
                            requestLogin: true,
                            accessToken: res.accessToken,
                            accountId: res.accountId,
                            account: res.account[0],
                        }));
                    }
                });

            } else if (props.phone !== '' && props.otp !== '') {
                props.signUpWithPhoneNumber(props.phone, props.otp, {
                    fullname: `${props.userName} ${props.userSurName}`.trim(),
                    username: props.userNickName,
                    grade: props.userGrade,
                    email: props.userEmail,
                    province: provinces.provinces.find((p) =>
                        p.province_code === props.userProvinceCode),
                    parentEmail: props.userParentEmail,
                    sex: "1",
                }).then((res) => {
                    if (res.error_code === 0) {
                        MixpanelTracking.alias(res.accountId);
                        MixpanelTracking.identify(res.accountId);
                        MixpanelTracking.signUp('Phone', res.account[0]);
                        ReactGA.event({
                            category: 'Account',
                            action: 'Sign Up',
                        });
                        dispatch(resetLessonSet());
                        props.setState('OFFER');
                        dispatch(setAccessData({
                            requestLogin: true,
                            accessToken: res.accessToken,
                            accountId: res.accountId,
                            account: res.account[0],
                        }));
                    } else {
                        toast("ไม่สามารถสมัครได้เนื่องจาก : " + res.error_description);
                    }
                });
            }
        }}>

            <div className="bg-sign-up position-fixed p-2">
                <div className="row">

                    <div className="col-12 pt-4"><h3>สร้างบัญชีฟรี</h3></div>

                    <div className="col-12 pt-2"><p className="m-0">ชื่อ</p></div>
                    <div className="col-12 input-signup">
                        <img src={userBlue} alt="" />
                        <input placeholder="ชื่อ" value={props.userName}
                            onChange={(e) => props.setUserName(e.target.value)}
                            required
                        >
                        </input>
                    </div>

                    <div className="col-12  pt-2">
                        <p className="m-0">นามสกุล</p>
                    </div>
                    <div className="col-12 input-signup">
                        <img src={userOrange} alt="" />
                        <input placeholder="นามสกุล" value={props.userSurName}
                            onChange={(e) => props.setUserSurName(e.target.value)}
                            required
                        >
                        </input>
                    </div>

                    <div className="col-12  pt-2">
                        <p className="m-0">ชื่อเล่น</p>
                    </div>
                    <div className="col-12 input-signup">
                        <img src={userGreen} alt="" />
                        <input placeholder="ชื่อเล่น" value={props.userNickName}
                            onChange={(e) => props.setUserNickName(e.target.value)}
                            required
                        >
                        </input>
                    </div>

                    <div className="col-12  pt-2"><p className="m-0">จังหวัด</p></div>
                    <div className="col-12 input-signup">
                        <img src={location} alt="" />
                        <select value={props.userProvinceCode} onChange={(e) => {
                            e.target.style.color = '#000000';
                            props.setUserProvinceCode(e.target.value);
                        }} required>
                            <option value="" hidden>จังหวัด</option>
                            {
                                provinces.provinces.map((p, i) => (
                                    <option key={i} value={p.province_code}>
                                        {p.province_name_local}
                                    </option>
                                ))
                            }
                        </select>
                        <img className="input-signup drop" src={dropDown} alt="" />
                    </div>

                    <div className="col-12  pt-2">
                        <p className="m-0">ชั้นเรียน</p>
                    </div>
                    <div className="col-12 input-signup">
                        <img src={grade} alt="" />
                        <select value={props.userGrade} onChange={(e) => {
                            e.target.style.color = '#000000';
                            props.setUserGrade(e.target.value);
                        }} required>
                            <option value="" hidden>ชั้นเรียน</option>
                            {
                                props.gradeState.grades.map((g, i) => (
                                    <option key={i} value={g.grade}>
                                        {g.descriptionTh}
                                    </option>
                                ))
                            }
                        </select>
                        <img className="input-signup drop" src={dropDown} alt="" />
                    </div>

                    <div className="col-12  pt-2">
                        <p className="m-0">อีเมล {!props.isValidUserEmail && <span className="invalid-email">- รูปแบบอีเมลไม่ถูกต้อง</span>}</p>
                    </div>
                    <div className="col-12 input-signup">
                        <img src={email} alt="" />
                        <input
                            placeholder="อีเมล"
                            value={props.userEmail}
                            onChange={(e) => props.handleUserEmailChange(e)}
                        />
                    </div>

                    <div className="col-12  pt-2">
                        <p className="m-0">อีเมลผู้ปกครอง {!props.isValidParentEmail && <span className="invalid-email">- รูปแบบอีเมลไม่ถูกต้อง</span>}</p>
                    </div>
                    <div className="col-12 input-signup">
                        <img src={email} alt="" />
                        <input
                            placeholder="อีเมลผู้ปกครอง"
                            value={props.userParentEmail}
                            onChange={(e) => props.handleParentEmailChange(e)}
                        />
                    </div>

                    <div className="col-12 mt-4 d-flex align-items-center">
                        <input className="premium-accept mr-2" type="checkbox" required />
                        <span>  ข้าพเจ้ายอมรับ
                            <a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/terms-and-conditions">เงื่อนไข</a>
                            และ<a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/privacy-and-cookie-policy">ข้อตกลงการใช้บริการ</a>
                        </span>
                    </div>

                    <div className="col-12 pt-4 register-button-container" >
                        {(!props.isValidUserEmail || !props.isValidParentEmail) && <p className="invalid-email">รูปแบบอีเมลไม่ถูกต้อง</p>}
                        <button
                            id="login-button2"
                            className="m-auto"
                            type="submit"
                            disabled={!props.isValidUserEmail || !props.isValidParentEmail || props.sendSubmit}
                        >
                            สร้างบัญชีฟรี
                            <img style={{ maxHeight: "8vh" }} src={loading} hidden={!props.sendSubmit} />
                        </button>
                    </div>

                </div>
            </div>
        </form>
    );
};

export default NaLoginStepSignUp;
