const initialState: NaTypes.GradeState = {
  pending: false,
  grades: [],
  error: null,
};

const FETCH_GRADE_PENDING = 'FETCH_GRADE_PENDING';
const fetchGradePending = () => {
  return {
    type: FETCH_GRADE_PENDING,
  };
};

const FETCH_GRADE_SUCCESS = 'FETCH_GRADE_SUCCESS';
const fetchGradeSuccess = (grades: Array<NaTypes.Grade>) => {
  return {
    type: FETCH_GRADE_SUCCESS,
    grades,
  };
};

const FETCH_GRADE_ERROR = 'FETCH_GRADE_ERROR';
const fetchGradeError = (error: string) => {
  return {
    type: FETCH_GRADE_ERROR,
    error,
  };
};

export const fetchGrades = () => {
  return (dispatch: any) => {
    dispatch(fetchGradePending());
    const url = `${process.env.REACT_APP_API_URL_V2}/v1/grade/list?enabled=1`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
        .then((res) => res.json())
        .then((res) => {
          if (res.error === 0) {
            const grades = res.data.grades.map((g: any): NaTypes.Grade => {
              return {
                grade: g.grade,
                description: g.description,
                descriptionTh: g.description_th,
                displayOrder: g.display_order,
                isEnabled: g.enabled === 1 ? true : false,
              };
            });
            dispatch(fetchGradeSuccess(grades));
          } else {
            dispatch(fetchGradeError(res.error));
          }
        })
        .catch((err) => {
          dispatch(fetchGradeError(err));
        });
  };
};

/**
 * Grade state
 * @param {NaTypes.GradeState} state
 * @param {any} action
 * @return {NaTypes.GradeState} current state
 */
export function GradeState(
    state: NaTypes.GradeState = initialState,
    action: any): NaTypes.GradeState {
  switch (action.type) {
    case FETCH_GRADE_PENDING:
      return Object.assign({}, state, {
        pending: true,
        grades: [],
        error: null,
      });
    case FETCH_GRADE_SUCCESS:
      return Object.assign({}, state, {
        pending: false,
        grades: action.grades,
        error: null,
      });
    case FETCH_GRADE_ERROR:
      return Object.assign({}, state, {
        pending: false,
        error: action.error,
      });
    default:
      return state;
  }
};
