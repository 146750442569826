import React from 'react';
import Latex from 'react-latex';
import { LiveQuizPercentage } from '../../types';



export const renderAnswerPercentage = (
    i: number,
    liveQuizPercentage: LiveQuizPercentage,
    showAnswer: boolean
) => (
    <div style={{ display: showAnswer ? 'block' : 'none' }}>
        {liveQuizPercentage.percentage?.answers[i] && (
            <>
                <div className="answer-percentage-container" style={{
                    background: `linear-gradient(90deg, 
                        ${liveQuizPercentage.percentage.answers[i].isCorrect ? '#19b71f' : '#c7c7cc'} ${liveQuizPercentage.percentage.answers[i].percent}%, 
                        ${liveQuizPercentage.percentage.answers[i].isCorrect ? '#c4f7c6' : '#f0f0f5'} 0%)`,
                    borderRadius: '200px',
                }}>
                </div>

                <p className="answer-percentage-text" style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                    {Math.floor(liveQuizPercentage.percentage.answers[i].percent ?? 0)}%
                </p>
            </>
        )}
    </div>
);



export const renderImageChoice = (
    a: LiveHistoryQuestionAnswer,
    i: number,
    isCorrect: (answer: LiveHistoryQuestionAnswer, i: number) => React.ReactNode,
    liveQuizPercentage: LiveQuizPercentage,
    showAnswer: boolean
) => (
    <div className="image-choice-layout">

        <div style={{ display: showAnswer ? 'block' : 'none' }}>
            {liveQuizPercentage.percentage?.answers[i] && (
                <>
                    <div className="answer-percentage-container" style={{
                        background: `linear-gradient(90deg, 
                            ${liveQuizPercentage.percentage.answers[i].isCorrect ? '#19b71f' : '#c7c7cc'} ${liveQuizPercentage.percentage.answers[i].percent}%, 
                            ${liveQuizPercentage.percentage.answers[i].isCorrect ? '#c4f7c6' : '#f0f0f5'} 0%)`,
                        borderRadius: '14px',
                        height: '25%',
                    }}>
                    </div>

                    <p className="answer-percentage-text">
                        {Math.floor(liveQuizPercentage.percentage.answers[i].percent ?? 0)}%
                    </p>
                </>
            )}
        </div>

        <div className="answer-choice-container">
            {isCorrect(a, i)}
            <p className="m-0">
                <Latex>{a.value != null ? a.value : ''}</Latex>
            </p>
        </div>

        <img className="image-choice w-100" src={a.image_path} alt="" />

    </div>
);
