import React, { useEffect } from 'react';



const Page404 = () => {

    useEffect(() => {
        const interval = setInterval(() => {
            window.location.href = `${process.env.REACT_APP_WEB_URL}`
        }, 1000);
        return () => clearInterval(interval);
    });

    return (
        <>
            <div className='col-12' style={{
                textAlign: "center",
                marginTop: "10%"
            }} >
                <div className='' style={{ marginBottom: "80px" }}>
                    <p style={{ fontSize: "55px" }}>404</p>
                    <h1>
                        Sorry, This page doesn't seem to exist.
                    </h1>
                </div>
                <div>
                    <button className='live-stream-enter-button' style={{ width: "20%" }} onClick={() => {
                        window.location.href = `${process.env.REACT_APP_WEB_URL}`;
                    }}>Go to homepage</button>
                </div>
            </div>
        </>
    );
};

export default Page404;
