import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './LiveStreamShowList.css';
import { MixpanelTracking } from '../../utility';
import { generateToken } from '../../utility/Token';
import { isMobile } from 'react-device-detect';
import crownGrey from '../../images/crown/crown-grey.svg';
import crownGold from '../../images/crown/crown-gold.svg';
import loading from '../../images/loading/loading_spinner.svg';

const LiveStreamShowList = () => {
    const refSectionElement = useRef<HTMLElement>(null);
    const gradeState = useSelector((state: NaTypes.Store) => state.grade);
    const subjectState = useSelector((state: NaTypes.Store) => state.subject);
    const accessData: NaTypes.Authenticate = useSelector((state: NaTypes.Store) => state.authenticate);
    const [liveStreamHistoryFilter, setLiveStreamHistoryFilter] = useState<NaTypes.LiveStreamHistoryList>([]);
    let lists: string[] = [];
    const [filter, setFilter] = useState<string>("all");
    const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const url = new URL(window.location.href);
    let search = url.searchParams.get("search") ? url.searchParams.get("search") : "";
    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const [loadAble, setLoadAble] = useState<boolean>(false);
    const [paging, setPaging] = useState<number>(1);

    useEffect(() => {
        setLoadingMore(true);
        setLoadAble(true);
        setPaging(1);
        if (search === "E4") {
            search = "E5";
        }
        if (search === "") {
            setFilter("all");
            getLSPlaybackList("?page=1&itemPerPage=18");
        } else if (search != null) {
            if (/\d/.test(search)) {
                getLSPlaybackList("?page=1&itemPerPage=18&grade=" + search);
            } else {
                getLSPlaybackList("?page=1&itemPerPage=18&subject=" + search);
            }
            setFilter(search);
        }
    }, [search]);

    const tracking = (livestream: any) => {
        // if (accessData.accessToken) {
        //     const token = generateToken(accessData.accountId!, accessData.accessToken!);
        //     fetch(`${process.env.REACT_APP_API_URL_V2}/v1/live-stream/lsplayback/view/tracking`,
        //         {
        //             method: 'POST',
        //             headers: {
        //                 'Accept': 'application/json',
        //                 'Content-Type': 'application/json',
        //                 'token': token,
        //             },
        //             body: JSON.stringify({
        //                 'user_id': accessData.accountId,
        //                 'ls_id': livestream.id,
        //                 'device': "WEB",
        //             }),
        //         })
        //         .then((res) => { })
        //         .catch((err) => console.log(err));
        //     MixpanelTracking.lsplaybackselected(livestream.subject_id,
        //         livestream.detail,
        //         livestream.id,
        //         livestream.subject,
        //         livestream.grade,
        //         accessData.account.grade);
        // }
    };

    if (isMobile) {
        refSectionElement.current?.scrollIntoView();
    }

    const getLSPlaybackList = (sort: string) => {
        let token = "";
        if (accessData.accountId) {
            token = generateToken(accessData.accountId!, accessData.accessToken!);
        }
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/type/recent${sort}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "token": token
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setLoadingMore(false);
                setLiveStreamHistoryFilter(res);
                if (res.length != 0 && res.length % 18 == 0) {
                    setLoadAble(true);
                } else {
                    setLoadAble(false);
                }
            });
    }

    const changeFilter = (e: any) => {
        setLoadingMore(true);
        setLiveStreamHistoryFilter([]);
        setLoadAble(true);
        setPaging(1);
        if (e === "all") {
            getLSPlaybackList("?page=1&itemPerPage=18");
        } else {
            if (/\d/.test(e)) {
                getLSPlaybackList("?page=1&itemPerPage=18&grade=" + e);
            } else {
                getLSPlaybackList("?page=1&itemPerPage=18&subject=" + e);
            }
        }
        setFilter(e);
    }

    const getLSPlaybackListLoadMore = () => {
        setLoadingMore(true)
        let token = "";
        if (accessData.accountId) {
            token = generateToken(accessData.accountId!, accessData.accessToken!);
        }

        let url = `${process.env.REACT_APP_API_URL_V2}/v1/live-stream/type/recent?page=${paging + 1}&itemPerPage=18`;
        setPaging(paging + 1);

        if (/\d/.test(filter)) {
            url = url + `&grade=${filter}`;
        } else if (filter != "all") {
            url = url + `&subject=${filter}`;
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "token": token
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setLoadingMore(false);
                setLiveStreamHistoryFilter(liveStreamHistoryFilter.concat(res));
                if (res.length != 0 && res.length % 18 == 0) {
                    setLoadAble(true);
                } else {
                    setLoadAble(false);
                }
            });
    }

    const checkScroll = (e: any) => {
        if (e.target.scrollHeight - e.target.scrollTop - 1 <= e.target.clientHeight && loadAble && !loadingMore) {
            getLSPlaybackListLoadMore();
        }
    }

    return <>
        <div className="show-bg row" style={{ overflowY: "scroll", height: "89.5vh" }} onScroll={checkScroll}>
            <div className="lesson-list-container my-card bg-white" style={{ borderRadius: '1vw' }}>
                <div className="w-100 p-0" style={{ marginLeft: '15px', marginTop: '2%' }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <p className="lesson-list-title" style={{ marginBottom: '15px' }}>
                                ประวัติการไลฟ์สด
                            </p>
                            <p className="lesson-list-sub-title">กดย้อนดูคลิปที่เคยผ่านการไลฟ์สดไปแล้ว เพื่อทบทวนอีกครั้ง</p>
                        </div>
                        <div className="view-all-history">
                            <select className='form-select-sm form-history' value={filter}
                                onChange={(e) => { changeFilter(e.target.value); }}>
                                <option value="all">All</option>
                                {/* {
                                    liveStreamHistoryRecent.map((item) => {
                                        if (item['instructor_name'] != null && item['instructor_profile'] != null) {
                                            if (lists.indexOf(item['instructor_profile']) < 0) {
                                                lists.push(item['instructor_profile']);
                                                return <option value={item['instructor_profile']}> {item['instructor_name']} </option>
                                            }
                                        }
                                    })} */}

                                {gradeState.grades.map((grade) => {
                                    if (grade['grade'] != "E4")
                                        return <option value={grade['grade']}> {grade['descriptionTh']} </option>
                                })}

                                {subjectState.subjects.map((subject) => {
                                    return <option value={subject.subjectId}> {subject.descriptionTh} </option>
                                })}

                            </select>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingTop: "20px", paddingLeft: '15px', paddingRight: '15px' }}>
                    {
                        liveStreamHistoryFilter?.map((livestream) => {
                            let date = new Date(livestream.starttime);
                            let dateStart = date.getDate() + " " + month[date.getMonth()] + " " + date.getFullYear();
                            return (
                                livestream.is_free === 1 ?
                                    <>
                                        <div className="item-video col-3 col-sm-4 col-lg-3 col-xl-2">
                                            <Link to={`/livehistory/${livestream.id}`} onClick={() => tracking(livestream)}>
                                                <div className="video-image-container" style={{ position: "relative" }}>
                                                    <img className="video-image" src={livestream.video_thumbnail_hd_url} alt={livestream.subject} />
                                                    <div className="video-type" style={{ position: "absolute", top: "10px", right: "10px" }}>
                                                        <p id="free">Free</p>
                                                    </div>
                                                    <div className="watched-ls">
                                                        {livestream.watched === 1 ?
                                                            <img className="video-tag-status-image" style={{width:"32px",height:"22px"}} src={crownGold} alt="" /> :
                                                            <img className="video-tag-status-image" style={{width:"32px",height:"22px"}} src={crownGrey} alt="" />
                                                        }
                                                    </div>
                                                    <div className="LSH-duration" style={{ fontSize: "10px" }}>
                                                        {Math.floor(livestream.video_duration / 3600).toFixed(0) !== '0' &&
                                                            <>{Math.floor(livestream.video_duration / 3600).toFixed(0)} : </>
                                                        }
                                                        <>{("0" + Math.floor((livestream.video_duration % 3600) / 60).toFixed(0)).slice(-2)} : </>
                                                        {("0" + Math.floor(((livestream.video_duration % 3600) % 60)).toFixed(0)).slice(-2)}
                                                    </div>
                                                </div>
                                                <p className="topic-name topic-name-ls topic-name-list">{livestream.subject}</p>
                                                <p className="livestream-date topic-name-list">{dateStart}</p>
                                            </Link>
                                            <div className="video-tag-group">
                                                {subjectState.subjects.find((s) => { return s.subjectId === livestream.subject_id; }) ?
                                                    <div className="video-tag-info video-tag-info-ls topic-name-list" onClick={() => changeFilter(livestream.subject_id)}>
                                                        <p className="subject-name-ls">+ {subjectState.subjects.find((s) => {
                                                            return s.subjectId === livestream.subject_id!;
                                                        })?.descriptionTh}</p>
                                                    </div>
                                                    : <></>}
                                                {livestream.instructor_name ?
                                                    <div className="video-tag-info video-tag-info-ls topic-name-list">
                                                        <p className="subject-name-ls">+ {livestream.instructor_name}</p>
                                                    </div>
                                                    : <></>}
                                                {
                                                    livestream.grade.split(",")?.map((grades) =>
                                                        gradeState.grades.find((g) => { return grades === g.grade }) ?
                                                            <><Link to={`/livehistory?search=${grades}`}>
                                                                <div className="video-tag-info video-tag-info-ls topic-name-list">
                                                                    <p className="subject-name-ls">+ {gradeState.grades.find((g) => {
                                                                        return grades === g.grade;
                                                                    })?.descriptionTh}</p>
                                                                </div>
                                                            </Link></>
                                                            : <></>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="item-video col-3 col-sm-4 col-lg-3 col-xl-2">
                                            <Link to={`/livehistory/${livestream.id}`} onClick={() => tracking(livestream)}>
                                                <div className="video-image-container" style={{ position: "relative" }}>
                                                    <img className="video-image"
                                                        // style={{ border: "4px solid #FFCC00", boxShadow: "0px 0px 20px rgba(255, 204, 0, 0.6)" }} 
                                                        src={livestream.video_thumbnail_hd_url} alt={livestream.subject} />
                                                    {/* <div className="video-type-premium" style={{ position: "absolute", top: "10px", right: "5px" }}>
                                                        <p id="premium">Premium</p>
                                                    </div> */}
                                                    <div className="watched-ls">
                                                        {livestream.watched === 1 ?
                                                            <img className="video-tag-status-image" style={{width:"32px",height:"22px"}} src={crownGold} alt="" /> :
                                                            <img className="video-tag-status-image" style={{width:"32px",height:"22px"}} src={crownGrey} alt="" />
                                                        }
                                                    </div>
                                                    <div className="LSH-duration" style={{ fontSize: "10px" }}>
                                                        {Math.floor(livestream.video_duration / 3600).toFixed(0) !== '0' &&
                                                            <>{Math.floor(livestream.video_duration / 3600).toFixed(0)} : </>
                                                        }
                                                        <>{("0" + Math.floor((livestream.video_duration % 3600) / 60).toFixed(0)).slice(-2)} : </>
                                                        {("0" + Math.floor(((livestream.video_duration % 3600) % 60)).toFixed(0)).slice(-2)}
                                                    </div>
                                                </div>
                                                <p className="topic-name topic-name-ls topic-name-list">{livestream.subject}</p>
                                                <p className="livestream-date topic-name-list">{dateStart}</p>
                                            </Link>
                                            <div className="video-tag-group">
                                                {subjectState.subjects.find((s) => { return s.subjectId === livestream.subject_id; }) ?
                                                    <div className="video-tag-info video-tag-info-ls topic-name-list" onClick={() => changeFilter(livestream.subject_id)}>
                                                        <p className="subject-name-ls">+ {subjectState.subjects.find((s) => {
                                                            return s.subjectId === livestream.subject_id!;
                                                        })?.descriptionTh}</p>
                                                    </div>
                                                    : <></>}
                                                {livestream.instructor_name ?
                                                    <div className="video-tag-info video-tag-info-ls topic-name-list">
                                                        <p className="subject-name-ls">+ {livestream.instructor_name}</p>
                                                    </div>
                                                    : <></>}
                                                {
                                                    livestream.grade.split(",")?.map((grades) =>
                                                        gradeState.grades.find((g) => { return grades === g.grade }) ?
                                                            <><Link to={`/livehistory?search=${grades}`}>
                                                                <div className="video-tag-info video-tag-info-ls topic-name-list">
                                                                    <p className="subject-name-ls">+ {gradeState.grades.find((g) => {
                                                                        return grades === g.grade;
                                                                    })?.descriptionTh}</p>
                                                                </div>
                                                            </Link></>
                                                            : <></>
                                                    )}
                                                {/* {livestream.topic ?
                                                    <div className="video-tag-info video-tag-info-ls topic-name-list">
                                                        <p className="subject-name-ls">+ {livestream.topic}</p>
                                                    </div>
                                                    : <></>} */}
                                            </div>
                                        </div>
                                    </>
                            )
                        })
                    }
                    {/* <div className="item-video col-3 col-sm-4 col-lg-3 col-xl-2" style={{ textAlign: "center", paddingTop: "3%" }}>
                        <div className="video-image-container" style={{ backgroundColor: "lightgrey", padding: "50px", borderRadius: "10px", cursor:"pointer" }}>
                            Load more...

                        </div>
                    </div> */}
                </div>
                {/* {loadAble && !loadingMore ?
                    <div className="row" style={{ margin: "auto" }}>
                        <div className='col-12' style={{ marginBottom: "30px", marginTop: "10px" }}>
                            <button className='chat-login-button' onClick={() => { getLSPlaybackListLoadMore() }}>Load more</button>
                        </div>
                    </div>
                    : <></>} */}
                {loadingMore ?
                    <div className="row" style={{ margin: "auto", paddingTop: "9%", paddingBottom: "9%" }}>
                        <div className='col-12'>
                            <img style={{ maxHeight: "250px" }} src={loading} />
                        </div>
                    </div>
                    : <></>}
                {!loadingMore && liveStreamHistoryFilter.length <= 0 ?
                    <div className="row" style={{ margin: "auto", paddingTop: "14%", paddingBottom: "14%" }}>
                        <div className='col-12' style={{ textAlign: "center" }}>
                            ขออภัย ขณะนี้ยังไม่มีไลฟ์ที่เกี่ยวข้องกับการค้นหา <br />
                            กรุณาเลือกระดับชั้นหรือรายวิชาอื่น
                        </div>
                    </div>
                    : <></>}
            </div>
        </div>
    </>;
};

export default LiveStreamShowList;
