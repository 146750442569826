import React from 'react';
import ViewerIcon from '../../../images/comment/viewer_grey.png';



const LiveStreamPageViewers = (props: {
    viewerCount: number,
    streamClose: boolean,
    liveStreamUrl: string,
}) => {

    return props.streamClose !== false && props.liveStreamUrl !== '' ? (
        <div className='LS-viewers'>
            <img src={ViewerIcon} alt="Viewer Icon" />
            {props.viewerCount}
        </div>
    ) : null;
};

export default LiveStreamPageViewers;