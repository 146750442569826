import { generateToken } from '../utility/Token';

export const sendStepVOD = (accessData: NaTypes.Authenticate, subjectId: string, grade: string, topicId: string, chapterId: string, step: number) => {
    if (typeof accessData.accountId !== 'string' || typeof accessData.accessToken !== 'string') {
        return;
    }
    const token = generateToken(accessData.accountId!, accessData.accessToken!);
    const params = new URLSearchParams();
    params.append('access_token', token);
    params.append('account_id', accessData.accountId!);
    params.append('subject_id', subjectId);
    params.append('grade', grade);
    params.append('topic_id', topicId);
    params.append('chapter_id', chapterId);
    params.append('step', step.toString());

    fetch(`${process.env.REACT_APP_API_URL}/api/v6/method/vod.step.add`,
        {
            method: 'POST',
            body: params,
            headers: new Headers({}),
        })
        .then((res) => { })
        .catch((err) => console.log(err));
};

export const addExchangePoint = (accessData: NaTypes.Authenticate, subjectId: string, grade: string, topicId: string, chapterId: string) => {
    if (typeof accessData.accountId !== 'string' || typeof accessData.accessToken !== 'string') {
        return;
    }
    const token = generateToken(accessData.accountId!, accessData.accessToken!);
    const params = new URLSearchParams();
    params.append('access_token', token);
    params.append('account_id', accessData.accountId!);
    params.append('subject_id', subjectId);
    params.append('grade', grade);
    params.append('topic_id', topicId);
    params.append('chapter_id', chapterId);

    fetch(`${process.env.REACT_APP_API_URL}/api/v5/method/vod.quiz.add.exchange`,
        {
            method: 'POST',
            body: params,
            headers: new Headers({}),
        })
        .then((res) => { })
        .catch((err) => console.log(err));
};

export const createPost = (accessData: NaTypes.Authenticate, subjectId: string, subjectName: string, studyTime: number) => {
    if (typeof accessData.accountId !== 'string' || typeof accessData.accessToken !== 'string' || studyTime < 60) {
        return;
    }

    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    const result = JSON.stringify({
        subject: subjectName,
        study_time: {
            hour: Math.floor(studyTime / 3600),
            minute: Math.floor((studyTime % 3600) / 60),
        },
        date: date + '/' + month + '/' + year,
        additional: '',
    });

    const params = new URLSearchParams();
    params.append('accessToken', accessData.accessToken);
    params.append('accountId', accessData.accountId);
    params.append('postText', result);
    params.append('groupId', '0');
    params.append('postMode', '1');
    params.append('certification', '0');

    fetch(`${process.env.REACT_APP_API_URL}/api/v4/method/items.new`,
        {
            method: 'POST',
            body: params,
            headers: new Headers({}),
        })
        .then((res) => { })
        .catch((err) => console.log(err));
};



export const recordStudyTime = async (accessData: NaTypes.Authenticate, subjectId: string, studyTime: number) => {

    const { accountId, accessToken } = accessData;

    if (!accountId || !accessToken || typeof accountId !== 'string' || typeof accessToken !== 'string' || studyTime < 60) return;

    const token = generateToken(accountId, accessToken);
    const recordTime = Math.floor(studyTime / 60).toString();

    const paramsRecordStudy = new URLSearchParams({
        access_token: token,
        account_id: accountId,
        subject_id: subjectId,
        record_time: recordTime,
    });

    try {
        const recordStudy = await fetch(`${process.env.REACT_APP_API_URL}/api/v5/method/record.study.time`, {
            method: 'POST',
            body: paramsRecordStudy,
        });

        if (!recordStudy.ok) throw new Error(`HTTP error! status: ${recordStudy.status}`);

        const recordStudyData = await recordStudy.json();

        const recordBadge = await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/badge/record`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': token,
            },
            body: JSON.stringify({
                exp: recordTime,
                badge_id: 1,
            }),
        });

        if (!recordBadge.ok) throw new Error(`HTTP error! status: ${recordBadge.status}`);

        const recordBadgeData = await recordBadge.json();

        return {
            recordStudyData: recordStudyData,
            recordBadgeData: recordBadgeData,
        };
    } catch (err) {
        console.error('Failed to record study time:', err);
        throw err;
    }
};



export const recordVideoWatched = async (accessData: NaTypes.Authenticate) => {

    const { accountId, accessToken } = accessData;
    if (!accountId || !accessToken || typeof accountId !== 'string' || typeof accessToken !== 'string') return;

    const token = generateToken(accountId, accessToken);

    try {
        const recordBadge = await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/badge/record`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': token,
            },
            body: JSON.stringify({
                exp: "1",
                badge_id: 2,
            }),
        });

        if (!recordBadge.ok) throw new Error(`HTTP error! status: ${recordBadge.status}`);
        const recordBadgeData = await recordBadge.json();
        return recordBadgeData;

    } catch (err) {
        console.error('Failed to record badge:', err);
        throw err;
    }
};
