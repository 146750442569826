import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval } from '../../utility';
import { submitAnswer, nextLesson } from './MiniTestUtils';
import { fetchMiniTest, hideScore } from '../../stores/mini-test/MiniTestState';
import { ChapterList } from '../../types';
import QuestionList from './QuestionList';
import Score from './Score';
import './MiniTest.css';



const MiniTest = (props: {
    chapter: NaTypes.Chapter,
    setChapter: React.Dispatch<React.SetStateAction<NaTypes.Chapter | undefined>>,
    setActiveChapter: React.Dispatch<React.SetStateAction<string | undefined>>,
    setState: React.Dispatch<React.SetStateAction<string | undefined>>,
    chapters: ChapterList
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { subject, grade, topic } = useParams() as { subject: string, grade: string, topic: string };
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const miniTestState = useSelector((state: NaTypes.Store) => state.miniTest);
    const lessonSetList = useSelector((state: NaTypes.Store) => state.lessonSetList);
    const subjectState = useSelector((state: NaTypes.Store) => state.subject);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [countStayingTime, setCountStayingTime] = useState<number>(0);
    const refCountStayingTime = useRef(0);



    const submitAnswerHandler = () => {
        submitAnswer(
            accessData, miniTestState, subjectState, props.chapter,
            refCountStayingTime.current, setShowAlert, dispatch,
        );
    };

    const nextLessonHandler = () => {
        nextLesson(
            lessonSetList, subject, grade, topic, props.chapter, props.chapters,
            history, props.setChapter, props.setActiveChapter, props.setState,
        );
    };



    useInterval(() => {
        setCountStayingTime(countStayingTime + (process.env.NODE_ENV === 'production' ? 1 : 60));
    }, 1000);



    useEffect(() => {
        refCountStayingTime.current = countStayingTime;
    }, [countStayingTime]);

    useEffect(() => {
        setCountStayingTime(0);
    }, [refresh]);

    useEffect(() => {
        dispatch(hideScore());
        if (accessData.accessToken && accessData.accountId) {
            dispatch(fetchMiniTest(accessData, subject!, grade!, topic!, props.chapter.chapterId));
        }
    }, [accessData, dispatch, grade, props.chapter, subject, topic, refresh]);



    return (
        (miniTestState.pending || miniTestState.test == null || miniTestState.test.testQuestionList == null) ? (
            <>Loading...</>
        ) : (
            <div id="mini-test-content">
                {miniTestState.showScore && (
                    <>
                        <Score test={miniTestState.test} />
                        <div className="mini-test-try-again-and-next-lesson-container">
                            <button className="mini-test-try-again-button" onClick={() => setRefresh(!refresh)}>ลองใหม่</button>
                            <button className="mini-test-next-lesson-button" onClick={() => nextLessonHandler()}>บทเรียนต่อไป</button>
                        </div>
                        <div className="mini-test-segmentation-line"></div>
                        <p className="mini-test-show-answer-title">เฉลยคำตอบ</p>
                    </>
                )}

                <QuestionList test={miniTestState.test} />

                {!miniTestState.showScore && (
                    <div className="mini-test-submit-container">
                        <button type="button" className="submit-answer-button btn btn-primary" onClick={() => submitAnswerHandler()}>
                            <p className="font-weight-bold m-0">ส่งคำตอบ</p>
                        </button>
                        {showAlert && (
                            <p className="text-center text-danger text-lowercase mt-4 mb-5">ยังเหลือข้อที่ยังไม่ได้ตอบนะ!!</p>
                        )}
                    </div>
                )}
            </div>
        )
    );
};

export default MiniTest;
