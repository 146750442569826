import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import closeImg from '../../images/actions/close.svg';
import './QuizPopUp.css';
import { ImageUtility, MixpanelTracking } from '../../utility';
import Latex from 'react-latex';
import { useDispatch, useSelector } from 'react-redux';
import { showCorrect, showIncorrect } from '../../stores/correct-and-incorrect-overlay/CorrectAndIncorrectState';

const QuizPopUp = forwardRef((props: {
  chapter: NaTypes.Chapter,
  onClose?(): void,
}, ref) => {
  const dispatch = useDispatch();
  const [choice, setChoice] = useState();
  const [show, setShow] = useState(false);
  const [number, setNumber] = useState(0);
  const [question, setQuestion] = useState<any>();
  const subjectState = useSelector((state: NaTypes.Store) => state.subject);

  useImperativeHandle(ref, (): QuestionRefs => ({
    showQuestion(q: any, num: number) {
      setQuestion(q);
      setNumber(num);
    },
  }));

  useEffect(() => {
    setChoice(undefined);
    setShow(true);
  }, [question]);

  useEffect(() => {
    let autoClose: any;
    if (choice) {
      autoClose = setTimeout(() => {
        setShow(false);
        if (props.onClose) props.onClose();
      }, 2000);
    }
    return () => {
      if (autoClose) {
        clearTimeout(autoClose);
      }
    };
  }, [choice, props]);

  useEffect(() => {
    if (choice) {
      const answer = question!.answerList.find((answer: { id: string }) => {
        return answer.id === choice;
      });

      const questionId = question.id;
      const questionText = question.value;
      const questionImage = question.imagePath;
      const answerText = answer.value;
      const answerImage = answer.imagePath;
      const isCorrect = answer.isCorrect;

      if (answer.isCorrect) {
        dispatch(showCorrect());
      } else {
        dispatch(showIncorrect());
      }

      const subject = subjectState.subjects.find((s) => {
        return s.subjectId === props.chapter.subject;
      });
      MixpanelTracking.questionAnswered(props.chapter, subject!.descriptionTh, questionId, questionText, questionImage, answerText, answerImage, isCorrect);
    }
  }, [choice, question, props.chapter, dispatch, subjectState]);

  if (!show || !question) {
    return <></>;
  }

  /**
   * close dialog quiz
   */
  function close() {
    setShow(false);
    setChoice(undefined);
    setQuestion(undefined);
    if (props.onClose) props.onClose();
  }

  /**
   * get view answer
   * @param {NaTypes.Answer} answer
   * @param {number} i index of choice
   * @return {JSX.Element}
   */
  function isCorrect(answer: NaTypes.Answer, i: number): JSX.Element {
    if (choice) {
      if (answer.id !== choice) {
        if (answer.isCorrect === true) {
          // return correct view
          return <>
            <div className="choice-default correct">
              {i + 1}
            </div>
          </>;
        }
      } else {
        if (answer.isCorrect === false) {
          // return incorrect view
          return <>
            <div className="choice-default incorrect">
              {i + 1}
            </div>
          </>;
        } else {
          // return correct view
          return <>
            <div className="choice-default correct">
              {i + 1}
            </div>
          </>;
        }
      }
    }
    // return default view
    return <>
      <div className="choice-default">
        {i + 1}
      </div>
    </>;
  };

  return (
    <div className="question-bg">
      <div className="row h-100 m-0">
        <div className="col-6 h-100 p-2">
          <div className="question-name-layout h-100">
            <div className="number-question">
              <p>{number}</p>
            </div>
            <p className="question-text">
              <Latex>{question.value != null ? question.value : ''}</Latex>
            </p>
            {ImageUtility.isImage(question.imagePath) ? (
              <div className="question-image">
                <img style={{maxHeight: "30vh",maxWidth: "20vw"}}  src={question.imagePath} alt="" />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="col-6 h-100 p-2">
          <div className="h-100 d-flex align-items-center">
            <img className="quiz-close can-click" src={closeImg} alt=""
              onClick={close} />
            {
              question?.answerList
                .every((a: any) => !ImageUtility.isImage(a.imagePath)) ? (<>
                  {/* text */}
                  <div className="row h-100 align-content-center m-0">
                    {question?.answerList.map((a: any, i: number) => (
                      <div key={i}
                        onClick={() => {
                          if (!choice) setChoice(a.id);
                        }}
                        className="col-12 d-flex
                        align-items-center mt-2 mb-2 p-0 can-click answer-text"
                      >
                        {isCorrect(a, i)}
                        <p className="answer-text pl-2 m-0 w-100" style={{whiteSpace: "break-spaces"}}>
                          <Latex>{a.value != null ? a.value : ''}</Latex>
                        </p>
                      </div>
                    ))}
                  </div>
                </>) : (<>
                  {/* image  */}
                  <div className="row h-100 w-100 p-2">
                    {question?.answerList.map((a: any, i: number) => (
                      <div key={i}
                        onClick={() => {
                          if (!choice) setChoice(a.id);
                        }}
                        className="col-6 can-click"
                      >
                        <div className="image-choice-layout">
                          <div className="w-100 d-flex p-0">
                            {isCorrect(a, i)}
                            <p className="m-0">
                              <Latex>{a.value != null ? a.value : ''}</Latex>
                            </p>
                          </div>
                          <img className="image-choice w-100"
                            src={a.imagePath} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>
                </>)
            }
          </div>
        </div>
      </div>
    </div>
  );
});
QuizPopUp.displayName = 'QuizPopUp';
export default QuizPopUp;
