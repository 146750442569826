import React, { useRef, useEffect, useState, } from 'react';
import { useParams } from 'react-router';
import './Exam.css'
import { MixpanelTracking } from '../../utility/MixpanelTracking';
import { ExamSet, ExamList, SummitExam } from '../../types';
import { generateToken } from '../../utility/Token';
import { useDispatch, useSelector } from 'react-redux';
import Latex from 'react-latex';
import { ImageUtility } from '../../utility';
import Modal from 'react-bootstrap/Modal';
import certificate from '../../images/certificate/certificate.svg';
import close from '../../images/close/close.svg';
import { requestLogin } from '../../stores/authorization/AuthorizationState';



const Exam = () => {
    const { grade, quarter, id }: any = useParams();
    const accessData: NaTypes.Authenticate = useSelector((state: NaTypes.Store) => state.authenticate);
    const [exams, setExams] = useState<Array<ExamList>>([]);
    const [exam, setExam] = useState<ExamList>();
    const gradeState = useSelector((state: NaTypes.Store) => state.grade);
    const [question, setQuestion] = useState<ExamSet>();
    const examContainer = useRef<HTMLDivElement>(null);
    const [currentSection, setCurrentSection] = useState<number>(0);
    const [checkAnswer, setCheckAnswer] = useState<boolean>(false);
    const [sendSummit, setSendSummit] = useState<boolean>(false);
    const [getPoint, setGetPoint] = useState<number>(0);
    const [fullPoint, setFullPoint] = useState<number>(0);
    const [summitExam, setSummitExam] = useState<Array<SummitExam>>([]);
    const dispatch = useDispatch();
    const transaction: Transaction = useSelector((state: NaTypes.Store) => state.transaction);
    const [showConfirmPayment, setShowConfirmPayment] = useState<boolean>(false);


    if (!accessData.accountId) {
        dispatch(requestLogin());
    }

    useEffect(() => {
        if (!exam) {
            const fetch = async () => {
                setExams(await getExamList());
            };
            fetch().catch(console.error);
        }
    }, []);

    useEffect(() => {
        if (!question) {
            const fetch = async () => {
                let quest = await getStart();
                if (quest) {
                    setQuestion(quest);
                }
            };
            fetch().catch(console.error);
        }
    }, []);

    useEffect(() => {
        let check = summitExam.filter(e => e.choice_id === "");
        if (check.length <= 0 && summitExam.length > 0) {
            setCheckAnswer(true);
        }
        setExam(exams.filter(ex => ex.questionSetId + "" === id + "")[0]);
    });

    function getExamList() {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/exam/${grade}/${quarter}`;
        return fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': generateToken(accessData.accountId!, accessData.accessToken!),
                },
            })
            .then((res) => res.json())
            .then((res) => {
                return res.data.map((d: any): NaTypes.ExamList => {
                    return {
                        duration: d.duration,
                        enable: d.enable,
                        examYear: d.exam_year,
                        grade: d.grade,
                        id: d.id,
                        lsId: d.ls_id,
                        name: d.name,
                        quarter: d.quarter,
                        questionSetId: d.question_set_id,
                        sectionId: d.section_id,
                        subject: d.subject,
                        subjectName: d.subject_name,
                        subjectTHName: d.subject_th_name,
                        pdfFile: d.pdf_exam
                    }
                })
            })
            .catch((err) => console.log(err));
    };

    function getStart() {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/exam/start`;
        return fetch(
            url,
            {
                method: 'POST',
                body: JSON.stringify({
                    'question_set_id': id
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': generateToken(accessData.accountId!, accessData.accessToken!),
                },
            })
            .then((res) => res.json())
            .then((res): NaTypes.ExamSet => {
                const examSets: NaTypes.ExamSet = {
                    examSummit: {
                        id: res.data.exam_submit.id,
                        userId: res.data.exam_submit.user_id,
                        questionSetId: res.data.exam_submit.question_set_id,
                        startTime: res.data.exam_submit.start_time,
                        endTime: res.data.exam_submit.end_time,
                        submitTime: res.data.exam_submit.submit_time
                    },
                    questionSet: {
                        id: res.data.question_set.id,
                        name: res.data.question_set.name,
                        duration: res.data.question_set.duration,
                        isEnable: res.data.question_set.is_enabled,
                        questionList: []
                    }
                }
                let setQuestionSummit: Array<SummitExam> = [];
                examSets.questionSet.questionList =
                    res.data.question_set.question_list.map((d: any): NaTypes.ExamQuestion => {
                        setQuestionSummit.push(
                            {
                                question_id: d.id,
                                choice_id: ""
                            }
                        )
                        let choice =
                            d.choice_list.map((c: any): NaTypes.Answer => {
                                return {
                                    id: c.id,
                                    imagePath: c.image_url,
                                    value: c.text,
                                    isCorrect: c.is_correct,
                                    questionId: c.question_id,
                                }
                            })
                        return {
                            questionSetId: d.question_set_id,
                            id: d.id,
                            displayOrder: d.display_order,
                            description: d.description,
                            imageUrl: d.image_url,
                            choiceType: d.choice_type,
                            isEnable: d.is_enable,
                            reasonText: d.reason_text,
                            reasonImageUrl: d.reason_image_url,
                            choiceList: choice,
                            questionAnswerId: ""
                        }
                    });
                setSummitExam(setQuestionSummit);
                return examSets;
            }
            )
            .catch((err) => console.log(err));
    };

    function postSummit() {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/exam/submit`;
        return fetch(
            url,
            {
                method: 'POST',
                body: JSON.stringify({
                    "exam_submit_id": question?.examSummit.id,
                    'answer_list': summitExam
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': generateToken(accessData.accountId!, accessData.accessToken!),
                },
            })
            .then((res) => getPointShow())
            .catch((err) => console.log(err));
    };


    function getPointShow() {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/exam/score/${question?.examSummit.id}`;
        return fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': generateToken(accessData.accountId!, accessData.accessToken!),
                },
            })
            .then((res) => res.json())
            .then((res) => {
                setGetPoint(res.data.score);
                setFullPoint(res.data.total);
            })
            .catch((err) => console.log(err));
    }

    function chooseSection(index: number) {
        setCurrentSection(index);
        scroll(index);
    }

    function chooseChoice(index: number, choiceID: string) {
        chooseSection(index);
        let changeChoice = summitExam;
        summitExam[index].choice_id = choiceID;
        setSummitExam(changeChoice);
        if (index + 1 !== currentSection) {
            chooseSection(index + 1);
        }
    }

    const scroll = (index: number) => {
        if (examContainer !== null) {
            examContainer.current?.scroll({ left: (index) * 421 });
        }
    };

    function summit() {
        if (checkAnswer) {
            if (accessData.accessToken) {
                if (!transaction.isPremium) {
                    setShowConfirmPayment(true);
                } else {
                    MixpanelTracking.completeExam(exam?.id, exam?.name, exam?.subject, exam?.subjectName, exam?.questionSetId, accessData.account.grade);
                    postSummit();
                    setSendSummit(true);
                }
            }
        } else {
            let check = summitExam.findIndex(e => e.choice_id === "");
            if (check >= 0 && summitExam.length > 0) {
                chooseSection(check);
            }
        }
    }

    function toAnswer() {
        MixpanelTracking.watchAnswerExam(exam?.id, exam?.name, exam?.subject, exam?.subjectName, exam?.questionSetId, accessData.account.grade);
        window.location.href = '/examanswer/' + grade + "/" + quarter + "/" + id + "/" + question?.examSummit.id;
    }

    return <>
        <div className='col-12 bg-grey' style={{ display: "flex", margin: "auto", justifyContent: "center", flexWrap: "wrap" }}>
            <div className='bg-white col-11'
                style={{ display: "flex", justifyContent: "center", marginTop: "20px", borderRadius: "10px", paddingTop: "20px", paddingBottom: "20px", marginBottom: "20px" }}>
                <div className='col-12'>
                    <div style={{ display: "inline-flex" }}>
                        <div className='start-text'>เริ่มสอบ</div>
                        <div className="video-tag-info exam-tag-info">
                            {
                                gradeState.grades.find((g) => {
                                    return exam?.grade === g.grade;
                                })?.descriptionTh
                            }
                        </div>
                        <div className="video-tag-info exam-tag-info">{exam?.subjectTHName}</div>
                        <div className="video-tag-info exam-tag-info">{exam?.name}</div>
                        <div className="video-tag-info exam-tag-info">{question?.questionSet.questionList.length} ข้อ</div>
                    </div>
                    <div className='question-count-group'>
                        {
                            question?.questionSet.questionList.map((exam, index) => {
                                return (
                                    <div className={currentSection + "" === index + "" ? 'question-count select-count' : "question-count"}
                                        onClick={() => {
                                            chooseSection(index);
                                        }}>
                                        {index + 1}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div ref={examContainer} className="exam-section-container">
                        {
                            question?.questionSet.questionList.map((exam, index) => {
                                return (
                                    <div className={currentSection + "" === index + "" ? 'exam-section exam-section-select' : "exam-section"}>
                                        <div style={{ display: "flex" }}>
                                            <div className='select-count' >
                                                {index + 1}
                                            </div>
                                            <div className="exam-question-text">
                                                <Latex>{exam.description != null ? exam.description : ''}</Latex>
                                            </div>
                                        </div>
                                        {ImageUtility.isImage(exam.imageUrl) ? (
                                            <div className="question-image">
                                                <img className="w-100" src={exam.imageUrl} alt="" />
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <div>
                                            {exam.choiceList.map((a, i: number) => (

                                                <div className='exam-choice' style={{ display: "flex" }}
                                                    onClick={() => {
                                                        chooseChoice(index, a.id);
                                                    }}
                                                >
                                                    <div className={summitExam[index].choice_id === a.id ? 'choice-number choice-number-select' : "choice-number"}>{i + 1}</div>
                                                    <div className='choice-exam-value'><Latex>{a.value}</Latex></div>
                                                    <div className='choice-exam-value'>{ImageUtility.isImage(a.imagePath) ? (
                                                        <img className="image-choice w-100"
                                                            src={a.imagePath} alt="" />
                                                    ) : (
                                                        <></>
                                                    )}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="exam-section-none"></div>
                        <div className="exam-section-none"></div>
                    </div>
                    <div className="col-12 exam-summit">
                        <div className={checkAnswer ? 'exam-summit-btn col-2' : "exam-summit-btn col-2 exam-summit-btn-disable"}
                            onClick={() => { summit(); }}
                        >ส่งคำตอบ
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <Modal dialogClassName="w-25" show={sendSummit}>
            <Modal.Body className='point-modal'>
                <div style={{ textAlign: "right" }} hidden>
                    <img style={{ cursor: "pointer" }} alt="" src={close} onClick={() => { setSendSummit(false) }}></img></div>
                <img src={certificate} alt="" style={{
                    margin: "10px"
                }}></img>
                <div className="exam-point-summit">{getPoint}/{fullPoint}</div>
                <div className='exam-point-summit-text'>คะแนน</div>
                <div className='exam-back-btn col-6' onClick={() => {
                    window.location.href = '/exam/' + grade + "/" + quarter;
                }}>กลับไปห้องสอบ</div>
                <div className='exam-ans-btn'
                    onClick={() => { toAnswer() }}
                >ดูเฉลย</div>
            </Modal.Body>
        </Modal>

        <Modal dialogClassName="w-25" show={showConfirmPayment && !transaction.waitingPayment} centered onHide={() => setShowConfirmPayment(false)}>
            <Modal.Body>
                <div className="row p-2">
                    <div className="col-12 mb-4 na-no-hover-text-nomal">ต้องการสมัครใช้บริการหรือไม่</div>
                    <div className="col-6"></div>
                    <div className="col-3 na-text-accept can-click" onClick={() => {
                        window.location.href = `${process.env.REACT_APP_PAY_PORTAL}`;
                    }}>สมัคร</div>
                    <div className="col-3 na-text-cancel can-click" onClick={() => { setShowConfirmPayment(false) }}>ยกเลิก</div>
                </div>
            </Modal.Body>
        </Modal>
        <Modal dialogClassName="w-25" show={showConfirmPayment && transaction.waitingPayment} centered onHide={() => setShowConfirmPayment(false)}>
            <Modal.Body>
                <div className="row p-2">
                    <div className="col-12 mb-4 na-no-hover-text-nomal">รอยืนยันการชำระเงิน</div>
                    <div className="col-6"></div>
                    <div className="col-3"></div>
                    <div className="col-3 na-text-cancel can-click" onClick={() => { setShowConfirmPayment(false) }}>ตกลง</div>
                </div>
            </Modal.Body>
        </Modal>
    </>;



}

export default Exam;
