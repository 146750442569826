import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './PaymentCardList.css';
import { generateToken } from '../../utility/Token';
import Button from 'react-bootstrap/Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import mastercardLogo from '../../images/omise/card-logo-mastercard.svg';
import visaLogo from '../../images/omise/card-logo-visa.svg';
import jcbLogo from '../../images/omise/card-logo-jcb.svg';
import unknownLogo from '../../images/omise/card-logo-unknown.svg';
import { fetchCardList } from '../../stores/payment-management/PaymentManagement';

const PaymentCardList = () => {
    const dispatch = useDispatch();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const paymentManagementState: PaymentManagement = useSelector((state: NaTypes.Store) => state.paymentManagement);

    useEffect(() => {
        dispatch(fetchCardList(accessData));
    }, [accessData, dispatch]);

    const deleteCard = (customerId: string | null, cardId: string) => {
        const at = accessData.accessToken ? accessData.accessToken : '';
        const aid = accessData.accountId ? accessData.accountId : '';

        fetch(`${process.env.REACT_APP_API_URL_V2}/v1/omise/card/${customerId}/${cardId}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': generateToken(aid, at),
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.error === 0) dispatch(fetchCardList(accessData));
            });
    };

    const cancelSubscribeConfirm = (customerId: string | null, cardId: string) => {
        confirmAlert({
            customUI: ({ onClose }) => { return getConfirmLayout(onClose, customerId, cardId); }
        });
    };

    const getConfirmLayout = (onClose: Function, customerId: string | null, cardId: string) => {
        return (
            <div className='delete-confirm-custom-ui'>
                <h1>ต้องการลบบัตรหรือไม่?</h1>
                <Button variant="secondary" onClick={() => { onClose(); }}>ไม่</Button>
                <Button variant="danger" onClick={() => {
                    deleteCard(customerId, cardId);
                    onClose();
                }}>ใช่</Button>
            </div>
        );
    };

    return (
        <div className="payment-card-list-container">
            {paymentManagementState.pending ? <></> :
                <>
                    {(paymentManagementState.cardList.length !== 0 && accessData.accountId) ? (
                        <>
                            {paymentManagementState.cardList.map((card) => (
                                <div key={card.id} className="row payment-card-list-item-container">
                                    <div className="col-12 col-md-2">
                                        <div className="payment-card-list-item-type-container">
                                            {(card.brand === 'Visa' || card.brand === 'MasterCard' || card.brand === 'JCB') ? <>
                                                {card.brand === 'Visa' && <img className="w-100" src={visaLogo} alt="Visa" />}
                                                {card.brand === 'MasterCard' && <img className="w-100" src={mastercardLogo} alt="MasterCard" />}
                                                {card.brand === 'JCB' && <img className="w-100" src={jcbLogo} alt="JCB" />}
                                            </> :
                                                <>
                                                    <img className="w-100" src={unknownLogo} alt="unknownCard" />
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-10">
                                        <div className="row h-100">
                                            <div className="col-12 col-md-10 p-0">
                                                <div className="payment-card-list-item-info-container">
                                                    <p className="payment-card-list-item-info-text">{card.bank}</p>
                                                    <p className="payment-card-list-item-info-text">xxxx xxxx xxxx {card.last_digits}</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-2 p-0">
                                                <div className="payment-card-list-item-info-container">
                                                    <Button className="w-100" variant="outline-danger"
                                                        onClick={() => {
                                                            cancelSubscribeConfirm(paymentManagementState.customerId, card.id);
                                                        }}>ลบ</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            }
                        </>
                    ) : (
                        <>
                            <div className="row payment-card-list-item-container">
                                <p className="payment-card-list-empty-item m-0">ไม่มีข้อมูลการผูกบัตร</p>
                            </div>
                        </>
                    )}
                </>
            }
        </div>
    );
};

export default PaymentCardList;
