import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { BigPlayButton, ControlBar, Player } from 'video-react';
import HLSSource from './HLSSource';
import '../../../../node_modules/video-react/dist/video-react.css';
import logo from '../../../images/logo/new_logo_nock.png';



const VideoPlayer = forwardRef((props: { fileList: File[] }) => {

    const player = useRef<VideoPlayerRefs>(null);
    const [currentVideo, setCurrentVideo] = useState<any>(null);



    useEffect(() => {
        setCurrentVideo(props.fileList.find((f: any) => f.quality === 'hls'));
    }, [props.fileList]);



    return (
        <>
            {currentVideo ? (
                <Player key={currentVideo.link} ref={player} width="100%" height="100%">
                    <HLSSource
                        isVideoChild
                        src={currentVideo.link ? currentVideo.link : ''}
                    />
                    <BigPlayButton position="center" />
                </Player>
            ) : (
                <Player ref={player} poster={logo}>
                    <ControlBar disableCompletely={true} />
                </Player>
            )}
        </>
    );
});

VideoPlayer.displayName = 'VideoPlayer';
export default VideoPlayer;
