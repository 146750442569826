import React from 'react';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';



const HeaderBarMenu = () => {
    return (
        <Nav>
            <Link to="/" className="menu-container">
                <p className="menu rainbow_text_animated">คลิป</p>
            </Link>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://nockacademy.com/course/"
                className="menu-container"
            >
                <p className="menu rainbow_text_animated">คอร์สของเรา</p>
            </a>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://nockacademy.com/article/"
                className="menu-container"
            >
                <p className="menu rainbow_text_animated">บทความ</p>
            </a>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://nockacademy.com/faq"
                className="menu-container"
            >
                <p className="menu rainbow_text_animated">คำถามที่พบบ่อย</p>
            </a>
        </Nav>
    );
};

export default HeaderBarMenu;
