const initialState: NaTypes.SkipQuestion = {
  questionIndex: -1,
  showAt: -1,
  isEnabled: true,
};

const SKIP_TO_QUESTION = 'SKIP_TO_QUESTION';
const skipToQuestion = (questionIndex: number, showAt: number, isEnabled: boolean) => {
  return {
    type: SKIP_TO_QUESTION,
    questionIndex: questionIndex,
    showAt: showAt,
    isEnabled: isEnabled,
  };
};

export {
  skipToQuestion,
};

/**
 * Skip Question state
 * @param {NaTypes.SkipQuestion} state
 * @param {any} action
 * @return {NaTypes.SkipQuestion} current state
 */
export function SkipQuestionState(
    state: NaTypes.SkipQuestion = initialState,
    action: any): NaTypes.SkipQuestion {
  switch (action.type) {
    case SKIP_TO_QUESTION:
      return Object.assign({}, state, {
        questionIndex: action.questionIndex,
        showAt: action.showAt,
        isEnabled: action.isEnabled,
      });
    default:
      return state;
  }
};
