import React, { useState, useEffect, useRef } from 'react';
import VideoPlayer from '../video-player/VideoPlayer';
import './VideoContent.css';
import QuizPopUp from '../quiz-pop-up/QuizPopUp';
import { useDispatch, useSelector } from 'react-redux';
import { skipToQuestion } from '../../stores/skip-question/SkipQuestion';

const VideoContent = (props: {
  chapter: NaTypes.Chapter,
  video: NaTypes.Video | undefined,
  enablePopQuiz?: boolean,
  canPlayHandler: Function,
  playHandler: Function,
  pauseHandler: Function,
  lock?: boolean,
  lockHandle?: Function,
}) => {
  const dispatch = useDispatch();
  const playerRef = useRef<VideoPlayerRefs>();
  const questionRef = useRef<QuestionRefs>();
  const skipQuestionState: NaTypes.SkipQuestion = useSelector((state: NaTypes.Store) => state.skipQuestion);
  const [isChose, setIsChose] = useState<boolean>(false);
  const [secNow, setSecNow] = useState<number>(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsChose(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [isChose]);

  useEffect(() => {
    if (skipQuestionState.showAt !== -1) {
      playerRef.current?.seek(skipQuestionState.showAt);
    }
  }, [dispatch, skipQuestionState]);

  /**
    * get video from vimeo
    * @param {string} id
    * @return {Promise<any>}
    */
  async function getVimeo(id: string | undefined): Promise<any> {
    try {
      const res = await fetch(`https://api.vimeo.com/videos/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_VIMEO_AUTH}`,
          'Content-Type': 'application/json',
        },
      });
      return await res.json();
    } catch (e) {
      return console.log(e);
    }
  };
  const [videoList, setVideoList] = useState<Array<any>>([]);
  useEffect(() => {
    setVideoList([]);
    getVimeo(props.video?.videoRef)
      .then((res) => {
        setVideoList(res.files);
      })
      .catch(() => { });
  }, [props.video]);

  /**
   * handle video time
   * @param {number} sec second
   */
  function currentTimeHandler(sec: number) {
    const index = props.video?.questionList
      .findIndex((q: NaTypes.Question) => q.showAt === sec);
    setSecNow(sec);
    if (props.enablePopQuiz && typeof index === 'number' && index >= 0 && !isChose && secNow != sec) {
      questionRef.current?.showQuestion(props.video?.questionList[index], index + 1);
      playerRef.current?.pause();
    }
  }

  return (
    <>
      {
        props.enablePopQuiz ? (
          <QuizPopUp ref={questionRef} chapter={props.chapter} onClose={() => {
            playerRef.current?.play();
            dispatch(skipToQuestion(skipQuestionState.questionIndex, -1, true));
          }} />
        ) : ''
      }
      <VideoPlayer
        ref={playerRef}
        fileList={videoList}
        currentTimeHandler={currentTimeHandler}
        canPlayHandler={props.canPlayHandler}
        playHandler={props.playHandler}
        pauseHandler={props.pauseHandler}
        lock={props.lock}
        lockHandle={props.lockHandle}
      />
    </>
  );
};

export default VideoContent;
