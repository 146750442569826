import React from 'react';
import crownGrey from '../../images/crown/crown-grey.svg';
import crownGold from '../../images/crown/crown-gold.svg';
import whiteCorrect from '../../images/correct/white_correct.svg';



const ExamListItem = (props: {
    quarterExam: NaTypes.QuaterExam,
    onExamItemClick: (quarterExam: NaTypes.QuaterExam) => void,
    subjectState: NaTypes.SubjectState,
    filterState: NaTypes.Filter,
    index: number,
}) => {
    return (
        <div
            onClick={() => {
                props.onExamItemClick(props.quarterExam);
            }}
            className="item-video col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
            key={`exam-item-${props.index}`}
        >
            <div className='exam-container'>
                {props.quarterExam.image && <img className="video-image" src={props.quarterExam.image} alt="" />}
                <p className="topic-name exam-name">{props.quarterExam.title}</p>

                <div className="video-tag-group">

                    <div className="exam-list-container">
                        <div>
                            <img className="video-tag-status-image" src={props.quarterExam.complete ? crownGold : crownGrey} alt="" />
                        </div>

                        {props.quarterExam.sectionIsFree == 1 && <div className="video-tag-info video-tag-info-ls" id="free"><span>Free</span></div>}

                        <div className="exam-list-tag">
                            <img className="exam-list-checkmark" src={whiteCorrect} alt="" />
                            <span>{props.quarterExam.questionNum} ข้อ</span>
                        </div>
                        <div className="exam-list-tag">
                            <span className="exam-grade">
                                {props.quarterExam.grades !== null ? (
                                    <span>{props.quarterExam.grades}</span>
                                ) : (
                                    <span>{props.quarterExam.grade}</span>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="exam-subject-tags-container">
                    {props.quarterExam.subjectNames?.map((sub, index) => (
                        <div key={`subject-${sub}-${index}`} className="video-tag-info video-tag-info-ls exam-subject-tag">
                            <span>{props.subjectState.subjects.find((g) => sub === g.subjectId)?.descriptionTh}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ExamListItem;
