import React, { useEffect, useRef, useState } from 'react';
import NaTypes from '../../types';
import VideoHistoryPlayer from '../video-history-player/VideoHistoryPlayer';
import './VideoHistory.css';



const VideoHistory = (props: {
    activeQuestion: number | null,
    enablePopQuiz?: boolean,
    lock?: boolean,
    lsVideoHistory: NaTypes.LiveStreamHistory,
    questionList: any,
    sameQuestion: boolean,
    currentTimeHandler: (sec: number) => void,
    lockHandle?: () => void,
}) => {

    const playerRef = useRef<VideoPlayerRefs>();
    const [videoList, setVideoList] = useState<Array<any>>([]);



    const getVimeo = async (id: string | undefined) => {
        try {
            const res = await fetch(`https://api.vimeo.com/videos/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_VIMEO_AUTH}`,
                    'Content-Type': 'application/json',
                },
            });
            return await res.json();
        } catch (e) {
            console.error(e);
        }
    };

    const canPlayHandler = (player: any) => {
    };

    const playHandler = () => {
    };

    const pauseHandler = () => {
    };



    useEffect(() => {
        setVideoList([]);
        if (props.lsVideoHistory.video_id) {
            getVimeo(props.lsVideoHistory?.video_id)
                .then((res) => {
                    setVideoList(res.files);
                })
                .catch(() => { });
        }
    }, [props.lsVideoHistory]);

    useEffect(() => {
        const { activeQuestion, questionList } = props;
        if (activeQuestion != null && questionList?.[activeQuestion]?.start != null) {
            playerRef.current?.seek(questionList[activeQuestion].start);
        }
    }, [props.activeQuestion, props.sameQuestion]);



    return (
        <VideoHistoryPlayer
            ref={playerRef}
            fileList={videoList}
            currentTimeHandler={props.currentTimeHandler}
            canPlayHandler={canPlayHandler}
            playHandler={playHandler}
            pauseHandler={pauseHandler}
            lock={props.lock}
            lockHandle={props.lockHandle}
        />
    );
};

export default VideoHistory;
