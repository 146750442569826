import {generateToken} from '../../utility/Token';
import queryString from 'query-string';

const initialState: NaTypes.ContinueWatching = {
  pending: false,
  topics: [],
};

const FETCH_CONTINUE_WATCHING_LIST_PENDING = 'FETCH_CONTINUE_WATCHING_LIST_PENDING';
const fetchContinueWatchingListPending = () => {
  return {
    type: FETCH_CONTINUE_WATCHING_LIST_PENDING,
  };
};

const FETCH_CONTINUE_WATCHING_LIST_SUCCESS = 'FETCH_CONTINUE_WATCHING_LIST_SUCCESS';
const fetchContinueWatchingListSuccess = (topics: Array<NaTypes.Lesson>) => {
  return {
    type: FETCH_CONTINUE_WATCHING_LIST_SUCCESS,
    topics,
  };
};

const RESET_CONTINUE_WATCHING_LIST = 'RESET_CONTINUE_WATCHING_LIST';
const resetContinueWatchingList = () => {
  return {
    type: RESET_CONTINUE_WATCHING_LIST,
  };
};

export {
  getContinueWatching,
};

/**
 * Continue watching state
 * @param {NaTypes.ContinueWatching} state
 * @param {any} action
 * @return {NaTypes.ContinueWatching} current state
 */
export function ContinueWatchingState(
    state: NaTypes.ContinueWatching = initialState,
    action: any): NaTypes.ContinueWatching {
  switch (action.type) {
    case FETCH_CONTINUE_WATCHING_LIST_PENDING:
      return Object.assign({}, state, {
        pending: true,
      });
    case FETCH_CONTINUE_WATCHING_LIST_SUCCESS:
      return Object.assign({}, state, {
        pending: false,
        topics: action.topics,
      });
    case RESET_CONTINUE_WATCHING_LIST:
      return initialState;
    default:
      return state;
  }
};

/**
 * get continue watching list
 * @param {NaTypes.Authenticate} accessData
 * @return {Function}
 */
function getContinueWatching(accessData: NaTypes.Authenticate): Function {
  return function(dispatch: any) {
    dispatch(fetchContinueWatchingListPending());
    if (typeof accessData.accountId !== 'string' || typeof accessData.accessToken !== 'string') {
      dispatch(resetContinueWatchingList());
    } else {
      fetchContinueWatchingList(accessData)?.then((topics) => {
        dispatch(fetchContinueWatchingListSuccess(topics));
      });
    }
  };
}

const fetchContinueWatchingList = (accessData: NaTypes.Authenticate) => {
  const token = generateToken(accessData.accountId!, accessData.accessToken!);
  const params = {
    'accountId': accessData.accountId,
    'access_token': token,
  };
  const queryParams = queryString.stringify(params);
  return fetch(`${process.env.REACT_APP_API_URL}/api/v6/method/vod.get.continue.watching?` + queryParams,
      {
        method: 'GET',
        headers: new Headers({}),
      })
      .then((res) => res.json())
      .then((res) => {
        return res.topics.map((d: any, index: number): NaTypes.Lesson => {
          return {
            index,
            topicId: d.topic_id,
            subject: d.subject_id,
            grade: d.grade,
            name: d.description,
            thumbnail: d.chapters[0].videos[0].video_thumbnail_hd_url,
            videoSize: d.chapters.length * 2,
            isFree: d.chapters.every((c: any) => c.is_free === '1'),
            isCompleted: d.chapters.every((c: any) => c.is_point === '1'),
            chapterList: d.chapters.map((c: any): NaTypes.Chapter => ({
              topicId: c.topic_id,
              chapterId: c.chapter_id,
              subject: c.subject_id,
              grade: c.grade,
              topicDescription: c.topic_description_th,
              description: c.description,
              isPoint: parseInt(c.is_free),
              isFree: parseInt(c.is_free),
              stepVOD: parseInt(c.is_free),
              videoList: c.videos.map((v: any): NaTypes.Video => {
                return {
                  host: v.video_host,
                  videoRef: v.video_id,
                  thumbnail: {
                    normal: v.video_thumbnail_url,
                    hd: v.video_thumbnail_hd_url,
                  },
                  documentList: [v.document_url],
                  questionList: v.questions.map((q: any): NaTypes.Question => ({
                    id: q.id,
                    imagePath: q.image_path,
                    value: q.value,
                    lsId: q.ls_id,
                    showAt: parseInt(q.show_at),
                    answerList: q.answers.map((a: any): NaTypes.Answer => ({
                      id: a.id,
                      imagePath: a.image_path,
                      value: a.value,
                      isCorrect: a.is_correct === '1' ? true : false,
                      questionId: a.question_id,
                    })),
                  })),
                  duration: (v.video_duration == null) ? 0 : parseInt(v.video_duration),
                };
              }),
            })),
          };
        });
      })
      .catch((err) => console.log(err));
};
