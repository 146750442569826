import React, {
  useEffect, useState, useRef, forwardRef, useImperativeHandle,
} from 'react';
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
  BigPlayButton,
  PlayToggle,
} from 'video-react';
import '../../../node_modules/video-react/dist/video-react.css';
import logo from '../../images/logo/new_logo_nock.png';
import playWhite from '../../images/play/play_white.svg';
import HLSSource from './HLSSource';

export type currentTimeCallback = (sec: number) => void
export interface VideoPlayerProps {
  fileList: any,
  currentTimeHandler: Function,
  canPlayHandler: Function,
  playHandler: Function,
  pauseHandler: Function,
  isLiveStream?: boolean,
  miniSize?: boolean,
  muted?: boolean,
  imagePreview?: string,
  lock?: boolean,
  lockHandle?: Function,
  autoplay?:boolean
}

const VideoPlayer = forwardRef((
  props: VideoPlayerProps,
  ref) => {
  const [currentVideo, setCurrentVideo] = useState<any>(null);
  const player = useRef<VideoPlayerRefs>(null);

  useEffect(() => {
    setCurrentVideo(props.fileList.find((f: any) => f.quality === 'hls'));
    return () => { };
  }, [props.fileList]);

  useImperativeHandle(ref, (): VideoPlayerRefs => ({
    pause: () => {
      player.current?.pause();
    },
    play: () => {
      player.current?.play();
    },
    getState: () => {
      return player.current?.getState();
    },
    seek: (seconds) => {
      player.current?.seek(seconds);
    },
    subscribeToStateChange: (listener) => {
      player.current?.subscribeToStateChange(listener);
    },
  }));

  if (player && props.lock) {
    player.current?.subscribeToStateChange((state, prevState) => {
      if (!state.paused && prevState.paused) {
        player.current?.pause();
        if (props.lockHandle) {
          props.lockHandle();
        }
      }
    });
  }

  const getSource = () => {
    return <HLSSource
      isVideoChild
      src={currentVideo.link ? currentVideo.link : ''}
    />;
  };

  class VideoTime {
    private second: number = -1;

    /**
     * constructor for create with callback
     * @param {Function} onUpdate callback when time update
     */
    constructor(private onUpdate: (sec: number) => void) { }

    /**
     * update second when second not same
     * @param {number} sec second
     */
    updateTime(sec: number) {
      if (this.second !== sec) {
        this.second = sec;
        this.onUpdate(this.second);
      }
    }
  }

  const time = new VideoTime((sec) => {
    props.currentTimeHandler(sec);
  });

  return (
    <>
      {props.lock ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
          player?.current?.play();
        }}>
          <Player ref={player}
            poster={logo}>
            <ControlBar disableCompletely={true}></ControlBar>
          </Player>
          <div style={{ position: 'absolute' }}>
            <img style={{ width: '50px', height: '50px', cursor: 'pointer' }} src={playWhite} alt="" />
          </div>
        </div>
      ) : currentVideo ? (
        <>
          <Player key={currentVideo.link} ref={player} width="100%" height="100%"
            poster={props.imagePreview}
            onTimeUpdate={(e: { currentTarget: { currentTime: { toString: () => string; }; }; }) => {
              time.updateTime(parseInt(e.currentTarget.currentTime.toString()));
            }}
            onCanPlay={() => {
              props.canPlayHandler(player.current?.getState());
            }}
            onPlay={props.playHandler}
            onPause={props.pauseHandler}
            muted={props.muted}
          >
            {
              getSource()
            }

            {props.miniSize ?
              <ControlBar disableDefaultControls={true}>
                <PlayToggle />
                <VolumeMenuButton />
              </ControlBar> :
              <ControlBar>
                <ReplayControl seconds={10} order={1.1} />
                <ForwardControl seconds={10} order={1.2} />
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.75, 0.5]} order={7.1} />
              </ControlBar>
            }
            <BigPlayButton position="center" />
          </Player>
        </>
      ) : (
        <></>
      )}
    </>
  );
});
VideoPlayer.displayName = 'VideoPlayer';
export default VideoPlayer;
