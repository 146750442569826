import React, { useState, useEffect, useRef } from 'react';
import './VideoExplain.css';
import NaTypes from '../../types';
import VideoExplainPlayer from '../video-history-player/VideoHistoryPlayer';


const VideoExplain = (props: {
  lsVideoExplain: NaTypes.ExplanationVideo;
  enablePopQuiz?: boolean,
  canPlayHandler: Function,
  currentTimeHandler: Function,
  playHandler: Function,
  pauseHandler: Function,
  lock?: boolean,
  lockHandle?: Function,
}) => {
  const playerRef = useRef<VideoPlayerRefs>();
  const [videoList, setVideoList] = useState<Array<any>>([]);

  /**
    * get video from vimeo
    * @param {string} id
    * @return {Promise<any>}
    */
  async function getVimeo(id: string | undefined): Promise<any> {
    try {
      const res = await fetch(`https://api.vimeo.com/videos/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_VIMEO_AUTH}`,
          'Content-Type': 'application/json',
        },
      });
      return await res.json();
    } catch (e) {
      return console.log(e);
    }
  };

  useEffect(() => {
    setVideoList([]);
    getVimeo(props.lsVideoExplain?.videoId)
      .then((res) => {
        setVideoList(res.files);
      })
      .catch(() => { });
  }, [props.lsVideoExplain]);

  return (
    <>
      <VideoExplainPlayer
        ref={playerRef}
        fileList={videoList}
        currentTimeHandler={props.currentTimeHandler}
        canPlayHandler={props.canPlayHandler}
        playHandler={props.playHandler}
        pauseHandler={props.pauseHandler}
        lock={props.lock}
        lockHandle={props.lockHandle}
      />
    </>
  );
};

export default VideoExplain;
