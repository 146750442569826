import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowingBoard } from '../../stores/notice-board/NoticeBoardState';

import closeImage from '../../images/actions/close_white_no_bg.svg';
import announcementImage from '../../images/promote/annousment.svg';
import './NoticeBoard.css';

interface NoticeBoard {
    description: string;
    id: number;
    status: string;
    title: string;
}

interface NoticeBoardData {
    notice_boards: NoticeBoard[];
}



const NoticeBoard = () => {
    const dispatch = useDispatch();
    const showing = useSelector((state: NaTypes.Store) => state.noticeBoard.showing);
    const [data, setData] = useState<NoticeBoardData | undefined>();



    const fetchNoticeBoard = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v5/method/noticeBoard.get`);
            if (!res.ok) {
                throw new Error('Failed to fetch notice board');
            }
            const jsonData = await res.json();
            setData(jsonData);
        } catch (error) {
            console.error('Error fetching notice board:', error);
        }
    };



    useEffect(() => {
        fetchNoticeBoard();
    }, []);



    return data?.notice_boards?.[0]?.description && showing ? (
        <div className="notice-board-container">
            <img className="notice-board-megaphone" src={announcementImage} alt="Notice Board Megaphone" />
            <span className="notice-board-text">{data.notice_boards[0].description}</span>
            <img
                className="notice-board-close-button"
                src={closeImage}
                alt="Notice Board's Close Button"
                onClick={() => {
                    dispatch(setShowingBoard(false));
                }}
            />
        </div>
    ) : null
};

export default NoticeBoard;
