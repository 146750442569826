import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LiveStreamPlaybackTopicList } from '../../types';
import { getLSPlaybackTopicList } from './LiveStreamPlaybackTopicRecentTableUtils';
import './LiveStreamPlaybackTopicRecentTable.css';

import {
  LiveStreamPlaybackTopicRecentTableLoading,
  LiveStreamPlaybackTopicRecentTableHeader,
  LiveStreamPlaybackTopicRecentTableItems,
} from './LiveStreamPlaybackTopicRecentTableRenders';



const LiveStreamPlaybackTopicRecentTable = () => {
  const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
  const filterState = useSelector((state: NaTypes.Store) => state.filter);
  const gradeState = useSelector((state: NaTypes.Store) => state.grade);
  const subjectState = useSelector((state: NaTypes.Store) => state.subject);

  const [LiveStreamPlaybackTopicList, setLiveStreamPlaybackTopicList] = useState<LiveStreamPlaybackTopicList>([]);
  const [redirect, setRedirect] = useState<any>();
  const [scroll, setScroll] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();
  const liveStreamListContainer = useRef<HTMLDivElement>(null);



  const scrollContainer = (direction: number) => {
    const container = liveStreamListContainer.current;
    if (!container) return;

    const scrollWidth = container.scrollWidth;
    const clientWidth = container.clientWidth;
    const maxScroll = scrollWidth - clientWidth;
    let newScroll = container.scrollLeft;

    if (direction === 0) {
      newScroll -= 300;
    } else if (direction === 1) {
      newScroll = Math.min(newScroll + clientWidth, maxScroll);
    }

    container.scroll({ left: newScroll });
    setScroll(newScroll);
  };



  useEffect(() => {
    const { gradeId, subjectId, typeId } = filterState;

    const queryParams = [
      'page=1',
      'itemPerPage=10',
      gradeId ? `grade=${gradeId}` : '',
      subjectId ? `subject=${subjectId}` : '',
      typeId ? `type=${typeId}` : '',
    ];

    const searchParam = `?${queryParams.filter(param => param !== '').join('&')}`;

    getLSPlaybackTopicList(accessData, setLiveStreamPlaybackTopicList, searchParam);
  }, [filterState.gradeId, filterState.subjectId, filterState.typeId]);

  useEffect(() => {
    const container = liveStreamListContainer.current;

    if (!container) return;

    const { scrollWidth, clientWidth } = container;
    const finalWidth = scrollWidth - clientWidth;
    const interval = setInterval(() => {
      if (scroll === finalWidth) {
        container.scrollLeft = 0;
        setScroll(0);
      } else {
        scrollContainer(1);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [liveStreamListContainer, scroll, setScroll, scrollContainer]);



  if (accessData.accessToken) {
    if (redirect) {
      history.push(redirect);
      setRedirect(null);
    }
  }



  return !LiveStreamPlaybackTopicList ? (
    <LiveStreamPlaybackTopicRecentTableLoading />
  ) : LiveStreamPlaybackTopicList.length === 0 ? (
    <></>
  ) : (
    <div className="lesson-list-container my-card bg-white" style={{ borderRadius: '1vw' }}>
      <LiveStreamPlaybackTopicRecentTableHeader scrollContainer={scrollContainer} />
      <LiveStreamPlaybackTopicRecentTableItems
        liveStreamListContainerRef={liveStreamListContainer}
        LiveStreamPlaybackTopicList={LiveStreamPlaybackTopicList}
        subjectState={subjectState}
        gradeState={gradeState}
        dispatch={dispatch}
      />
    </div>
  )
};

export default LiveStreamPlaybackTopicRecentTable;
