import React, { useEffect, useRef, useState } from 'react';
import './Testimonal.css';
import rightArrow from '../../images/announcement/right.svg';
import image01 from '../../images/testimonal/testimonal01.webp';
import image02 from '../../images/testimonal/testimonal02.webp';
import image03 from '../../images/testimonal/testimonal03.webp';
import image04 from '../../images/testimonal/testimonal04.webp';
import image05 from '../../images/testimonal/testimonal05.webp';
import image06 from '../../images/testimonal/testimonal06.webp';
import image07 from '../../images/testimonal/testimonal07.webp';
import image08 from '../../images/testimonal/testimonal08.webp';
import image09 from '../../images/testimonal/testimonal09.webp';
import image10 from '../../images/testimonal/testimonal10.webp';
import image11 from '../../images/testimonal/testimonal11.webp';
import image12 from '../../images/testimonal/testimonal12.webp';
import image13 from '../../images/testimonal/testimonal13.webp';
import image14 from '../../images/testimonal/testimonal14.webp';
import image15 from '../../images/testimonal/testimonal15.webp';
import image16 from '../../images/testimonal/testimonal16.webp';
import image17 from '../../images/testimonal/testimonal17.webp';
import image18 from '../../images/testimonal/testimonal18.webp';
import image19 from '../../images/testimonal/testimonal19.webp';
import image20 from '../../images/testimonal/testimonal20.webp';
import image21 from '../../images/testimonal/testimonal21.webp';


const Testimonal = () => {
  const TestimonalListContainer = useRef<HTMLDivElement>(null);
  const [scroll, setScroll] = useState(0);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [scrollTotalPage, setScrollTotalPage] = useState(0);

  const imageList = [image01, image02, image03, image04, image05, image06, image07, image08, image09, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21]

  useEffect(() => {
    if (TestimonalListContainer != null) {
      const childElementCount = TestimonalListContainer.current!.childElementCount;
      if (window.innerWidth >= 1200) {
        const totalPageDouble = childElementCount / 3;
        const totalPageRoundUp = Math.ceil(totalPageDouble);
        setScrollTotalPage(totalPageRoundUp);
      } else 
      // if (window.innerWidth >= 768) 
      {
        const totalPageDouble = childElementCount / 2;
        const totalPageRoundUp = Math.ceil(totalPageDouble);
        setScrollTotalPage(totalPageRoundUp);
      } 
      // else {
      //   setScrollTotalPage(childElementCount/2);
      // }
    }
  }, [scroll, imageList]);

  useEffect(() => {
    const timeout = setInterval(() => {
      if (TestimonalListContainer != null) {
        const scrollWidth = TestimonalListContainer.current!.scrollWidth;
        const clientWidth = TestimonalListContainer.current!.clientWidth;
        const finalWidth = scrollWidth - clientWidth;
        if (scroll === finalWidth) {
          TestimonalListContainer.current?.scroll({ left: 0 });
          setScroll(0);
          setScrollIndex(0);
        } else {
          scrollRight();
        }
      }
    }, 6000);
    return () => {
      clearInterval(timeout);
    };
  });

  const scrollLeft = () => {
    if (TestimonalListContainer != null) {
      const clientWidth = TestimonalListContainer.current!.clientWidth;
      if ((scroll - clientWidth) < 0) {
        TestimonalListContainer.current?.scroll({ left: 0 });
        setScroll(0);
        setScrollIndex(0);
      } else {
        TestimonalListContainer.current?.scroll({ left: scroll - clientWidth });
        setScroll(scroll - clientWidth);
        setScrollIndex(scrollIndex - 1);
      }
    }
  };

  const scrollRight = () => {
    if (TestimonalListContainer != null) {
      const scrollWidth = TestimonalListContainer.current!.scrollWidth;
      const clientWidth = TestimonalListContainer.current!.clientWidth;
      const finalWidth = scrollWidth - clientWidth;
      if ((scroll + clientWidth) > finalWidth) {
        TestimonalListContainer.current?.scroll({ left: finalWidth });
        setScroll(finalWidth);
        setScrollIndex(scrollTotalPage - 1);
      } else {
        TestimonalListContainer.current?.scroll({ left: scroll + clientWidth });
        setScroll(scroll + clientWidth);
        setScrollIndex(scrollIndex + 1);
      }
    }
  };

  // const handleTestimonalAction = (action: string) => {
  //   window.open(action);
  // };

  const GetScrollIndexContent = () => {
    const rows = [];
    for (let i = 0; i < scrollTotalPage; i++) {
      rows.push(
        <div key={i} className={`announcement-scroll-index-point${scrollIndex === i ? '-active' : ''}`} />,
      );
    }
    return <>{rows}</>;
  };

  return (
    <div className="announcement-card" style={{marginBottom:"5vh"}}>
      <div className="live-stream-detail-title rainbow_text_animated" style={{ textAlign: "center", marginBottom: "15px" }}>
        ผลตอบรับจากผู้ใช้งานจริง
      </div>
      <div className="announcement-scrollable-container">
        <div className="announcement-left-button"
          onClick={scrollLeft}>
          <img className="announcement-left-icon" style={{ width: "18px", height: "30px" }} src={rightArrow} alt="" />
        </div>
        <div className="row announcement-list-container" ref={TestimonalListContainer}>
          {
            imageList.map((a, index) => {
              return (
                // col col-md-6 col-xl-4 col-sm-12 p-0 testimonal-item
                <div className="col-sm-6 col-md-6 col-xl-4 col-6 testimonal-item" key={"review-" + index}>
                  {
                    <img
                      className="testimonal-img"
                      src={a}
                      alt=""
                    />
                  }
                </div>
              );
            })
          }
        </div>
        <div className="announcement-right-button"
          onClick={scrollRight}>
          <img className="announcement-right-icon" style={{ width: "18px", height: "30px" }} src={rightArrow} alt="" />
        </div>
      </div>
      <div className="announcement-scroll-index-container">
        <GetScrollIndexContent />
      </div>
    </div>
  );
};

export default Testimonal;
