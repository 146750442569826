import {generateToken} from '../../utility/Token';

const initialState: PaymentManagement = {
  pending: false,
  customerId: null,
  cardList: [],
};

const FETCH_CARD_LIST_PENDING = 'FETCH_CARD_LIST_PENDING';
const fetchCardListPending = () => {
  return {
    type: FETCH_CARD_LIST_PENDING,
  };
};

const FETCH_CARD_LIST_SUCCESS = 'FETCH_CARD_LIST_SUCCESS';
const fetchCardListSuccess = (data: any) => {
  return {
    type: FETCH_CARD_LIST_SUCCESS,
    payload: data,
  };
};

/**
 * fetch card list
 * @param {Authenticate} authenticate
 * @return {Function}
 */
function fetchCardList(authenticate: NaTypes.Authenticate): Function {
  return function(dispatch: any) {
    if (authenticate.accountId === null || authenticate.accessToken === null) {
      return;
    }
    const userToken = generateToken(authenticate.accountId, authenticate.accessToken);
    dispatch(fetchCardListPending());
    fetch(`${process.env.REACT_APP_API_URL_V2}/v1/omise/card/list`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': userToken,
      },
    })
        .then((res) => res.json())
        .then((res) => {
          if (res.error === 0) {
            return dispatch(fetchCardListSuccess(res.data));
          } else {
            return dispatch(fetchCardListSuccess([]));
          }
        });
  };
}

export {
  fetchCardList,
};

/**
 * Payment management state
 * @param {PaymentManagement} state
 * @param {any} action
 * @return {PaymentManagement} current state
 */
export function PaymentManagementState(
    state: PaymentManagement = initialState,
    action: any): PaymentManagement {
  switch (action.type) {
    case FETCH_CARD_LIST_PENDING:
      return Object.assign({}, state, {
        pending: true,
      });
    case FETCH_CARD_LIST_SUCCESS:
      return Object.assign({}, state, {
        pending: false,
        customerId: action.payload.id,
        cardList: action.payload.cards.data,
      });
    default:
      return state;
  }
};
