import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import './Promote.css';



const Promote = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);

    const isPayMobile = location.pathname.endsWith('pay-mobile-guest');
    const isMobile = location.pathname.endsWith('mobile');



    return (
        accessData.accessToken || isPayMobile || isMobile ? null : (
            <div className="d-flex flex-column justify-content-center promote-container">
                <div className="promote1-title">
                    ลงทะเบียนฟรี! สมัครสมาชิกเพื่อชมไลฟ์สอนสดได้ทุกวันและชมคลิปได้ทันที
                </div>
                <div className="promote1-subtitle">
                    ( ไม่มีค่าใช้จ่าย สมัครง่าย ๆ ด้วยตัวเองใน 1 นาที )
                </div>
                <div className="promote-button-container">
                    <button className="promote1-button" onClick={() => dispatch(requestLogin())}>ลงทะเบียนฟรี</button>
                </div>
            </div>
        )
    );
};

export default Promote;
