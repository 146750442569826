import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLiveQuizPercentageById } from '../../stores/live-quiz-percentage/LiveQuizPercentageState';
import LiveHistoryQuizQuestion from './LiveHistoryQuizQuestion';
import LiveHistoryQuizAnswer from './LiveHistoryQuizAnswer';
import './LiveHistoryQuizPopUp.css';



const LiveHistoryQuizPopUp = forwardRef((_props: { livesteamHistory: NaTypes.LiveStreamHistory }, ref) => {

    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const LiveQuizPercentage = useSelector((state: NaTypes.Store) => state.liveQuizPercentage);
    const dispatch = useDispatch();
    const [choice, setChoice] = useState<string>();
    const [question, setQuestion] = useState<LiveHistoryQuestion | undefined>();
    const [sendingSubmit] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);



    useImperativeHandle(ref, (): LiveHistoryQuestionRefs => ({
        getCurrentQuestion: () => question,
        reset: () => {
            setShowAnswer(false);
            setChoice('');
            setShow(false);
        },
        setQuestion: (q) => setQuestion(q),
        showQuestion: () => setShow(true),
        showAnswer: () => {
            if (question) dispatch(fetchLiveQuizPercentageById(question.id));
            setShowAnswer(true);
            setShow(true);
        },
    }));



    return !show || !question ? null : (
        <div className="question-bg">
            <div className="row h-100 m-0" id="quiz-container">
                <LiveHistoryQuizQuestion question={question} setShow={setShow} />
                <LiveHistoryQuizAnswer
                    accessData={accessData}
                    choice={choice}
                    liveQuizPercentage={LiveQuizPercentage}
                    question={question}
                    sendingSubmit={sendingSubmit}
                    setChoice={setChoice}
                    setShow={setShow}
                    showAnswer={showAnswer}
                />
            </div>
        </div>
    );
});



LiveHistoryQuizPopUp.displayName = 'LiveQuizHistoryPopUp';

export default LiveHistoryQuizPopUp;
