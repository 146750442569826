import React from 'react';
import UseApp from '../use-app/UseApp';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import HeaderBarBrand from './HeaderBarBrand';
import HeaderBarMenu from './HeaderBarMenu';
import HeaderBarUser from './HeaderBarUser';
import './HeaderBar.css';



const HeaderBar = () => {
    return (
        <>
            <UseApp />
            <Navbar id="headerbar" expand="lg">

                <Navbar.Brand>
                    <HeaderBarBrand />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto" style={{ minWidth: '30%' }}>
                    </Nav>
                    <HeaderBarMenu />
                    <HeaderBarUser />
                </Navbar.Collapse>

            </Navbar>
        </>
    );
};

export default HeaderBar;
