import { generateToken } from '../../utility/Token';



export const getLSPlaybackList = async (
    accessData: NaTypes.Authenticate,
    searchParams: string,
    setLiveStreamHistoryFilter: React.Dispatch<React.SetStateAction<NaTypes.LiveStreamPlaybackTopicList>>,
    setLoadAble: React.Dispatch<React.SetStateAction<boolean>>,
    setLoadingMore: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    try {
        setLiveStreamHistoryFilter([]);

        const token = accessData.accountId
            ? generateToken(accessData.accountId!, accessData.accessToken!)
            : "";

        const baseUrl = process.env.REACT_APP_API_URL_V2;
        const url = `${baseUrl}/v1/live-stream/lsplayback/topic?${searchParams}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "token": token
            },
        });

        if (response.ok) {
            const res = await response.json();
            setLoadingMore(false);
            setLiveStreamHistoryFilter(res.data);
            setLoadAble(res.data.length !== 0 && res.data.length % 18 === 0);
        } else {
            throw new Error(`HTTP error: ${response.status} - ${response.statusText}`);
        }
    } catch (error) {
        console.error("An error occurred:", error);
    }
}



export const getLSPlaybackListLoadMore = async (
    accessData: NaTypes.Authenticate,
    filterState: NaTypes.Filter,
    liveStreamHistoryFilter: NaTypes.LiveStreamPlaybackTopicList,
    paging: number,
    searchText: string,
    setLiveStreamHistoryFilter: React.Dispatch<React.SetStateAction<NaTypes.LiveStreamPlaybackTopicList>>,
    setLoadAble: React.Dispatch<React.SetStateAction<boolean>>,
    setLoadingMore: React.Dispatch<React.SetStateAction<boolean>>,
    setPaging: React.Dispatch<React.SetStateAction<number>>,
) => {
    try {
        setLoadingMore(true);

        const page = paging + 1;
        const itemPerPage = 18;
        setPaging(page);

        const baseUrl = process.env.REACT_APP_API_URL_V2;
        const searchParams = new URLSearchParams({
            page: String(page),
            itemPerPage: String(itemPerPage),
            ...(filterState.gradeId && { grade: filterState.gradeId }),
            ...(filterState.subjectId && { subject: filterState.subjectId }),
            ...(filterState.typeId && { type: filterState.typeId }),
            ...(searchText && { keyword: searchText }),
        });

        const url = `${baseUrl}/v1/live-stream/lsplayback/topic?${searchParams.toString()}`;

        const token = accessData.accountId && accessData.accessToken
            ? generateToken(accessData.accountId, accessData.accessToken)
            : '';

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': token
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }

        const data = await response.json();
        setLoadingMore(false);
        setLiveStreamHistoryFilter(liveStreamHistoryFilter.concat(data.data));
        setLoadAble(data.data.length !== 0 && data.data.length % itemPerPage === 0);
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
}
