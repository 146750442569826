import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { requestLogin, clearAccessData } from '../../stores/authorization/AuthorizationState';
import { resetLessonSet } from '../../stores/lesson-list/LessonSetListState';
import dropDownImg from '../../images/other/dropdown.svg';
import UserProfileIcon from "../../images/user/user.svg";



const HeaderBarUser = () => {
    const dispatch = useDispatch();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const profileState = useSelector((state: NaTypes.Store) => state.profile);
    const [profileMenu, setProfileMenu] = useState({
        show: false,
        topOffset: 0,
        leftOffset: 0,
    });



    return (
        <>
            {accessData.accessToken === null ? (
                <div className="menu-container" onClick={() => dispatch(requestLogin())}>
                    <div className="rainbow_text_animated" id="login-button">ลงชื่อเข้าใช้ฟรี</div>
                </div>
            ) : (
                <div
                    className="menu-container profile-bt d-flex can-click"
                    onClick={(e) => {
                        const { offsetTop, offsetHeight, offsetLeft, offsetWidth } = e.currentTarget;
                        const topOffset = offsetTop + offsetHeight;
                        const leftOffset = offsetLeft + offsetWidth - 200 + 13;
                        setProfileMenu({
                            show: !profileMenu.show,
                            topOffset,
                            leftOffset,
                        });
                    }}
                >
                    <img
                        className="profile-image"
                        src={profileState.profile?.lowPhotoUrl || UserProfileIcon}
                        alt="Profile Image"
                    />
                    <p className="menu ml-2 rainbow_text_animated">
                        {profileState.profile &&
                            profileState.profile?.login.length >= 20
                            ? profileState.profile?.login.substring(0, 17) + '...'
                            : profileState.profile?.login}
                    </p>
                    <img className="ml-2" width="10" src={dropDownImg} alt="Dropdown Button" />
                    <div
                        className="card menu-profile position-absolute m-2"
                        style={{
                            top: `${profileMenu.topOffset}px`,
                            left: `${profileMenu.leftOffset}px`,
                            display: profileMenu.show ? 'unset' : 'none',
                        }}
                    >
                        <div className="menu-profile-text-container">
                            <Link to={`/profile/edit`}>
                                <p className="menu-profile-text font-weight-bold rainbow_text_animated">
                                    แก้ไขข้อมูลส่วนตัว
                                </p>
                            </Link>
                        </div>
                        <div className="menu-profile-text-container">
                            <Link to="/payment-history">
                                <p className="menu-profile-text font-weight-bold rainbow_text_animated">
                                    ประวัติการชำระค่าบริการ
                                </p>
                            </Link>
                        </div>
                        <div className="menu-profile-text-container">
                            <Link to="/payment-management">
                                <p className="menu-profile-text font-weight-bold rainbow_text_animated">
                                    จัดการข้อมูลการชำระเงิน
                                </p>
                            </Link>
                        </div>
                        <div className="menu-profile-border" />
                        <div
                            className="menu-profile-text-container"
                            onClick={() => {
                                dispatch(clearAccessData());
                                dispatch(resetLessonSet());
                                window.location.reload();
                            }}
                        >
                            <p className="menu-profile-text rainbow_text_animated">ออกจากระบบ</p>
                        </div>
                    </div>
                    {profileMenu.show && <div className="wrap"></div>}
                </div>
            )}
        </>
    );
};

export default HeaderBarUser;
