import {generateToken} from '../../utility/Token';
import {postTestAnswer} from '../../api/MiniTest';

const initialState: NaTypes.MiniTest = {
  pending: false,
  test: null,
  showScore: false,
  testAttempt: null,
};

const FETCH_MINITEST_PENDING = 'FETCH_MINITEST_PENDING';
const FETCH_MINITEST_SUCCESS = 'FETCH_MINITEST_SUCCESS';
const SELECT_ANSWER = 'SELECT_ANSWER';
const SHOW_SCORE = 'SHOW_SCORE';
const HIDE_SCORE = 'HIDE_SCORE';

const fetchMiniTestPending = () => {
  return {
    type: FETCH_MINITEST_PENDING,
  };
};

const fetchMiniTestSuccess = (test: NaTypes.Test, testAttempt: any) => {
  return {
    type: FETCH_MINITEST_SUCCESS,
    test,
    testAttempt,
  };
};

const selectAnswer = (questionNo: string, choiceId: string) => {
  return {
    type: SELECT_ANSWER,
    questionNo,
    choiceId,
  };
};

const showScore = () => {
  return {
    type: SHOW_SCORE,
  };
};

const hideScore = () => {
  return {
    type: HIDE_SCORE,
  };
};

/**
 * fetch mini test
 * @param {NaTypes.Authenticate} accessData
 * @param {string} subjectId
 * @param {string} grade
 * @param {string} topicId
 * @param {string} chapterId
 * @return {Function}
 */
function fetchMiniTest(accessData: NaTypes.Authenticate, subjectId: string, grade: string, topicId: string, chapterId: string): Function {
  return function(dispatch: any) {
    dispatch(fetchMiniTestPending());
    const url = `${process.env.REACT_APP_API_URL_V2}/v1/minitest/test`;
    const params = `?grade=${grade}&subject=${subjectId}&topic=${topicId}&chapter=${chapterId}&shuffle=${true}&limit=${4}`;
    fetch(url + params, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': generateToken(accessData.accountId!, accessData.accessToken!),
      },
    })
        .then((res) => res.json())
        .then((res) => {
          const test: NaTypes.Test = {
            testId: res.data.test_id,
            testQuestionList: res.data.questions.map((question: any): NaTypes.TestQuestion => ({
              testId: question.test_id,
              questionNo: question.question_no,
              description: question.description ?? '',
              descriptionTh: question.description_th ?? '',
              imageUrl: question.image_url ?? '',
              imageThUrl: question.image_th_url ?? '',
              choiceType: question.choice_type,
              choiceList: question.choice_list.map((choice: any): NaTypes.TestChoice => ({
                id: choice.id,
                description: choice.description ?? '',
                isCorrect: choice.is_correct === 1 ? true : false,
              })),
              selectedAnswer: '0',
              reasonText: question.reason_text,
              reasonImage: question.reason_image,
            })),
          };
          const questionList = test.testQuestionList.map((q: any) => q.questionNo).join(',');
          postTestAnswer(accessData, test.testId, questionList)
              .then((res: any) => {
                return dispatch(fetchMiniTestSuccess(test, res));
              });
        });
  };
}

export {
  fetchMiniTest,
  fetchMiniTestPending,
  fetchMiniTestSuccess,
  selectAnswer,
  showScore,
  hideScore,
};

/**
 * mini test state
 * @param {NaTypes.MiniTest} state
 * @param {any} action
 * @return {NaTypes.MiniTest} current state
 */
export function MiniTestState(
    state: NaTypes.MiniTest = initialState,
    action: any): NaTypes.MiniTest {
  switch (action.type) {
    case FETCH_MINITEST_PENDING:
      return Object.assign({}, state, {
        pending: true,
      });
    case FETCH_MINITEST_SUCCESS:
      return Object.assign({}, state, {
        pending: false,
        test: action.test,
        testAttempt: action.testAttempt,
      });
    case SELECT_ANSWER:
      const test = state.test;
      const index = test!.testQuestionList.findIndex((question) => question.questionNo === action.questionNo);
      test!.testQuestionList[index].selectedAnswer = action.choiceId;
      return Object.assign({}, state, {
        test,
      });
    case SHOW_SCORE:
      return Object.assign({}, state, {
        showScore: true,
      });
    case HIDE_SCORE:
      return Object.assign({}, state, {
        showScore: false,
      });
    default:
      return state;
  }
};
